import { FC, useRef } from "react"
import { v4 } from "uuid"
import style from "./LabelledToggle.module.css"

type Props = { currentValue: boolean; onChange: () => void; trueLabel: string; falseLabel: string }

export const LabelledToggle: FC<Props> = ({ currentValue, onChange, trueLabel, falseLabel }) => {
	const inputId = useRef(v4())
	return (
		<div style={{ marginTop: "20px", marginBottom: "20px" }}>
			<div className={style.btnContainer}>
				<label className={style.labelledToggleSwitch}>
					<input
						type="checkbox"
						id={inputId.current}
						value="1"
						defaultChecked={currentValue}
						onChange={() => onChange()}
					/>
					<label
						htmlFor={inputId.current}
						data-on={trueLabel}
						data-off={falseLabel}
						className={style.labelledToggleSwitchInner}
					/>
				</label>
			</div>
		</div>
	)
}
