export function objectOfError(error?: Error): any {
	if (!error) return null

	return {
		...error,
		message: error.message,
		stack: error.stack,
		cause: objectOfError(error.cause as Error) ?? null,
	}
}
