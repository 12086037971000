import { CSSProperties, FC, PropsWithChildren } from "react"
import style from "./MbtH5.module.css"

type Props = {
	styles?: CSSProperties
} & PropsWithChildren
export const MbtH5: FC<Props> = ({ children, styles }) => {
	return (
		<h5 className={style.mbtH5} style={styles}>
			{children}
		</h5>
	)
}
