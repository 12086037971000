import { getArticlePathName } from "../../Client/articleTrees/ArticleTreeResolver"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { Article } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import {
	DiscountExplanationTooltip,
	discountOneLineSummary,
} from "../../CustomerPortal/OrderDetails/ExplainDiscount/ExplainDiscount"
import { InformationAnnotationIcon } from "../Annotation/InformationAnnotationIcon"
import { cls } from "../cls"
import { getKey } from "../getKey"
import { currencyFormatter } from "../numberFormatter"
import style from "./DiscountEntry.module.css"

type DiscountBasketEntryProps = {
	discountArticle: Article
	className: string
	renderVAT?: boolean
}

/**
 * Renders a "line" or "entry" of a discount Article, it contains the name and the value of the discount.
 */
export function DiscountEntry({ discountArticle, className, renderVAT: extRenderVAT }: DiscountBasketEntryProps) {
	const consumerCatalog = useConsumerCatalog()
	const renderVAT = extRenderVAT !== undefined ? extRenderVAT : consumerCatalog.renderVAT

	let discount = discountArticle.price
	let taxDiscount = renderVAT ? discount * discountArticle.taxPercentage : 0
	const discountName =
		discountOneLineSummary(discountArticle.discountDescription, renderVAT ? discountArticle.taxPercentage : null, 0) ??
		getArticlePathName(discountArticle.articlePath)
	let key = getKey(discountArticle)
	let toolTipAnchor = `discountElements-${key}`
	return (
		<div key={key} className={className}>
			<span className={cls(toolTipAnchor, style.discountName)}>
				{discountName ?? "Rabatt"} <InformationAnnotationIcon />
			</span>
			<span className={cls(toolTipAnchor, style.discountPrice)}>{currencyFormatter(discount + taxDiscount)}</span>
			<DiscountExplanationTooltip
				discountDescription={discountArticle.discountDescription}
				anchorSelect={"." + toolTipAnchor}
				taxPercentage={renderVAT ? discountArticle.taxPercentage : null}
			/>
		</div>
	)
}
