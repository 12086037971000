import { WasteTypeImages } from "../../../ProductDefinitions"
import { cls } from "../../../Shared/cls"
import { WasteImage } from "../../../WasteImages/WasteImage"
import style from "./WasteTypeIcon.module.css"

type Props = { wasteType: WasteTypeImages; className?: string }

export const WasteTypeIcon = ({ wasteType, className }: Props) => {
	return <WasteImage wasteTypeImage={wasteType} className={cls(style.icon, className)} />
}
