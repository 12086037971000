import { faFileLines, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isNull } from "lodash"
import { convertTextToInitials } from "Orders/Helpers"
import { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import { v4 } from "uuid"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { MeResponseAccountType } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PenIcon, TrashIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import { EditConsumerDetails } from "../EditConsumerDetails/EditConsumerDetails"
import style from "./CustomerPortalConsumerInformation.module.css"

export type GetConsumer = {
	id: string
	consumerDetails: CompanyConsumerDetails | PrivateConsumerDetails
	state: ConsumerState
	files: GetCustomerFile[]
	profilePictureUrl?: string
	type: GetConsumerType
	stats?: GetConsumerStats
	customerNumber?: string
}

export type GetConsumerStats = {
	orderAmount: number
	userAmount: number
	inviteAmount: number
}

export enum GetConsumerType {
	Company = "Company",
	PrivatePerson = "PrivatePerson",
}

export enum ConsumerState {
	Active = "Active",
	Inactive = "Inactive",
	Removed = "Removed",
}

export enum GetCustomerFileUploadedByUserType {
	Consumer = "Consumer",
	Client = "Client",
}

type CustomerFile = {
	url: string
	originalFileName: string
	id: string
	uploadedByUserType: GetCustomerFileUploadedByUserType
}

type GetCustomerFileImage = CustomerFile & {
	type: "Image"
}

type GetCustomerFileDocument = CustomerFile & {
	type: "Document"
	fileType: string
}

type GetCustomerFile = GetCustomerFileImage | GetCustomerFileDocument

export type ConsumerDetails = {
	name: string
}

export type CompanyConsumerDetails = ConsumerDetails & {
	type: "CompanyConsumerDetails"
	orgNumber?: string
}

export type PrivateConsumerDetails = ConsumerDetails & {
	type: "PrivateConsumerDetails"
	personNumber?: string
}

type GetCustomerFileCustom = GetCustomerFile & {
	uploading?: boolean
	uploadFailed?: boolean
	tooBig?: boolean
}

type CustomerPortalConsumerInformationProps = {
	consumer: GetConsumer
	onInactivate: () => void
	onConsumerUpdated: () => void
	className?: string
}

export function getFile(e: ChangeEvent<HTMLInputElement>, accept?: string[]): (File & { tooBig?: boolean }) | null {
	e.preventDefault()
	if (e.target.files && e.target.files.length > 0) {
		const file: (File & { tooBig?: boolean }) | undefined = e.target.files[0]
		//TODO Refactor, we shouldn't cast and modify the "file type" used above here, also the old null check is used after file.type is accessed so that could blow up as well.
		if (file == null) {
			return null
		}

		if (accept && !accept.includes(file.type)) {
			return null
		}
		const maxSize = 1000 * 1000 * 10
		if (!isNull(file)) {
			if (file.size > maxSize) {
				file.tooBig = true
				return file
			} else {
				return file
			}
		}
	}
	return null
}

export const CustomerPortalConsumerInformation: FC<CustomerPortalConsumerInformationProps> = ({
	consumer,
	onInactivate,
	onConsumerUpdated,
	className,
}) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	const files = useRef<GetCustomerFileCustom[] | null>(consumer?.files || null)
	const [, setTick] = useState(0)
	const [showPfpTooBig, setShowPfpTooBig] = useState(false)
	const [edit, setEdit] = useState(false)
	const prevConsumer = useRef<GetConsumer | null>(null)

	useEffect(() => {
		if (consumer?.state === ConsumerState.Inactive && prevConsumer.current?.state === ConsumerState.Active) {
			onInactivate()
		}
		prevConsumer.current = consumer
		files.current = consumer?.files || null
		setTick((tick) => tick + 1)
	}, [consumer])

	function addFileForConsumer(e: ChangeEvent<HTMLInputElement>) {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Attachments_Create)) {
			return
		}

		const file = getFile(e)

		if (!file || !consumer) {
			return
		}
		const tempId = v4()
		files.current?.push({
			id: tempId,
			type: "Document",
			url: "",
			originalFileName: file.name,
			fileType: file.type,
			uploading: true,
			tooBig: file.tooBig,
			uploadedByUserType:
				auth.Me?.type === MeResponseAccountType.Client
					? GetCustomerFileUploadedByUserType.Client
					: GetCustomerFileUploadedByUserType.Consumer,
		})
		setTick((tick) => tick + 1)

		if (!file.tooBig) {
			uploadFileForConsumer(file, consumer, tempId)
		}
	}

	function addProfilePictureForConsumer(e: ChangeEvent<HTMLInputElement>, accept?: string[]) {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Information_Profile_Picture_Edit)) {
			return
		}

		const file = getFile(e, accept)

		if (!file || !consumer) {
			return
		}

		if (file.tooBig) {
			setShowPfpTooBig(true)
		} else {
			uploadProfilePictureForConsumer(file, consumer)
		}
	}

	function uploadFileForConsumer(file: File, customer: GetConsumer, tempId: string) {
		const newForm = new FormData()
		newForm.append("file", file)
		API.postWithRetries(
			`/customer-portal/consumers-v1/${client.identifier}/upload-file/${customer.id}`,
			newForm,
			undefined,
			30,
			"Raw",
		)
			.then(() => {
				onConsumerUpdated()
			})
			.catch(() => {
				if (files.current?.find((x) => x.id === tempId)) {
					files.current.find((x) => x.id === tempId)!.uploadFailed = true
				}
				setTick((tick) => tick + 1)
			})
	}

	function uploadProfilePictureForConsumer(file: File, customer: GetConsumer) {
		const newForm = new FormData()
		newForm.append("file", file)
		setShowPfpTooBig(false)
		API.postWithRetries(
			`/customer-portal/consumers-v1/${client.identifier}/profile-picture/${customer.id}`,
			newForm,
			undefined,
			30,
			"Raw",
		)
			.then(() => {
				onConsumerUpdated()
			})
			.catch(() => {
				setTick((tick) => tick + 1)
			})
	}

	function removeFileForConsumer(fileId: string) {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Attachments_Delete)) {
			return
		}

		if (!window.confirm("Är du säker att du vill ta bort filen?")) {
			return
		}

		const file = files.current?.find((x) => x.id === fileId)
		if (file) {
			file.uploading = true
		}
		setTick((tick) => tick + 1)
		API.delete(`/customer-portal/consumers-v1/${client.identifier}/delete-file/${consumer.id}/${fileId}`)
			.then(() => {
				onConsumerUpdated()
			})
			.catch(() => {
				setTick((tick) => tick + 1)
			})
	}

	function headerAndEditRow(): JSX.Element {
		let penIcon

		if (
			auth.IsLoggedInClient &&
			permissions.isAllowed(PermissionAreaLocation.Client_Consumer_Information_Update) &&
			consumer?.state === ConsumerState.Active
		) {
			penIcon = (
				<PenIcon
					size={22}
					iconClassName={style.editIcon}
					onClick={() => {
						setEdit(true)
					}}
				/>
			)
		}

		return (
			<div className={style.headerAndEditRow}>
				<span className={style.headerText}>Kunduppgifter</span>
				<span>{penIcon}</span>
			</div>
		)
	}

	function profilePicRow(customer: GetConsumer) {
		const id = v4()
		return (
			<div className={style.profilePicRow}>
				<span className={style.profilePic}>
					{customer.profilePictureUrl ? (
						<img
							src={customer.profilePictureUrl}
							onClick={() => {
								const url = customer.profilePictureUrl
								if (url) {
									window.open(url, "_blank")
								}
							}}
							onError={() => {
								customer.profilePictureUrl = undefined
								setTick((tick) => tick + 1)
							}}
							alt="Profilbild"
						/>
					) : (
						<div className={style.noProfilePic}>{convertTextToInitials(customer.consumerDetails.name)}</div>
					)}
				</span>
				{customer.state === ConsumerState.Active &&
				permissions.isAllowed(PermissionAreaLocation.Consumer_Information_Profile_Picture_Edit) ? (
					<span style={{ display: "flex", flexDirection: "column" }}>
						<label htmlFor={id} className={style.uploadProfilePictureButton}>
							<span className={style.text}>Ladda upp profilbild</span>
							<span className={style.icon}>+</span>
						</label>
						<input
							id={id}
							style={{ display: "none" }}
							type="file"
							accept=".png,.jpeg,.jpg"
							multiple={true}
							onChange={(e) => {
								addProfilePictureForConsumer(e, ["image/png", "image/jpg", "image/jpeg"])
							}}
						/>
						{showPfpTooBig ? (
							<div style={{ color: "var(--invalid-color)", fontWeight: 500 }}>För stor bild, max 10MB</div>
						) : null}
					</span>
				) : null}
			</div>
		)
	}

	function uploadedFiles() {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Attachments_View)) {
			return null
		}

		return (
			<div className={style.uploadedFiles}>
				<div className={style.header}>Uppladdade bilagor</div>
				<div className={style.filesContainer}>
					{files.current?.map((file, index) => {
						let iconElement

						if (file.tooBig || file.uploadFailed) {
							iconElement = (
								<div className={style.spinner}>
									<FontAwesomeIcon
										icon={faXmark}
										style={{ color: "var(--invalid-color)" }}
										onClick={() => {
											files.current?.splice(index, 1)
											setTick((tick) => tick + 1)
										}}
									/>
								</div>
							)
						} else if (file.uploading) {
							iconElement = (
								<div className={style.spinner}>
									<FontAwesomeIcon icon={faSpinner} spin={true} />
								</div>
							)
						} else {
							if (
								!permissions.isAllowed(PermissionAreaLocation.Consumer_Attachments_Delete) ||
								(file.uploadedByUserType === GetCustomerFileUploadedByUserType.Client &&
									auth.Me?.type !== MeResponseAccountType.Client)
							) {
								iconElement = null
							} else {
								iconElement = (
									<TrashIcon
										size={22}
										className={style.trashIcon}
										onClick={(e) => {
											e?.stopPropagation()
											removeFileForConsumer(file.id)
										}}
									/>
								)
							}
						}

						return (
							<div
								key={v4()}
								className={style.fileContainer}
								onClick={() => {
									if (!file.uploading) {
										window.open(file.url, "_blank")
									}
								}}>
								{iconElement}
								{file.type === "Image" ? (
									<img
										alt={file.originalFileName}
										className={style.image}
										key={file.url}
										src={file.url}
										style={{ marginBottom: "5px" }}
									/>
								) : (
									<div
										key={file.url}
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											marginBottom: "5px",
										}}>
										<FontAwesomeIcon
											style={{
												color: "var(--module-box-icon-color)",
												width: "70%",
												height: "70%",
											}}
											icon={faFileLines}
										/>
									</div>
								)}
								{file.tooBig ? (
									<div
										style={{
											color: "var(--invalid-color)",
											fontWeight: 500,
											fontSize: "14px",
										}}>
										För stor fil, max 10MB
									</div>
								) : file.uploadFailed ? (
									<div
										style={{
											color: "var(--invalid-color)",
											fontWeight: 500,
											fontSize: "14px",
										}}>
										Uppladdning misslyckades
									</div>
								) : (
									<span className={cls(style.fileName, style.twoLineClamp)} title={file.originalFileName}>
										{file.originalFileName}
									</span>
								)}
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Information_View)) {
		return null
	}

	return (
		<>
			{consumer && edit && auth.IsLoggedInClient ? (
				<EditConsumerDetails
					consumer={consumer}
					onClose={() => setEdit(false)}
					onDone={() => {
						setEdit(false)
						onConsumerUpdated()
					}}
				/>
			) : null}
			<div className={cls(style.wrapper, className)}>
				{!consumer ? (
					<AbsolutCentered>
						<Loader />
					</AbsolutCentered>
				) : (
					<>
						{headerAndEditRow()}
						{profilePicRow(consumer)}
						{consumer.state === ConsumerState.Inactive ? (
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<div className={style.headerText} style={{ fontSize: "22px" }}>
									Inaktiverad kund
								</div>
							</div>
						) : (
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<div className={cls(style.headerText, style.consumerTypeText)}>
									{consumer.consumerDetails.type === "CompanyConsumerDetails"
										? "Företagskund"
										: "Privatpersonskund"}
								</div>
							</div>
						)}
						<hr style={{ marginTop: "5px", marginBottom: "15px" }} />
						<div className={style.textInputWithLabel}>
							<label>
								{consumer.consumerDetails.type === "CompanyConsumerDetails" ? "Företagsnamn" : "Namn"}
							</label>
							<span className={style.textAsInput}>{consumer.consumerDetails.name}</span>
						</div>
						<div className={style.textInputWithLabel}>
							<label>
								{consumer.consumerDetails.type === "CompanyConsumerDetails"
									? "Organisationsnummer"
									: "Personnummer"}
							</label>
							{consumer.consumerDetails.type === "CompanyConsumerDetails" ? (
								<span className={style.textAsInput}>{consumer.consumerDetails.orgNumber}</span>
							) : (
								<span className={style.textAsInput}>{consumer.consumerDetails.personNumber}</span>
							)}
						</div>
						<div className={style.textInputWithLabel}>
							<label>Kundnummer</label>
							<span className={style.textAsInput}>{consumer.customerNumber}</span>
						</div>

						<hr style={{ marginTop: "30px", marginBottom: "30px" }} />
						{uploadedFiles()}
						{consumer.state === ConsumerState.Active &&
						permissions.isAllowed(PermissionAreaLocation.Consumer_Attachments_Create) ? (
							<div className={style.fileInput}>
								<label htmlFor={"uploadCustomerFile"}>
									<span>Ladda upp bilaga</span>
									<span>+</span>
								</label>

								<input
									id={"uploadCustomerFile"}
									type="file"
									multiple={false}
									onChange={(e) => {
										addFileForConsumer(e)
									}}
								/>
							</div>
						) : null}
					</>
				)}
			</div>
		</>
	)
}
