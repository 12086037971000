import { useCallback, useState } from "react"

/**
 * Rethrow an Error in the main render thread of React.
 * This is usefull to trigger ErrorBoundry or similar from an async task.
 */
export const useThrowAsync = () => {
	const [, setError] = useState()
	return useCallback(
		(e: Error) => {
			setError(() => {
				throw e
			})
		},
		[setError],
	)
}
