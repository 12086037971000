/**
 * Helper to create filter notNull in a type safe manner
 * ```
 * const ["a", null].filter(notNull)
 * // Same as
 * const ["a", null].filter((x):x is string => x !== null)
 * ```
 *
 * @param value
 */
export function notNull<T>(value: T): value is NonNullable<T> {
	return value !== null
}
