import { FC, MouseEventHandler, PropsWithChildren } from "react"
import { cls } from "Shared/cls"
import style from "./FieldModuleBox.module.css"

type Props = PropsWithChildren & {
	selected?: boolean
	onClick?: () => void
	className?: string
	onMouseEnter?: MouseEventHandler<HTMLElement>
	onMouseLeave?: MouseEventHandler<HTMLElement>
	hoverAndClick?: "propagate" | "no-propagate"
}

export const FieldModuleBox: FC<Props> = ({
	selected = false,
	children,
	onClick = () => {},
	className,
	onMouseEnter,
	onMouseLeave,
	hoverAndClick = "propagate",
}) => {
	const content = (
		<div onClick={onClick} className={cls(style.projectFieldModuleBox, { [style.selected]: selected }, className)}>
			{children}
		</div>
	)

	if (hoverAndClick === "no-propagate") {
		return (
			<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				{content}
			</div>
		)
	}
	return (
		<label onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			{content}
		</label>
	)
}
