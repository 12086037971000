import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { FC, useRef, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import {
	ConsumerBasicInvoicingInformation,
	ConsumerInvoicingInformation,
} from "../CustomerPortalConsumerInvoicingInformation/CustomerPortalConsumerInvoicingInformation"
import style from "./EditConsumerInvoicingInformation.module.css"

type EditConsumerInvoicingInformationProps = {
	consumerId: string
	invoicingInformation: ConsumerInvoicingInformation | null
	onClose: () => void
	onDone: () => void
}

export const ConsumerInvoicingAddressSchema = z.object({
	street: z.string().min(1),
	city: z.string().min(1),
	zipCode: z.string().min(1),
})

export const ConsumerBasicInvoicingInformationSchema = z.object({
	type: z.literal("Basic"),
	email: z.string().email(),
	address: ConsumerInvoicingAddressSchema.required(),
})

type UpdateConsumerBasicInvoicingInformation = {
	invoiceInformation: ConsumerBasicInvoicingInformation
}

export const EditConsumerInvoicingInformation: FC<EditConsumerInvoicingInformationProps> = (
	props: EditConsumerInvoicingInformationProps,
) => {
	const client = useClient()
	const permissions = usePermissions()

	const [submitting, setSubmitting] = useState<boolean>(false)
	const formRef = useRef<HTMLFormElement | null>(null)

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<ConsumerBasicInvoicingInformation>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(ConsumerBasicInvoicingInformationSchema)(data, context, options)
		},
		defaultValues: {
			type: "Basic",
			...(props.invoicingInformation || {}),
		},
	})

	const onSubmit: SubmitHandler<ConsumerBasicInvoicingInformation> = async (data) => {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Invoicing_Information_Update)) {
			return
		}

		setSubmitting(true)
		let req: UpdateConsumerBasicInvoicingInformation = {
			invoiceInformation: {
				type: "Basic",
				email: data.email,
				address: data.address,
			},
		}

		API.postWithRetries(
			`/customer-portal/consumers-v1/invoicing-information/${client.identifier}/${props.consumerId}/basic`,
			req,
		)
			.then(() => {
				props.onClose()
				props.onDone()
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Invoicing_Information_Update)) {
		return null
	}

	return (
		<ModulePopup onClose={props.onClose} className={style.modal} disableClose={submitting}>
			<div className={style.wrapper}>
				<div className={style.header}>Redigera faktureringsuppgifter</div>

				<form className={style.form} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
					<div className={orderConfirmStyle.fields}>
						<label>
							<SbtH4>E-post*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("email")}
								placeholder="exempel@exempel.se"
							/>
							<SbtRHFError error={errors.email} />
						</label>
						<label>
							<SbtH4>Gata*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("address.street")}
								placeholder="Gatan 3"
							/>
							<SbtRHFError error={errors.address?.street} />
						</label>
						<label>
							<SbtH4>Postnummer*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("address.zipCode")}
								placeholder="123 45"
							/>
							<SbtRHFError error={errors.address?.zipCode} />
						</label>
						<label>
							<SbtH4>Postort*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("address.city")}
								placeholder="Stockholm"
							/>
							<SbtRHFError error={errors.address?.city} />
						</label>
					</div>
					<p className={orderConfirmStyle.mandatoryFieldInformation}>*Obligatoriskt fält</p>
				</form>

				<FinalizeButton
					className={style.finalize}
					onClick={() => {
						formRef.current?.requestSubmit()
					}}
					disabled={!isValid || submitting}
					type="submit">
					Spara
				</FinalizeButton>
			</div>
		</ModulePopup>
	)
}
