export type Features = {
	orderUiShowOrgNumber: boolean
	pwaDisplay: string
	orderUiRenderTransportZones: boolean
	orderUIProductImagesWithLogos: boolean
	orderUIShowSidebar: boolean
	allowKlarnaIntegration: boolean
	allowExactOrderDeliveryLocation: boolean
	orderUiAllowUploadingOrderImages: boolean
	orderUiAllowAnonymousOrders: boolean
	orderUiAllowLoggingIn: boolean
	allowUserInvitesToBeSentWithSms: boolean
	orderUiLoginPlacement: OrderUILoginPlacement
	orderActivityRenderMode: OrderActivityRenderMode
	orderUiOrderReferencesMode: OrderReferenceMode
	discountCodeMode: DiscountCodeMode
	notificationsEnabled: boolean
	orderUiProductSelectionMode: ProductSelectionMode
}

export enum OrderUILoginPlacement {
	Hide = "Hide",
	HeaderRight = "HeaderRight",
	SideMenuBottom = "SideMenuBottom",
}

export enum OrderActivityRenderMode {
	NoRender = "NoRender",
	WithoutChat = "WithoutChat",
	WithChat = "WithChat",
}

export enum OrderReferenceMode {
	Hide = "Hide",
	ShowExternalReference = "ShowExternalReference",
}

export enum DiscountCodeMode {
	Hide = "Hide",
	AllowEntry = "AllowEntry",
	//AllowCreation = "AllowCreation",
}

export enum ProductSelectionMode {
	Modal = "Modal",
	FullPage = "FullPage",
}
