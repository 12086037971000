import {
	DatumtidIcon as DatetimeIcon,
	KommentarIcon as CommentIcon,
	KontaktIcon as ContactIcon,
	MarkingIcon as TagIcon,
	OrganisationsnummerIcon as OrganizationIcon,
	PlatsIcon as LocationIcon,
	TelefonIcon as PhoneIcon,
} from "../../../Icons/Icon"
import { useDesktop } from "../../../Responsiveness/Responsiveness"
import { Contact, OrderFromAPI } from "../../OrderCollection"
import { InfoBlobWithIcon } from "../InfoBlob/InfoBlobWithIcon"
import style from "./DisplayMeta.module.css"
import { dayOfWeek, getDeliveryTime, parseAddress } from "./Helpers"

type Props = {
	order: OrderFromAPI
}

export const DisplayMeta = ({ order }: Props) => {
	const [street, address] = parseAddress(order.address)

	const contacts: Contact[] = []
	if (order.contact != null) {
		contacts.push(order.contact)
	}

	if (order.contactPersons != null) {
		order.contactPersons.forEach((contact) => {
			contacts.push(contact)
		})
	}

	return (
		<div>
			{useDesktop() ? <div className={style.metaHeading}>Kundinformation</div> : null}
			<table className={style.table}>
				<tbody className={style.tableBody}>
					<tr className={style.listRow}>
						<td className={style.listCell}>
							<InfoBlobWithIcon
								icon={<OrganizationIcon iconClassName={style.iconColor} />}
								firstLine={order.customer.name}
								secondLine={order.customer.id}
							/>
						</td>
						<td className={style.listCell}>
							<InfoBlobWithIcon
								icon={<ContactIcon iconClassName={style.iconColor} />}
								firstLine={order.customer.email}
								secondLine={order.customer.phone}
							/>
						</td>
					</tr>
					<tr className={style.listRow}>
						<td className={style.listCell}>
							<InfoBlobWithIcon
								icon={<LocationIcon iconClassName={style.iconColor} />}
								firstLine={street}
								secondLine={address}
							/>
						</td>
						{contacts.length === 1 ? (
							<td className={style.listCell}>
								<InfoBlobWithIcon
									icon={<PhoneIcon iconClassName={style.iconColor} />}
									firstLine={contacts[0].name}
									secondLine={contacts[0].phone}
								/>
							</td>
						) : null}
					</tr>
					{contacts.length > 1
						? contacts.map((contact) => {
								return (
									<tr className={style.listRow}>
										<td className={style.listCell}>
											<InfoBlobWithIcon
												icon={<PhoneIcon iconClassName={style.iconColor} />}
												firstLine={contact.name}
												secondLine={contact.phone}
											/>
										</td>
									</tr>
								)
						  })
						: null}
					<tr className={style.listRow}>
						<td className={style.listCell}>
							<InfoBlobWithIcon
								icon={<DatetimeIcon iconClassName={style.iconColor} />}
								firstLine={`${order.datetime.date ? dayOfWeek(order.datetime.date) : ""} ${getDeliveryTime(
									order.datetime,
								)}`}
								secondLine={
									order.datetime.date
										? order.datetime.date.toString()
										: order.datetime.dateSlot
										? order.datetime.dateSlot.fromDate === order.datetime.dateSlot.toDate
											? `${order.datetime.dateSlot.fromDate}`
											: `${order.datetime.dateSlot.fromDate} - ${order.datetime.dateSlot.toDate}`
										: ""
								}
							/>
						</td>
						<td className={style.listCell}>
							<InfoBlobWithIcon
								icon={<TagIcon iconClassName={style.iconColor} />}
								firstLine={order.marking}
							/>
						</td>
					</tr>
					<tr className={style.listRow}>
						<td colSpan={2} className={style.listCell}>
							<InfoBlobWithIcon
								icon={<CommentIcon iconClassName={style.iconColor} />}
								firstLine={order.comment}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
