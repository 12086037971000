//TODO Should we change the locale perhaps?
const numberFormat = new Intl.NumberFormat("sv-SE", {
	signDisplay: "auto", // 'negative' we want, but that is an experimental feature still.
	style: "decimal",
	minimumFractionDigits: 0,
	maximumFractionDigits: 3,
})

const currencyFormat = new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" })

/**
 * Reusable number formatter that, that for now uses Swedish locale by default.
 * @param value a number to format
 * @returns the formatted value as a string
 */
export const numberFormatter = (value: number | null) => {
	if (value === null) {
		return null
	}

	return numberFormat.format(value)
}

/**
 * Reusable currency formatter that, that for now uses Swedish locale and SEK by default.
 * @param value a number to format
 * @returns the formatted value as a string
 */
export const currencyFormatter = (value: number | null) => {
	if (value === null) {
		return null
	}

	if (value === 0) {
		return "Gratis"
	}

	return currencyFormat.format(value)
}
