import { LoginResponse } from "Auth/Auth.types"
import { useClient } from "Client/ClientAndUserProvider"
import { isArray, isUndefined } from "lodash"
import { HappyCustomer } from "Lottie/AnimationComponents"
import { MbactH4 } from "Orders/Components/Text/MbactH4/MbactH4"
import { SbtH1 } from "Orders/Components/Text/SbtH1/SbtH1"
import { FC } from "react"
import { Countdown } from "Shared/Countdown/Countdown"
import { when } from "Shared/when"
import { z } from "zod"
import { useAuth } from "../../../Auth/AuthContext"
import { useNavigator } from "../../../Navigator/useNavigator"
import { useBrandedLocalStorage } from "../../../Shared/useBrandedLocalStorage"

export enum CompleteType {
	CreatedNewUser,
	AddedConnection,
}

export const ConnectionCompleted: FC<{
	completeType: CompleteType
	consumerName: string
	loginDetails: LoginResponse | undefined
}> = ({ completeType, consumerName, loginDetails }) => {
	const auth = useAuth()
	const client = useClient()
	const navigator = useNavigator()
	const [, setSelectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const login = () => {
		if (auth.IsLoggedIn) {
			auth.refreshMeData().then(() => {
				const consumers = auth.Me?.consumers
				if (isArray(consumers) && consumers.length > 0) {
					setSelectedConsumerId(consumers[consumers.length - 1]?.id || "")
				}
				navigator.open("order")
			})
		} else if (!isUndefined(loginDetails)) {
			auth.setLoggedInFromResponse(loginDetails, client.identifier).then(() => {
				navigator.open("order")
			})
		}
	}
	return (
		<div>
			{when(completeType, {
				[CompleteType.CreatedNewUser]: () => (
					<SbtH1 styles={{ marginBottom: "1rem", textAlign: "center" }}>Ditt konto är skapat!</SbtH1>
				),
				[CompleteType.AddedConnection]: () => (
					<SbtH1 styles={{ marginBottom: "1rem", textAlign: "center" }}>Koppling är skapad!</SbtH1>
				),
			})}

			<div>
				<div style={{ width: "70%", height: "70%", margin: "auto" }}>
					<HappyCustomer color={client.branding.colors.accent} />
				</div>
				<div style={{ marginBottom: "1rem", textAlign: "center" }}>
					<MbactH4>Välkommen! Nu kan du boka våra tjänster via:</MbactH4>
					<MbactH4 styles={{ marginTop: "1rem" }}>{consumerName}</MbactH4>
					<MbactH4 styles={{ marginTop: "2rem" }}>
						Du omdirigeras till bokningssidan om... <Countdown count={6} done={login} />
					</MbactH4>
				</div>
			</div>
		</div>
	)
}
