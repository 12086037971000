import { exhaustive } from "exhaustive"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useClient } from "../Client/ClientAndUserProvider"
import { Loader } from "../Loader/Loader"
import { Demo1Intro } from "./Demo1Intro"
import { Demo2Start } from "./Demo2Start"
import { Demo3End } from "./Demo3End"
import { Demo4OptionalAddress } from "./Demo4OptionalAddress"
import { Demo5ThankYou } from "./Demo5ThankYou"
import { DemoLayout } from "./DemoLayout"
import { useDemoState } from "./demoState"

export function DemoRouter() {
	const client = useClient()
	const navigation = useNavigate()
	let [searchParams] = useSearchParams()

	let [demoState, setDemoState, removeDemoState] = useDemoState(client.identifier ?? "demo-start-default")

	useEffect(() => {
		function navigateToReturn() {
			const returnPath = searchParams.get("return") ?? `/${client.identifier}`
			navigation(returnPath, { replace: true })
		}

		if (!client.isDemo()) {
			removeDemoState()
			navigateToReturn()
		} else {
			if (demoState == null) {
				setDemoState("intro")
			}

			if (demoState === "demoing" || demoState === "done") {
				navigateToReturn()
			}
		}
	}, [client, demoState, navigation, removeDemoState, searchParams, setDemoState])

	const component = exhaustive(demoState ?? "loading", {
		intro: () => {
			return <Demo1Intro setDemoState={setDemoState} />
		},
		"start-survey": () => {
			return <Demo2Start setDemoState={setDemoState} />
		},
		demoing: () => {
			return <Loader />
		},
		"end-survey": () => {
			return <Demo3End setDemoState={setDemoState} />
		},
		"optional-address": () => {
			return <Demo4OptionalAddress setDemoState={setDemoState} />
		},
		"thank-you": () => {
			return <Demo5ThankYou setDemoState={setDemoState} />
		},
		done: () => {
			return <Loader />
		},
		loading: () => {
			return <Loader />
		},
	})

	return (
		<>
			{/*<div>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("intro")}>*/}
			{/*		1-intro*/}
			{/*	</button>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("start-survey")}>*/}
			{/*		2-start*/}
			{/*	</button>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("demoing")}>*/}
			{/*		3-demoing*/}
			{/*	</button>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("end-survey")}>*/}
			{/*		4-end*/}
			{/*	</button>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("optional-address")}>*/}
			{/*		5-optional*/}
			{/*	</button>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("thank-you")}>*/}
			{/*		6-thank*/}
			{/*	</button>*/}
			{/*	<button type={"button"} onClick={() => setDemoState("done")}>*/}
			{/*		7-done*/}
			{/*	</button>*/}
			{/*</div>*/}
			<DemoLayout>{component}</DemoLayout>
		</>
	)
}
