import { ReactNode } from "react"
import { ReactComponent as SkrappyLogo } from "../Orders/Layout/SkrappyLogo.svg"
import style from "./DemoStyle.module.css"

export function DemoLayout({ children }: { children: ReactNode }) {
	return (
		<div className={style.background}>
			<div className={style.container}>
				<div className={style.logo}>
					<SkrappyLogo />
				</div>
				{children}
			</div>
		</div>
	)
}
