import { faCircleUser } from "@fortawesome/free-regular-svg-icons"
import { faAngleDown, faAngleUp, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { cloneDeep, debounce } from "lodash"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
	ConsumerState,
	GetConsumer,
	GetConsumerType,
} from "../../CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { Paging, setPageAndFilterInUrl } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import customerPortalOrdersStyle from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders.module.css"
import { CrossIcon, EmailIcon, FiltreraIcon, ListaOrdersIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { addModalOpenClass, removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { convertTextToInitials, parseNumberSafe } from "../../Orders/Helpers"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { RelativeCentered } from "../../RelativeCentered/RelativeCentered"
import { cls } from "../../Shared/cls"
import {
	rowsPerPageSection,
	selectedFilters,
	SkrappyTable,
	TableColumn,
	tablePagingSection,
} from "../../Shared/SkrappyTable/SkrappyTable"
import { ClientPortalNewConsumerModal } from "../ClientPortalNewConsumerModal/ClientPortalNewConsumerModal"
import {
	ConsumerListFilterDataSchema,
	ConsumerListFilterDataSchemaType,
	ConsumerListFilterSidebar,
	OrderByType,
	SortByDirection,
} from "../ConsumerListFilterSidebar/ConsumerListFilterSidebar"
import style from "./ClientPortalConsumerList.module.css"

type ClientPortalConsumerListProps = {
	dataLoading: boolean
	consumers: GetConsumer[]
	paging: Paging | null
	selectConsumer: (consumer: GetConsumer) => void
	setNewConsumer: (newConsumer: GetConsumer) => void
}

export const ClientPortalConsumerList: FC<ClientPortalConsumerListProps> = ({
	dataLoading,
	consumers,
	paging,
	selectConsumer,
	setNewConsumer,
}) => {
	const permissions = usePermissions()

	const [, setTick] = useState(0)

	const [queryParams, setQueryParams] = useSearchParams({ page: "1", pageSize: "20", filters: JSON.stringify({}) })
	const [textSearchValue, setTextSearchValue] = useState<string>("")

	const [showFilterSidebar, setShowFilterSidebar] = useState(false)

	const searchInputRef = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		searchInputRef.current?.focus()
	}, [consumers])

	useEffect(() => {
		let filters = getActiveFilters()

		if (filters.searchText) {
			setTextSearchValue(filters.searchText)
		} else if (textSearchValue) {
			setTextSearchValue("")
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams])

	const filterRowsByTextDebounced = useCallback(
		debounce((text: string | null, data: ConsumerListFilterDataSchemaType) => {
			if (text !== null) {
				data.searchText = text
			} else {
				delete data.searchText
			}
			setPageAndFilterInUrl(1, null, data, setQueryParams)
		}, 1000),
		[],
	)

	function getPageNumber() {
		return parseNumberSafe(queryParams.get("page"), 1)
	}

	function getPageSize() {
		return parseNumberSafe(queryParams.get("pageSize"), 20)
	}

	function getActiveFilters(): ConsumerListFilterDataSchemaType {
		const ret = ConsumerListFilterDataSchema.safeParse(JSON.parse(queryParams.get("filters") ?? "{}"))

		if (ret.success) {
			return ret.data
		} else {
			return {}
		}
	}

	const newConsumerHasBeenCreated = (newConsumer: GetConsumer) => {
		setNewConsumer(newConsumer)
	}

	function consumerStateToHumanText(state: ConsumerState) {
		return exhaustive(state, {
			[ConsumerState.Active]: () => "Aktiv",
			[ConsumerState.Inactive]: () => "Inaktiv",
			[ConsumerState.Removed]: () => "Borttagen",
		})
	}

	function setStatsOrder(type: OrderByType) {
		const filters = getActiveFilters()
		let sortBy

		if (!filters.sortBy || filters.sortBy.type !== type) {
			sortBy = {
				type: type,
				direction: SortByDirection.DESC,
			}
		} else {
			if (filters.sortBy?.direction === SortByDirection.DESC) {
				sortBy = {
					type: type,
					direction: SortByDirection.ASC,
				}
			} else if (filters.sortBy?.direction === SortByDirection.ASC) {
				sortBy = undefined
			}
		}

		filters.sortBy = sortBy

		setPageAndFilterInUrl(getPageNumber(), getPageSize(), filters, setQueryParams)
	}

	function columnSortingArrows(type: OrderByType) {
		const filters = getActiveFilters()
		return (
			<span style={{ display: "flex", flexDirection: "column" }}>
				<FontAwesomeIcon
					icon={faAngleUp}
					style={{
						color:
							!filters.sortBy ||
							filters.sortBy?.type !== type ||
							filters.sortBy?.direction === SortByDirection.ASC
								? undefined
								: "transparent",
					}}
				/>
				<FontAwesomeIcon
					icon={faAngleDown}
					style={{
						color:
							!filters.sortBy ||
							filters.sortBy?.type !== type ||
							filters.sortBy?.direction === SortByDirection.DESC
								? undefined
								: "transparent",
					}}
				/>
			</span>
		)
	}

	function tableColumns(): TableColumn<GetConsumer>[] {
		return [
			{
				headerContent: "Kund",
				headerClass: style.consumerColumn,
				cellContent: {
					content: (data) => (
						<div style={{ display: "flex", gap: "10px" }}>
							<div className={style.consumerListItemPic}>
								{data.profilePictureUrl ? (
									<img
										src={data.profilePictureUrl}
										onError={() => {
											data.profilePictureUrl = undefined
											setTick((tick) => tick + 1)
										}}
										alt="Profilbild"
									/>
								) : (
									<div className={style.noProfilePic}>
										{convertTextToInitials(data.consumerDetails.name)}
									</div>
								)}
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<strong>{data.consumerDetails.name}</strong>
								<span>
									{exhaustive(data.consumerDetails, "type", {
										CompanyConsumerDetails: (it) => {
											return it.orgNumber
										},
										PrivateConsumerDetails: (it) => {
											return it.personNumber
										},
									})}
								</span>
							</div>
						</div>
					),
					className: () => style.consumerCell,
				},
			},
			{
				headerContent: "Kundnr",
				cellContent: {
					content: (data) => <strong>{data.customerNumber}</strong>,
					className: () => style.customerNumberCell,
				},
			},
			{
				headerContent: "Kundtyp",
				cellContent: {
					content: (data) => (
						<div className={style.customerType}>
							{exhaustive(data.consumerDetails, "type", {
								CompanyConsumerDetails: () => {
									return "Företag"
								},
								PrivateConsumerDetails: () => {
									return "Privatperson"
								},
							})}
						</div>
					),
					className: () => style.consumerTypeCell,
				},
			},
			{
				headerContent: "Status\xa0",
				cellContent: {
					content: (data) => <strong>{consumerStateToHumanText(data.state)}</strong>,
					className: () => style.consumerStateCell,
				},
			},
			{
				headerOnClick: () => {
					setStatsOrder(OrderByType.Orders)
				},
				headerClass: cls(style.textWithIconColumn, style.clickableColumn),
				headerContent: (
					<span style={{ display: "flex", alignItems: "center", gap: "15px", paddingRight: "5px" }}>
						Ordrar
						{columnSortingArrows(OrderByType.Orders)}
					</span>
				),
				cellContent: {
					content: (data) => (
						<div className={style.tableIconAndTextWrapper}>
							<ListaOrdersIcon className={style.tableIcon} size={24} />
							<span className={style.numberCircle}>{data.stats ? data.stats.orderAmount : 0}</span>
						</div>
					),
					className: () => style.orderAmountCell,
				},
			},
			{
				headerOnClick: () => {
					setStatsOrder(OrderByType.Users)
				},
				headerClass: cls(style.textWithIconColumn, style.clickableColumn),
				headerContent: (
					<span style={{ display: "flex", alignItems: "center", gap: "15px", paddingRight: "5px" }}>
						Användare
						{columnSortingArrows(OrderByType.Users)}
					</span>
				),
				cellContent: {
					content: (data) => (
						<div className={style.tableIconAndTextWrapper}>
							<FontAwesomeIcon className={style.tableIcon} icon={faCircleUser} />
							<span className={style.numberCircle}>{data.stats ? data.stats.userAmount : 0}</span>
						</div>
					),
					className: () => style.userAmountCell,
				},
			},
			{
				headerOnClick: () => {
					setStatsOrder(OrderByType.Invites)
				},
				headerClass: cls(style.textWithIconColumn, style.clickableColumn),
				headerContent: (
					<span style={{ display: "flex", alignItems: "center", gap: "15px", paddingRight: "10px" }}>
						Inbjudningar
						{columnSortingArrows(OrderByType.Invites)}
					</span>
				),
				cellContent: {
					content: (data) => (
						<div className={style.tableIconAndTextWrapper}>
							<EmailIcon className={style.tableIcon} size={24} />
							<span className={style.numberCircle}>{data.stats ? data.stats.inviteAmount : 0}</span>
						</div>
					),
					className: () => style.inviteAmountCell,
				},
			},
		]
	}

	function getFilterDataValue(
		key: keyof ConsumerListFilterDataSchemaType,
		value: string | string[] | number | boolean | object,
	): string | number {
		return exhaustive(key, {
			name: () => (value as string[]).join(", "),
			customerNumber: () => "Kundnr: " + (value as string[]).join(", "),
			consumerType: () => (value === GetConsumerType.PrivatePerson ? "Privatpersoner" : "Företag"),
			state: () =>
				(value as string[])
					.map((x) => {
						return consumerStateToHumanText(x as ConsumerState)
					})
					.join(", "),
			hasUsers: () => `Har användare: ${value === "true" ? "Ja" : "Nej"}`,
			hasOrders: () => `Har ordrar: ${value === "true" ? "Ja" : "Nej"}`,
			hasInvites: () => `Har inbjudningar: ${value === "true" ? "Ja" : "Nej"}`,
			searchText: () => "",
			sortBy: () => "",
		})
	}

	function clearFilters() {
		setQueryParams((queryParams) => {
			queryParams.delete("filters")
			queryParams.set("page", "1")

			return queryParams
		})
	}

	function filterRowsByText(text: string) {
		if (!text) {
			setTextSearchValue("")
		} else {
			setTextSearchValue(text)
		}
		filterRowsByTextDebounced(text || null, getActiveFilters())
	}

	function filterSection() {
		return (
			<div className={style.filterSection}>
				<div className={style.firstRow}>
					{permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Create) ? (
						<span className={customerPortalOrdersStyle.onlyDesktop}>
							<ClientPortalNewConsumerModal
								newConsumerButtonClass={style.newConsumerButton}
								onAdd={newConsumerHasBeenCreated}
							/>
						</span>
					) : null}
					<div className={cls(style.searchInputWrapper, { [style.disabled]: dataLoading })}>
						<span className={style.searchIcon}>
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</span>
						<input
							disabled={dataLoading}
							ref={searchInputRef}
							className={style.searchInput}
							onChange={(e) => filterRowsByText(e.target.value.toLocaleLowerCase())}
							placeholder={"Sök"}
							value={textSearchValue}
						/>
						<button
							onClick={() => filterRowsByText("")}
							className={style.clearSearchButton}
							disabled={dataLoading}>
							{textSearchValue !== "" ? (
								<CrossIcon size={22} className={style.closeXIcon} iconClassName={style.closeXIconInside} />
							) : (
								<span style={{ marginLeft: "1rem" }} />
							)}
						</button>
					</div>
					<div className={cls({ [style.disabled]: dataLoading })}>
						<button
							disabled={dataLoading}
							className={cls(
								style.filterButton,
								customerPortalOrdersStyle.filterButton,
								customerPortalOrdersStyle.onlyDesktop,
							)}
							onClick={() => {
								if (dataLoading) {
									return
								}
								setShowFilterSidebar(true)
								addModalOpenClass()
							}}>
							<span>Filtrera</span>
							<FiltreraIcon size={22} />
						</button>
						<button
							disabled={dataLoading}
							className={cls(
								style.filterButton,
								customerPortalOrdersStyle.filterButton,
								customerPortalOrdersStyle.onlyMobile,
							)}
							onClick={() => {
								if (dataLoading) {
									return
								}
								setShowFilterSidebar(true)
								addModalOpenClass()
							}}>
							<FiltreraIcon size={22} />
						</button>
					</div>
				</div>
				<div className={style.secondRow}>
					{selectedFilters(
						getActiveFilters(),
						(key, value) => getFilterDataValue(key as keyof ConsumerListFilterDataSchemaType, value),
						(key) => {
							const activeFilters: { [key: string]: any } = cloneDeep(getActiveFilters())
							delete activeFilters[key]
							setPageAndFilterInUrl(1, null, activeFilters, setQueryParams)
						},
						() => {
							clearFilters()
						},
					)}
				</div>
			</div>
		)
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Read)) {
		return null
	}

	return (
		<>
			{showFilterSidebar && consumers ? (
				<ConsumerListFilterSidebar
					consumers={consumers}
					onClose={() => {
						setShowFilterSidebar(false)
						removeModalOpenClass()
					}}
					onDone={(data) => {
						setPageAndFilterInUrl(1, null, data, setQueryParams)
					}}
					filterData={getActiveFilters()}
				/>
			) : null}
			<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
				<div className={style.headerAndNewConsumerSection}>
					<div className={style.headerText}>Kunder</div>
					{permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Create) ? (
						<span className={customerPortalOrdersStyle.onlyMobile}>
							<ClientPortalNewConsumerModal
								newConsumerButtonClass={style.newConsumerButton}
								onAdd={newConsumerHasBeenCreated}
							/>
						</span>
					) : null}
				</div>
				{filterSection()}
				{rowsPerPageSection(
					getActiveFilters(),
					getPageSize(),
					(page) => {
						setPageAndFilterInUrl(1, page, getActiveFilters(), setQueryParams)
					},
					dataLoading,
				)}
				{dataLoading ? (
					<RelativeCentered styles={{ height: "300px" }}>
						<Loader />
					</RelativeCentered>
				) : (
					<SkrappyTable
						data={consumers}
						columns={tableColumns()}
						tableClass={style.consumersTable}
						tableRowClass={style.clickableRow}
						onRowClick={(consumer) => {
							selectConsumer(consumer)
						}}
					/>
				)}
				{tablePagingSection(
					paging,
					{ pageNumber: getPageNumber(), pageSize: getPageSize() },
					() => {
						setPageAndFilterInUrl(getPageNumber() - 1, null, getActiveFilters(), setQueryParams)
					},
					() => {
						setPageAndFilterInUrl(getPageNumber() + 1, null, getActiveFilters(), setQueryParams)
					},
					(number) => {
						setPageAndFilterInUrl(number, null, getActiveFilters(), setQueryParams)
					},
					dataLoading,
				)}
			</div>
		</>
	)
}
