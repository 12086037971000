import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isUndefined } from "lodash"
import { DateTime } from "luxon"
import { FC, useCallback, useEffect, useState } from "react"
import { ButtonWithConfirmation } from "Shared/ButtonWithConfirmation/ButtonWithConfirmation"
import { v4 } from "uuid"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { EmailIcon, TrashIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import style from "./UsersInvitations.module.css"

export interface UserDetails {
	email?: string
	phoneNumber?: string
	firstName?: string
	lastName?: string
}

export interface SenderDetails {
	consumerName: string
	firstName?: string
	lastName?: string
	isClientUser: boolean
}

export interface InvitationDetails {
	userDetails: UserDetails
	message?: string
}

export interface GetUserInvitation {
	id: string
	initiatedAt: string
	expiresAt: string
	consumerRef: string
	requestDetails: InvitationDetails
	senderDetails: SenderDetails
}

type UserInvitationProps = {
	clientIdentifier: string
	consumerId: string
	newInviteId: string
}
export const UserInvitations: FC<UserInvitationProps> = ({ clientIdentifier, consumerId, newInviteId }) => {
	const [activeUserInvitations, setActiveUserInvitations] = useState<GetUserInvitation[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const init = useCallback(() => {
		setDataLoading(true)
		API.getWithRetries<GetUserInvitation[]>(`/customer-portal/user-invitation-v1/${consumerId}/list`, true)
			.then((res) => {
				setActiveUserInvitations(res)
				setDataLoading(false)
			})
			.catch(() => {
				setActiveUserInvitations([])
				setDataLoading(false)
			})
	}, [])

	useEffect(() => {
		init()
	}, [init, newInviteId])

	return (
		<div style={{ position: "relative" }}>
			{dataLoading ? (
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			) : activeUserInvitations.length > 0 ? (
				<>
					<div className={style.header}>Aktiva inbjudningar ({activeUserInvitations.length})</div>
					<div className={style.activeInvitationList}>
						{activeUserInvitations.map((invite) => {
							return (
								<div key={v4()} className={style.invitationRow}>
									<InvitationTableRow invitation={invite} consumerId={consumerId} reload={init} />
								</div>
							)
						})}
					</div>
				</>
			) : null}
		</div>
	)
}

type InvitationTableRowProp = {
	invitation: GetUserInvitation
	reload: () => void
	consumerId: string
}

const InvitationTableRow: FC<InvitationTableRowProp> = ({ invitation, reload, consumerId }) => {
	const permissions = usePermissions()

	const [showDetails, setShowDetails] = useState(false)

	function deleteInvite(invitationId: string): void {
		API.deleteWithRetries(`/customer-portal/user-invitation-v1/${invitationId}`)
			.then(() => reload())
			.catch(() => {})
	}
	return (
		<>
			<div className={style.invitationMainDetails} onClick={() => setShowDetails(!showDetails)}>
				<div className={style.inviteRowName}>
					<div style={{ fontWeight: 600 }}>{getInvitationStrongText(invitation)}</div>
					<div>{getInvitationDullText(invitation)}</div>
				</div>
				<div style={{ width: "fit-content", height: "fit-content", marginTop: "auto", marginBottom: "auto" }}>
					{showDetails ? (
						<FontAwesomeIcon size={"xl"} icon={faAngleUp} />
					) : (
						<FontAwesomeIcon size={"xl"} icon={faAngleDown} />
					)}
				</div>
			</div>
			{showDetails ? (
				<>
					<hr />
					<div className={style.inviteExtraDetailSection}>
						<div className={style.inviteExtraDetailInfo}>
							<strong>Skickad</strong>
							<span>
								{DateTime.fromISO(invitation.initiatedAt, {
									zone: "Europe/Stockholm",
								})
									.setLocale("sv-SE")
									.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
							</span>
						</div>
						{permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Resend) ? (
							<ButtonWithConfirmation
								onClick={async () => {
									resendMail(invitation.id)
								}}
								buttonText={"Skicka på nytt"}
								icon={<EmailIcon size={22} className={style.resendIcon} />}
							/>
						) : (
							<div />
						)}

						<div className={style.inviteExtraDetailInfo}>
							<strong>Går ut om</strong>
							<CountdownComponent endTimeISO={invitation.expiresAt} />
						</div>

						{permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Recall) ? (
							<ButtonWithConfirmation
								onClick={async () => {
									deleteInvite(invitation.id)
								}}
								buttonText={"Återkalla inbjudan"}
								icon={<TrashIcon size={22} className={style.trashIcon} />}
							/>
						) : (
							<div />
						)}
					</div>
				</>
			) : null}
		</>
	)
}

async function resendMail(invitationId: string): Promise<void> {
	return API.postWithRetries(`/customer-portal/user-invitation-v1/${invitationId}/resend`, undefined, {}, 1)
		.then(() => {})
		.catch(() => {})
}

type CountdownComponentProps = { endTimeISO: string }
const one_day_in_msec = 86400000
const one_hour_in_msec = 3600000
const one_min_in_msec = 60000
const CountdownComponent: FC<CountdownComponentProps> = ({ endTimeISO }) => {
	const endTime = new Date(endTimeISO).getTime()
	const [countDown, setCountDown] = useState(endTime - new Date().getTime())
	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(endTime - new Date().getTime())
		}, 1000)

		return () => clearInterval(interval)
	}, [endTime])

	const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
	const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

	if (countDown < 0) {
		return <span>Inbjudan har upphört</span>
	} else if (one_day_in_msec < countDown) {
		return (
			<span>
				{days} {days > 1 ? "dagar" : "dag"}
			</span>
		)
	} else if (one_hour_in_msec < countDown) {
		return (
			<span>
				{hours} {hours > 1 ? "timmar" : "timme"}{" "}
			</span>
		)
	} else if (one_min_in_msec < countDown) {
		return (
			<span>
				{minutes} {minutes > 1 ? "minuter" : "minut"}{" "}
			</span>
		)
	} else {
		return <span>{seconds} sekunder</span>
	}

	/*
	return (
		<span>
			{one_day_in_msec < countDown ? <span>{days} d </span> : null}
			{one_hour_in_msec < countDown ? <span>{hours} h </span> : null}
			{one_min_in_msec < countDown ? <span>{minutes} min </span> : null}
			{seconds} sec
		</span>
	)
	*/
}

function getInvitationStrongText(request: GetUserInvitation) {
	const firstName = request.requestDetails.userDetails.firstName
	const lastName = request.requestDetails.userDetails.lastName
	const email = request.requestDetails.userDetails.email
	const phoneNumber = request.requestDetails.userDetails.phoneNumber
	if ((isUndefined(firstName) || firstName === "") && (isUndefined(lastName) || lastName === "")) {
		return <span>{isUndefined(email) ? phoneNumber : email}</span>
	} else {
		return (
			<span style={{ wordBreak: "break-word" }}>
				{!isUndefined(firstName) ? firstName + " " : null}
				{!isUndefined(lastName) ? lastName : null}
			</span>
		)
	}
}

function getInvitationDullText(request: GetUserInvitation) {
	const firstName = request.requestDetails.userDetails.firstName
	const lastName = request.requestDetails.userDetails.lastName
	const email = request.requestDetails.userDetails.email
	const phoneNumber = request.requestDetails.userDetails.phoneNumber
	if ((isUndefined(firstName) || firstName === "") && (isUndefined(lastName) || lastName === "")) {
		return null
	} else {
		return <span style={{ wordBreak: "break-word" }}>{isUndefined(email) ? phoneNumber : email}</span>
	}
}
