import { exhaustive } from "exhaustive"
import { ClientInstance, ProductCategoryInstance } from "../../Client/ClientInstance"
import {
	GetContactPerson,
	GetProject,
} from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { Project } from "../order-data-model"
import { BasketInstance } from "./BasketProvider"

export function convertGetProjectToInternalProject(project: GetProject | Project): Project {
	if ("id" in project) {
		return {
			street: project.address.street,
			city: project.address.city,
			zipcode: project.address.zipCode,
			coordinates: project.address.coordinates,
			marking: project.marking,
			contactPersons: project.contactPersons.map((x: GetContactPerson) => ({
				id: x.id,
				name: x.name,
				phone: x.phone,
			})),
		}
	}
	return project
}

export function allowedDateOrTimeSelectValues(
	basket: BasketInstance,
	client: ClientInstance,
	category: ProductCategoryInstance | null,
	type: "date" | "time",
): string[] {
	if (
		!category ||
		basket.values.selectedOrderItemIndex == null ||
		!basket.orderItems[basket.values.selectedOrderItemIndex]
	) {
		return []
	}

	const orderItem = basket.orderItems[basket.values.selectedOrderItemIndex]

	if (!orderItem) {
		return []
	}

	return exhaustive(category, "type", {
		WasteCategory: (wasteCategory) => {
			if (!orderItem.serviceId) {
				return []
			}
			if (type === "date") {
				return wasteCategory.services[orderItem.serviceId]?.dateSlots ?? []
			} else {
				return wasteCategory.services[orderItem.serviceId]?.timeSlots ?? []
			}
		},
		GoodsCategory: () => {
			const product = orderItem.products.find((x) => x.packagingMethod)
			if (!product?.packagingMethod?.id) {
				return []
			}

			if (type === "date") {
				return client.possiblePackagingMethods[product.packagingMethod.id]?.dateSlotIds ?? []
			} else {
				return client.possiblePackagingMethods[product.packagingMethod.id]?.timeSlotIds ?? []
			}
		},
	})
}

export function handleQueryParamsOrderItemEditTime(
	orderItemIndex: number | null,
	basket: BasketInstance,
	client: ClientInstance,
	isMobileSize: boolean,
	onDone: () => void,
) {
	if (orderItemIndex == null) {
		return
	}

	const orderItem = basket.orderItems[orderItemIndex]
	const categoryName = orderItem?.category

	if (!orderItem || !categoryName) {
		return
	}

	let categoryInstance = client.categories[categoryName]

	if (!categoryInstance) {
		return
	}

	let timeslots: string[] = []

	if (categoryInstance.type === "WasteCategory" && orderItem.serviceId) {
		timeslots = categoryInstance.services[orderItem.serviceId]?.timeSlots || []
	} else if (categoryInstance.type === "GoodsCategory") {
		const packagingMethodId = orderItem.products.find((x) => x.packagingMethod)?.packagingMethod?.id

		if (packagingMethodId) {
			timeslots = client.possiblePackagingMethods[packagingMethodId]?.timeSlotIds || []
		}
	}

	if (timeslots.length > 1 || (timeslots.length === 1 && client.possibleTimeSlots[timeslots[0]]?.specificTime === true)) {
		if (isMobileSize) {
			basket.functions.setMobileBasketShown(true)
		}
		basket.functions.setSelectedOrderItem(orderItemIndex)
		setTimeout(() => {
			onDone()
		}, 50)
	}
}

export function handleQueryParamsOrderItemEditDate(
	orderItemIndex: number | null,
	basket: BasketInstance,
	isMobileSize: boolean,
	onDone: () => void,
) {
	if (orderItemIndex == null) {
		return
	}

	if (isMobileSize) {
		basket.functions.setMobileBasketShown(true)
	}
	basket.functions.setSelectedOrderItem(orderItemIndex)
	setTimeout(() => {
		onDone()
	}, 50)
}
