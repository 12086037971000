export type ConditionalClassName = {
	[key: string]: boolean
}

export function cls(...classNames: (string | ConditionalClassName | undefined)[]): string {
	let classes = classNames
		.filter((value) => value !== undefined)
		.map((value) => {
			if (typeof value === "object") {
				for (let [className, condition] of Object.entries(value)) {
					if (condition) {
						return className
					}
					return null
				}
			}

			return value
		})

	return classes.filter((value) => value).join(" ")
}
