import { HappyCustomer } from "../Lottie/AnimationComponents"
import { DemoProps } from "./demoState"
import style from "./DemoStyle.module.css"

export function Demo5ThankYou({ setDemoState }: DemoProps) {
	function onContinue() {
		setDemoState("done")
	}

	return (
		<>
			<div className={style.topImage}>
				<HappyCustomer />
			</div>
			<h3>TACK & HEJ!</h3>
			<h1>Stort tack för att du testade och att du svarade på frågorna!</h1>
			<p>Om du vill testa att klicka runt igen klickar du på knappen nedan.</p>
			<p>Annars är det bara att stänga fönstret och vi hoppas på ett kärt återseende!</p>

			<button type={"button"} onClick={onContinue}>
				Tillbaka till Demo
			</button>
		</>
	)
}
