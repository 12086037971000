import { getLogger } from "../Logging/getLogger"
import { ClientBranding, Colors } from "./GetClientResponse"

const logger = getLogger("SetBrandingCssVariables")

/**
 * The css variables used here should be defined in clientBranding.css where the default values are defined.
 **/
export const colorMapping: Record<keyof Colors, string> = {
	header: "header-color",
	headerDivider: "header-divider-color",
	headerShadow: "header-shadow-color",
	headerIcon: "header-icon-color",
	headerChosenColor: "header-chosen-color",
	headerTextColor: "header-text-color",
	headerHoverColor: "header-hover-color",
	headerNotification: "header-notification-color",
	headerNotificationInside: "header-notification-inside-color",
	footer: "footer-color",
	footerLogo: "footer-logo-color",
	accent: "accent-color",
	accentHover: "accent-hover-color",
	accentHoverInside: "accent-hover-inside-color",
	accentInside: "accent-inside-color",
	finalize: "finalize-color",
	finalizeInside: "finalize-inside-color",
	finalizeHover: "finalize-hover-color",
	finalizeHoverInside: "finalize-hover-inside-color",
	disable: "disable-color",
	disableInside: "disable-inside-color",
	sectionValueBox: "section-value-box-color",
	sectionValueBoxInside: "section-value-box-inside-color",
	sectionValueBoxHover: "section-value-box-hover-color",
	sectionValueBoxHoverInside: "section-value-box-hover-inside-color",
	selectedSectionValueBox: "selected-section-value-box-color",
	selectedSectionValueBoxHover: "selected-section-value-box-hover-color",
	selectedSectionValueBoxInside: "selected-section-value-box-inside-color",
	confirmedAmount: "confirmed-amount-color",
	confirmedAmountInside: "confirmed-amount-inside-color",
	sectionBackground: "section-background-color",
	selectedSectionBackground: "selected-section-background-color",
	sectionIcon: "section-icon-color",
	sectionBackgroundText: "section-background-text-color",
	moduleBox: "module-box-color",
	moduleBoxIcon: "module-box-icon-color",
	moduleBoxText: "module-box-text-color",
	moduleBoxDiscountColor: "module-box-discount-color",
	inputField: "input-field-color",
	inputFieldInside: "input-field-inside-color",
	mainBackground: "main-background-color",
	mainBackgroundText: "main-background-text-color",
	hoverBorder: "hover-border-color",
	chosenBorder: "chosen-border-color",
	invalid: "invalid-color",
	valid: "valid-color",
	productBase: "product-base-color",
	productSecondary: "product-secondary-color",
	productDetails: "product-details-color",
	clientNotificationColor: "client-notification-color",
	clientNotificationInsideColor: "client-notification-inside-color",
	filterButtonColor: "filter-button-color",
	filterButtonInsideColor: "filter-button-inside-color",
	filterButtonHoverColor: "filter-button-hover-color",
	filterButtonHoverInsideColor: "filter-button-hover-inside-color",
	sectionHeaderColor: "section-header-color",
	sectionHeaderInsideColor: "section-header-inside-color",
	sectionBorderColor: "section-border-color",
	sectionDiscountColor: "section-discount-color",
	accentSecondaryColor: "accent-secondary-color",
	accentSecondaryHoverColor: "accent-secondary-hover-color",
	accentSecondaryInsideColor: "accent-secondary-inside-color",
	statusNotManagedColor: "status-not-managed-color",
	statusNotManagedInsideColor: "status-not-managed-inside-color",
	statusAcceptedColor: "status-accepted-color",
	statusAcceptedInsideColor: "status-accepted-inside-color",
	statusDeniedColor: "status-denied-color",
	statusDeniedInsideColor: "status-denied-inside-color",
	statusDoneColor: "status-done-color",
	statusDoneInsideColor: "status-done-inside-color",
	sidebarColor: "sidebar-color",
	sidebarChosenColor: "sidebar-chosen-color",
	sidebarIconColor: "sidebar-icon-color",
	sidebarTextColor: "sidebar-text-color",
	sidebarLogoColor: "sidebar-logo-color",
	sidebarHoverColor: "sidebar-hover-color",
	inputFieldColorMback: "input-field-color-mback",
	deliveryColor: "delivery-color",
	deliveryMeasurementColor: "delivery-measurement-color",
	deliveryMeasurementInsideColor: "delivery-measurement-inside-color",
	underHeaderColor: "under-header-color",
	underHeaderInsideColor: "under-header-inside-color",
	underHeaderChosenColor: "under-header-chosen-color",
	underHeaderChosenInsideColor: "under-header-chosen-inside-color",
	underHeaderHoverColor: "under-header-hover-color",
	underHeaderHoverInsideColor: "under-header-hover-inside-color",
	sectionHoverColor: "section-hover-color",
	basketMainColor: "basket-main-color",
	basketMainTextColor: "basket-main-text-color",
	basketMainIconColor: "basket-main-icon-color",
	basketBackgroundColor: "basket-background-color",
	basketBackgroundTextColor: "basket-background-text-color",
	basketBackgroundIconColor: "basket-background-icon-color",
	basketModuleColor: "basket-module-color",
	basketModuleTextColor: "basket-module-text-color",
	basketModuleIconColor: "basket-module-icon-color",
	basketSectionColor: "basket-section-color",
	basketSectionTextColor: "basket-section-text-color",
	basketSectionIconColor: "basket-section-icon-color",
	basketFinalizeColor: "basket-finalize-color",
	basketFinalizeInsideColor: "basket-finalize-inside-color",
	basketFinalizeIconColor: "basket-finalize-icon-color",
	basketFinalizeAccentColor: "basket-finalize-accent-color",
	basketFinalizeAccentInsideColor: "basket-finalize-accent-inside-color",
	moduleBubbleConsumerColor: "module-bubble-consumer-color",
	moduleBubbleConsumerTextColor: "module-bubble-consumer-text-color",
	moduleBubbleClientColor: "module-bubble-client-color",
	moduleBubbleClientTextColor: "module-bubble-client-text-color",
	imageScrollAccentInsideColor: "image-scroll-accent-inside-color",
	imageScrollAccentHoverColor: "image-scroll-accent-hover-color",
	imageScrollAccentHoverInsideColor: "image-scroll-accent-hover-inside-color",
	collapsibleTitleBackgroundColor: "collapsible-title-background-color",
	collapsibleTitleColor: "collapsible-title-color",
	collapsibleContentBackgroundColor: "collapsible-content-background-color",
	collapsibleContentColor: "collapsible-content-color",
	headerButtonColor: "header-button-color",
	headerButtonInsideColor: "header-button-inside-color",
	headerButtonInsideHoverColor: "header-button-inside-hover-color",
	headerDropdownMain: "header-dropdown-main",
	headerDropdownMainText: "header-dropdown-main-text",
	headerDropdownHover: "header-dropdown-hover",
	headerDropdownChosen: "header-dropdown-chosen",
	headerDropdownChosenInside: "header-dropdown-chosen-inside",
	headerDropdownSecondText: "header-dropdown-second-text",
	headerDropdownAccent: "header-dropdown-accent",
	headerDropdownAccentInside: "header-dropdown-accent-inside",
	headerDropdownAccentHover: "header-dropdown-accent-hover",
	headerDropdownAccentHoverInside: "header-dropdown-accent-hover-inside",
	headerDropdownSelectedColor: "header-dropdown-selected-color",
	headerDropdownSelectedColorInside: "header-dropdown-selected-color-inside",
	headerDropdownSelectedHoverColor: "header-dropdown-selected-hover-color",
	headerDropdownSelectedHoverColorInside: "header-dropdown-selected-hover-color-inside",
	chatNotificationColor: "chat-notification-color",
	chatNotificationInsideColor: "chat-notification-inside-color",
	chatNotificationHoverColor: "chat-notification-hover-color",
	toggleBackgroundColor: "toggle-background-color",
	toggleBackgroundInsideColor: "toggle-background-inside-color",
	toggleSelectedColor: "toggle-selected-color",
	toggleSelectedInsideColor: "toggle-selected-inside-color",
	productImageBackgroundColor: "product-image-background-color",
	productSectionColor: "product-section-color",
	productSectionTextColor: "product-section-text-color",
	productBorderColor: "product-border-color",
}

export const setBrandingCssVariables = (branding: ClientBranding) => {
	const { colors } = branding

	Object.entries(colorMapping).forEach(([key, cssVariable]) => {
		const value = colors[key as keyof Colors]
		if (value) {
			applyStyleIfDefined(cssVariable, value)
			logger.debug(`Setting '${cssVariable}' to '${value}' by branding color '${key}'`)
		}
	})

	//Other parameters
	applyStyleIfDefined("header-logo-height", branding.logoHeight)
}

export const applyStyleIfDefined = (cssVariable: string, value?: string) => {
	if (!!value) {
		document.documentElement.style.setProperty(`--${cssVariable}`, value)
	}
}
