import { useEffect, useState } from "react"
import { KommentarIcon as CommentIcon } from "../../Icons/Icon"
import { getLogger } from "../../Logging/getLogger"
import { API } from "../../network/API"
import { useThrowAsync } from "../../Shared/throwAsync"
import { NotificationType, OrderFromAPI, OrderState } from "../OrderCollection"
import { DisplayMeta } from "./DisplayMeta/DisplayMeta"
import { Header } from "./Header/Header"
import { InfoBlobWithIcon } from "./InfoBlob/InfoBlobWithIcon"
import style from "./Order.module.css"
import { ProductListing } from "./ProductListing/ProductListing"
import { UpdateOrderRequest } from "./UpdateOrderRequest"

type Props = {
	order: OrderFromAPI
}

const logger = getLogger("Order")

export const Order = ({ order }: Props) => {
	const throwAsync = useThrowAsync()
	const [cachedOrder, setCachedOrder] = useState(order)
	const [comment, setComment] = useState(cachedOrder.clientComment)
	const [show, setShow] = useState(cachedOrder.state === OrderState.Created)

	useEffect(() => {
		setCachedOrder(order)
	}, [order])

	const updateOrder = (state: OrderState, comment: string) => {
		const updateOrder: UpdateOrderRequest = {
			state: state,
			clientComment: comment,
		}
		API.patchMerge<OrderFromAPI>(`/order-v1/${cachedOrder.id}`, updateOrder)
			.then((updated) => {
				logger.debug(updated)
				setCachedOrder(updated)
				setShow(false)
			})
			.catch((error) => {
				logger.debug(`Unable to load order data for: ${cachedOrder.id}`)
				throwAsync(new Error(`Unable to load order data for: ${cachedOrder.id}`, { cause: error }))
			})
	}

	const commentUpdated = (event: any) => {
		setComment(event.target.value)
	}

	const showSwapper = () => {
		setShow(!show)
	}

	return (
		<div>
			<Header
				orderState={cachedOrder.state}
				emailState={cachedOrder.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer]}
				orderNumber={cachedOrder.orderNumber}
				shown={show}
				showSwapper={showSwapper}
			/>
			{show ? (
				<div className={style.showWrapper}>
					<div className={style.orderInfoWrapper}>
						{cachedOrder.state === OrderState.Created ? <div className={style.divider} /> : null}
						<div className={style.gridWrapper}>
							<div className={style.productListing}>
								<ProductListing products={order.products} />
							</div>
							<div className={style.metaInfo}>
								<DisplayMeta order={order} />
							</div>
						</div>
					</div>

					<div className={style.commentWrapper}>
						{!cachedOrder.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer] ? (
							<InfoBlobWithIcon
								icon={<CommentIcon iconClassName={style.iconColor} />}
								firstLine="Lämna kommentar"
							/>
						) : (
							<InfoBlobWithIcon icon={<CommentIcon iconClassName={style.iconColor} />} firstLine={comment} />
						)}
						{!cachedOrder.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer] ? (
							<textarea
								id="commentInput"
								name="commentInput"
								className={style.commentInput}
								placeholder="Kommentar till kund gällande order"
								value={comment}
								onChange={commentUpdated}></textarea>
						) : null}
					</div>
					{!cachedOrder.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer] ? (
						<div className={style.buttonContainer}>
							<button
								className={style.acceptButton}
								onClick={() => updateOrder(OrderState.Accepted, comment)}>
								Godkänn
							</button>
							<button
								className={style.declineButton}
								onClick={() => updateOrder(OrderState.Declined, comment)}>
								Neka
							</button>
						</div>
					) : null}
				</div>
			) : null}
		</div>
	)
}
