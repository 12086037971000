import { isNumber } from "lodash"
import { GetAuthToken, GetResolvedSelectedConsumerId } from "../Auth/AuthContext"
import { cache } from "./Cache"

export class ServerError<T> extends Error {
	public status: number
	public statusText: string
	public data: T

	constructor(status: number, statusText: string, data: T) {
		super(`Server error - ${status}: ${statusText}`)
		this.status = status
		this.statusText = statusText
		this.data = data
	}
}

interface HandleResponse {
	response: Response
	deleteCacheUrl?: string
}

async function handleResponse<T>(opts: HandleResponse): Promise<T> {
	const { response, deleteCacheUrl } = opts
	const contentType = response.headers.get("content-type")
	let data

	if (contentType && contentType.indexOf("application/json") !== -1) {
		data = response.json()
	} else {
		data = response.text()
	}

	if (!response.ok) {
		const resolvedData = await data

		if (deleteCacheUrl) {
			cache.delete(deleteCacheUrl)
		}

		throw new ServerError<T>(response.status, response.statusText, resolvedData)
	}

	return data
}

const appId = generateUID()
const deviceId = getDeviceId()

export class API {
	static fetch<T>(url: string, refreshCache: boolean = false, options: RequestInit = {}): Promise<T> {
		const cachedResult = cache.get(url)
		if (cachedResult && !refreshCache) {
			return cachedResult
		}

		const promise = fetch(API.getUrl(url), {
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			...options,
		})
			.then((response) => handleResponse<T>({ response, deleteCacheUrl: url }))
			.catch((err: ServerError<T>) => {
				cache.delete(url)
				throw err
			})

		return cache.set(url, promise)
	}

	static get<T>(url: string, refreshCache: boolean = false, options: RequestInit = {}): Promise<T> {
		const cachedResult = cache.get(url)
		if (cachedResult && !refreshCache) {
			return cachedResult
		}

		const promise = fetch(API.getUrl(url), {
			credentials: "include",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			...options,
		})
			.then((response) => handleResponse<T>({ response, deleteCacheUrl: url }))
			.catch((err: ServerError<T>) => {
				cache.delete(url)
				throw err
			})

		return cache.set(url, promise)
	}

	static getWithRetries<T>(
		url: string,
		refreshCache: boolean = false,
		options: RequestInit = {},
		retries: number = 30,
	): Promise<T> {
		const cachedResult = cache.get(url)
		if (cachedResult && !refreshCache) {
			return cachedResult
		}

		return cache.set(url, this.doRequestWithRetries("GET", url, undefined, options, retries, "JSON"))
	}

	static post<TResponse, TRequest>(url: string, data?: TRequest, options: RequestInit = {}): Promise<TResponse> {
		return fetch(API.getUrl(url), {
			method: "POST",
			mode: "cors",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			body: JSON.stringify(data),
			...options,
		}).then((response) => handleResponse<TResponse>({ response }))
	}

	static patch<TResponse, TRequest>(url: string, data?: TRequest, options: RequestInit = {}): Promise<TResponse> {
		return fetch(API.getUrl(url), {
			method: "PATCH",
			mode: "cors",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			body: JSON.stringify(data),
			...options,
		}).then((response) => handleResponse<TResponse>({ response }))
	}

	static postRaw<T>(url: string, data?: Object, options: RequestInit = {}): Promise<T> {
		const headers = new Headers()
		headers.append("Authorization", GetAuthToken() || "")
		headers.append("resolved-selected-consumer-id", GetResolvedSelectedConsumerId())
		headers.append("x-app-id", appId)
		headers.append("x-device-id", deviceId)

		return fetch(API.getUrl(url), {
			method: "POST",
			mode: "cors",
			credentials: "include",
			headers: headers,
			body: data as any,
			...options,
		}).then((response) => handleResponse<T>({ response }))
	}

	static postWithRetries<TResponse, TRequest = any>(
		url: string,
		data?: TRequest,
		options: RequestInit = {},
		retries: number = 30,
		contentType: "JSON" | "Raw" = "JSON",
	): Promise<TResponse> {
		return this.doRequestWithRetries<TResponse, TRequest>("POST", url, data, options, retries, contentType)
	}

	static put<T>(url: string, data?: Object, options: RequestInit = {}): Promise<T> {
		return fetch(API.getUrl(url), {
			method: "PUT",
			mode: "cors",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			body: JSON.stringify(data),
			...options,
		}).then((response) => handleResponse<T>({ response }))
	}

	static patchWithRetries<TResponse, TRequest = any>(
		url: string,
		data?: TRequest,
		options: RequestInit = {},
		retries: number = 30,
		contentType: "JSON" | "Raw" = "JSON",
	): Promise<TResponse> {
		return this.doRequestWithRetries<TResponse, TRequest>("PATCH", url, data, options, retries, contentType)
	}

	static patchMerge<T>(url: string, data?: Object, options: RequestInit = {}): Promise<T> {
		return fetch(API.getUrl(url), {
			method: "PATCH",
			mode: "cors",
			credentials: "include",
			headers: {
				"Content-Type": "application/merge-patch+json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			body: JSON.stringify(data),
			...options,
		}).then((response) => handleResponse<T>({ response }))
	}

	static delete<T>(url: string, data?: Object, options: RequestInit = {}): Promise<T> {
		return fetch(API.getUrl(url), {
			method: "DELETE",
			mode: "cors",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				"x-app-id": appId,
				"x-device-id": deviceId,
				Authorization: GetAuthToken() || "",
				"resolved-selected-consumer-id": GetResolvedSelectedConsumerId(),
			},
			body: JSON.stringify(data),
			...options,
		}).then((response) => handleResponse<T>({ response }))
	}

	static deleteWithRetries<TResponse, TRequest = any>(
		url: string,
		data?: TRequest,
		options: RequestInit = {},
		retries: number = 30,
		contentType: "JSON" | "Raw" = "JSON",
	): Promise<TResponse> {
		return this.doRequestWithRetries<TResponse, TRequest>("DELETE", url, data, options, retries, contentType)
	}

	static getUrl(url: string) {
		if (url.indexOf("http") === 0) {
			return url
		}

		return (process.env.REACT_APP_API_BASE || "") + url
	}

	private static doRequestWithRetries<TResponse, TRequest>(
		method: "POST" | "GET" | "PATCH" | "DELETE",
		url: string,
		data?: TRequest,
		options: RequestInit = {},
		retries: number = 30,
		contentType: "JSON" | "Raw" = "JSON",
	) {
		return new Promise<TResponse>(async (resolve, reject) => {
			for (let i = 0; i < retries; i++) {
				const headers = new Headers()
				headers.append("Authorization", GetAuthToken() || "")
				headers.append("resolved-selected-consumer-id", GetResolvedSelectedConsumerId())
				headers.append("x-app-id", appId)
				headers.append("x-device-id", deviceId)
				if (contentType === "JSON") {
					headers.append("Content-Type", "application/json")
				}

				const promise = await fetch(API.getUrl(url), {
					method: method,
					mode: "cors",
					credentials: "include",
					headers: headers,
					body: contentType === "JSON" ? JSON.stringify(data) : (data as any),
					...options,
				})
					.then((response) => response)
					.catch((x) => x)

				if ("json" in promise) {
					const resolvedData = await promise.json().catch(() => ({}))
					if (!promise.ok) {
						if (url) {
							cache.delete(url)
						}

						// only reject on last or if the error code is 4XX
						if (
							i === retries - 1 ||
							(isNumber(promise.status) && promise.status >= 400 && promise.status < 500)
						) {
							reject(new ServerError<TResponse>(promise.status, promise.statusText, resolvedData))
							break
						}
					} else {
						resolve(resolvedData)
						break
					}
				} else {
					// only reject on last or if the error code is 4XX
					if (i === retries - 1 || (isNumber(promise.status) && promise.status >= 400 && promise.status < 500)) {
						reject(new ServerError<{}>(promise.status, promise.statusText, {}))
						break
					}
				}
				if (i !== retries - 1) {
					await new Promise((retryDelayResolve) => setTimeout(retryDelayResolve, this.getRetryDelay(i)))
				}
			}
		})
	}

	/**
	 * Returns delay in milliseconds, with the delay going up as the amount goes up
	 * 0->4 is 1000
	 * 5->9 is 2000
	 * 10->19 is 5000
	 * 20->infinity is 8000
	 * @param retry
	 * @private
	 */
	private static getRetryDelay(retry: number): number {
		if (retry < 5) {
			return 1000
		} else if (retry < 10) {
			return 2000
		} else if (retry < 20) {
			return 5000
		} else {
			return 8000
		}
	}
}

function getDeviceId() {
	const keyDeviceId = "x-device-id"
	let userId = window.localStorage.getItem(keyDeviceId)
	if (userId == null) {
		userId = generateUID()
		window.localStorage.setItem(keyDeviceId, userId)
	}
	return userId
}

/**
 * https://stackoverflow.com/a/2117523
 */
export function generateUID() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}
