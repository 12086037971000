import { AbsolutCentered } from "AbsolutCentered/AbsolutCentered"
import { GetUserInvitation } from "CustomerPortal/UserInvitations/UsersInvitations"
import { Loader } from "Loader/Loader"
import { includes, isUndefined } from "lodash"
import { API, ServerError } from "network/API"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "Orders/Components/Text/SbtH4/SbtH4"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useAuth } from "../../../Auth/AuthContext"
import { HandleInvitationState } from "../HandleUserInvitation"
import style from "./../HandleUserInvitation.module.css"
import { compileDisplayName, PresentConsumer } from "./PresentConsumer"

type LinkExistingUserProps = { invitation: GetUserInvitation; setState: (state: HandleInvitationState) => void }

export type PublicConsumer = {
	name: string
	identifier: string
	profilePicture?: string
}
export const LinkExistingUser: FC<LinkExistingUserProps> = ({ invitation, setState }) => {
	const auth = useAuth()
	const { clientName } = useParams()
	const [queryParams] = useSearchParams()
	const [readConsumer, setReadConsumer] = useState<PublicConsumer | undefined>(undefined)
	const [isSubmitting, setIsSubmitting] = useState(false)

	useEffect(() => {
		API.getWithRetries<PublicConsumer>(
			`/customer-portal/consumers-v1/${clientName}/read/${invitation.consumerRef}`,
			true,
		)
			.then((res) => {
				setReadConsumer(res)
			})
			.catch((error: ServerError<unknown>) => {})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function approveNewLink() {
		const inviteIdentifier = queryParams.get("inviteId")
		API.postWithRetries<GetUserInvitation>(
			`/customer-portal/user-invitation-v1/${inviteIdentifier}/add-connection`,
			{},
			{},
			1,
		)
			.then(() => {
				setState(HandleInvitationState.CompleteConnection)
				setIsSubmitting(false)
			})
			.catch(() => {
				setIsSubmitting(false)
			})
	}

	const me = auth.Me
	const loggedInUserConsumerRefs = me?.consumers.map((consumer) => consumer.id)

	if (includes(loggedInUserConsumerRefs, invitation.consumerRef)) {
		setState(HandleInvitationState.AlreadyLinkedToAccount)
	}
	if (isUndefined(readConsumer) || me === null) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}
	return (
		<div>
			<SbtH2>Mitt konto</SbtH2>
			<div style={{ marginBottom: "2rem" }}>
				<PresentConsumer
					name={compileDisplayName(me.firstName, me.lastName)}
					details={[me.email, me.phoneNumber]}
					profilePic={me.profilePicture}
					noBg={true}
				/>
			</div>
			{me.consumers.length > 0 ? (
				<>
					<SbtH4>Anslutna företag</SbtH4>
					<div style={{ marginBottom: "0.5rem" }}>
						Företag ditt användarkonto är anslutet till och du kan boka ifrån
					</div>
					<div className={style.currentlyLinkedConsumers}>
						{auth.Me?.consumers.map((consumer, index) => (
							<PresentConsumer
								key={index}
								name={consumer.name}
								details={[readConsumer.identifier]}
								profilePic={consumer.profilePicture}
								noBg={false}
							/>
						))}
					</div>
				</>
			) : null}
			<SbtH4>Godkänn koppling till:</SbtH4>
			<div style={{ marginTop: "1rem" }}>Godkänn kopplingen för att kunna boka via företaget</div>
			<PresentConsumer
				name={readConsumer.name}
				details={[readConsumer.identifier]}
				profilePic={readConsumer.profilePicture}
				noBg={false}
			/>
			<div style={{ marginTop: "1rem" }}>Du kommer att kunna boka från alla företagen med samma inlogg</div>
			<FinalizeButton onClick={() => approveNewLink()} disabled={isSubmitting} style={{ marginTop: "1rem" }}>
				Godkänn koppling
			</FinalizeButton>
		</div>
	)
}
