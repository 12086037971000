import { FC, useCallback, useEffect, useState } from "react"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import consumerInfoStyle from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { EditConsumerInvoicingInformation } from "../EditConsumerInvoicingInformation/EditConsumerInvoicingInformation"
import style from "./CustomerPortalConsumerInvoicingInformation.module.css"

type CustomerPortalConsumerInvoicingInformationProps = {
	consumerId: string
}

export type ConsumerBasicInvoicingInformation = {
	type: "Basic"
	email: string
	address: ConsumerInvoicingAddress
}

export type ConsumerInvoicingAddress = {
	street: string
	city: string
	zipCode: string
}

export type ConsumerInvoicingInformation = ConsumerBasicInvoicingInformation

type GetConsumerInvoicingDetails = {
	invoiceInformation?: ConsumerInvoicingInformation
}

export const CustomerPortalConsumerInvoicingInformation: FC<CustomerPortalConsumerInvoicingInformationProps> = (
	props: CustomerPortalConsumerInvoicingInformationProps,
) => {
	const client = useClient()
	const permissions = usePermissions()

	const [invoicingInformation, setInvoicingInformation] = useState<ConsumerInvoicingInformation | "loading" | null>(
		"loading",
	)

	const [edit, setEdit] = useState(false)

	const fetchInvoicingInformation = useCallback(() => {
		setInvoicingInformation("loading")
		API.get<GetConsumerInvoicingDetails>(
			`/customer-portal/consumers-v1/invoicing-information/${client.identifier}/${props.consumerId}`,
			true,
		).then(
			(res) => {
				setInvoicingInformation(res?.invoiceInformation || null)
			},
			() => {
				setInvoicingInformation(null)
			},
		)
	}, [client.identifier, props.consumerId])

	useEffect(() => {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Invoicing_Information_Read)) {
			setInvoicingInformation(null)
			return
		}

		fetchInvoicingInformation()
	}, [fetchInvoicingInformation, permissions])

	if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Invoicing_Information_Read)) {
		return null
	}

	return (
		<>
			{invoicingInformation !== "loading" &&
			permissions.isAllowed(PermissionAreaLocation.Consumer_Invoicing_Information_Update) &&
			edit ? (
				<EditConsumerInvoicingInformation
					consumerId={props.consumerId}
					invoicingInformation={invoicingInformation}
					onClose={() => setEdit(false)}
					onDone={() => {
						setEdit(false)
						fetchInvoicingInformation()
					}}
				/>
			) : null}
			<div className={style.wrapper}>
				{invoicingInformation === "loading" ? (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Loader />
					</div>
				) : null}
				{invoicingInformation !== "loading" ? (
					<>
						<div className={style.headerAndEditRow} style={{ marginBottom: "20px" }}>
							<span className={style.headerText}>Faktureringsuppgifter</span>
							{permissions.isAllowed(PermissionAreaLocation.Consumer_Invoicing_Information_Update) ? (
								<span style={{ marginLeft: "5px" }}>
									<PenIcon
										size={22}
										iconClassName={style.editIcon}
										onClick={() => {
											setEdit(true)
										}}
									/>
								</span>
							) : null}
						</div>
						<div className={consumerInfoStyle.textInputWithLabel}>
							<label>E-post för faktura</label>
							<span className={consumerInfoStyle.textAsInput}>{invoicingInformation?.email}</span>
						</div>
						<div className={consumerInfoStyle.textInputWithLabel}>
							<label>Gata</label>
							<span className={consumerInfoStyle.textAsInput}>{invoicingInformation?.address.street}</span>
						</div>
						<div className={consumerInfoStyle.textInputWithLabel}>
							<label>Postnummer</label>
							<span className={consumerInfoStyle.textAsInput}>{invoicingInformation?.address.zipCode}</span>
						</div>
						<div className={consumerInfoStyle.textInputWithLabel}>
							<label>Postort</label>
							<span className={consumerInfoStyle.textAsInput}>{invoicingInformation?.address.city}</span>
						</div>
					</>
				) : null}
			</div>
		</>
	)
}
