import { isArray, isFunction, isUndefined } from "lodash"
import { v4 } from "uuid"

/**
 * Simple copy of sending dom events (document.dispatchEvent) but with a queue
 *
 * Since React initiates components bottom -> top, sending events from a child
 * to a parent doesn't work with the regular document class
 *
 * So this can be used instead
 */
export class EventQueue {
	private static events: Record<string, object[]> = {}
	private static listeners: Record<string, { id: string; fn: (data: object) => void }[]> = {}

	public static dispatchEvent(eventName: string, data: object): void {
		if (isArray(this.events[eventName])) {
			this.events[eventName].push(data)
		} else {
			this.events[eventName] = [data]
		}

		if (isArray(this.listeners[eventName]) && this.listeners[eventName].length > 0) {
			this.listeners[eventName].forEach((listener) => {
				if (!isUndefined(listener.fn) && isFunction(listener.fn)) {
					listener.fn(data)
				}
			})
			this.events[eventName].pop()
		}
	}
	public static addEventListener<T extends object>(eventName: string, callback: (data: T) => void): string {
		const id = v4()

		if (isArray(this.listeners[eventName])) {
			this.listeners[eventName].push({ id: id, fn: callback as any })
		} else {
			this.listeners[eventName] = [{ id: id, fn: callback as any }]
		}

		if (isArray(this.events[eventName]) && this.events[eventName].length > 0) {
			this.listeners[eventName].forEach((listener) => {
				this.events[eventName].forEach((eventData) => {
					if (!isUndefined(listener.fn) && isFunction(listener.fn)) {
						listener.fn(eventData)
					}
				})
			})
			this.events[eventName] = []
		}

		return id
	}

	public static removeEventListener(id: string): void {
		Object.values(this.listeners).forEach((eventListeners) => {
			const idx = eventListeners.findIndex((x) => x.id === id)

			if (idx > -1) {
				eventListeners.splice(idx, 1)
			}
		})
	}
}
