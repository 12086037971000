import _ from "lodash"
import log, { Logger, LoggingMethod, LogLevel, LogLevelNames, LogLevelNumbers } from "loglevel"

export type LogSender = (logEntries: LogEntry[]) => Promise<unknown>

export class RemoteLogPusher {
	private logLevelNumberToPush: LogLevelNumbers
	private logQueue = new Queue<LogEntry>()
	private logCount = 0

	private pushBatchSize = 10
	private messageConverter = (message: any) => {
		if (_.isString(message)) {
			return message
		}
		return JSON.stringify(message)
	}
	public sendLogEntries: LogSender | null = null
	public clientIdentifier: string | null = null

	constructor(levelToPush: keyof LogLevel) {
		const levelNumber = log.levels[levelToPush]
		if (levelNumber == null) {
			throw Error(`Unknown log level: ${levelToPush}`)
		}
		this.logLevelNumberToPush = levelNumber
	}

	public apply(logger: Logger) {
		try {
			const originalFactory = logger.methodFactory
			const logLevelNumberToPush = this.logLevelNumberToPush

			logger.methodFactory = (logLevelName, logLevel, loggerName): LoggingMethod => {
				const originalMethod = originalFactory(logLevelName, logLevel, loggerName)

				return (...messages: any[]) => {
					originalMethod(...messages)

					try {
						if (logLevel >= logLevelNumberToPush) {
							const logCount = this.logCount++
							this.queueAndPush({
								logger: loggerName.toString(),
								logLevel: logLevelName,
								logCount: logCount,
								dupeCount: 1,
								clientIdentifier: this.clientIdentifier,
								message: messages.map(this.messageConverter),
							})
						}
					} catch (e: unknown) {
						console.error("Failed to push log: ", messages, e)
					}
				}
			}
			logger.rebuild() // Be sure to call the rebuild method in order to apply plugin.
		} catch (e) {
			console.error("Failed to apply 'loglevel' plugin for remote log pushing. ", e)
		}
	}

	public queueAndPush(logEntry: LogEntry) {
		this.logQueue.enqueue(logEntry)
		this.pump()
	}

	get queueLength() {
		return this.logQueue.length
	}

	get count() {
		return this.logCount
	}

	private ongoingPump: Promise<unknown> | null = null

	public pump() {
		try {
			if (this.ongoingPump == null) {
				this.ongoingPump =
					this.takeAndPush()
						?.then((value) => {
							this.ongoingPump = null
							this.pump()
						})
						?.catch((reason) => {
							console.error("Remote log pusher failed!", reason)
							this.ongoingPump = null
						}) ?? null
			}
		} catch (e: unknown) {
			this.ongoingPump = null
			console.error("Pump failed!", e)
		}
	}

	private takeAndPush: () => null | Promise<unknown> = () => {
		if (this.sendLogEntries == null) {
			return null
		}

		if (this.logQueue.length <= 0) {
			return null
		}

		const logEntries: LogEntry[] = []
		let prevEntry: LogEntry | null = null
		for (let takeCount = 0; takeCount < this.pushBatchSize; takeCount++) {
			const logEntry = this.logQueue.dequeue()
			if (logEntry != null) {
				let dupeEntry = this.dedupeLogEntry(logEntry, prevEntry)

				if (dupeEntry != null) {
					takeCount--
				} else {
					logEntries.push(logEntry)
					prevEntry = logEntry
				}
			} else {
				break
			}
		}

		return this.sendLogEntries(logEntries)
	}

	private dedupeLogEntry(logEntry: LogEntry, prevEntry: LogEntry | null) {
		if (prevEntry == null) {
			return null
		}

		let isDupe = this.isDupe(logEntry, prevEntry)
		if (isDupe) {
			prevEntry.dupeCount++
			return prevEntry
		}
		return null
	}

	private isDupe(logEntry: LogEntry, prevEntry: LogEntry) {
		const value: LogEntry = { ...logEntry, logCount: 0, dupeCount: 0 }
		const other: LogEntry = { ...prevEntry, logCount: 0, dupeCount: 0 }
		return _.isEqual(value, other)
	}
}

export type LogEntry = {
	logger: string
	logLevel: LogLevelNames
	logCount: number
	dupeCount: number
	clientIdentifier: string | null
	message: string[]
}

class Queue<E> {
	private readonly items: { [index: number]: E | undefined } = {}
	private headIndex: number = 0
	private tailIndex: number = 0
	private limit: number = 200

	enqueue(item: E) {
		if (this.length >= this.limit) {
			throw Error("Max Limit Reached: " + this.limit)
		}
		this.items[this.tailIndex] = item
		this.tailIndex++
	}

	dequeue() {
		if (this.length <= 0) {
			return null
		}
		const item = this.items[this.headIndex]
		delete this.items[this.headIndex]
		this.headIndex++
		return item ?? null
	}

	peek() {
		return this.items[this.headIndex] ?? null
	}

	get length() {
		return this.tailIndex - this.headIndex
	}
}
