import { getLogger } from "../Logging/getLogger"

const logger = getLogger("Plausible")

/**
 * Wrapper to access and type the plausible API function.
 * https://plausible.io/docs/custom-event-goals
 */
declare global {
	interface Window {
		plausible: (customEvent: string, options: PlausibleOptions) => void
	}
}

export type PlausibleOptions = {
	callback?: () => void
	props: { [prop: string]: unknown }
}

export function plausible(customEvent: string, options: PlausibleOptions) {
	const plausibleFunc = window["plausible"]
	if (!plausibleFunc && typeof plausibleFunc === "function") {
		logger.error("plausible is not loaded")
		options.callback && options.callback()
		return
	}

	window.plausible(customEvent, options)
}
