import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { z } from "zod"
import { getLogger } from "../Logging/getLogger"
import { WeFix } from "../Lottie/AnimationComponents"
import { API } from "../network/API"
import { SbtRHFError } from "../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { DemoProps } from "./demoState"
import style from "./DemoStyle.module.css"
import { RatingStars } from "./RatingStars"

const logger = getLogger("Demo3End")

const pleasePickAnAlternative = "Vänligen välj ett alternativ"
const radioError = {
	invalid_type_error: pleasePickAnAlternative,
}
const DemoEndSchema = z.object({
	easeOfUse: z.string(radioError).min(1),
	preferApp: z.string(radioError).min(1),
	recommendationChance: z.string(radioError).min(1),
	rate: z.number({ required_error: pleasePickAnAlternative }),
	comment: z.string(),
})
type DemoEndSchemaType = z.input<typeof DemoEndSchema>

export function Demo3End({ setDemoState }: DemoProps) {
	const { clientName } = useParams()

	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isSubmitting },
	} = useForm<DemoEndSchemaType>({
		resolver: async (data, context, options) => {
			return zodResolver(DemoEndSchema)(data, context, options)
		},
	})

	const onSubmit: SubmitHandler<DemoEndSchemaType> = async (data) => {
		const request = {
			clientIdentifier: clientName,
			easeOfUse: parseInt(data.easeOfUse, 10),
			preferApp: parseInt(data.preferApp, 10),
			recommendationChance: parseInt(data.recommendationChance, 10),
			rate: data.rate,
			comment: data.comment,
		}

		try {
			await API.post("/survey/generic-v1/demo-end", request)
		} catch (e) {
			logger.log("Error when submitting survey, nothing to crash about", e)
		}
		setDemoState("optional-address")
	}

	return (
		<>
			<div className={style.topImage}>
				<WeFix />
			</div>
			<h3>FEEDBACK</h3>
			<h1>
				Grymt!
				<br /> Tack för att du testade vår demo!
			</h1>
			<p>Kan du vara vänlig att svara på frågorna nedan?</p>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div>
							<h1>Hur enkelt var det att lägga en bokning?</h1>
						</div>
					</div>
					<div className={style.sectionRadioGradient}>
						<div className={style.radioGradient}>
							<span>Svårt</span>
							{[1, 2, 3, 4, 5].map((title) => (
								<label>
									{title}
									<input type={"radio"} {...register("easeOfUse")} value={title} />
								</label>
							))}
							<span>Enkelt</span>
						</div>
						<SbtRHFError error={errors.easeOfUse} />
					</div>
				</div>

				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div>
							<h1>Föredrar du att göra beställningar digitalt, tex. med app eller webbtjänst?</h1>
						</div>
					</div>
					<div className={style.sectionRadioGradient}>
						<div className={style.radioGradient}>
							<span>Nej</span>
							{[1, 2, 3, 4, 5].map((title) => (
								<label>
									{title}
									<input type={"radio"} {...register("preferApp")} value={title} />
								</label>
							))}
							<span>Ja</span>
						</div>
						<SbtRHFError error={errors.preferApp} />
					</div>
				</div>

				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div>
							<h1>
								Hur stor är chansen att du hade rekommenderat detta till något företag som är verksamt i
								branchen?
							</h1>
						</div>
					</div>
					<div className={style.sectionRadioGradient}>
						<div className={style.radioGradient}>
							<span>Låg</span>
							{[1, 2, 3, 4, 5].map((title) => (
								<label>
									{title}
									<input type={"radio"} {...register("recommendationChance")} value={title} />
								</label>
							))}
							<span>Hög</span>
						</div>
						<SbtRHFError error={errors.recommendationChance} />
					</div>
				</div>

				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div>
							<h1> Vad för betyg skulle du ge denna tjänsten/appen?</h1>
						</div>
					</div>
					<div className={style.sectionRadioGradient}>
						<Controller
							name={"rate"}
							control={control}
							render={({ field: { value, onChange } }) => (
								<RatingStars starCount={5} value={value} onChange={(value) => onChange(value)} />
							)}
						/>

						<SbtRHFError error={errors.rate} />
					</div>
					<div>
						<label>
							Lämna gärna en kommentar och berätta mer
							<textarea {...register("comment")} rows={6} />
							<SbtRHFError error={errors.comment} />
						</label>
					</div>
				</div>

				<div className={style.buttonWrapper}>
					<button type={"submit"} disabled={isSubmitting}>
						Lämna feedback
					</button>
				</div>

				<a
					href={"https://skrappy.se/privacy-notice/"}
					rel="noreferrer"
					target="_blank"
					className={style.privacyNotice}>
					* läs mer om hur vi hanterar personuppgifter
				</a>
			</form>
		</>
	)
}
