import { exhaustive } from "exhaustive"
import { FC, useState } from "react"
import { ModulePopup, ModulePopupProps } from "../../Orders/Components/ModulePopup/ModulePopup"
import style from "./Login.module.css"
import { LoginForm } from "./LoginForm"
import { PasswordResetForm } from "./PasswordResetForm"
import { PasswordResetSent } from "./PasswordResetSent"

type ShowLogin = "login" | "resetPassword" | "resetSent"

export const LoginDialog: FC<ModulePopupProps> = ({ onClose, ...props }) => {
	const [show, setShow] = useState<ShowLogin>("login")
	const [suggestedReset, setSuggestedReset] = useState<string>("")

	const onShowResetPassword = (emailSuggestion?: string) => {
		setSuggestedReset(emailSuggestion ?? "")
		setShow("resetPassword")
	}

	const onResetSent = () => {
		setShow("resetSent")
	}

	const component = exhaustive(show, {
		login: () => <LoginForm onClose={onClose} onShowResetPassword={onShowResetPassword} />,
		resetPassword: () => <PasswordResetForm onClose={onClose} onResetSent={onResetSent} suggested={suggestedReset} />,
		resetSent: () => <PasswordResetSent onClose={onClose} />,
	})

	const onCancel = () => {
		exhaustive(show, {
			login: () => {
				onClose()
			},
			resetPassword: () => {
				setShow("login")
			},
			resetSent: () => {
				onClose()
			},
		})
	}

	return (
		<ModulePopup {...props} onClose={onCancel}>
			<div className={style.outerWrapper}>{component}</div>
		</ModulePopup>
	)
}
