import { API } from "../network/API"
import { LogEntry } from "./RemoteLogPusher"

/**
 * Log sender implementation, this cannot be in the remoteLogPusher because it causes circular dependencies between modules.
 * So this need to be supplied to the RemoteLogPush after it has been loaded.
 */
export const sendLogEntries = (logEntries: LogEntry[]): Promise<unknown> =>
	API.postWithRetries<unknown, LogPushRequest>("/logging-v1/log/CustomerApp", {
		entries: logEntries,
	})

type LogPushRequest = {
	entries: LogEntry[]
}
