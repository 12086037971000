import { Loader } from "Loader/Loader"
import Lottie from "lottie-react"
import { API } from "network/API"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useThrowAsync } from "Shared/throwAsync"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useClient, useClientRoot } from "../../Client/ClientAndUserProvider"
import { fixLottieColor } from "../../Lottie/Helpers"
import orderCompleted from "../../Lottie/OrderCompleted.json"
import { useNavigator } from "../../Navigator/useNavigator"
import { CountDownIndicator } from "../../Survey/CountDownIndicator/CountDownIndicator"
import { useDemoState } from "../../Survey/demoState"
import { MbactH1 } from "../Components/Text/MbactH1/MbactH1"
import { AnonymousOrderCollection, AnonymousOrderResponse } from "./AnonymousOrderResponse"
import style from "./OrderCompleted.module.css"
import { OrderCompletedSummary } from "./OrderCompletedSummary/OrderCompletedSummary"

export const OrderCompleted = () => {
	const { collectionNumber: anonymousId } = useParams()
	const clientRoot = useClientRoot()
	const throwAsync = useThrowAsync()
	const client = useClient()

	const [orderCollection, setOrderCollection] = useState<AnonymousOrderCollection | null>(null)
	const [orderCollectionNotFound, setOrderCollectionNotFound] = useState<boolean>(false)

	let navigator = useNavigator()
	let location = useLocation()
	let [demoState, setDemoState] = useDemoState(client.identifier ?? "demo-start-default")

	const inDemo = demoState === "demoing"
	const redirectForDemoFeedback = () => {
		setDemoState("end-survey")
		navigator.open(`demo?return=${location.pathname}`)
	}

	useEffect(() => {
		API.get<AnonymousOrderResponse>(`/orders-v1/anonymous/${anonymousId}`)
			.then((data) => {
				setOrderCollection(data.collection)
			})
			.catch((reason) => {
				setOrderCollectionNotFound(true)
			})
	}, [clientRoot, anonymousId, throwAsync])

	return (
		<div className={style.wrapper}>
			{inDemo ? <CountDownIndicator count={6} done={redirectForDemoFeedback} /> : null}
			{orderCollectionNotFound ? (
				<AbsolutCentered>
					Ingen order kunde hittas :( <br /> Försök igen om ett litet tag så eller kontakta din leverantör för mer
					hjälp
				</AbsolutCentered>
			) : (
				<>
					<ul className={style.timeline}>
						<li className={style.complete}>
							<div />
						</li>
						<div className={style.timelineLineComplete} />
						<li className={style.complete}>
							<div />
						</li>
						<div className={style.timelineLineComplete} />
						<li className={style.complete}>
							<div />
						</li>
					</ul>
					<div style={{ display: "flex" }}>
						<span style={{ margin: "0 auto", display: "block" }}>
							<MbactH1>Tack för din beställning!</MbactH1>
						</span>
					</div>
					<Lottie
						animationData={fixLottieColor(orderCompleted, client.branding.colors.accent)}
						className={style.lottieAnimation}
						autoPlay
						loop
					/>
					{!orderCollection ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Loader />
						</div>
					) : (
						<>
							<OrderCompletedSummary collection={orderCollection} />
						</>
					)}
				</>
			)}
		</div>
	)
}
