import { useSearchParams } from "react-router-dom"

/**
 * If T is null or contains null as a possible type, the resulting type is 'string|null' else just 'string'
 */
type NullableString<T> = T extends null ? string | null : string

export function useSearchParamState<E>(
	key: string,
	defaultValue: NullableString<E>,
	replaceDefault = false,
): [NullableString<E>, (newValue: string | null, replace?: boolean) => void] {
	const [searchParams, setSearchParams] = useSearchParams()
	const value: NullableString<E> = searchParams.get(key) ?? defaultValue

	const update = (newValue: NullableString<E> | null, replace = replaceDefault) => {
		setSearchParams(
			(prev) => {
				if (newValue == null) {
					prev.delete(key)
				} else {
					prev.set(key, newValue)
				}
				return prev
			},
			{ replace: replace },
		)
	}

	return [value, update]
}
