import { CSSProperties, FC, PropsWithChildren } from "react"
import { cls } from "../../../../Shared/cls"
import style from "./SbtH2.module.css"

type Props = {
	styles?: CSSProperties
	className?: string
}

/* SBT - H2 28pt semibold */
export const SbtH2: FC<PropsWithChildren<Props>> = ({ styles, className, children }) => {
	return (
		<h2 className={cls(style.sbtH2, className)} style={styles}>
			{children}
		</h2>
	)
}
