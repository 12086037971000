import { HTMLAttributeAnchorTarget, MouseEvent } from "react"
import { NavLink, NavLinkProps } from "react-router-dom"
import { useNavigator } from "./useNavigator"

type Props = {
	/**
	 * Should probably convert this to a `To` type in the future
	 */
	to: string
} & Omit<NavLinkProps, "to">

/**
 * Use this instead of `NavLink`, this wraps a `NavLink` and intercepts the onClick handler,
 * then utilizes `useNavigator` to handle the actual navigation.
 *
 * Maybe this shouldn't wrap NavLink but replace it all together... TBD
 */
export function NavigatorLink({ to, onClick, target, ...props }: Props) {
	let navigator = useNavigator()
	let formatHref = navigator.formatHref(to)
	return (
		<NavLink
			to={formatHref.relativeURL ?? formatHref.absoluteURL}
			onClick={(event) => {
				if (onClick) {
					onClick(event)
				}

				if (event.isDefaultPrevented()) {
					return
				}

				if (shouldProcessLinkClick(event, target)) {
					navigator.open(to)
					event.preventDefault() //Prevent React-Router from executing a click as well
				}
				//Let the browser handle this click
			}}
			target={target}
			{...props}
		/>
	)
}

/**
 * Copied from React-Router, we want the same behaviour as React-Router, but we aren't able to use
 * the `useLinkClickHandler` and still intercept some clicks in a stable manner, so we have to
 * implement parts of the same logic that isn't exported from that lib.
 */
function shouldProcessLinkClick<T>(event: MouseEvent<T>, target: HTMLAttributeAnchorTarget | undefined) {
	return (
		event.button === 0 && // Ignore everything but left clicks
		(!target || target === "_self") && // Let browser handle "target=_blank" etc.
		!isModifiedEvent(event) // Ignore clicks with modifier keys
	)
}

/**
 * Copied from React-Router
 */
function isModifiedEvent<T>(event: MouseEvent<T>) {
	return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}
