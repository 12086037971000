import { FC, PropsWithChildren, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { CrossIcon } from "../../../Icons/Icon"
import { cls } from "../../../Shared/cls"
import style from "./ModulePopup.module.css"

export function addModalOpenClass() {
	document.body.classList.add("modal-open")
	document.getElementsByTagName("html")[0].classList.add("modal-open")

	const main = document.getElementById("mainLayoutPageContent")
	if (main) {
		main.classList.add("modal-open")
	}
}

export function removeModalOpenClass() {
	document.body.classList.remove("modal-open")
	document.getElementsByTagName("html")[0].classList.remove("modal-open")
	const main = document.getElementById("mainLayoutPageContent")
	if (main) {
		main.classList.remove("modal-open")
	}
}

export function isModalOpen(): boolean {
	return document.body.classList.contains("modal-open")
}

export type ModulePopupProps = PropsWithChildren & {
	show?: boolean
	onClose: (data?: string) => void
	className?: string
	disableClose?: boolean
	disableCloseWithEsc?: boolean
}

export const ModulePopup: FC<ModulePopupProps> = ({
	show = true,
	children,
	onClose,
	className,
	disableClose,
	disableCloseWithEsc,
}) => {
	const anotherModalOpenAlready = useRef(isModalOpen())

	useEffect(() => {
		addModalOpenClass()

		return () => {
			removeModalOpenClass()
		}
	}, [])

	useEffect(() => {
		if (show && !disableClose) {
			const closeWithKeypress = (e: KeyboardEvent) => {
				if (e.key === "Escape" && !disableCloseWithEsc) {
					onClose("esc")
				}
			}
			document.addEventListener("keydown", closeWithKeypress)
			return () => {
				document.removeEventListener("keydown", closeWithKeypress)
			}
		}
		return
	}, [onClose, show, disableCloseWithEsc, disableClose])

	if (!show) {
		return null
	}

	return createPortal(
		<>
			<div
				className={style.backdrop}
				style={{ zIndex: anotherModalOpenAlready ? 1000 : 998 }}
				onClick={() => {
					if (!disableClose) {
						onClose("backdropClick")
					}
				}}
			/>
			<div aria-label={"modal"} className={cls(style.popup, className)}>
				<div className={style.header}>
					<span
						className={cls(
							style.closeX,
							{ [style.disabled]: disableClose === true },
							"google-maps-custom-hidden-fullscreen",
						)}
						onClick={() => {
							if (!disableClose) {
								onClose("closeCross")
							}
						}}>
						<CrossIcon size={22} className={style.closeXIcon} iconClassName={style.closeXIconInside} />
					</span>
				</div>
				{children}
			</div>
		</>,
		document.body,
	)
}
