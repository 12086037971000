import { getLogger } from "loglevel"
import React, { ReactNode, useEffect, useRef } from "react"
import { clearAllServiceWorkers, useAuth } from "./Auth/AuthContext"
import { useClient } from "./Client/ClientAndUserProvider"
import { ClientInstance } from "./Client/ClientInstance"
import { API } from "./network/API"
import { PermissionAreaLocation, usePermissions } from "./PermissionContext"

const logger = getLogger("ServiceWorkerContext")

const ServiceWorkerContext = React.createContext<string>("")

type Props = {
	children: ReactNode
}

type ServiceWorkerRegistrationResult = {
	status: "success" | "already-registered" | "not-available" | "error"
}

type NewPushSubscription = {
	clientIdentifier: string
	loggedInUserId: string
	pushNotificationEndpoint: object
	consumerRef?: string
}

const broadcastChannel = new BroadcastChannel("sw-messages")

const urlBase64ToUint8Array = (base64String: string) => {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

	const rawData = atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i)
	}

	return outputArray
}

export function ServiceWorkerContextProvider({ children }: Props) {
	const client = useClient()
	const auth = useAuth()
	const permissions = usePermissions()

	const publicVapidKey = useRef<string | null>(null)
	const swRegRef = useRef<ServiceWorkerRegistration | null>(null)

	useEffect(() => {
		if (
			auth.IsLoggedIn &&
			client &&
			client.features.notificationsEnabled &&
			permissions.isAllowed(PermissionAreaLocation.Notifications_Read)
		) {
			initServiceWorkerStuff(client)
		} else {
			clearAllServiceWorkers(client.identifier)
		}
	}, [client, auth.IsLoggedIn, permissions])

	function initServiceWorkerStuff(client: ClientInstance) {
		const loggedInUserId = auth.Me?.userId
		fetchPublicVapidKey().then((key) => {
			publicVapidKey.current = key

			if (key && loggedInUserId) {
				registerServiceWorker(key, client.identifier, loggedInUserId).then(
					(res) => {
						if (res.status === "success") {
							document.addEventListener(
								"click",
								() => {
									requestNotificationPermission(key, client.identifier, loggedInUserId)
								},
								{ once: true },
							)
						}
					},
					(err) => {
						console.error("service worker register callback error", err)
					},
				)
			}
		})
	}

	async function fetchPublicVapidKey() {
		return API.get<string>("/order-ui/notifications-v1/public-vapid-key")
			.then((resp) => {
				return resp
			})
			.catch(() => {
				return null
			})
	}

	async function registerServiceWorker(
		publicVapidKey: string,
		clientIdentifier: string,
		loggedInUserId: string,
	): Promise<ServiceWorkerRegistrationResult> {
		if ("serviceWorker" in navigator) {
			return navigator.serviceWorker
				.register(`/service-worker/service-worker.js`, { scope: `/service-worker/${clientIdentifier}/` })
				.then(
					async (swReg) => {
						broadcastChannel.addEventListener(
							"message",
							async (event) => {
								if (event.data?.type === "service-worker-activate") {
									if ("Notification" in window && Notification.permission === "granted") {
										swReg.pushManager
											.subscribe({
												userVisibleOnly: true,
												applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
											})
											.then(
												async (subscription) => {
													await saveSubscription(clientIdentifier, loggedInUserId, subscription)
												},
												(err) => {
													console.error("service worker activate subscribe error", err)
												},
											)
									}
								}
							},
							{ once: true },
						)

						swRegRef.current = swReg
						if ("Notification" in window && Notification.permission === "granted") {
							return swRegRef.current.pushManager.getSubscription().then(
								async (sub) => {
									if (sub) {
										return { status: "already-registered" }
									}
									return swReg.pushManager
										.subscribe({
											userVisibleOnly: true,
											applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
										})
										.then(
											(subscription) => {
												return saveSubscription(
													clientIdentifier,
													loggedInUserId,
													subscription,
												).then(
													() => {
														return { status: "success" }
													},
													() => {
														return { status: "not-available" }
													},
												)
											},
											(err) => {
												console.error("service worker register callback subscribe error", err)
												return { status: "error" }
											},
										)
								},
								() => {
									return { status: "not-available" }
								},
							)
						}
						return { status: "success" }
					},
					(err) => {
						console.error("service worker register error", err)
						return { status: "error" }
					},
				)
		} else {
			return { status: "not-available" }
		}
	}

	function requestNotificationPermission(publicVapidKey: string, clientIdentifier: string, loggedInUserId: string) {
		if (!("serviceWorker" in navigator)) {
			return
		}

		if (!("Notification" in window)) {
			// Check if the browser supports notifications
		} else if (Notification.permission === "granted") {
			// Check whether notification permissions have already been granted;
			// if so, create a notification
			// const notification = new Notification("Hi there 1!")
			// …
		} else if (Notification.permission !== "denied") {
			// We need to ask the user for permission
			Notification.requestPermission().then(async (permission) => {
				// If the user accepts, let's create a notification
				const swReg = swRegRef.current
				if (permission === "granted" && swReg) {
					// const notification = new Notification("Hi there 2!")
					// …
					swReg.pushManager.getSubscription().then(
						async (sub) => {
							if (sub) {
								return
							}
							swReg.pushManager
								.subscribe({
									userVisibleOnly: true,
									applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
								})
								.then(
									async (subscription) => {
										await saveSubscription(clientIdentifier, loggedInUserId, subscription)
									},
									(err) => {
										console.error("service worker request notification permission subscribe error", err)
									},
								)
						},
						() => {},
					)
				}
			})
		}
	}

	async function saveSubscription(clientIdentifier: string, loggedInUserId: string, subscription: PushSubscription) {
		if (!auth.IsLoggedIn) {
			return Promise.resolve()
		}

		const body: NewPushSubscription = {
			clientIdentifier: clientIdentifier,
			loggedInUserId: loggedInUserId,
			pushNotificationEndpoint: subscription,
		}

		const consumerId = getSelectedConsumerId(clientIdentifier)
		if (consumerId && auth.IsLoggedInConsumer) {
			body.consumerRef = consumerId
		}

		return API.post<void, NewPushSubscription>("/order-ui/notifications-v1/add-push-subscribe", body)
			.then(() => {})
			.catch(() => {
				navigator.serviceWorker.getRegistration(`/service-worker/${clientIdentifier}/`).then(
					(registration) => {
						if (registration) {
							registration.pushManager.getSubscription().then((x) => {
								if (x) {
									x.unsubscribe()
								}
							})
						}
					},
					(err) => {
						console.error(
							"service worker getRegistration on add push subscribe error on unsubscribe error",
							err,
						)
					},
				)
			})
	}

	function getSelectedConsumerId(clientIdentifier: string) {
		try {
			const item = localStorage.getItem(`${clientIdentifier}.selected-consumer-id`)
			const parsed = JSON.parse(item || '""')

			if (!parsed) {
				return ""
			}

			return parsed
		} catch (e) {
			return ""
		}
	}

	return <ServiceWorkerContext.Provider value={"serviceWorker"}>{children}</ServiceWorkerContext.Provider>
}
