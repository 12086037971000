import { isFunction } from "lodash"
import { useState } from "react"
import { ZodSchema } from "zod"
import { useClientContext } from "../Client/ClientAndUserProvider"
import { getLogger } from "../Logging/getLogger"

const logger = getLogger("useClientLocalStorage")

export const useClientLocalStorage = <T>(
	key: string,
	initialState: T | (() => T),
	deserializer: (value: string) => T | null,
	serializer: (obj: T) => string,
): [T, (value: T) => void, () => void] => {
	const brandRoot = useClientContext()?.identifier

	if (!brandRoot) {
		throw new Error("Unable to resolve client identifier")
	}
	return useLocalStorage<T>(`${brandRoot}.${key}`, initialState, deserializer, serializer)
}

const useLocalStorage = <T>(
	localStorageKey: string,
	initialState: T | (() => T),
	deserializer: (value: string) => T | null,
	serializer: (obj: T) => string,
): [T, (value: T) => void, () => void] => {
	const [data, setData] = useState(() => {
		let value = null
		const strValue = localStorage.getItem(localStorageKey)
		if (strValue != null) {
			try {
				value = deserializer(strValue)
			} catch (e) {
				logger.error(`Unable to deserializer '${strValue}'`, e)
			}
		}

		if (value == null) {
			value = isFunction(initialState) ? initialState() : initialState
		}

		if (value != null) {
			localStorage.setItem(localStorageKey, serializer(value))
		}
		return value
	})

	const updateData = (newValue: T) => {
		setData(newValue)
		localStorage.setItem(localStorageKey, serializer(newValue))
	}

	const removeData = () => {
		localStorage.removeItem(localStorageKey)
	}

	return [data, updateData, removeData]
}

export const useZodClientLocalStorage = <T>(key: string, initialState: T | (() => T), schema: ZodSchema<T>) => {
	return useClientLocalStorage<T>(key, initialState, zodDeserializer(schema), jsonSerializer)
}

export const useZodLocalStorage = <T>(key: string, initialState: T | (() => T), schema: ZodSchema<T>) => {
	return useLocalStorage<T>(key, initialState, zodDeserializer(schema), jsonSerializer)
}

function jsonSerializer<T>(value: T) {
	return JSON.stringify(value)
}

function zodDeserializer<T>(schema: ZodSchema<T>) {
	return (strValue: string) => {
		let safeParse = schema.safeParse(JSON.parse(strValue))
		if (safeParse.success) {
			return safeParse.data
		} else {
			logger.warn(`Unable to parse with Zod, input: '${strValue}'`, safeParse.error)
			return null
		}
	}
}
