import { map, sortBy } from "lodash"
import { HorizontalScrollBox } from "Orders/Components/HorizontalScrollBox/HorizontalScrollBox"
import { MbactH1 } from "Orders/Components/Text/MbactH1/MbactH1"
import style from "Orders/OrderContainer/OrderContainer.module.css"
import { CategoryCard, CategoryLikeWithImages } from "Orders/OrderContainer/SubComponents/CategoryImage/CategoryCard"
import { cls } from "Shared/cls"

export type CategoryLike = CategoryLikeWithImages & {
	title: string
	key: string
	selected?: boolean
	order: number
}

type Props = {
	categories: CategoryLike[]
	onSelected: (category: CategoryLike) => void
}

/**
 * Renders the "Category" section, this contains no logic more than render, so it can be reused for
 * multiple data sources that are "CategoryLike".
 */
export function CategorySelectionSection({ categories, onSelected }: Props) {
	return (
		<div className={style.section}>
			<div className={style.sectionH1}>
				<MbactH1>Kategori</MbactH1>
			</div>

			<HorizontalScrollBox
				className={style.categorySelectionHorizontalScroll}
				cellClassName={style.categoryCell}
				key={`categories`}>
				{map(sortBy(categories, "order"), (category) => {
					return (
						<CategoryCard
							category={category}
							key={category.key}
							aria-label={category.title}
							aria-selected={category.selected ?? false}
							className={cls(style.sectionBox, style.productCategory, style.productCategoryImage, {
								[style.selectedSectionBox]: category.selected ?? false,
							})}
							onClick={() => {
								onSelected(category)
							}}>
							<span className={style.productCategoryName}>{category.title}</span>
						</CategoryCard>
					)
				})}
			</HorizontalScrollBox>
		</div>
	)
}
