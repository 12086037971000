import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useState } from "react"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { GetOrder } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { OrderDetails, SelectedTab } from "../../CustomerPortal/OrderDetails/OrderDetails"
import { CrossIcon } from "../../Icons/Icon"
import { API } from "../../network/API"
import { OrderState } from "../../OrderAcceptance/OrderCollection"
import { AcceptButton, DenyButton, FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup, ModulePopupProps } from "../../Orders/Components/ModulePopup/ModulePopup"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import style from "./OrderAcceptance.module.css"

type Props = {
	order: GetOrder
	onUpdate: (order: GetOrder) => void
	onDone: (newOrder: GetOrder) => void
} & ModulePopupProps

export type UpdateOrderStateAndComment = {
	state: OrderState
	comment: string
}
export const OrderAcceptance: FC<Props> = ({ order, onClose, onDone, onUpdate, ...props }) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	const [selectedOutcome, setSelectedOutcome] = useState<"approve" | "deny" | null>(null)
	const [comment, setComment] = useState<string>("")

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	const [showFinalizeButtons, setShowFinalizeButtons] = useState(true)

	function onSubmit() {
		setIsSubmitting(true)
		if (!selectedOutcome) {
			return
		}

		const updateOrder: UpdateOrderStateAndComment = {
			state: selectedOutcome === "approve" ? OrderState.Accepted : OrderState.Declined,
			comment: comment,
		}
		API.postWithRetries<GetOrder>(
			`/customer-portal/orders/client-v1/${client.identifier}/set-state-and-comment/${order.id}`,
			updateOrder,
		).then(
			(updated) => {
				onDone(updated)
				onClose()
			},
			() => {
				setIsSubmitting(false)
			},
		)
	}

	return (
		<ModulePopup {...props} onClose={onClose} className={style.modal}>
			<div className={style.contentWrapper}>
				<OrderDetails
					onUpdate={onUpdate}
					order={order}
					className={cls(
						style.orderDetails,
						{ [style.valueSelected]: selectedOutcome !== null },
						{ [style.fullHeight]: !auth.Me || auth.IsLoggedInConsumer },
					)}
					onTabChange={(tab) => {
						setShowFinalizeButtons(tab === SelectedTab.Booking)
					}}
					onClose={onClose}
				/>

				{auth.IsLoggedInClient &&
				showFinalizeButtons &&
				permissions.isAllowed(PermissionAreaLocation.Client_Orders_Update) ? (
					<div className={cls(style.confirmWrapper, "google-maps-custom-hidden-fullscreen")}>
						<div className={style.outcomesWrapper}>
							<AcceptButton
								onClick={() => {
									if (selectedOutcome === "approve") {
										setSelectedOutcome(null)
									} else {
										setSelectedOutcome("approve")
									}
								}}
								className={cls({ [style.selectedOutcome]: selectedOutcome === "approve" }, style.outcome)}>
								<FontAwesomeIcon className={style.iconColorAccept} icon={faCheck} />
								Acceptera
							</AcceptButton>
							<DenyButton
								onClick={() => {
									if (selectedOutcome === "deny") {
										setSelectedOutcome(null)
									} else {
										setSelectedOutcome("deny")
									}
								}}
								className={cls({ [style.selectedOutcome]: selectedOutcome === "deny" }, style.outcome)}>
								<CrossIcon
									size={22}
									className={style.iconColorDeny}
									iconClassName={style.closeXIconInside}
								/>
								Neka
							</DenyButton>
						</div>
						{selectedOutcome === null ? null : (
							<>
								<textarea
									id="commentInput"
									name="commentInput"
									className={style.commentInput}
									value={comment}
									placeholder="Lägg till en kommentar som skickas till kunden"
									onChange={(e) => {
										setComment(e.target.value)
									}}></textarea>
								<FinalizeButton disabled={isSubmitting} onClick={onSubmit}>
									Skicka bekräftelse&nbsp;
									{isSubmitting ? (
										<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
									) : null}
								</FinalizeButton>
							</>
						)}
					</div>
				) : null}
			</div>
		</ModulePopup>
	)
}
