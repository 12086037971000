import { CSSProperties, FC, PropsWithChildren } from "react"
import { cls } from "../../../../Shared/cls"
import style from "./SbtH1.module.css"

/* SBT - H1 40pt semibold */

type Props = {
	className?: string
	styles?: CSSProperties
} & PropsWithChildren
export const SbtH1: FC<Props> = ({ className, styles, children }) => {
	return (
		<h1 className={cls(style.sbtH1, className)} style={styles}>
			{children}
		</h1>
	)
}
