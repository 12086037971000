import style from "./OnboardingHeader.module.css"
import SkrappyLogo from "./Skrappy_turkos.svg"

export const OnboardingHeader = () => {
	return (
		<header className={style.header}>
			<img src={SkrappyLogo} alt="Skrappy" />
			<h2 className={style.subHeader}>ONBOARDING</h2>
		</header>
	)
}
