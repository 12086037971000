import style from "./Loader.module.css"

export const Loader = () => {
	return (
		<div className={style.ldsEllipsis}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}
