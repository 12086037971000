import { cls } from "Shared/cls"
import style from "./Annotation.module.css"

type Props = {
	children?: React.ReactNode
	wrapperClassName?: string
	annotationClassName?: string
}

/**
 * Reusable wrapper for annotations that are symbols or icons placed to the right and slightly above some text.
 * An information icon of sorts.
 */
export function Annotation({ children, wrapperClassName, annotationClassName }: Props) {
	return (
		<div className={cls(style.wrapper, wrapperClassName)}>
			<div className={cls(style.annotation, annotationClassName)}>{children}</div>
		</div>
	)
}
