import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from "react"

/**
 * Based on: https://github.com/h-kanazawa/react-detectable-overflow
 */

export function useHorizontalOverflowDetector<T>(ref: RefObject<T & HTMLElement>): boolean {
	const [overflow, setOverflow] = useState<boolean>(false)

	const updateState = useCallback(() => {
		if (ref.current == null) {
			return
		}

		const newState = ref.current.offsetWidth < ref.current.scrollWidth
		if (newState === overflow) {
			return
		}
		setOverflow(newState)
	}, [ref, overflow])

	useEffect(() => {
		if (!ref.current) return // wait for the elementRef to be available
		const resizeObserver = new ResizeObserver(updateState)
		resizeObserver.observe(ref.current)
		return () => resizeObserver.disconnect() // clean up
	}, [ref, updateState])

	useLayoutEffect(() => {
		updateState()
	})

	return overflow
}
