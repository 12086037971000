import { WasteTypeImages } from "ProductDefinitions"
import {
	AllowedOnboardingFeatureEnum,
	ProductDefinitionDimensionTemplate,
	QuantityTypeTemplateEnum,
	TransportationConstraintsTemplate,
	TransportationDetailsTemplate,
} from "./templateTypes"

export enum SessionStatus {
	Created = "Created", //The session has been Created
	Opened = "Opened", // Session has been opened in order-ui
	LastUpdatedByClient = "LastUpdatedByClient",
	LastUpdatedByAdmin = "LastUpdatedByAdmin",
	Completed = "Completed", //The session is completed
}

export type OnboardingSessionApiObject = {
	session: OnboardingSession
}

export type OnboardingSession = {
	id: string
	identifier: string
	status: SessionStatus
	createdAt: string
	lastModifiedAt: string
	availableTabs: OnboardingState[]
	allowedOnboardingFeatures: AllowedOnboardingFeatureEnum[]
	customerDetails?: CustomerDetails
	wasteTypeDetails: WasteTypeOnboardingDefinition[]
	transportDetails: TransportationOnboardingDefinition[]
	timeSlotDetails: TimeSlotOnboardingDefinition[]
	serviceDetails: ServiceOnboardingDefinition[]
	productDetails: ProductOnboardingDefinition[]
	activeClientComment: string
	commentHistory: Comment[]
}

export type CustomerDetails = {
	organizationName?: string
	organizationNumber?: string
	address?: string
	postalNumber?: string
	city?: string
	orderEmail?: string
	contact?: ClientContactDetails
	clientConsumerContact?: ClientConsumerContactDetails
}

export type ClientContactDetails = {
	clientEmail?: string
	emailForUpdates?: string
	phonenumber?: string
	name?: string
}

export type ClientConsumerContactDetails = {
	phoneNumber?: string
	email?: string
	homepage?: string
}

export type WasteTypeOnboardingDefinition = {
	id: string
	name: string
	info: string
	typeImage: WasteTypeImages
	categories: string[]
}

export type TransportationOnboardingDefinition = {
	id: string
	name: string
	transportationDetails: TransportationDetailsTemplate
	constraints: TransportationConstraintsTemplate
}

export type TimeSlotOnboardingDefinition = {
	id: string
	name: string
	specificTime: boolean
	description?: string
	startTime?: string
	endTime?: string
}

export type ServiceOnboardingDefinition = {
	id: string
	name: string
	description: string
	quantityType: QuantityTypeTemplateEnum
	timeSlotIds: string[]
}

export type ProductOnboardingDefinition = {
	id: string
	name: string
	typeImage: string
	dimensions: ProductDefinitionDimensionTemplate
	description: string
}

export type TabDetails = {
	onboardingState: OnboardingState
	readableName: string
	description: string
}

export type Comment = {
	text: string
	timeStamp: string
}

export enum OnboardingState {
	CustomerDetails = "customerDetails",
	Products = "products",
	WasteType = "wasteTypes",
	TimeSlot = "timeSlots",
	Service = "services",
	Transportation = "transportations",
	None = "None",
}
