export type GetKlarnaSettingsResponse = {
	klarnaSettings?: GetKlarnaSettings
}

export type GetKlarnaSettings = {
	shopIdentifier: string
	username: string
	enable: KlarnaEnabled
}

export enum KlarnaEnabled {
	None = "None",
	AllPrepaid = "AllPrepaid",
}

export type SetKlarnaSettingsRequest = {
	klarnaSettings: SetKlarnaSettings
}

export type SetKlarnaSettings = {
	shopIdentifier: string
	username: string
	password: string | null
	enabled: KlarnaEnabled
}

export type SetKlarnaSettingsResponse = {
	klarnaSettings: GetKlarnaSettings
}
