const CACHE_TIME = 1000 * 60 * 5 // 5 minutes

interface cacheData {
	[key: string]: { promise: Promise<any>; timeout: number }
}

declare global {
	interface Window {
		cc: any
	}
}

class Cache {
	private promises: cacheData = {}

	delete(key: string) {
		if (this.promises[key]) {
			delete this.promises[key]
		}
	}

	clear() {
		this.promises = {}
	}

	get(key: string) {
		const cachePromise = this.promises[key]
		if (cachePromise && cachePromise.timeout > new Date().getTime()) {
			return cachePromise.promise
		} else {
			this.delete(key)
			return
		}
	}

	set(key: string, promise: Promise<any>) {
		this.promises[key] = {
			promise,
			timeout: new Date().getTime() + CACHE_TIME,
		}
		return promise
	}
}

export const cache = new Cache()
window.cc = cache
