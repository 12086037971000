import { useClient } from "Client/ClientAndUserProvider"
import { Thinking } from "Lottie/AnimationComponents"
import { MbactH1 } from "Orders/Components/Text/MbactH1/MbactH1"
import { MbactH4 } from "Orders/Components/Text/MbactH4/MbactH4"
import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { when } from "Shared/when"
import { Widget, WidgetRendererV2 } from "Shared/Widget/Widgets"
import { AbsolutCentered } from "../../../AbsolutCentered/AbsolutCentered"
import { Loader } from "../../../Loader/Loader"
import { API } from "../../../network/API"
import style from "./PageRenderer.module.css"

enum PageType {
	WidgetPage = "WidgetPage",
}

type ApiWidgetPage = {
	type: PageType.WidgetPage
	widgets: ApiWidget[]
}

export type ApiWidget = {
	widget: Widget
	wrapperStyles: WidgetPageItemStyle[]
}

export type WidgetWrapperStyle = {
	widgetRef: string
	items: WidgetPageItemStyle[]
}

export type WidgetPageItemStyle = {
	breakPoint: WidgetPageItemStyleBreakpoint
	css: string
}

export type WidgetPageItemStyleBreakpoint = WidgetPageItemKeywordBreakpoint | WidgetPageItemCustomBreakPoint

export type WidgetPageItemKeywordBreakpoint = {
	type: "Keyword"
	trigger: CssBreakpoint
}

export type WidgetPageItemCustomBreakPoint = {
	type: "Custom"
	maxWidth: number
}

export enum CssBreakpoint {
	XS = "XS",
	SM = "SM",
	MD = "MD",
	LG = "LG",
	XL = "XL",
	XXL = "XXL",
}

type GetPage = {
	page?: ApiWidgetPage
}

type Props = {}
export const ViewPageOnPath: FC<Props> = () => {
	const { clientName, pagePath } = useParams()

	if (clientName == null || pagePath == null) {
		return <MissingPage />
	}
	return <RenderPage getPageUrl={`/pages/pages-v2/${clientName}/${pagePath}`} />
}

export const PreviewPageRenderer: FC<Props> = () => {
	const { clientName, pageId } = useParams()

	if (clientName == null || pageId == null) {
		return <MissingPage />
	}
	return <RenderPage getPageUrl={`/pages/pages-v2/${clientName}/preview/${pageId}`} />
}

export const MissingPage: FC<{}> = () => {
	const client = useClient()
	return (
		<div>
			<div style={{ marginBottom: "1rem", marginTop: "2rem", textAlign: "center" }}>
				<MbactH1>404!</MbactH1>
			</div>
			<div style={{ marginBottom: "1rem", textAlign: "center" }}>
				<MbactH4>Det verkar som att sidan du söker inte finns.</MbactH4>
			</div>
			<div style={{ maxWidth: "500px", maxHeight: "500px", margin: "auto" }}>
				<Thinking color={client.branding.colors.accent} />
			</div>
		</div>
	)
}

type RenderPageProps = { getPageUrl: string }
const RenderPage: FC<RenderPageProps> = (props) => {
	const [pageState, setPageState] = useState<ApiWidgetPage | "missing" | "loading">("loading")

	const fetchPage = useCallback((getPageUrl: string) => {
		API.getWithRetries<GetPage>(getPageUrl, true, {}, 5)
			.then((res) => {
				setPageState(res.page == null ? "missing" : res.page)
			})
			.catch(() => {
				setPageState("missing")
			})
	}, [])

	useEffect(() => {
		fetchPage(props.getPageUrl)
	}, [props.getPageUrl])

	if (pageState === "loading") {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	} else {
		return (
			<>
				{pageState === "missing" ? (
					<MissingPage />
				) : (
					<>
						{when(pageState.type, {
							[PageType.WidgetPage]: () => <WidgetPageRenderer page={pageState} />,
						})}
					</>
				)}
			</>
		)
	}
}

type WidgetPageRendererProps = { page: ApiWidgetPage }
export const WidgetPageRenderer: FC<WidgetPageRendererProps> = (props) => {
	return (
		<div className={style.page}>
			<WidgetRendererV2 apiWidgets={props.page.widgets} />
		</div>
	)
}
