import { CSSProperties, FC, PropsWithChildren } from "react"
import { cls } from "../../../../Shared/cls"
import style from "./SbtH4.module.css"

type Props = PropsWithChildren & {
	className?: string
	styles?: CSSProperties
}

/* SBT - H4 20pt semibold */
export const SbtH4: FC<Props> = ({ children, className, styles }) => {
	return (
		<h4 className={cls(style.sbtH4, className)} style={styles}>
			{children}
		</h4>
	)
}
