import { ITooltip, Tooltip } from "react-tooltip"
import { cls } from "../cls"
import style from "./StdTooltip.module.css"

type Props = {
	/**
	 * A modified version of isOpen, that accepts false as the tooltip should be hidden, true means it should be open,
	 * and nullish values means to ignore this property.
	 */
	open?: boolean | null
} & ITooltip

/**
 * A wrapper around for Tooltips to apply our default styling.
 */
export function StdTooltip({ variant, className, classNameArrow, open, children, ...props }: Props) {
	let isOpen = undefined
	let hidden = undefined

	if (open != null) {
		isOpen = open
		hidden = !open
	}

	return (
		<Tooltip
			variant={variant ?? "light"}
			className={cls(style.tooltipWrapper, className)}
			classNameArrow={cls(style.tooltipArrowBorder, classNameArrow)}
			isOpen={isOpen}
			hidden={hidden}
			{...props}>
			{children}
		</Tooltip>
	)
}
