import { DateTime } from "luxon"
import { ChangeEvent, FC, useState } from "react"
import { cls } from "Shared/cls"
import { getLogger } from "../../../../Logging/getLogger"
import { styleMap } from "../Buttons/Buttons"
import style from "./NativeModuleTimePicker.module.css"

const logger = getLogger("NativeModuleTimePicker")

type Props = {
	selected: boolean
	onTimeSelected: (time: string) => void
	defaultValue: string | null
	className?: string
	disabled?: boolean
}

export const NativeModuleTimePicker: FC<Props> = ({ selected, onTimeSelected, defaultValue, className, disabled }) => {
	const [selectedTime, setSelectedTime] = useState<string>(() => {
		if (defaultValue && DateTime.fromFormat(defaultValue, "HH:ss").isValid) {
			return defaultValue
		}

		return "12:00"
	})

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setSelectedTime(value)
		onTimeSelected(value)
	}

	const btnStyle = selected ? "Finalize" : "Accent"
	const newClassName = cls(style.chooserButton, styleMap[btnStyle], style.baseButton, className)

	return (
		<input
			disabled={disabled}
			className={newClassName}
			type="time"
			value={selectedTime}
			onFocus={(e) => {
				try {
					e.target.showPicker()
				} catch (e: unknown) {
					logger.debug("Exception while showing date picker", e)
				}
				onTimeSelected(selectedTime)
				setSelectedTime(selectedTime)
			}}
			onChange={handleChange}
		/>
	)
}
