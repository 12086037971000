import { cls } from "../../Shared/cls"
import style from "./Header.module.css"
import SkrappyLogo from "./Skrappy_turkos.svg"

export const Header = () => {
	return (
		<header className={cls(style.container, style.header)}>
			<img src={SkrappyLogo} alt="Skrappy" />
		</header>
	)
}
