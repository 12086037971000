import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { isArray, isEqual, omit } from "lodash"
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { ConsumerProjectMode, useConsumerCatalog } from "../../../Client/ConsumerCatalogContext"
import { useConsumer } from "../../../Client/ConsumerContext"
import { addNewProject } from "../../../CustomerPortal/CustomerPortalProjectsList/CustomerPortalProjectsList"
import { GetProject } from "../../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { PermissionAreaLocation, usePermissions } from "../../../PermissionContext"
import { cls } from "../../../Shared/cls"
import { useBrandedLocalStorage } from "../../../Shared/useBrandedLocalStorage"
import { AccentButton } from "../../Components/Form/Buttons/Buttons"
import { MbactH1 } from "../../Components/Text/MbactH1/MbactH1"
import { Project, ProjectSchema } from "../../order-data-model"
import { ProductSelectionReturnValue } from "../../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { InputProject, ProjectInputModule } from "../../ProjectInputModule/ProjectInputModule"
import { ProjectSelectModule } from "../../ProjectSelectModule/ProjectSelectModule"
import { removeModalOpen } from "../OrderContainer"
import orderContainerStyle from "../OrderContainer.module.css"
import style from "./ProjectSelection.module.css"

type Props = {
	project: Project | GetProject | null
	onProjectSelected: (
		project: Project | GetProject,
		isNew: boolean,
		productSelectionReturnValues: ProductSelectionReturnValue[] | null,
	) => void
	currentOrderItemProject: Project | GetProject | null
	onSetShowProjectSelectModule: () => void
	onSetShowProjectInputModule: () => void
	onProjectInputClose: () => void
	onProjectSelectClose: (reason?: "esc" | "backdropClick" | "closedCross" | string) => void
	invisible?: boolean
}

export type ProjectSelectionRefProps = {
	setEditProject: (obj: InputProject | null) => void
	setShowProjectSelectModule: (show: boolean, onSelected: (project: Project | GetProject) => void) => void
	close: () => void
	isOpen: boolean
}

export const ProjectSelection = forwardRef((props: Props, ref: ForwardedRef<ProjectSelectionRefProps>) => {
	const auth = useAuth()
	const client = useClient()
	const consumer = useConsumer()
	const consumerCatalog = useConsumerCatalog()
	const permissions = usePermissions()

	const [queryParams, setQueryParams] = useSearchParams()
	const [oldProjects, setOldProjects] = useBrandedLocalStorage("old-projects", z.array(ProjectSchema), {
		defaultValue: [],
	})
	const [editProject, setEditProject] = useState<InputProject | null>(null)
	const [showProjectSelectModule, setShowProjectSelectModule] = useState(false)

	const productSelectionReturnValues = useRef<ProductSelectionReturnValue[] | null>(null)

	const showProjectRefOnSelect = useRef<((project: Project | GetProject) => void) | null>(null)

	useImperativeHandle(
		ref,
		() => ({
			setEditProject(obj: InputProject | null) {
				setEditProject(obj)
			},

			setShowProjectSelectModule(show: boolean, onSelected: (project: Project | GetProject) => void) {
				if (!editProject) {
					setShowProjectSelectModule(show)
				}
				showProjectRefOnSelect.current = onSelected
			},

			close: () => {
				setShowProjectSelectModule(false)
			},
			isOpen: showProjectSelectModule,
		}),
		[auth.IsLoggedIn, editProject, oldProjects.length, showProjectSelectModule],
	)

	useEffect(() => {
		localStorage.setItem(`${client.identifier}.old-projects`, JSON.stringify(oldProjects))
	}, [client.identifier, oldProjects])

	function setProject(project: Project | GetProject, isNew: boolean) {
		// Only do this append stuff if the project is from localstorage and if we're not logged in
		if (!("id" in project) && !auth.IsLoggedIn) {
			if (!project.contactName) {
				delete project.contactName
			}

			if (!project.contactPhone) {
				delete project.contactPhone
			}
			if (!project.contactPersons) {
				delete project.contactPersons
			}
			if (!oldProjects.find((v) => isEqual(v, project))) {
				const index = oldProjects.findIndex(
					(v) => isEqual(v, omit(project, "zipcode", "coordinates")) || isEqual(v, omit(project, "zipcode")),
				)
				// If the old and new project match when we omit zip and coordinates from the new project
				// it lacked the info from the start, and as such, we add it to the project instead of duplicating it
				if (index > -1) {
					oldProjects[index].zipcode = project.zipcode
					oldProjects[index].coordinates = project.coordinates
					setOldProjects([...oldProjects])
				} else {
					setOldProjects([project, ...oldProjects])
				}
			}
		}

		const func = showProjectRefOnSelect?.current

		const select = (_project: Project | GetProject) => {
			props.onProjectSelected(_project, isNew, productSelectionReturnValues.current)
			productSelectionReturnValues.current = null

			if (func) {
				func(_project)
			}

			showProjectRefOnSelect.current = null
		}

		if (isNew && auth.IsLoggedIn && auth.Me) {
			let consumerId = consumer?.consumerId

			if (consumerId) {
				addNewProject(client.identifier, consumerId, project, (res) => {
					select(res)
				})
			} else {
				select(project)
			}
		} else {
			select(project)
		}
	}

	function onProjectInputClose(reason?: string) {
		setEditProject(null)
		removeModalOpen(queryParams, setQueryParams)

		if (reason !== "done") {
			productSelectionReturnValues.current = null
		}

		props.onProjectInputClose()
	}

	function onProjectSelectClose(reason?: string) {
		setShowProjectSelectModule(false)
		if (
			(!document.body.classList.contains("mobile-basket-open") &&
				reason !== "old_project" &&
				reason !== "new_project") ||
			!reason
		) {
			removeModalOpen(queryParams, setQueryParams)
		}
		props.onProjectSelectClose(reason)
	}

	function modalElements(): JSX.Element | null {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Read)) {
			return null
		}

		return (
			<>
				{editProject ? (
					<ProjectInputModule inputProject={editProject} onDone={setProject} onClose={onProjectInputClose} />
				) : null}
				{showProjectSelectModule ? (
					<ProjectSelectModule
						openNewWhenNoProjects={consumerCatalog.features.projectMode === ConsumerProjectMode.SimpleAddress}
						oldProjects={oldProjects}
						onSelected={(project) => {
							setProject(project, false)
							onProjectSelectClose("select")
						}}
						onNewProject={() => {
							setEditProject({ isNew: true, project: {} })
						}}
						onClose={onProjectSelectClose}
						currentSelectedProject={props.currentOrderItemProject}
					/>
				) : null}
			</>
		)
	}

	function projectContactSection(): JSX.Element | null {
		if (!props.project) {
			return null
		}

		if ("contactPersons" in props.project) {
			const contactPersons = props?.project?.contactPersons
			if (isArray(contactPersons) && contactPersons.length > 0) {
				return (
					<div className={orderContainerStyle.projectSubText}>
						{contactPersons.map((item, index) => {
							return `${index > 0 ? "; " : ""}${item.name}, ${item.phone}`
						})}
					</div>
				)
			}
		} else {
			if (props.project.contactName || props.project.contactPhone) {
				return (
					<div className={orderContainerStyle.projectSubText}>
						{props.project.contactName}, {props.project.contactPhone}
					</div>
				)
			}
		}

		return null
	}

	function fullProjectView(): JSX.Element {
		return (
			<div className={orderContainerStyle.section} style={{ maxWidth: "780px" }}>
				<div className={orderContainerStyle.sectionH1}>
					<MbactH1>Leveransinformation</MbactH1>
				</div>
				<div className={orderContainerStyle.projectCell}>
					{props.project !== null ? (
						<div
							className={orderContainerStyle.selectedProjectSection}
							onClick={() => {
								setShowProjectSelectModule(true)
								props.onSetShowProjectSelectModule()
							}}>
							<div>
								<div className={orderContainerStyle.projectMainText}>
									{props.project.marking ||
										("id" in props.project ? props.project.address.street : props.project.street)}{" "}
								</div>
								<div className={orderContainerStyle.projectSubText}>
									{"id" in props.project ? props.project.address.street : props.project.street},{" "}
									{"id" in props.project
										? (props.project.address.zipCode || "") + " "
										: (props.project.zipcode || "") + " "}
									{"id" in props.project ? props.project.address.city : props.project.city}
								</div>
								{projectContactSection()}
							</div>
							<div>
								<FontAwesomeIcon className={orderContainerStyle.sectionIconColor} icon={faCheck} />
							</div>
						</div>
					) : oldProjects.length > 0 || auth.IsLoggedIn ? (
						<div
							className={cls(orderContainerStyle.selectExistingProjectSection)}
							onClick={() => {
								setShowProjectSelectModule(true)
								props.onSetShowProjectSelectModule()
							}}>
							<span>Välj befintligt projekt...</span>
							<div className={orderContainerStyle.existingProjectPlus}>+</div>
						</div>
					) : null}
					{permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Create) ? (
						<div
							className={cls(
								orderContainerStyle.sectionOption,
								orderContainerStyle.newProjectButton,
								oldProjects.length === 0 && !auth.IsLoggedIn
									? orderContainerStyle.newProjectButtonFullWidth
									: undefined,
							)}
							onClick={() => {
								setEditProject({ isNew: true, project: {} })
								props.onSetShowProjectInputModule()
							}}>
							<span>Nytt projekt</span>
							<div className={orderContainerStyle.newProjectPlus}>+</div>
						</div>
					) : null}
				</div>
			</div>
		)
	}

	function simpleAddressView(): JSX.Element {
		return (
			<div className={orderContainerStyle.section}>
				<div className={orderContainerStyle.sectionH1}>
					<MbactH1>Leveransinformation</MbactH1>
				</div>
				<div className={style.simpleAddressWrapper}>
					{!props.project ? (
						<>
							<div>
								<div className={style.header}>Fyll i din adress</div>
								<div className={style.subHeader}>Så att du ser ditt leveranspris</div>
							</div>
							{simpleAddressSelector()}
						</>
					) : (
						<>
							{selectedSimpleAddress()}
							{simpleAddressSelector()}
						</>
					)}
				</div>
			</div>
		)
	}

	function simpleAddressSelector(): JSX.Element | null {
		return (
			<AccentButton
				className={style.simpleAddressSelector}
				onClick={() => {
					setShowProjectSelectModule(true)
					props.onSetShowProjectSelectModule()
				}}>
				{!props.project ? (
					<>
						<span className={style.normal}>Välj adress</span>
						<span className={style.collapsed}>Välj</span>
					</>
				) : (
					<>
						<span className={style.normal}>Ändra adress</span>
						<span className={style.collapsed}>Ändra</span>
					</>
				)}
			</AccentButton>
		)
	}

	function selectedSimpleAddress(): JSX.Element | null {
		if (!props.project) {
			return null
		}

		let street, city, zip

		if ("id" in props.project) {
			street = props.project.address.street
			city = props.project.address.city
			zip = props.project.address.zipCode
		} else {
			street = props.project.street
			city = props.project.city
			zip = props.project.zipcode
		}
		return (
			<div
				className={style.simpleAddressSelectedProjectSection}
				onClick={() => {
					setShowProjectSelectModule(true)
					props.onSetShowProjectSelectModule()
				}}>
				<div style={{ marginRight: "20px" }}>
					<div className={cls(orderContainerStyle.projectMainText, style.simpleAddressText)}>
						<strong>{street}</strong>
					</div>
					<div className={cls(orderContainerStyle.projectSubText, style.simpleAddressText)}>
						{zip ? zip + ", " : ""}
						{city}
					</div>
				</div>
			</div>
		)
	}

	function projectView(): JSX.Element | null {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Read) || props.invisible === true) {
			return null
		}

		return exhaustive(consumerCatalog.features.projectMode, {
			[ConsumerProjectMode.FullProject]: () => {
				return fullProjectView()
			},
			[ConsumerProjectMode.SimpleAddress]: () => {
				return simpleAddressView()
			},
		})
	}

	return (
		<>
			{modalElements()}
			{projectView()}
		</>
	)
})
