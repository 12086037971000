import { InformationIcon } from "../../Icons/Icon"
import { Annotation } from "./Annotation"
import style from "./InformationAnnotationIcon.module.css"

export function InformationAnnotationIcon() {
	return (
		<Annotation>
			<InformationIcon size={null} iconClassName={style.iconClass} />
		</Annotation>
	)
}
