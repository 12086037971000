import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { v4 } from "uuid"
import { cls } from "../../../Shared/cls"
import style from "./ExpandableIncrementor.module.css"

type Props = {
	name: string
	defaultValue: number
	min: number
	max: number
	validation?: (name: string, value: number) => boolean
	onChange: (
		name: string,
		value: number,
		action: "addClick" | "removeClick" | "text",
		currentValue: number,
		componentId: string,
	) => void
	disableIncrement?: boolean
	onBlur?: (value: number, componentId: string) => void
	currentValue?: { value: number; show: boolean }
}

export type ExpandableIncrementorRefProps = {
	id: string
	reset: () => void
}

export const ExpandableIncrementor = forwardRef((props: Props, ref: ForwardedRef<ExpandableIncrementorRefProps>) => {
	const [currentValue, setCurrentValue] = useState<number>(
		props.currentValue !== undefined ? props.currentValue.value : props.defaultValue,
	)
	const initiallyShown = useRef(false)

	const componentId = useRef(v4())

	useEffect(() => {
		if (props.currentValue !== undefined) {
			setCurrentValue(props.currentValue.value)
			initiallyShown.current = props.currentValue.show
		}
	}, [props.currentValue])

	useImperativeHandle(
		ref,
		() => ({
			id: componentId.current,
			reset() {
				setCurrentValue(props.defaultValue)
				initiallyShown.current = false
			},
		}),
		[props.defaultValue],
	)

	const validate = (aValue: number) => {
		if (aValue < props.min) return false

		if (aValue > props.max) return false

		return !(props.validation !== undefined && !props.validation(props.name, aValue))
	}

	const changeTo = (newValue: number, action: "addClick" | "removeClick" | "text", skipValidate: boolean) => {
		if (skipValidate || validate(newValue)) {
			setCurrentValue(newValue)
			props.onChange(props.name, newValue, action, currentValue, componentId.current)
		} else {
			if (newValue === 0) {
				setCurrentValue(1)
				props.onChange(props.name, 1, action, currentValue, componentId.current)
			} else if (newValue < props.min) {
				setCurrentValue(props.min)
				props.onChange(props.name, props.min, action, currentValue, componentId.current)
			} else {
				setCurrentValue(props.max)
				props.onChange(props.name, props.max, action, currentValue, componentId.current)
			}
		}
	}

	const increment = () => changeTo(currentValue + 1, "addClick", false)

	const decrement = () => changeTo(currentValue - 1, "removeClick", false)

	return (
		<div
			className={cls(style.wrapper, {
				[style.noWrapper]: currentValue === 0 && !initiallyShown.current,
			})}>
			<button
				disabled={!validate(currentValue - 1)}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					decrement()
					if (currentValue - 1 === 0 && props.onBlur) {
						initiallyShown.current = false
						props.onBlur(0, componentId.current)
					}
				}}
				type="button"
				className={cls(style.buttons, {
					[style.dNone]: currentValue === 0 && !initiallyShown.current,
				})}>
				-
			</button>
			<input
				type="number"
				id={props.name}
				key={props.name}
				className={cls(style.value, { [style.dNone]: currentValue === 0 && !initiallyShown.current })}
				value={currentValue === -1 ? "" : currentValue}
				onChange={(event) => {
					if (event.target.value === "") {
						changeTo(0, "text", true)
					} else {
						changeTo(event.target.valueAsNumber, "text", false)
					}
				}}
				onFocus={(e) => {
					e.target.addEventListener(
						"wheel",
						function (e) {
							e.preventDefault()
						},
						{ passive: false },
					)
				}}
				onBlur={() => {
					if (currentValue < props.min) {
						changeTo(props.min, "text", true)
					}
					if (currentValue === 0) {
						initiallyShown.current = false
					}
					if (props.onBlur) {
						props.onBlur(currentValue < props.min ? props.min : currentValue, componentId.current)
					}
				}}
			/>
			<button
				disabled={!validate(currentValue + 1) || props.disableIncrement === true}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					initiallyShown.current = true
					increment()
				}}
				type="button"
				className={style.buttons}>
				+
			</button>
		</div>
	)
})
