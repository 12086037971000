import { exhaustive } from "exhaustive"
import { DiscountRateSubTypeMapping } from "../../Client/articleTrees/ArticleTreeDataModel"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { DiscountDescription } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { DiscountExplanationTooltip } from "../../CustomerPortal/OrderDetails/ExplainDiscount/ExplainDiscount"
import { InformationAnnotationIcon } from "../Annotation/InformationAnnotationIcon"
import { cls } from "../cls"
import { getKey } from "../getKey"
import { lets } from "../lets"
import { currencyFormatter } from "../numberFormatter"
import style from "./Price.module.css"

type Priced = {
	price: number
	taxPercentage: number
}

type PriceProps = {
	priced: Priced
	discountDescription?: DiscountDescription | null
	className?: string
	classNamePrice?: string
	classNameDiscount?: string
}

export function Price({ priced, discountDescription, className, classNamePrice, classNameDiscount }: PriceProps) {
	const consumerCatalog = useConsumerCatalog()

	const discounted = lets(discountDescription?.discountRate, (it): Discounted => {
		it.subType = DiscountRateSubTypeMapping[it.type]

		return exhaustive(it, "subType", {
			OnEach: (discountRate): Discounted =>
				exhaustive(discountRate, "type", {
					PerItemAmount: (discountRate): Discounted => {
						return { discountedPrice: priced.price + discountRate.price }
					},
					Percentage: (discountRate): Discounted => {
						return { discountedPrice: priced.price * (1 - discountRate.percent) }
					},
				}),
			OnTotal: (discountRate): Discounted => {
				return { discountedPrice: null }
			},
		})
	})

	const priceStr = (() => {
		if (consumerCatalog.renderVAT) {
			return currencyFormatter(priced.price * (1 + priced.taxPercentage))
		} else {
			return currencyFormatter(priced.price)
		}
	})()
	const discountedPriceStr = lets(discounted?.discountedPrice, (it) => {
		if (consumerCatalog.renderVAT) {
			return currencyFormatter(it * (1 + priced.taxPercentage))
		} else {
			return currencyFormatter(it)
		}
	})
	const displayOrderDiscountMarker = discounted != null && discountedPriceStr == null

	const key = getKey(priced)
	const toolTipAnchor = `priceWrapper-${key}`

	return (
		<>
			<DiscountExplanationTooltip
				discountDescription={discountDescription}
				anchorSelect={`#${toolTipAnchor}`}
				taxPercentage={consumerCatalog.renderVAT ? priced.taxPercentage : null}
			/>
			<span className={cls(style.wrapper, className)} id={toolTipAnchor} key={key}>
				{lets(discountedPriceStr, (it) => (
					<span className={cls(classNameDiscount)}>{it}</span>
				))}
				<span className={cls({ [style.overriddenPrice]: discountedPriceStr != null }, classNamePrice)}>
					{priceStr}
					{displayOrderDiscountMarker ? <InformationAnnotationIcon /> : null}
				</span>
			</span>
		</>
	)
}

type Discounted = {
	discountedPrice: number | null
}
