import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { Loader } from "../Loader/Loader"

export const ClientRoot = () => {
	const location = useLocation()
	/**
	 * Using `useNavigate` instead of `useNavigator` since this is a special case and
	 * only relative navigation is performed.
	 */
	const navigate = useNavigate()

	useEffect(() => {
		if (location.pathname.endsWith("/")) {
			navigate("order", { replace: true })
		} else {
			/*
			The root without a trailing slash should never be triggered in production
			since there is a redirect rule set for CloudFlare, if the empty path is
			triggered anyway, probably during development, the url needs to be updated
			and the page needs to be reloaded for our dynamic PWA manifest to work.
			*/
			navigate(location.pathname + "/", { replace: true })
			window.location.reload()
		}
	}, [location.pathname, navigate])

	return (
		<AbsolutCentered>
			<Loader />
		</AbsolutCentered>
	)
}
