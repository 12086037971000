/**
 * Helper function to make sure an index for any array is valid and within the bounds of the array.
 */
export function indexWithinBounds<T>(index: number | null | undefined, array: T[]) {
	if (index != null && !Number.isNaN(index) && Number.isInteger(index) && index > -1 && index < array.length) {
		return index
	}

	return null
}
