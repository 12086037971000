import { FC } from "react"
import { AcceptButton, DenyButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import { SbtH1 } from "../../Orders/Components/Text/SbtH1/SbtH1"
import { cls } from "../cls"
import style from "./ConfirmModal.module.css"

export enum ConfirmModalCloseReason {
	Close,
	Deny,
	Accept,
}

export type ConfirmModalProps = {
	onClose: (reason: ConfirmModalCloseReason) => void
	text: string
	customContent?: JSX.Element | null
	className?: string
}

export const ConfirmModal: FC<ConfirmModalProps> = (props: ConfirmModalProps) => {
	return (
		<ModulePopup
			className={cls(props.className, style.modal)}
			onClose={() => {
				props.onClose(ConfirmModalCloseReason.Close)
			}}>
			<div className={style.wrapper}>
				<SbtH1>Är du säker?</SbtH1>
				<div className={style.content}>{props.text}</div>
				{props.customContent ? <div className={style.customContent}>{props.customContent}</div> : null}
				<div className={style.buttons}>
					<AcceptButton
						onClick={() => {
							props.onClose(ConfirmModalCloseReason.Accept)
						}}>
						Ja
					</AcceptButton>
					<DenyButton
						onClick={() => {
							props.onClose(ConfirmModalCloseReason.Deny)
						}}>
						Nej
					</DenyButton>
				</div>
			</div>
		</ModulePopup>
	)
}
