import { Dispatch, FC, SetStateAction, useEffect } from "react"
import { MeResponseAccountType } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { OrderAcceptance } from "../../ClientPortal/OrderAcceptance/OrderAcceptance"
import { OrderCompletion } from "../../ClientPortal/OrderCompletion/OrderCompletion"
import { removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { GetOrder } from "../CustomerPortalOrders/CustomerPortalOrders"
import { OrderInformation } from "../OrderInformartion/OrderInformation"

type OrderInformationModalHandlerProps = {
	acceptanceOrder: GetOrder | null
	completionOrder: GetOrder | null
	informationOrder: GetOrder | null
	setAcceptanceOrder: Dispatch<SetStateAction<GetOrder | null>>
	setCompletionOrder: Dispatch<SetStateAction<GetOrder | null>>
	setInformationOrder: Dispatch<SetStateAction<GetOrder | null>>
	onDone: (order: GetOrder) => void
	onUpdate: (order: GetOrder) => void
	onClose: () => void
}

export const OrderInformationModalHandler: FC<OrderInformationModalHandlerProps> = (props) => {
	const auth = useAuth()
	const permissions = usePermissions()

	useEffect(() => {}, [props.acceptanceOrder, props.informationOrder, props.completionOrder])

	if (!props.informationOrder && !props.completionOrder && !props.acceptanceOrder) {
		return null
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Orders_Read)) {
		return null
	}

	if (props.completionOrder && auth?.Me?.type === MeResponseAccountType.Client) {
		return (
			<OrderCompletion
				order={props.completionOrder}
				onClose={() => {
					props.setCompletionOrder(null)
					props.onClose()
					removeModalOpenClass()
				}}
				onDone={(order) => {
					props.onDone(order)
				}}
				onUpdate={props.onUpdate}
			/>
		)
	}

	if (props.acceptanceOrder && auth?.Me?.type === MeResponseAccountType.Client) {
		return (
			<OrderAcceptance
				order={props.acceptanceOrder}
				onClose={() => {
					props.setAcceptanceOrder(null)
					props.onClose()
					removeModalOpenClass()
				}}
				onDone={(order) => {
					props.onDone(order)
				}}
				onUpdate={props.onUpdate}
			/>
		)
	}

	// Above it checks if all three order inputs are falsy and returns null if so
	// This means that when we get here at least on of them is truthy, but typescript is mad for some reason, hence the ! to force typescript
	const order: GetOrder = (props.informationOrder || props.completionOrder || props.acceptanceOrder)!

	return (
		<OrderInformation
			order={order}
			onClose={() => {
				props.setInformationOrder(null)
				props.onClose()
				removeModalOpenClass()
			}}
			onUpdate={props.onUpdate}
		/>
	)
}
