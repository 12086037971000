import { FC } from "react"
import { v4 } from "uuid"
import { StdTooltip } from "../../../Shared/StdTooltip/StdTooltip"
import style from "./Incrementor.module.css"

type Props = {
	name: string
	value: number
	min: number
	max: number
	validation?: (name: string, value: number) => boolean
	onChange: (name: string, value: number) => void
	disableIncrement?: boolean
	disableIncrementTooltip?: string
}

export const Incrementor: FC<Props> = ({
	max,
	min,
	name,
	value,
	onChange,
	validation,
	disableIncrement,
	disableIncrementTooltip,
}: Props) => {
	const validate = (aValue: number) => {
		if (aValue < min) return false

		if (aValue > max) return false

		return !(validation !== undefined && !validation(name, aValue))
	}

	const changeTo = (newValue: number, skipValidate?: boolean) => {
		if (skipValidate || validate(newValue)) {
			onChange(name, newValue === -1 ? 0 : newValue)
		} else {
			if (newValue === 0) {
				onChange(name, 1)
			} else if (newValue < min) {
				onChange(name, min)
			} else {
				onChange(name, max)
			}
		}
	}

	const increment = () => changeTo(value + 1)

	const decrement = () => changeTo(value - 1)

	const randomId: string = v4()

	return (
		<div className={style.wrapper}>
			<button disabled={!validate(value - 1)} onClick={decrement} type="button" className={style.buttons}>
				-
			</button>
			<input
				type="number"
				id={name}
				key={name}
				className={style.value}
				value={value === -1 ? "" : value}
				onChange={(event) => {
					if (event.target.value === "") {
						changeTo(min, true)
					} else {
						changeTo(event.target.valueAsNumber)
					}
				}}
				onFocus={(e) => {
					e.target.addEventListener(
						"wheel",
						function (e) {
							e.preventDefault()
						},
						{ passive: false },
					)
				}}
				onBlur={() => {
					if (value < min) {
						changeTo(min)
					}
				}}
			/>
			{disableIncrementTooltip && (!validate(value + 1) || disableIncrement === true) ? (
				<StdTooltip id={randomId} openOnClick={true} />
			) : null}
			<button
				disabled={!validate(value + 1) || disableIncrement === true}
				onClick={increment}
				type="button"
				className={style.buttons}>
				<div data-tooltip-id={randomId} data-tooltip-content={disableIncrementTooltip} data-tooltip-place="top">
					+
				</div>
			</button>
		</div>
	)
}
