import { faArrowRotateRight, faFileLines, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cloneDeep, filter, isNull, map, split } from "lodash"
import { ChangeEvent, FC, useEffect, useState } from "react"
import { v4 } from "uuid"
import { MeResponseAccountType } from "../../../Auth/Auth.types"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { TrashIcon } from "../../../Icons/Icon"
import { getLogger } from "../../../Logging/getLogger"
import { API } from "../../../network/API"
import { FinalizeButton } from "../../../Orders/Components/Form/Buttons/Buttons"
import { maxImageSize, SelectedFile } from "../../../Orders/Components/OrderConfirmCheckout/OrderConfirmCheckout"
import orderConfirmCheckoutStyle from "../../../Orders/Components/OrderConfirmCheckout/OrderConfirmCheckout.module.css"
import { cleanMimeString, getImageURLFromBase64 } from "../../../Orders/Helpers"
import { PermissionAreaLocation, usePermissions } from "../../../PermissionContext"
import { cls } from "../../../Shared/cls"
import {
	CompanyConsumer,
	GetOrderFile,
	OrderFileUploadedBy,
	PrivateConsumer,
} from "../../CustomerPortalOrders/CustomerPortalOrders"
import { UploadFileResponse } from "../ActivityTab/OrderDetailsActivity"
import orderDetailsStyle from "../OrderDetails.module.css"
import style from "./OrderDetailsAttachments.module.css"

const logger = getLogger("OrderDetailsAttachmentsTab")

type Props = {
	orderId: string
	customer: CompanyConsumer | PrivateConsumer
	files: GetOrderFile[]
	onAmount: (amount: number) => void
}

type CustomSelectedFile = SelectedFile & { uploadedBy?: OrderFileUploadedBy; fileId?: string }

export type Files = { [imageId: string]: CustomSelectedFile }

type FileAddResult = FileAddResultOk | FileAddResultFail

type FileAddResultOk = {
	type: "ok"
	files: SelectedFile[]
}

type FileAddResultFail = {
	type: "fail"
	message: string
}

function isImage(contentType: string): boolean {
	return (
		contentType === "image/png" ||
		contentType === "image/jpg" ||
		contentType === "image/jpeg" ||
		contentType === "image/gif"
	)
}
export function orderDetailsGetFilesFromFileInput(
	e: ChangeEvent<HTMLInputElement>,
	maxAllowedToAdd: number,
): Promise<FileAddResult> {
	e.preventDefault()
	if (e.target.files && e.target.files.length > 0) {
		const fileList: FileList = e.target.files
		let imagesAdded: number = 0
		if (fileList.length > maxAllowedToAdd) {
			e.target.value = ""
			return Promise.resolve({
				type: "fail",
				message: "För många valda filer, välj maximalt 5 åt gången",
			})
		}
		return new Promise<FileAddResult>((resolve) => {
			const files: SelectedFile[] = []

			for (let i = 0; i < fileList.length && i < maxAllowedToAdd; i++) {
				const file = fileList.item(i)

				if (!isNull(file)) {
					const reader = new FileReader()
					reader.readAsDataURL(file as Blob)
					reader.onload = () => {
						const res = reader.result
						if (!isNull(res) && (res as string)) {
							const resSplit = split(res as string, ",", 10)
							const res0 = resSplit[0]
							const res1 = resSplit[1]

							if (res0 && res1) {
								const cleanMime = cleanMimeString(res0)
								const url = isImage(file.type)
									? getImageURLFromBase64(res1, cleanMime)
									: Promise.resolve<string>("")

								url.then((res) => {
									const tooBig = file.size > maxImageSize
									const newFile: SelectedFile = {
										id: v4(),
										imageName: file.name,
										originalName: file.name,
										url: res,
										file: file,
										uploading: !tooBig,
										uploaded: false,
										uploadFailed: false,
										tooBig: tooBig,
										type: isImage(file.type) ? "Image" : "Generic",
									}
									if (!newFile.tooBig) {
										files.push(newFile)
									}
									imagesAdded++
								}).then(() => {
									if (imagesAdded === fileList.length || imagesAdded === maxAllowedToAdd) {
										e.target.value = ""
										resolve({ type: "ok", files: files })
									}
								})
							}
						}
					}
					reader.onerror = (error) => {
						logger.error("Failed to resolve image: ", error)
						imagesAdded++
					}
				}
			}
		})
	} else {
		return Promise.resolve({ type: "fail", message: "no files" })
	}
}

export function orderFileToSelectedImage(file: GetOrderFile): CustomSelectedFile {
	return {
		id: file.attachmentId,
		fileId: file.fileId,
		imageName: file.fileId,
		originalName: file.originalFileName || "",
		url: file.url,
		file: undefined,
		uploading: false,
		uploaded: true,
		uploadFailed: false,
		tooBig: false,
		uploadedBy: file.uploadedBy,
		type: file.type,
	}
}

export const OrderDetailsAttachments: FC<Props> = ({ orderId, customer, onAmount, ...props }) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	const [allFiles, setAllFiles] = useState<Files>({})

	const [uploadError, setUploadError] = useState<string | null>(null)

	const clientFiles = filter(allFiles, (file) => {
		return !!file.uploadedBy && file.uploadedBy.type === "Client"
	})

	const consumerFiles = filter(allFiles, (file) => {
		return !file.uploadedBy || (!!file.uploadedBy && file.uploadedBy?.type === "Consumer")
	})

	useEffect(() => {
		if (props.files) {
			setAllFiles(
				props.files.reduce<Files>((result, element) => {
					result[element.attachmentId] = orderFileToSelectedImage(element)
					return result
				}, {}),
			)
		}
	}, [props.files])

	useEffect(() => {
		onAmount(Object.keys(allFiles).length)
	}, [allFiles])

	function blockContent(files: CustomSelectedFile[]) {
		return (
			<div style={{ wordBreak: "break-all", marginTop: "10px" }} className={orderDetailsStyle.blockContent}>
				<div className={cls(orderDetailsStyle.orderImagesWrapper, style.orderImagesWrapper)}>
					{files.length === 0 ? <strong style={{ margin: "auto" }}>Inga bilagor tillagda</strong> : null}
					<div
						className={orderConfirmCheckoutStyle.orderItemSelectedImages}
						style={{ gap: "12px" }}
						key={"filesListWrapper"}>
						{map(files, (selectedImage) => {
							return (
								<div key={v4()}>
									<div className={cls(orderConfirmCheckoutStyle.imageWrapper, style.imageWrapper)}>
										<div
											className={orderConfirmCheckoutStyle.error}
											style={{ display: selectedImage.uploadFailed ? "flex" : "none" }}>
											<FontAwesomeIcon
												icon={faArrowRotateRight}
												onClick={() => {
													if (selectedImage.uploading) {
														return
													}
													uploadFile(selectedImage.id, allFiles)
												}}
											/>
										</div>
										{auth.IsLoggedInClient ||
										(auth.IsLoggedInConsumer &&
											(!selectedImage.uploadedBy || selectedImage.uploadedBy.type === "Consumer") &&
											permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Delete)) ? (
											<TrashIcon
												onClick={() => {
													if (selectedImage.uploading) {
														return
													}

													deleteImage(selectedImage)
												}}
												size={26}
												className={orderConfirmCheckoutStyle.imageDeleteIconWrapper}
												iconClassName={orderConfirmCheckoutStyle.imageDeleteIcon}
											/>
										) : null}

										<div
											className={orderConfirmCheckoutStyle.loader}
											style={{ display: selectedImage.uploading ? "flex" : "none" }}>
											<FontAwesomeIcon spin={true} icon={faSpinner} />
										</div>

										{selectedImage.type === "Image" ? (
											<img
												style={{ minWidth: "100px", minHeight: "100px" }}
												alt={""}
												src={selectedImage.url}
												onClick={() => {
													window.open(selectedImage.url, "_blank")
												}}
											/>
										) : null}

										{selectedImage.type === "Generic" ? (
											<div
												className={style.genericFile}
												key={selectedImage.url}
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginBottom: "5px",
												}}
												onClick={() => {
													window.open(selectedImage.url, "_blank")
												}}>
												<FontAwesomeIcon
													style={{
														color: "var(--module-box-icon-color)",
														width: "70%",
														height: "70%",
													}}
													icon={faFileLines}
												/>
											</div>
										) : null}
									</div>
									<div className={style.originalFileName} title={selectedImage.originalName}>
										{selectedImage.originalName}
									</div>
									{selectedImage.uploadFailed ? (
										<div className={orderConfirmCheckoutStyle.errorText}>Uppladdning misslyckades</div>
									) : null}
									{selectedImage.tooBig ? (
										<div className={orderConfirmCheckoutStyle.errorText}>
											Filen är för stor, max 10MB
										</div>
									) : null}
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	}

	function addFiles(e: ChangeEvent<HTMLInputElement>) {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create)) {
			return
		}

		const maxAllowedToAdd = 5 // no more than five at a time
		setUploadError(null)
		orderDetailsGetFilesFromFileInput(e, maxAllowedToAdd)
			.then((res) => {
				if (res.type === "ok") {
					res.files.forEach((file: CustomSelectedFile) => {
						file.uploadedBy = auth.Me
							? auth.Me.type === MeResponseAccountType.Client
								? { type: "Client", userRef: auth.Me.userId }
								: { type: "Consumer", userRef: auth.Me.userId }
							: undefined
						allFiles[file.id] = file
					})
					setAllFiles(Object.assign({}, allFiles))
					return cloneDeep(allFiles)
				} else {
					setUploadError(res.message)
				}
				return null
			})
			.then(async (res) => {
				if (!res) {
					return
				}
				for await (const fileToUpload of Object.values(res).filter((x) => !x.uploaded)) {
					await uploadFile(fileToUpload.id, res)
				}
			})
	}

	function uploadFile(fileId: string, files: Files) {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create)) {
			return Promise.resolve()
		}

		const file = files[fileId]
		if (!file || !file.file) {
			return
		}
		file.uploading = true
		file.uploadFailed = false
		files[fileId] = file
		setAllFiles(Object.assign({}, files))

		const newForm = new FormData()
		newForm.append("file", file.file)
		return API.postRaw<UploadFileResponse>(`/order-ui/order-files-v1/${client.identifier}/${orderId}/upload`, newForm)
			.then((res) => {
				if (res.fileId === "duplicate") {
					delete files[fileId]
				} else {
					const file = files[fileId]

					if (file) {
						file.uploading = false
						file.uploaded = true
						file.imageName = file?.file?.name || res.fileId
						file.url = res.url
						file.fileId = res.fileId

						files[res.fileId] = cloneDeep(file)
						files[res.fileId]!.id = res.fileId
						delete files[fileId]
					}
				}
				setAllFiles(Object.assign({}, files))
			})
			.catch(() => {
				const file = files[fileId]

				if (file) {
					file.uploading = false
					file.uploadFailed = true
					files[fileId] = file
					setAllFiles(Object.assign({}, files))
				}
			})
	}

	function deleteImage(file: CustomSelectedFile) {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Delete)) {
			return
		}

		if (auth.IsLoggedInConsumer && allFiles[file.id]?.uploadedBy?.type === "Client") {
			// Consumer is not allowed to remove client files
			return
		}

		if (file.uploadFailed) {
			delete allFiles[file.id]
			setAllFiles(Object.assign({}, allFiles))
			return
		}

		if (!window.confirm("Är du säker att du vill ta bort bilagan?")) {
			return
		}

		const fileRef = allFiles[file.id]

		if (fileRef) {
			fileRef.uploading = true
			fileRef.uploadFailed = false
			allFiles[file.id] = fileRef
			setAllFiles(Object.assign({}, allFiles))

			API.delete<void>(`/order-ui/order-files-v1/${client.identifier}/${orderId}/${file.fileId}/delete`)
				.then(() => {
					delete allFiles[file.id]
					setAllFiles(Object.assign({}, allFiles))
				})
				.catch(() => {
					const fileRef = allFiles[file.id]

					if (fileRef) {
						fileRef.uploading = false
						fileRef.uploadFailed = true
						allFiles[file.id] = fileRef
						setAllFiles(Object.assign({}, allFiles))
					}
				})
		}
	}

	return (
		<span className={style.wrapper}>
			<div className={style.orderFilesSection} style={{ marginTop: "20px" }}>
				<div className={style.orderFilesSectionHeader}>Uppladdat av {client.clientInfo.clientName}</div>
				{blockContent(clientFiles)}
			</div>
			<div className={style.orderFilesSection}>
				<div className={style.orderFilesSectionHeader}>Uppladdat av {customer.name}</div>
				{blockContent(consumerFiles)}
			</div>
			{client.features.orderUiAllowUploadingOrderImages &&
			permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create) ? (
				<div>
					<FinalizeButton className={style.filesTabUploadButton}>
						<label style={{ color: "var(--finalize-inside-color)" }} htmlFor={orderId + "attachment-input"}>
							<span>Ladda upp bilaga</span>
							<span style={{ fontSize: "26px" }}>+</span>
						</label>

						<input
							style={{ display: "none" }}
							id={orderId + "attachment-input"}
							type="file"
							multiple={true}
							onChange={(e) => {
								addFiles(e)
							}}
						/>
					</FinalizeButton>
					{uploadError ? <div className={style.uploadError}>{uploadError}</div> : null}
				</div>
			) : null}
		</span>
	)
}
