import { useJsApiLoader } from "@react-google-maps/api"
import { isEmpty } from "lodash"
import { FC, useEffect, useState } from "react"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerCatalogInstance, ConsumerProjectMode, useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { PenIcon, TrashIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { Dropdown } from "../../Orders/Components/ExpandableDropdown/ExpandableDropdown"
import { Project } from "../../Orders/order-data-model"
import { ProjectInputContactPersonSelector } from "../../Orders/ProjectInputContactPersonSelector/ProjectInputContactPersonSelector"
import { googleMiniMapOptions, libraries, ProjectInputModule } from "../../Orders/ProjectInputModule/ProjectInputModule"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import {
	addAddressAndExactDeliveryMarkersToMap,
	GoogleMapComponent,
	initGoogleMapsCustomFullscreenControl,
} from "../../Shared/GoogleMapComponent/GoogleMapComponent"
import { GetContactPerson } from "../ConsumerContactPersons/ConsumerContactPersons"
import {
	GetProject,
	ProjectStatus,
	UpdateProjectRequest,
} from "../CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { EditProjectMarkingModal } from "../EditProjectMarkingModal/EditProjectMarkingModal"
import style from "./CustomerPortalProjectDetails.module.css"

type CustomerPortalProjectDetailsProps = {
	allowEditAndDelete: boolean
	consumerId: string
	consumerCatalog: ConsumerCatalogInstance | null
	project: GetProject
	orderAmount: number
	onUpdate: (project: GetProject) => void
	onDelete: (projectId: string) => void
	onMarkingUpdate: (project: GetProject) => void
}
export const CustomerPortalProjectDetails: FC<CustomerPortalProjectDetailsProps> = (
	props: CustomerPortalProjectDetailsProps,
) => {
	const auth = useAuth()
	const client = useClient()
	const consumerCatalogUse = useConsumerCatalog()
	const permissions = usePermissions()

	const [updating, setUpdating] = useState(false)
	const [showEdit, setShowEdit] = useState(false)
	const [showEditMarking, setShowEditMarking] = useState(false)
	const [project, setProject] = useState<GetProject | null>(props.project)

	const consumerCatalog = props.consumerCatalog || consumerCatalogUse

	useEffect(() => {
		setProject(props.project)
	}, [props.project])

	const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	const statusDropdownOptions: Record<ProjectStatus, string> = {
		[ProjectStatus.Active]: "Pågående",
		[ProjectStatus.Inactive]: "Avslutad",
	}

	function updateProjectStatus(projectId: string, status: ProjectStatus) {
		const body: UpdateProjectRequest = {
			project: {
				status: status,
			},
		}
		updateProject(projectId, body)
	}

	function updateProjectContactPersons(projectId: string, contactPersons: GetContactPerson[]) {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update)) {
			return
		}

		const body: UpdateProjectRequest = {
			project: {
				contactPersonIds: contactPersons.map((x) => x.id),
			},
		}
		setTimeout(() => {
			updateProject(projectId, body)
		}, 50)
	}

	function updateProject(projectId: string, body: UpdateProjectRequest) {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update)) {
			return
		}

		setUpdating(true)
		API.patchWithRetries<GetProject, UpdateProjectRequest>(
			`/customer-portal/project-definitions-v1/${client.identifier}/${props.consumerId}/${projectId}`,
			body,
		)
			.then((project) => {
				setProject(project)
				props.onUpdate(project)
				setUpdating(false)
			})
			.catch(() => {
				setUpdating(false)
			})
	}

	function deleteProject() {
		if (!project || !permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Delete)) {
			return
		}

		setUpdating(true)
		API.deleteWithRetries<void, void>(
			`/customer-portal/project-definitions-v1/${client.identifier}/${props.consumerId}/${project.id}`,
		)
			.then(() => {
				props.onDelete(project.id)
				setUpdating(false)
			})
			.catch(() => {
				setUpdating(false)
			})
	}

	function onEditDone(project: Partial<Project>) {
		if (!project) {
			return
		}

		const body: UpdateProjectRequest = {
			project: {
				street: project.street,
				city: project.city,
				zipCode: project.zipcode,
				coordinates: project.coordinates,
				marking: project.marking,
				contactPersonIds: project.contactPersons?.map((x) => x.id) ?? [],
				status: ProjectStatus.Active,
			},
		}
		updateProject(props.project.id, body)
	}

	function idAdditionalDataNeeded(): boolean {
		if (auth.IsLoggedIn && project) {
			if (consumerCatalog.features.projectMode === ConsumerProjectMode.SimpleAddress) {
				return !project.address.coordinates
			} else {
				return (
					isEmpty(project.contactPersons) ||
					!project.address.coordinates ||
					(consumerCatalog.markingRequired && !project.marking)
				)
			}
		}

		return true
	}

	function fullProjectMarkingText() {
		if (!project) {
			return null
		}

		if (consumerCatalog.markingRequired) {
			if (!project.marking) {
				return <div className={style.noMarking}>Märkning saknas</div>
			} else {
				return <div className={style.marking}>{project.marking}</div>
			}
		}

		return <div className={style.marking}>{project.marking || project.address.street}</div>
	}

	function additionalDataText() {
		if (!idAdditionalDataNeeded()) {
			return null
		}

		let text: string
		if (consumerCatalog.features.projectMode === ConsumerProjectMode.SimpleAddress) {
			text = "För att en adress ska vara valbar måste den ha en plats vald i kartan"
		} else if (consumerCatalog.markingRequired) {
			text =
				"För att ett projekt ska vara valbart måste det ha en plats vald i kartan, en märkning satt, och minst en kontaktperson tillagd"
		} else {
			text =
				"För att ett projekt ska vara valbart måste det ha en plats vald i kartan samt minst en kontaktperson tillagd"
		}

		return (
			<div className={style.subTitle} style={{ color: "var(--invalid-color)", fontWeight: 600, fontSize: "14px" }}>
				{text}
			</div>
		)
	}

	function editAndDeleteSection() {
		if (props.allowEditAndDelete) {
			return (
				<>
					{permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update) ? (
						<div
							className={cls(style.deleteProject, {
								[style.disabled]: updating,
							})}
							onClick={() => {
								setShowEdit(true)
							}}>
							<PenIcon size={22} className={style.deleteIcon} />
							<div>
								Redigera{" "}
								{consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject
									? "projekt"
									: "adress"}
							</div>
						</div>
					) : null}
					{permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Delete) ? (
						<div
							className={cls(style.deleteProject, {
								[style.disabled]: updating,
							})}
							style={{ marginTop: "15px" }}
							onClick={() => {
								deleteProject()
							}}>
							<TrashIcon size={22} className={style.deleteIcon} />
							<div>
								Ta bort{" "}
								{consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject
									? "projekt"
									: "adress"}
							</div>
						</div>
					) : null}
				</>
			)
		}

		if (
			consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject &&
			auth.IsLoggedInClient &&
			permissions.isAllowed(PermissionAreaLocation.Client_Consumer_Address_Marking_Update)
		) {
			return (
				<>
					<div
						className={cls(style.deleteProject, {
							[style.disabled]: updating,
						})}
						onClick={() => {
							setShowEditMarking(true)
						}}>
						<PenIcon size={22} className={style.deleteIcon} />
						<div>Redigera märkning</div>
					</div>
					<div className={style.editMarkingNotificationText}>
						Detta projekt har ordrar kopplade till sig och endast redigering av märkning är tillåten
					</div>
				</>
			)
		}

		return null
	}

	if (!project) {
		return (
			<AbsolutCentered>
				<Loader></Loader>
			</AbsolutCentered>
		)
	}

	return (
		<>
			{showEdit && permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update) ? (
				<ProjectInputModule
					consumerId={props.consumerId}
					consumerCatalog={props.consumerCatalog || undefined}
					onClose={() => {
						setShowEdit(false)
					}}
					inputProject={{ project: project, isNew: false }}
					onDone={onEditDone}
				/>
			) : null}
			{showEditMarking ? (
				<EditProjectMarkingModal
					marking={project.marking || ""}
					projectId={project.id}
					consumerId={props.consumerId}
					orderAmount={props.orderAmount}
					onClose={() => {
						setShowEditMarking(false)
					}}
					onDone={(project) => {
						setProject(project)
						props.onMarkingUpdate(project)
					}}
				/>
			) : null}
			<div className={style.wrapper}>
				<div className={style.title}>
					{consumerCatalog.features.projectMode === ConsumerProjectMode.SimpleAddress ? "Adress" : "Projekt"}
				</div>
				{additionalDataText()}

				<div className={style.box}>
					<div className={style.headerRow}>
						{consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject ? (
							<div className={style.address}>
								{fullProjectMarkingText()}
								{project.address.street}, {project.address.zipCode ? project.address.zipCode + " " : ""}
								{project.address.city}
							</div>
						) : (
							<div className={style.address}>
								<div className={style.marking}>{project.address.street}</div>
								{project.address.zipCode ? project.address.zipCode + " " : ""}
								{project.address.city}
							</div>
						)}
						<div>
							<Dropdown
								className={style.statusDropdown}
								disabled={!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update)}
								getItems={() => {
									return Object.entries(statusDropdownOptions).map((entry) => {
										return {
											key: entry[1],
											value: entry[0],
										}
									})
								}}
								onChange={(item) => {
									if (item) {
										updateProjectStatus(project.id, item.value as ProjectStatus)
									}
								}}
								defaultValue={project.status}
							/>
						</div>
					</div>
					{isGoogleMapsLoaded && project.address.coordinates ? (
						<GoogleMapComponent
							mapContainerClassName={style.mapContainer}
							center={project.address.coordinates}
							zoom={19}
							onLoad={(map) => {
								initGoogleMapsCustomFullscreenControl(map)
								addAddressAndExactDeliveryMarkersToMap(map, project)
							}}
							options={googleMiniMapOptions(true, "cooperative")}
						/>
					) : null}

					{consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject &&
					permissions.isAllowed(PermissionAreaLocation.Consumer_Contact_Persons_Read) ? (
						<div style={{ marginTop: "40px" }}>
							<div className={style.contactPersonsHeader}>Kontaktpersoner</div>
							<ProjectInputContactPersonSelector
								isNew={false}
								consumerId={props.consumerId}
								project={project}
								onOpenAddNew={() => {}}
								onCloseAddNew={() => {}}
								onOrderChange={(newContactPersons) => {
									updateProjectContactPersons(project.id, newContactPersons)
								}}
								onSelected={(res) => {
									updateProjectContactPersons(project.id, res)
								}}
							/>
						</div>
					) : null}

					{editAndDeleteSection()}
				</div>
			</div>
		</>
	)
}
