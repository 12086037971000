import { FC } from "react"
import { cls } from "../../../Shared/cls"
import { Button, ButtonProps } from "../Form/Buttons/Buttons"
import style from "./ModulChooserButton.module.css"

type Props = ButtonProps & {
	selected: boolean
}

export const ModulChooserButton: FC<Props> = ({ selected, className, ...props }) => {
	return <Button className={cls(style.chooserButton, className)} btnStyle={selected ? "Finalize" : "Accent"} {...props} />
}
