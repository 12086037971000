import { FC, useEffect, useState } from "react"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import { ConsumerContactPersons } from "../ConsumerContactPersons/ConsumerContactPersons"
import {
	CustomerPortalConsumerInformation,
	GetConsumer,
} from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { CustomerPortalConsumerInvoicingInformation } from "../CustomerPortalConsumerInvoicingInformation/CustomerPortalConsumerInvoicingInformation"
import { Users } from "../Users/Users"
import style from "./CustomerPortalConsumerDetails.module.css"

type CustomerPortalConsumerDetailsProps = {
	consumerId: string
}
export const CustomerPortalConsumerDetails: FC<CustomerPortalConsumerDetailsProps> = ({ consumerId }) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	const [showUsers, setShowUsers] = useState<boolean>(true)
	const [consumer, setConsumer] = useState<GetConsumer | "loading" | null>("loading")

	useEffect(() => {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Information_View)) {
			setConsumer(null)
			return
		}

		API.getWithRetries<GetConsumer>(
			`/customer-portal/consumers-v1/${client.identifier}/details/${consumerId}`,
			true,
			undefined,
			10,
		)
			.then((res) => {
				setConsumer(res)
			})
			.catch(() => {
				setConsumer(null)
			})
	}, [client.identifier, consumerId, auth.Me, permissions])

	return (
		<div className={style.wrapper}>
			{consumerId ? (
				<div className={cls(style.flexColumns, style.consumerInfoAndInvoicingInfo)}>
					{consumer && consumer !== "loading" ? (
						<CustomerPortalConsumerInformation
							consumer={consumer}
							onInactivate={() => {
								setShowUsers(false)
								setTimeout(() => {
									setShowUsers(true)
								}, 15)
							}}
							onConsumerUpdated={() => {
								auth.refreshMeData()
							}}
						/>
					) : null}

					{consumer === "loading" ? (
						<div className={style.box}>
							<Loader />
						</div>
					) : null}

					<CustomerPortalConsumerInvoicingInformation consumerId={consumerId} />
				</div>
			) : null}

			<div className={style.flexColumns}>
				{showUsers ? (
					<Users type={"Consumer"} clientIdentifier={client.identifier} consumerId={consumerId} />
				) : null}
				<ConsumerContactPersons consumerId={consumerId} />
			</div>
		</div>
	)
}
