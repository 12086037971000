import { exhaustive } from "exhaustive"
import { camelCase, isArray, isEqual, omit, sortBy, transform } from "lodash"
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import ReactMarkdown from "react-markdown"
import { PluggableList } from "react-markdown/lib/react-markdown"
import rehypeRaw from "rehype-raw"
import { throwIllegalState } from "Shared/throwIllegalState"
import { when } from "Shared/when"
import { v4 } from "uuid"
import { ApiWidget, CssBreakpoint, WidgetPageItemStyle } from "../../Client/pages/renderer/PageRenderer"
import { useNavigator } from "../../Navigator/useNavigator"
import { cls } from "../cls"
import { createCollapsiblesFromHtml } from "../Collapsible/Collapsible"
import style from "./Widget.module.css"

export enum WidgetType {
	HtmlPageWidget = "HtmlPageWidget",
	ContentAndImageWidget = "ContentAndImageWidget",
	TopContentBottomBoxes = "TopContentBottomBoxesWidget",
	CallToActionWidget = "CallToActionWidget",
	HtmlPageBuilderWidget = "HtmlPageBuilderWidget",
}

export abstract class Widget {
	type: WidgetType

	constructor(type: WidgetType) {
		this.type = type
	}
}

export abstract class PageWidget extends Widget {}

export class HtmlPageWidget extends PageWidget {
	type = WidgetType.HtmlPageWidget
	content?: string
	backgroundImage?: WidgetImage
	styles?: string
}

export class HtmlPageBuilderWidget extends PageWidget {
	type = WidgetType.HtmlPageBuilderWidget
	content?: string
	styles?: string
	configAsJson?: string
	js?: string
}

export class ContentAndImageWidgetWidget extends PageWidget {
	type = WidgetType.ContentAndImageWidget
	content?: string
	backgroundColor?: string
	sideImage?: WidgetImage
	contentStyles?: string
	sideImageStyles?: string
	imagePosition?: ContentAndImageWidgetImagePosition
}

export enum ContentAndImageWidgetImagePosition {
	Left = "Left",
	Right = "Right",
}

export class TopContentBottomBoxesWidget extends PageWidget {
	type = WidgetType.TopContentBottomBoxes
	backgroundColor?: string
	content?: string
	contentStyles?: string
	boxes?: BottomBoxes
}

export class BottomBoxes {
	topColor?: string
	bottomColor?: string
	amountPerRow?: number
	boxes?: BottomBox[]
	headerTextColor?: string
	bodyTextColor?: string
	headerTextFontSize?: string
}

export type BottomBox = {
	id: string
	topImage?: WidgetImage
	headerText: string
	bodyText: string
	link?: string
	sidewaysContent?: boolean
	hrefTarget?: string
}

export class CallToActionWidget extends PageWidget {
	type = WidgetType.CallToActionWidget
	backgroundColor?: string
	content?: string
	contentStyles?: string
	buttons?: CallToActionButton[]
}

export type CallToActionButton = {
	id: string
	text: string
	backgroundColor: string
	backgroundColorHover: string
	textColor: string
	textColorHover: string
	link: string
	hrefTarget?: string
}

type WidgetImage = WidgetImageUrl

type WidgetImageUrl = {
	type: "WidgetImageUrl"
	url: string
}

/**
 * Returns the max-width value for a given breakpoint
 * @param breakPoint
 */
function cssBreakPointToNumber(breakPoint: CssBreakpoint): number {
	return exhaustive(breakPoint, {
		XS: () => 576,
		SM: () => 710,
		MD: () => 900,
		LG: () => 1100,
		XL: () => 1400,
		XXL: () => 1600,
	})
}

/**
 * Returns the max-width value for either an <i>WidgetStyleKeywordBreakpoint</i> or an <i>WidgetStyleKeywordBreakpoint</i>
 * @param breakPoint
 * @constructor
 */
function widgetBreakPointToNumber(breakPoint: WidgetPageItemStyle["breakPoint"]): number {
	return exhaustive(breakPoint, "type", {
		Keyword: (it) => {
			return cssBreakPointToNumber(it.trigger)
		},
		Custom: (it) => {
			return it.maxWidth
		},
	})
}

/**
 * Generates and appends (to <head> tag) one or more <style> tags based on inputted data
 * Each style tag has a custom breakpoint with custom css added inside it
 * @param id
 * @param wrapperStyles
 */
function addWrapperStylesToHead(id: string, wrapperStyles: WidgetPageItemStyle[]) {
	// Sort items so they are added in large -> small order, to get correct css hierarchy applied
	sortBy(wrapperStyles, (x) => {
		return widgetBreakPointToNumber(x.breakPoint)
	})
		.reverse()
		.forEach((style) => {
			let parsed: { [key: string]: string } | null = null

			try {
				parsed = JSON.parse(style.css)
			} catch (_) {}

			if (parsed === null) {
				return
			}
			document.head.insertAdjacentHTML(
				"beforeend",
				renderToStaticMarkup(
					<style
						className={`widgetWrapperStyle_${id}_style_element`}
						dangerouslySetInnerHTML={{
							__html: `
						@media (max-width: ${widgetBreakPointToNumber(style.breakPoint)}px) {
							.widgetWrapperStyle_${id} {
								${
									Object.entries(parsed)
										.map(([key, value]) => {
											return `${key}: ${value}`
										})
										.join(";\n") + ";\n"
								}
							}
						}
							`,
						}}></style>,
				),
			)
		})
}

function removeWrapperStyleElements(id: string) {
	const elements = document.getElementsByClassName(`widgetWrapperStyle_${id}_style_element`)
	Array.from(elements).forEach((x) => x.remove())
}

export const WidgetRendererV1: FC<{
	widgets: Widget[]
	className?: string
}> = ({ widgets, className }) => {
	return (
		<>
			<div className={className}>
				{widgets.map((widget, index) => {
					return (
						<div key={index + "_widget"}>
							{when(widget.type, {
								[WidgetType.HtmlPageWidget]: () => (
									<HtmlPageWidgetComponent widget={widget} wrapperStyles={[]} />
								),
								[WidgetType.ContentAndImageWidget]: () => (
									<ContentAndImageComponent widget={widget} wrapperStyles={[]} />
								),
								[WidgetType.TopContentBottomBoxes]: () => (
									<TopContentBottomBoxesComponent widget={widget} wrapperStyles={[]} />
								),
								[WidgetType.CallToActionWidget]: () => (
									<CallToActionComponent widget={widget} wrapperStyles={[]} />
								),
								[WidgetType.HtmlPageBuilderWidget]: () => (
									<HtmlPageBuilderWidgetComponent widget={widget} wrapperStyles={[]} />
								),
							})}
						</div>
					)
				})}
			</div>
		</>
	)
}

export const WidgetRendererV2: FC<{
	apiWidgets: (ApiWidget | Widget)[]
	className?: string
	scrollToTopOnWidgetChange?: boolean
}> = ({ apiWidgets, className, scrollToTopOnWidgetChange = true }) => {
	type CustomWidget = (ApiWidget | Widget) & {
		id: string
	}
	const [widgets, setWidgets] = useState<CustomWidget[]>([])

	useEffect(() => {
		if (scrollToTopOnWidgetChange) {
			const element = document.getElementById("mainLayoutPageContent")
			if (element) {
				element.scrollTop = 0
			}
		}

		const current = widgets.map((x) => omit(x, "id"))

		if (!isEqual(current, apiWidgets)) {
			setWidgets(apiWidgets.map((x) => ({ id: v4(), ...x })))
		}
	}, [apiWidgets])

	return (
		<>
			<div className={className}>
				{widgets.map((apiWidget) => {
					const widget = "widget" in apiWidget ? apiWidget.widget : apiWidget
					const wrapperStyles = "wrapperStyles" in apiWidget ? apiWidget.wrapperStyles : []
					return (
						<div key={apiWidget.id + "_apiWidget"}>
							{when(widget.type, {
								[WidgetType.HtmlPageWidget]: () => (
									<HtmlPageWidgetComponent widget={widget} wrapperStyles={wrapperStyles} />
								),
								[WidgetType.ContentAndImageWidget]: () => (
									<ContentAndImageComponent widget={widget} wrapperStyles={wrapperStyles} />
								),
								[WidgetType.TopContentBottomBoxes]: () => (
									<TopContentBottomBoxesComponent widget={widget} wrapperStyles={wrapperStyles} />
								),
								[WidgetType.CallToActionWidget]: () => (
									<CallToActionComponent widget={widget} wrapperStyles={wrapperStyles} />
								),
								[WidgetType.HtmlPageBuilderWidget]: () => (
									<HtmlPageBuilderWidgetComponent widget={widget} wrapperStyles={wrapperStyles} />
								),
							})}
						</div>
					)
				})}
			</div>
		</>
	)
}

const HtmlPageWidgetComponent: FC<{ widget: HtmlPageWidget; wrapperStyles: WidgetPageItemStyle[] }> = ({
	widget,
	wrapperStyles,
}) => {
	const navigator = useNavigator()
	const wrapperElement = useRef<HTMLDivElement>(null)

	const randomId = useRef(v4())

	useEffect(() => {
		const id = randomId.current
		addWrapperStylesToHead(id, wrapperStyles)

		return () => {
			removeWrapperStyleElements(id)
		}
	}, [])

	useEffect(() => {
		if (wrapperElement.current != null) {
			const links = wrapperElement.current.getElementsByTagName("a")

			for (const link of links) {
				const hrefAttribute = link.getAttribute("href")
				if (hrefAttribute == null) {
					continue
				}

				const origHrefAttribute = link.getAttribute("data-orig-href") ?? hrefAttribute
				const hrefToUse = navigator.formatHref(origHrefAttribute)

				link.setAttribute("href", hrefToUse.absoluteURL)
				link.setAttribute("data-orig-href", hrefAttribute)

				link.onclick = (ev) => {
					navigator.open(origHrefAttribute)
					ev.preventDefault()
				}
			}

			createCollapsiblesFromHtml(wrapperElement.current)
		}
	}, [wrapperElement, widget.content])

	return (
		<>
			{widget.content == null ? null : (
				<div className={cls(style.customStyles, `widgetWrapperStyle_${randomId.current}`)}>
					<div style={getStyles(widget.styles, widget.backgroundImage)} ref={wrapperElement}>
						<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>{widget.content}</ReactMarkdown>
					</div>
				</div>
			)}
		</>
	)
}

const ContentAndImageComponent: FC<{ widget: ContentAndImageWidgetWidget; wrapperStyles: WidgetPageItemStyle[] }> = ({
	widget,
	wrapperStyles,
}) => {
	const randomId = useRef(v4())
	const wrapperElement = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const id = randomId.current
		addWrapperStylesToHead(id, wrapperStyles)

		return () => {
			removeWrapperStyleElements(id)
		}
	}, [])

	useEffect(() => {
		if (wrapperElement.current != null) {
			createCollapsiblesFromHtml(wrapperElement.current)
		}
	}, [wrapperElement, widget.content])

	if (!widget.content) {
		return null
	}

	return (
		<div
			className={cls(style.customStyles, `widgetWrapperStyle_${randomId.current}`)}
			style={{ backgroundColor: widget.backgroundColor || "#ffffff" }}>
			<div
				className={cls(style.sixtyFortySplit, {
					[style.flipOrder]: widget.imagePosition === ContentAndImageWidgetImagePosition.Left,
				})}>
				<div style={getStyles(widget.contentStyles)} className={style.contentPart} ref={wrapperElement}>
					<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>{widget.content}</ReactMarkdown>
				</div>
				<div style={{ display: "flex" }} className={style.imagePart}>
					{widget.sideImage ? (
						<img style={getStyles(widget.sideImageStyles)} src={widget.sideImage.url} alt="" />
					) : null}
				</div>
			</div>
		</div>
	)
}

export const TopContentBottomBoxesComponent: FC<{
	widget: TopContentBottomBoxesWidget
	wrapperStyles: WidgetPageItemStyle[]
}> = ({ widget, wrapperStyles }) => {
	const navigator = useNavigator()
	const randomId = useRef(v4())
	const wrapperElement = useRef<HTMLDivElement>(null)

	let boxes = useMemo(() => {
		if (!widget.boxes?.boxes || !isArray(widget.boxes?.boxes)) {
			return []
		}

		return widget.boxes.boxes.map((box) => {
			return (
				<BottomBoxToHtml
					key={box.id}
					widget={widget}
					box={box}
					onClick={() => {
						if (box.link) {
							navigator.open(box.link, box.hrefTarget)
						}
					}}
				/>
			)
		})
	}, [widget, navigator])

	useEffect(() => {
		const id = randomId.current
		addWrapperStylesToHead(id, wrapperStyles)

		return () => {
			removeWrapperStyleElements(id)
		}
	}, [])

	useEffect(() => {
		if (wrapperElement.current != null) {
			createCollapsiblesFromHtml(wrapperElement.current)
		}
	}, [wrapperElement, widget.content])

	if (!widget.content || !isArray(widget.boxes?.boxes)) {
		return null
	}

	return (
		<div
			className={cls(style.customStyles, `widgetWrapperStyle_${randomId.current}`)}
			style={{
				backgroundColor: widget.backgroundColor || "#ffffff",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}>
			<div style={{ maxWidth: "1600px", paddingBottom: "60px", width: "100%" }}>
				<div style={getStyles(widget.contentStyles)} ref={wrapperElement}>
					<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>{widget.content}</ReactMarkdown>
				</div>
				<div className={style.boxesWrapper}>{boxes}</div>
			</div>
		</div>
	)
}

type BottomBoxToHtmlProps = {
	widget: TopContentBottomBoxesWidget
	box: BottomBox
	onClick: () => void
}

function BottomBoxToHtml({ widget, box, onClick }: BottomBoxToHtmlProps) {
	let topHalf

	if (box.topImage) {
		topHalf = (
			<div className={style.topHalf} style={{ backgroundColor: widget.boxes?.topColor }}>
				{box.topImage ? <img src={box.topImage.url} alt="" /> : null}
			</div>
		)
	}

	return (
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `
					.box_dynamic_${box.id} {
						width: calc(${(100 / (widget.boxes?.amountPerRow || 4)).toString() + "%"} - 10px)
					}
				`,
				}}
			/>
			<div
				className={cls(
					style.box,
					{ [style.clickableBox]: !!box.link },
					{ [style.noTopHalf]: !topHalf },
					{ [style.sidewaysContent]: box.sidewaysContent === true },
					`box_dynamic_${box.id}`,
				)}
				onClick={() => {
					onClick()
				}}>
				{topHalf}
				<div
					className={style.bottomHalf}
					style={{ backgroundColor: widget.boxes?.bottomColor, borderRadius: !topHalf ? "12px" : undefined }}>
					<div
						className={cls(style.header)}
						title={box.headerText}
						style={{
							color: widget.boxes?.headerTextColor,
							fontSize: (widget.boxes?.headerTextFontSize || 16).toString() + "px",
						}}>
						<span className={"twoLineClamp"} style={{ wordBreak: "break-word" }}>
							{box.headerText}
						</span>
					</div>
					<div className={style.body} style={{ color: widget.boxes?.bodyTextColor }}>
						<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>{box.bodyText}</ReactMarkdown>
					</div>
				</div>
			</div>
		</>
	)
}

export const CallToActionComponent: FC<{ widget: CallToActionWidget; wrapperStyles: WidgetPageItemStyle[] }> = ({
	widget,
	wrapperStyles,
}) => {
	const randomId = useRef(v4())
	const wrapperElement = useRef<HTMLDivElement>(null)

	let buttons = useMemo(() => {
		if (!widget.buttons || !isArray(widget.buttons)) {
			return []
		}

		return widget.buttons.map((button) => {
			return <CallToActionButtonToHtml key={button.id} button={button} />
		})
	}, [])

	useEffect(() => {
		const id = randomId.current
		addWrapperStylesToHead(id, wrapperStyles)

		return () => {
			removeWrapperStyleElements(id)
		}
	}, [])

	useEffect(() => {
		if (wrapperElement.current != null) {
			createCollapsiblesFromHtml(wrapperElement.current)
		}
	}, [wrapperElement, widget.content])

	if (!widget.content || !isArray(widget.buttons)) {
		return null
	}

	return (
		<div
			className={cls(style.customStyles, `widgetWrapperStyle_${randomId.current}`)}
			style={{ backgroundColor: widget.backgroundColor || "#ffffff" }}>
			<div style={getStyles(widget.contentStyles)} ref={wrapperElement}>
				<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>{widget.content}</ReactMarkdown>
			</div>
			{(widget?.buttons || []).map((button) => {
				return (
					<style
						key={button.id + "_style"}
						dangerouslySetInnerHTML={{
							__html: `
								.callToActionButton_${button.id} {
									cursor: pointer;
									background-color: ${button.backgroundColor};
									color: ${button.textColor};
									border-radius: 32px;
									font-size: 20px;
									padding: 10px 15px;
									font-weight: 600;
									max-width: 220px;
									min-width: 150px;
									display: flex;
									justify-content: center;
									text-decoration: none;
									flex-grow: 1;
									flex-basis: 0;
								}

								.callToActionButton_${button.id}:hover {
									background-color: ${button.backgroundColorHover};
									color: ${button.textColorHover};
								}
							`,
						}}
					/>
				)
			})}
			<div className={style.callToActionButtonsWrapper}>{buttons}</div>
		</div>
	)
}

type CallToActionButtonToHtmlProps = {
	button: CallToActionButton
}

function CallToActionButtonToHtml({ button }: CallToActionButtonToHtmlProps) {
	const navigator = useNavigator()

	return (
		<a
			className={`callToActionButton_${button.id}`}
			href={navigator.formatHref(button.link).absoluteURL}
			target={button.hrefTarget}
			onClick={(e) => {
				e.preventDefault()
				navigator.open(button.link, button.hrefTarget)
			}}>
			{button.text}
		</a>
	)
}

const HtmlPageBuilderWidgetComponent: FC<{ widget: HtmlPageBuilderWidget; wrapperStyles: WidgetPageItemStyle[] }> = ({
	widget,
	wrapperStyles,
}) => {
	const navigator = useNavigator()
	const wrapperElement = useRef<HTMLDivElement>(null)

	const randomId = useRef(v4())

	useEffect(() => {
		const id = randomId.current
		addWrapperStylesToHead(id, wrapperStyles)

		if (widget.js) {
			const script = document.createElement("script")
			script.classList.add(id + "_script")
			const scriptContent = document.createTextNode(widget.js)
			script.appendChild(scriptContent)
			document.head.appendChild(script)
		}

		return () => {
			removeWrapperStyleElements(id)
			const scriptTags = document.getElementsByClassName(id + "_script")

			Array.from(scriptTags).forEach((x) => x.remove())
		}
	}, [])

	useEffect(() => {
		if (wrapperElement.current != null) {
			const links = wrapperElement.current.getElementsByTagName("a")

			for (const link of links) {
				const hrefAttribute = link.getAttribute("href")
				if (hrefAttribute == null) {
					continue
				}

				const origHrefAttribute = link.getAttribute("data-orig-href") ?? hrefAttribute
				const hrefToUse = navigator.formatHref(origHrefAttribute)

				link.setAttribute("href", hrefToUse.absoluteURL)
				link.setAttribute("data-orig-href", hrefAttribute)

				link.onclick = (ev) => {
					navigator.open(origHrefAttribute)
					ev.preventDefault()
				}
			}

			const scriptTags = wrapperElement.current.querySelectorAll("script")

			// remove script tags that are included in the generated html, they are never executed and not used, but afaik they cannot
			// be excluded from the generated html
			for (const scriptTag of scriptTags) {
				scriptTag.remove()
			}
		}
	}, [wrapperElement, widget.content])

	if (widget.content == null) {
		return null
	}

	return (
		<>
			<div className={cls(style.customStyles, `widgetWrapperStyle_${randomId.current}`)}>
				<style
					dangerouslySetInnerHTML={{
						__html: widget.styles || "",
					}}
				/>
				<div ref={wrapperElement} style={{ height: "100%" }}>
					<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>
						{widget.content.replace("<body ", "<div ").replace("</body>", "</div>")}
					</ReactMarkdown>
				</div>
			</div>
		</>
	)
}

export const getStyles = (maybeStyles: string | undefined, maybeWidgetImage?: WidgetImage): CSSProperties => {
	if (maybeWidgetImage == null) {
		if (maybeStyles != null) {
			const ret = transform(JSON.parse(maybeStyles), (acc, value, key) => {
				const newKey = camelCase(key as string)
				;(acc as any)[newKey] = value
				if (newKey !== key) {
					delete (acc as any)[key]
				}
			}) as CSSProperties

			return ret
		} else {
			return {}
		}
	} else if (maybeWidgetImage.type === "WidgetImageUrl") {
		if (maybeStyles != null) {
			const parsedStyles = transform(JSON.parse(maybeStyles), (acc, value, key) => {
				const newKey = camelCase(key as string)
				;(acc as any)[newKey] = value
				delete (acc as any)[key]
			}) as CSSProperties
			parsedStyles.backgroundImage = `url(${maybeWidgetImage.url})`
			return parsedStyles
		} else {
			return { backgroundImage: `url(${maybeWidgetImage.url})` }
		}
	} else {
		throwIllegalState(
			`WidgetImage type is not supported, attempting to use this as widget image object: ${maybeWidgetImage.toString()}`,
		)
	}
}
