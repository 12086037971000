import { faEnvelope, faPhone, faSignature, faSpinner, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { exhaustive } from "exhaustive"
import { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { API } from "../../network/API"
import { AccentButton, FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { SbtH1 } from "../../Orders/Components/Text/SbtH1/SbtH1"
import { SbtH5 } from "../../Orders/Components/Text/SbtH5/SbtH5"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "../../Orders/order-data-model"
import style from "./Login.module.css"

const ResetByUsernameSchema = z.object({
	data: z.string().min(1),
})

const ResetByEmailSchema = z.object({
	data: z.string().min(1).email(),
})

const ResetByPhoneSchema = z.object({
	data: z.string().min(3, invalidPhoneNumberMessage).refine(validatePhoneNumber, { message: invalidPhoneNumberMessage }),
})

const ResetData = z.object({ data: z.string() })

type ResetDataType = z.input<typeof ResetData>

type IdentifierType = "username" | "email" | "phone"

type Props = {
	onClose: (data?: string) => void
	onResetSent: () => void
	suggested: string
}

export const PasswordResetForm: FC<Props> = ({ onResetSent, suggested }) => {
	const client = useClient()

	const [identifierType, setIdentifierType] = useState<IdentifierType>("email")
	const [backendError, setBackendError] = useState<string | null>(null)

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<ResetDataType>({
		resolver: async (data, context, options) => {
			return exhaustive(identifierType, {
				email: () => zodResolver(ResetByEmailSchema)(data, context, options),
				username: () => zodResolver(ResetByUsernameSchema)(data, context, options),
				phone: () => zodResolver(ResetByPhoneSchema)(data, context, options),
			})
		},
		defaultValues: {
			data: suggested,
		},
	})

	const onSubmit: SubmitHandler<ResetDataType> = async (formData) => {
		setBackendError(null)
		const requestContent = exhaustive(identifierType, {
			email: (): PasswordResetByEmail => ({
				email: formData.data,
			}),
			username: (): PasswordResetByUsername => ({
				username: formData.data,
			}),
			phone: (): PasswordResetByPhone => ({
				phone: formData.data,
			}),
		})
		const reqObj: ResetPasswordRequest = {
			...requestContent,
			clientIdentifier: client.identifier,
		}

		try {
			await API.post(`/auth/reset-password-v1`, reqObj)
			onResetSent()
		} catch (e) {
			setBackendError("Gick inte att begära lösenordsåterställning, försök igen senare.")
		}
	}

	const [icon, placeholderText] = exhaustive(identifierType, {
		email: (): [IconDefinition, string] => [faEnvelope, "Fyll i din e-postadress"],
		username: (): [IconDefinition, string] => [faSignature, "Fyll i ditt användarnamn"],
		phone: (): [IconDefinition, string] => [faPhone, "Fyll i ditt telefonnummer"],
	})

	return (
		<>
			<form className={style.wrapper} onSubmit={handleSubmit(onSubmit)}>
				<SbtH1 className={style.title}>Återställ lösenord</SbtH1>
				<SbtH5>
					{exhaustive(identifierType, {
						email: () =>
							"Ange din e-postadress så skickar vi ett e-postmeddelande med återställningsinstruktioner",
						username: () =>
							"Ange ditt användarnamn så skickar vi ett e-postmeddelande med återställningsinstruktioner",
						phone: () =>
							"Ange ditt telefonnummer så skickar vi ett e-postmeddelande med återställningsinstruktioner",
					})}
				</SbtH5>
				<div className={style.fields}>
					<label>
						<div className={style.inputWrapper}>
							<div>
								<FontAwesomeIcon icon={icon} />
							</div>
							<input className={style.input} {...register("data")} placeholder={placeholderText} />
						</div>
						<SbtRHFError error={errors.data} />
					</label>
					{backendError}
				</div>
				<div className={style.bottomSection}>
					<FinalizeButton disabled={isSubmitting} type="submit">
						Återställ lösenord
						{isSubmitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
					<p className={style.infoChangeType}>
						{exhaustive(identifierType, {
							email: () => "Ingen e-postadress?",
							username: () => "Inget användarnamn?",
							phone: () => "Inget telefonnummer?",
						})}
					</p>
					{identifierType !== "username" ? (
						<AccentButton
							style={{ marginBottom: "20px" }}
							disabled={isSubmitting}
							onClick={() => {
								setIdentifierType("username")
							}}>
							Återställ med användarnamn
						</AccentButton>
					) : null}
					{identifierType !== "email" ? (
						<AccentButton
							style={{ marginBottom: identifierType === "phone" ? undefined : "20px" }}
							disabled={isSubmitting}
							onClick={() => {
								setIdentifierType("email")
							}}>
							Återställ med e-post
						</AccentButton>
					) : null}
					{identifierType !== "phone" ? (
						<AccentButton
							disabled={isSubmitting}
							onClick={() => {
								setIdentifierType("phone")
							}}>
							Återställ med telefonnummer
						</AccentButton>
					) : null}
				</div>
			</form>
		</>
	)
}

type ResetPasswordRequest = {
	clientIdentifier: string
} & (PasswordResetByEmail | PasswordResetByUsername | PasswordResetByPhone)

type PasswordResetByEmail = {
	email: string
}

type PasswordResetByUsername = {
	username: string
}

type PasswordResetByPhone = {
	phone: string
}
