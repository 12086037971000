import { faEye, faEyeSlash, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { FC, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import style from "./ChangeUserPassword.module.css"

type Props = {
	onClose: () => void
	onDone: () => void
}

const FormSchema = z
	.object({
		oldPassword: z.string().min(1),
		newPassword: z.string().min(8, "Minst 8 tecken krävs"),
		newPasswordAgain: z.string().min(8, "Minst 8 tecken krävs"),
	})
	.superRefine((val, ctx) => {
		if (val.newPassword && val.newPasswordAgain && val.newPassword !== val.newPasswordAgain) {
			ctx.addIssue({
				path: ["newPasswordAgain"],
				code: z.ZodIssueCode.custom,
				message: "Ditt nya lösenord måste matcha i båda rutorna",
			})
		}
	})

type FormSchemaType = z.input<typeof FormSchema>

export const ChangeUserPassword: FC<Props> = ({ onClose: extOnClose, onDone: extOnDone }) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [passwordChanged, setPasswordChanged] = useState<boolean>(false)
	const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
	const [showNewPasswordAgain, setShowNewPasswordAgain] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		reset,
	} = useForm<FormSchemaType>({
		resolver: async (data, context, options) => {
			return zodResolver(FormSchema)(data, context, options)
		},
	})

	const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
		setIsSubmitting(true)

		const reqObj = {
			oldPassword: data.oldPassword,
			newPassword: data.newPassword,
		}

		API.postWithRetries<void>(`/customer-portal/users-v1/myself/change-password`, reqObj, {}).then(
			() => {
				setIsSubmitting(false)
				setPasswordChanged(true)
				reset()
				onDone()
			},
			() => {
				setIsSubmitting(false)
				setError("newPasswordAgain", { message: "Felaktiga uppgifter, försök igen" })
			},
		)
	}

	function onClose() {
		extOnClose()
	}

	function onDone() {
		onClose()
		extOnDone()
	}

	return (
		<ModulePopup onClose={onClose} disableClose={isSubmitting} className={style.modal}>
			<div className={style.wrapper}>
				<div className={style.mainPageTitle}>Byt lösenord</div>
				<form id="changePasswordForm" className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={style.fields}>
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={style.input}
									disabled={isSubmitting}
									type={showOldPassword ? "text" : "password"}
									{...register("oldPassword")}
									onChange={() => {
										if (passwordChanged) {
											setPasswordChanged(false)
										}
									}}
									placeholder="Nuvarande lösenord"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showOldPassword ? faEyeSlash : faEye}
									onClick={() => {
										setShowOldPassword(!showOldPassword)
									}}
								/>
							</div>

							<SbtRHFError error={errors.oldPassword} />
						</label>
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={style.input}
									disabled={isSubmitting}
									type={showNewPassword ? "text" : "password"}
									{...register("newPassword")}
									onChange={() => {
										if (passwordChanged) {
											setPasswordChanged(false)
										}
									}}
									placeholder="Nytt lösenord"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showNewPassword ? faEyeSlash : faEye}
									onClick={() => {
										setShowNewPassword(!showNewPassword)
									}}
								/>
							</div>

							<SbtRHFError error={errors.newPassword} />
						</label>
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={style.input}
									disabled={isSubmitting}
									type={showNewPasswordAgain ? "text" : "password"}
									{...register("newPasswordAgain")}
									onChange={() => {
										if (passwordChanged) {
											setPasswordChanged(false)
										}
									}}
									placeholder="Upprepa nytt lösenord"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showNewPasswordAgain ? faEyeSlash : faEye}
									onClick={() => {
										setShowNewPasswordAgain(!showNewPasswordAgain)
									}}
								/>
							</div>

							<SbtRHFError error={errors.newPasswordAgain} />
						</label>
					</div>
					<FinalizeButton
						disabled={isSubmitting}
						type="button"
						onClick={() => {
							;(document.getElementById("changePasswordForm") as HTMLFormElement).requestSubmit()
						}}>
						Byt lösenord{" "}
						{isSubmitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
					{passwordChanged ? <div className={style.passwordChangedText}>Lösenordet has uppdaterats</div> : null}
				</form>
			</div>
		</ModulePopup>
	)
}
