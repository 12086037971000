import { FunctionComponent, SVGProps } from "react"
import { InformationIcon } from "../Icons/Icon"
import { WasteTypeImages } from "../ProductDefinitions"
import { ReactComponent as AsbestosImg } from "./images/Asbestos.svg"
import { ReactComponent as Asphalt } from "./images/Asphalt.svg"
import { ReactComponent as BlueConcreteImg } from "./images/BlueConcrete.svg"
import { ReactComponent as CardboardImg } from "./images/Cardboard.svg"
import { ReactComponent as ClayBricksImg } from "./images/ClayBricks.svg"
import { ReactComponent as CleanSoilImg } from "./images/CleanSoil.svg"
import { ReactComponent as CombustibleImg } from "./images/Combustible.svg"
import { ReactComponent as CompostImg } from "./images/Compost.svg"
import { ReactComponent as ConcreteImg } from "./images/Concrete.svg"
import { ReactComponent as ConcreteBlocksImg } from "./images/ConcreteBlocks.svg"
import { ReactComponent as ContaminatedSoilImg } from "./images/ContaminatedSoil.svg"
import { ReactComponent as DemolitionDrywallImg } from "./images/DemolitionDrywall.svg"
import { ReactComponent as DrywallImg } from "./images/Drywall.svg"
import { ReactComponent as ElectronicsImg } from "./images/Electronics.svg"
import { ReactComponent as FillingMassImg } from "./images/FillingMass.svg"
import { ReactComponent as FluorescentLightTubeImg } from "./images/FluorescentLightTube.svg"
import { ReactComponent as GardenWasteImg } from "./images/GardenWaste.svg"
import { ReactComponent as GlassBottleImg } from "./images/GlassBottle.svg"
import { ReactComponent as GlassWareImg } from "./images/GlassWare.svg"
import { ReactComponent as ImpureFillingMaterialImg } from "./images/ImpureFillingMaterial.svg"
import { ReactComponent as InsulationImg } from "./images/Insulation.svg"
import { ReactComponent as MetalImg } from "./images/Metal.svg"
import { ReactComponent as MixedMassImg } from "./images/MixedMass.svg"
import { ReactComponent as MixedWasteImg } from "./images/MixedWaste.svg"
import { ReactComponent as OtherWasteImg } from "./images/OtherWaste.svg"
import { ReactComponent as ParkAndGardenImg } from "./images/ParkAndGarden.svg"
import { ReactComponent as PlasticImg } from "./images/Plastic.svg"
import { ReactComponent as ReinforcedConcreteImg } from "./images/ReinforcedConcrete.svg"
import { ReactComponent as SandImg } from "./images/Sand.svg"
import { ReactComponent as SanitaryWareImg } from "./images/SanitaryWare.svg"
import { ReactComponent as StumpsImg } from "./images/Stumps.svg"
import { ReactComponent as UncleanSoilImg } from "./images/UncleanSoil.svg"
import { ReactComponent as UnsortableImg } from "./images/Unsortable.svg"
import { ReactComponent as WetMassImg } from "./images/WetMass.svg"
import { ReactComponent as WoodImg } from "./images/Wood.svg"

const wasteTypeImageToIcons: Record<WasteTypeImages, FunctionComponent<SVGProps<SVGSVGElement>>> = {
	Asbestos: AsbestosImg,
	Asphalt: Asphalt,
	BlueConcrete: BlueConcreteImg,
	Cardboard: CardboardImg,
	ClayBricks: ClayBricksImg,
	CleanSoil: CleanSoilImg,
	Combustible: CombustibleImg,
	Compost: CompostImg,
	Concrete: ConcreteImg,
	ConcreteBlocks: ConcreteBlocksImg,
	ContaminatedSoil: ContaminatedSoilImg,
	DemolitionDrywall: DemolitionDrywallImg,
	Drywall: DrywallImg,
	Electronics: ElectronicsImg,
	FillingMass: FillingMassImg,
	FluorescentLightTube: FluorescentLightTubeImg,
	GardenWaste: GardenWasteImg,
	GlassBottle: GlassBottleImg,
	GlassWare: GlassWareImg,
	ImpureFillingMaterial: ImpureFillingMaterialImg,
	Insulation: InsulationImg,
	Metal: MetalImg,
	MixedMass: MixedMassImg,
	MixedWaste: MixedWasteImg,
	OtherWaste: OtherWasteImg,
	ParkAndGarden: ParkAndGardenImg,
	Plastic: PlasticImg,
	ReinforcedConcrete: ReinforcedConcreteImg,
	Sand: SandImg,
	SanitaryWare: SanitaryWareImg,
	Stumps: StumpsImg,
	UncleanSoil: UncleanSoilImg,
	Unsortable: UnsortableImg,
	WetMass: WetMassImg,
	Wood: WoodImg,
}

const missingIcon = InformationIcon

type Props = SVGProps<SVGSVGElement> & {
	wasteTypeImage: WasteTypeImages
}

export const WasteImage = ({ wasteTypeImage, ...props }: Props) => {
	const Image = wasteTypeImageToIcons[wasteTypeImage] ?? missingIcon
	return <Image {...props} />
}
