import { DateTime } from "luxon"
import { ChangeEvent, FC, useState } from "react"
import { cls } from "Shared/cls"
import { getLogger } from "../../../../Logging/getLogger"
import { nextWorkday } from "../../../../Shared/tomorrow"
import { styleMap } from "../Buttons/Buttons"
import style from "./NativeModuleDatePicker.module.css"

const logger = getLogger("NativeModuleDatePicker")

type Props = {
	selected: boolean
	onDateSelected: (date: string) => void
	value: string | null
	className?: string
}

export const NativeModuleDatePicker: FC<Props> = ({ selected, onDateSelected, value, className }) => {
	const [selectedDate, setSelectedDate] = useState<string>(() => {
		if (value && DateTime.fromFormat(value, "yyyy-MM-dd").isValid) {
			return value
		}

		return DateTime.now().toISODate()
	})

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setSelectedDate(value)
		onDateSelected(value)
	}

	const btnStyle = selected ? "Finalize" : "Accent"
	const newClassName = cls(className, style.chooserButton, styleMap[btnStyle], style.baseButton)

	return (
		<input
			className={newClassName}
			type="date"
			min={nextWorkday()}
			value={selectedDate}
			onFocus={(e) => {
				try {
					e.target.showPicker()
				} catch (e: unknown) {
					logger.debug("Exception while showing date picker", e)
				}
				if (value !== null) {
					onDateSelected(value)
				}
			}}
			onChange={handleChange}
		/>
	)
}
