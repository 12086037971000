import { CategoryImageDeprecated, CategoryImages } from "Client/ProductDefinitionsByCategories"
import { exhaustive } from "exhaustive"
import { CSSProperties, ReactNode } from "react"
import { cls } from "Shared/cls"
import { TypedCategoryImage } from "SkrappyShared/Components/TypedCategoryImage/TypedCategoryImage"
import style from "./CategoryImage.module.css"
import { ReactComponent as NoImage } from "./NoImage.svg"

export type CategoryLikeWithImages = {
	image?: CategoryImageDeprecated | undefined
	categoryImages: CategoryImages | undefined
}

type Props = {
	category: CategoryLikeWithImages
	className?: string
	children?: ReactNode
	onClick?: () => void
	style?: CSSProperties | undefined
}

/**
 * A wrapper for either a Svg tag or an Img tag, depending on the main image set on the category
 */
export function CategoryCard({ category, className, style: cssStyle, children, onClick }: Props) {
	function fitWrapper(element: ReactNode) {
		return (
			<div className={cls(style.wrapper, className)} style={cssStyle} onClick={onClick}>
				{children}
				{element}
			</div>
		)
	}

	let categoryImages = category.categoryImages
	if (categoryImages == null) {
		//Fallback to old parameters
		let image = category.image
		if (image == null) {
			return fitWrapper(<NoImage />)
		}
		return fitWrapper(<TypedCategoryImage category={image?.productType} className={style.typedImage} />)
	}

	return exhaustive.tag(categoryImages.main, "type", {
		Typed: (typed) => {
			return fitWrapper(<TypedCategoryImage category={typed.typeImage} className={style.typedImage} />)
		},
		Url: (urlImage) => {
			return exhaustive(urlImage.renderMode, {
				Fit: () => {
					return fitWrapper(<img className={style.imageFit} src={urlImage.url} alt="Produktbild" />)
				},
				Fill: () => {
					return (
						<div
							className={cls(style.wrapper, style.fill, className)}
							style={{ backgroundImage: `url(${urlImage.url})`, ...cssStyle }}
							onClick={onClick}>
							{children}
						</div>
					)
				},
			})
		},
	})
}
