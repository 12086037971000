import { useEffect, useState } from "react"
import { API } from "../network/API"
import { ConsumerCatalogInstance, GetConsumerCatalogConfigurationResponse } from "./ConsumerCatalogContext"

export type ConsumerCatalogContextType = ConsumerCatalogInstance | "loading" | null
export const useConsumerConfiguration = (
	clientIdentifier: string,
	consumerId?: string,
	requireConsumerId: boolean = false,
): ConsumerCatalogContextType => {
	if (!clientIdentifier) {
		throw new Error("Unable to resolve client identifier when getting consumer configuration")
	}

	const [data, setData] = useState<ConsumerCatalogContextType>("loading")

	useEffect(() => {
		if (requireConsumerId && !consumerId) {
			setData(null)
			return
		}

		setData("loading")
		fetchConfig(clientIdentifier, consumerId)
			.then((response) => {
				setData(response)
			})
			.catch(() => {
				setData(null)
			})
	}, [clientIdentifier, consumerId, requireConsumerId])

	async function fetchConfig(clientIdentifier: string, consumerId?: string): Promise<ConsumerCatalogInstance> {
		const getUrl = `/order-ui/consumer-configuration-v1/${clientIdentifier}`
		const url = consumerId ? `${getUrl}?consumerId=${consumerId}` : getUrl

		return API.getWithRetries<GetConsumerCatalogConfigurationResponse>(url, true).then((response) => {
			return new ConsumerCatalogInstance(response.configuration, response.articleTrees, response.features)
		})
	}

	return data
}
