import { toNumber } from "lodash"
import { FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { v4 } from "uuid"
import style from "./IncrementorPill.module.css"

type IncrementorPillProps = {
	defaultValue: number | null
	max: number
	text: string
	onChange: (value: number | null, oldValue: number | null, componentId: string) => void
	onBlur?: (value: number | null, componentId: string) => void
	multiplier: number
}

export const IncrementorPill: FC<IncrementorPillProps> = ({
	defaultValue,
	max,
	onChange: extOnChange,
	text,
	onBlur,
	multiplier = 1,
}) => {
	const [value, setValue] = useState<number | null>(null)

	let oldValue: number | null = null

	const componentId = useRef(v4())

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue * multiplier)
		}
	}, [defaultValue])

	function onChange(newValue: number | null, oldVal: number | null, element: HTMLInputElement): void {
		if (newValue !== null && newValue > max) {
			newValue = max
			element.value = (newValue * multiplier).toString()
		}

		extOnChange(newValue, oldVal, componentId.current)
		setValue(newValue)
		oldValue = oldVal
	}

	return (
		<span className={style.incrementorPill} style={{ height: `45px` }}>
			<input
				id={componentId.current}
				key={componentId.current}
				type={"number"}
				value={value || ""}
				onFocus={(e) => {
					e.target.select()
					e.target.addEventListener(
						"wheel",
						function (e) {
							e.preventDefault()
						},
						{ passive: false },
					)
				}}
				onChange={(event) => {
					if (event.target.value === "") {
						onChange(null, value !== null ? value / multiplier : null, event.target)
					} else if (event.target.value === "0") {
						onChange(0, value !== null ? value / multiplier : null, event.target)
					} else {
						let number = toNumber(event.target.value)
						if (number % multiplier !== 0) {
							number += 4 - (number % multiplier)
						}
						onChange(number / multiplier, value !== null ? value / multiplier : null, event.target)
					}
				}}
				onBlur={(event) => {
					let number = event.target.value ? toNumber(event.target.value) : null
					let result

					if (number !== null && number % multiplier !== 0) {
						if (number > 0) result = Math.ceil(number / multiplier) * multiplier
						else result = multiplier

						event.target.value = result.toString()
						onChange(result / multiplier, value !== null ? value / multiplier : null, event.target)
					}

					if (number !== null && number === 0) {
						result = 0
					}

					if (onBlur && result !== undefined) {
						onBlur(result, componentId.current)
					}

					if (number === null) {
						event.target.value = oldValue?.toString() || ""
					}
				}}
				step={multiplier}
			/>
			&nbsp;
			<span className={style.unitText}>/ {text}</span>
		</span>
	)
}

type ExpandableIncrementorPillProps = IncrementorPillProps & {
	size: number
	currentValue?: { value: number; show: boolean }
}

export type ExpandableIncrementorPillRefProps = {
	id: string
	reset: () => void
}

export const ExpandableIncrementorPill = forwardRef(
	(props: ExpandableIncrementorPillProps, ref: ForwardedRef<ExpandableIncrementorPillRefProps>) => {
		const [value, setValue] = useState(props.defaultValue)
		const [showIncrementor, setShowIncrementor] = useState(value !== null)

		const componentId = useRef(v4())

		useEffect(() => {
			if (props.currentValue !== undefined) {
				setValue(props.currentValue.value)
				setShowIncrementor(props.currentValue.show)
			}
		}, [props.currentValue])

		useImperativeHandle(
			ref,
			() => ({
				id: componentId.current,
				reset() {
					setTimeout(() => {
						setShowIncrementor(false)
						setValue(props.defaultValue)
					}, 50)
				},
			}),
			[props.defaultValue],
		)

		function onChange(value: number | null, oldValue: number | null) {
			if (value === null || value <= 0) {
				setShowIncrementor(false)
			}
			setValue(value)
			props.onChange(value, oldValue, componentId.current)
		}

		function onBlur(value: number | null) {
			if (value === null || value <= 0) {
				setShowIncrementor(false)
			}
			if (props.onBlur) {
				props.onBlur(value, componentId.current)
			}
		}

		return (
			<div
				className={style.expandableButtonWrapper}
				style={{ minHeight: `${props.size}px`, minWidth: `${props.size}px` }}>
				{showIncrementor ? (
					<IncrementorPill
						defaultValue={value}
						max={props.max}
						text={props.text}
						onChange={onChange}
						onBlur={onBlur}
						multiplier={props.multiplier}
					/>
				) : (
					<button
						onClick={(event) => {
							event.preventDefault()
							event.stopPropagation()
							onChange(1, 0)
							setShowIncrementor(true)
						}}
						type="button"
						style={{ minHeight: "34px", maxHeight: "34px", minWidth: "34px", maxWidth: "34px" }}
						className={style.expandableButton}>
						+
					</button>
				)}
			</div>
		)
	},
)
