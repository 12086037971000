import { throwIllegalState } from "Shared/throwIllegalState"

/**
 * Function to help with exhaustive case/pattern matching-like code when using enum like types.
 *
 * ```
 * when(quantityType, {
 *   [ServiceQuantityTypeEnum.Amount]: (value) => {
 *   ...
 *   },
 *   [ServiceQuantityTypeEnum.Waste]: (value) => {
 *   ...
 *   },
 * })
 * ```
 *
 * @param value
 * @param cases
 */
export function when<T extends keyof any, R>(value: T, cases: Record<T, (value: T) => R>): R {
	const theCase = cases[value]
	if (!theCase) {
		throwIllegalState("Case not defined!")
	}
	return theCase(value)
}
