import { ClientInstance } from "Client/ClientInstance"
import { ClientModes } from "Client/GetClientResponse"
import { plausible } from "Shared/plausible"
import { getLogger } from "../Logging/getLogger"

const logger = getLogger("metrics")

export function metric(customEvent: string, client: ClientInstance) {
	const clientLifeCycle =
		client.modes.find((it) => it === ClientModes.Demo) ??
		client.modes.find((it) => it === ClientModes.Live) ??
		client.modes.find((it) => it === ClientModes.Dead) ??
		client.modes.find((it) => it === ClientModes.Removed)
	const testMode = client.modes.find((it) => it === ClientModes.Test) != null

	plausible(customEvent, {
		props: {
			clientIdentifier: client.identifier,
			lifeCycle: clientLifeCycle?.toString() ?? "Undefined",
			testMode: testMode,
		},
		callback: () => {
			logger.log("Plausible not loaded")
		},
	})
}
