/**
 * This should probably be removed before production release
 **/
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getLogger } from "../Logging/getLogger"
import { API } from "../network/API"

const logger = getLogger("ListClients")

export const ListClients = () => {
	const [clientList, setClientList] = useState<string[] | "loading" | "not-available">("loading")

	useEffect(() => {
		API.get("/clients/list")
			.then((clients) => {
				const list = clients as string[]
				setClientList(list)
			})
			.catch((e) => {
				logger.error(e)
				setClientList("not-available")
			})
	})

	const content = () => {
		if (clientList === "loading") {
			return <p>Laddar...</p>
		} else if (clientList === "not-available") {
			return <p>Klient listan är inte tillgänglig</p>
		} else {
			if (clientList.length <= 0) {
				return <p>Inga klienter in lagda</p>
			}

			return clientList.map((client) => {
				return (
					<p key={client}>
						<Link to={`/${client}`}>{client}</Link>
					</p>
				)
			})
		}
	}

	return <div style={{ width: "200px", margin: "auto" }}>{content()}</div>
}
