import { faArrowLeft, faArrowRight, faMinus, faPlus, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	BusinessDetails,
	ClientContactDetails,
	CommentIcon,
	ConsumerContactDetails,
	ExampleTimeSlots,
	MailForOrders,
	ProductsLibrary,
	SelectedProducts,
	SelectedTimeSlots,
	SelectedWasteTypes,
	TabIconChevronUp,
	TabIconCustomerDetails,
	TabIconResources,
	TabIconTimeSlot,
	TabIconTransportation,
	TabIconWasteType,
	TransportationTerms,
	TransportLibrary,
	TrashIcon,
	WasteTypeLibrary,
} from "Icons/Icon"
import {
	cloneDeep,
	debounce,
	filter,
	find,
	forEach,
	includes,
	isNull,
	isUndefined,
	map,
	orderBy,
	remove,
	sortBy,
	toNumber,
} from "lodash"
import Lottie from "lottie-react"
import { fixLottieColor } from "Lottie/Helpers"
import { DateTime } from "luxon"
import { API, ServerError } from "network/API"
import { NativeModuleTimePicker } from "Orders/Components/Form/NativeModuleTimePicker/NativeModuleTimePicker"
import { ToggleSwitchSlim } from "Orders/Components/Form/ToggleSwitchSlim/ToggleSwitchSlim"
import { ProductImage } from "Orders/Components/ProductImage/ProductImage"
import { TransportDescription } from "Orders/Components/TransportDescription/TransportDescription"
import { WasteTypeIcon } from "Orders/Components/WasteTypeIcon/WasteTypeIcon"
import { unitFormatter } from "Orders/unit-formatter"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useThrowAsync } from "Shared/throwAsync"
import { v4 } from "uuid"
import style from "../Onboarding/Onboarding.module.css"
import {
	Comment,
	CustomerDetails,
	OnboardingSession,
	OnboardingSessionApiObject,
	OnboardingState,
	ProductOnboardingDefinition,
	ServiceOnboardingDefinition,
	SessionStatus,
	TabDetails,
	TimeSlotOnboardingDefinition,
	TransportationOnboardingDefinition,
	WasteTypeOnboardingDefinition,
} from "./types/onboardingTypes"
import {
	AllowedOnboardingFeatureEnum,
	ProductDefinitionTemplate,
	QuantityTypeTemplateEnum,
	ServiceDefinitionTemplate,
	TemplateModelHolder,
	TimeSlotDefinitionTemplate,
	TransportationDefinitionTemplate,
	WasteTypeDefinitionTemplate,
} from "./types/templateTypes"

import { ClientNotFound } from "Client/ClientNotFound/ClientNotFound"
import { Footer } from "OrderAcceptance/Footer/Footer"
import { cls } from "Shared/cls"
import comfortable from "../Lottie/comfortable.json"
import skrappyLoading from "../Lottie/skrappyLoading.json"
import thinking from "../Lottie/thinking.json"
import underConstruction from "../Lottie/underConstruction.json"
import { OnboardingHeader } from "./OnboardingHeader/OnboardingHeader"

enum OnboardingError {
	IdentifierNotFound,
	SessionNotFound,
	None,
}

enum SavingState {
	PendingSave,
	Saved,
	None,
}

const onboardingTabStructures: TabDetails[] = [
	{
		onboardingState: OnboardingState.CustomerDetails,
		readableName: "Uppgifter",
		description: "Fyll i era företagsuppgifter",
	},
	{
		onboardingState: OnboardingState.Products,
		readableName: "Resurser",
		description: "Skapa och anpassa era resurser",
	},
	{
		onboardingState: OnboardingState.WasteType,
		readableName: "Avfallstyper",
		description: "Skapa era avfallslistor med våra ikoner",
	},
	{
		onboardingState: OnboardingState.TimeSlot,
		readableName: "Tidsluckor",
		description: "Skapa era bokningsbara tidsluckor",
	},
	{
		onboardingState: OnboardingState.Service,
		readableName: "Tjänster",
		description: "Skapa era tjänster som erbjuds",
	},
	{
		onboardingState: OnboardingState.Transportation,
		readableName: "Transportvillkor",
		description: "Anpassa och sätt transportbegränsningar",
	},
]

export const Onboarding = () => {
	const { onboardingId } = useParams()

	const throwAsync = useThrowAsync()
	const [onboardingSession, setOnboardingSession] = useState<OnboardingSession>()
	const [templateData, setTemplateData] = useState<TemplateModelHolder>()
	const [loadingError, setLoadingError] = useState<OnboardingError>(OnboardingError.None)
	const [onboardingState, setOnboardingState] = useState<OnboardingState>(OnboardingState.CustomerDetails)
	const [tick, setTick] = useState(0)
	const [previousTickValue, setPreviousTickValue] = useState(0)
	const [isSavingData, setIsSavingData] = useState(SavingState.None)
	const [screenSize, setScreenSize] = useState(getCurrentDimension())
	const [showTabDropdown, setShowTabDropdown] = useState(false)

	function getCurrentDimension() {
		return {
			width: window.innerWidth,
			height: window.innerHeight,
		}
	}

	const [queryParams, setQueryParams] = useSearchParams()
	const navigate = useNavigate()

	function incrementTick() {
		setTick(tick + 1)
	}

	function setOnboardingStateFunction(targetState: OnboardingState) {
		closeDialogs()
		setShowTabDropdown(false)
		queryParams.set("tabId", targetState)
		setQueryParams(queryParams)
	}

	function setOnboardingSessionFunction(p_onboardingSession: OnboardingSession) {
		setOnboardingSession(p_onboardingSession)
		incrementTick()
	}

	useEffect(() => {
		const updateDimension = () => {
			setScreenSize(getCurrentDimension())
		}
		window.addEventListener("resize", updateDimension)

		return () => {
			window.removeEventListener("resize", updateDimension)
		}
	}, [screenSize])

	useEffect(() => {
		function fetchOnboardingSession(p_onboardingId: string) {
			API.get<OnboardingSessionApiObject>(`/onboarding/session-v1/${p_onboardingId}`)
				.then((apiObject) => {
					setOnboardingSession(apiObject.session)
					const selectedTab = queryParams.get("tabId")
					if (!isNull(selectedTab) && selectedTab !== onboardingState) {
						if (includes(apiObject.session.availableTabs, selectedTab)) {
							setOnboardingStateFunction(selectedTab as OnboardingState)
						} else if (apiObject.session.availableTabs.length > 0) {
							const obj = apiObject.session.availableTabs[0]
							if (obj) {
								setOnboardingStateFunction(obj)
							} else {
								setOnboardingStateFunction(OnboardingState.None)
							}
						} else {
							setOnboardingStateFunction(OnboardingState.None)
						}
					} else {
						if (apiObject.session.availableTabs.length > 0) {
							const obj = apiObject.session.availableTabs[0]
							if (obj) {
								setOnboardingStateFunction(obj)
							} else {
								setOnboardingStateFunction(OnboardingState.None)
							}
						} else {
							setOnboardingStateFunction(OnboardingState.None)
						}
					}
					if (apiObject.session.status === SessionStatus.Created) {
						const sessionClone = cloneDeep(apiObject.session)
						sessionClone.status = SessionStatus.Opened
						setOnboardingSessionFunction(sessionClone)
					}
				})
				.catch((error: ServerError<unknown>) => {
					if (error.status === 404) {
						setLoadingError(OnboardingError.SessionNotFound)
					} else {
						throwAsync(new Error(`Unable to load session for: ${p_onboardingId}`, { cause: error }))
					}
				})
		}

		function fetchTemplateData() {
			API.get<TemplateModelHolder>(`/onboarding/template-v1`).then((templates) => {
				setTemplateData(templates)
			})
		}

		if (onboardingId !== undefined) {
			fetchOnboardingSession(onboardingId)
			fetchTemplateData()
			setLoadingError(OnboardingError.None)
		} else {
			setLoadingError(OnboardingError.IdentifierNotFound)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onboardingId, throwAsync])

	const debounceHandler = useMemo(
		() =>
			debounce((p_onboardingSession: OnboardingSession | undefined) => {
				setIsSavingData(SavingState.PendingSave)
				if (!isUndefined(p_onboardingSession)) {
					const putObject: OnboardingSessionApiObject = {
						session: p_onboardingSession,
					}
					API.put<OnboardingSessionApiObject>(
						`/onboarding/session-v1/${p_onboardingSession.identifier}`,
						putObject,
					)
						.then(() => {
							setIsSavingData(SavingState.Saved)
						})
						.catch((error: ServerError<unknown>) => {
							if (error.status === 404) {
								setLoadingError(OnboardingError.SessionNotFound)
							}
						})
				}
			}, 1000),
		[],
	)

	useEffect(() => {
		if (tick !== previousTickValue) {
			debounceHandler(onboardingSession)
		}
		setPreviousTickValue(tick)
	}, [onboardingSession, tick, debounceHandler, previousTickValue])

	useEffect(() => {
		setOnboardingState(queryParams.get("tabId") as OnboardingState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams])

	// Customer details
	function renderCustomerWizardStep(p_onboardingSession: OnboardingSession) {
		return (
			<div className={style.tabFrame}>
				<div>
					<h1 className={style.tabTitle}>Uppgifter för att skapa ett konto</h1>
					<span className={style.tabDescriptionText}>
						Fyll i de uppgifter skrappy behöver för att skapa ett konto
					</span>
				</div>
				<div className={style.clientDetailsContent}>
					<div className={style.section}>
						<div className={style.clientSectionHeader}>
							<div className={style.sectionIcon}>
								<BusinessDetails size={64} iconClassName={style.containerIcon} />
							</div>
							<div className={style.indentSection}>
								<h2 className={style.sectionTitle}>Företagsuppgifter</h2>
								<span className={style.sectionDescriptionText}>
									Era företagsuppgifter för att skapa ett konto i vårt system
								</span>
							</div>
						</div>
						<div className={style.sectionInputArea}>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Företagsnamn</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "organizationName")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(p_onboardingSession, e.target.value, "organizationName"),
										)
									}
								/>
							</div>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Organisationsnummer</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "organizationNumber")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(
												p_onboardingSession,
												e.target.value,
												"organizationNumber",
											),
										)
									}
								/>
							</div>
						</div>
						<div className={style.sectionInputArea}>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Adress</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "address")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(p_onboardingSession, e.target.value, "address"),
										)
									}
								/>
							</div>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Postnummer</h3>
								</div>
								<input
									type="number"
									pattern="[0-9]*"
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "postalNumber")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(p_onboardingSession, e.target.value, "postalNumber"),
										)
									}
								/>
							</div>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Postort</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "city")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(p_onboardingSession, e.target.value, "city"),
										)
									}
								/>
							</div>
						</div>
					</div>
					<div className={style.clientDetailsContent}>
						<div className={style.section}>
							<div className={style.clientSectionHeader}>
								<div className={style.sectionIcon}>
									<MailForOrders size={64} iconClassName={style.containerIcon} />
								</div>
								<div className={style.indentSection}>
									<h2 className={style.sectionTitle}>Adress för bokningar</h2>
									<span className={style.sectionDescriptionText}>
										Adressen som bokningarna skickas till primärt
									</span>
								</div>
							</div>
							<div className={style.sectionInputArea}>
								<div className={style.sectionInput}>
									<div>
										<h3 className={style.slimTitle}>Mailadress dit ordrar skickas</h3>
									</div>
									<input
										className={style.inputTextBox}
										value={getCustomerDetailsValue(p_onboardingSession, "orderEmail")}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateCustomerDetails(p_onboardingSession, e.target.value, "orderEmail"),
											)
										}
									/>
								</div>
							</div>
						</div>
						<div className={style.section}>
							<div className={style.clientSectionHeader}>
								<div className={style.sectionIcon}>
									<ClientContactDetails size={64} iconClassName={style.containerIcon} />
								</div>
								<div className={style.indentSection}>
									<h2 className={style.sectionTitle}>Kontaktuppgifter för Skrappy</h2>
									<span className={style.sectionDescriptionText}>
										Kontaktuppgifter för supportfrågor och uppdateringar
									</span>
								</div>
							</div>
							<div className={style.sectionInputArea}>
								<div className={style.sectionInput}>
									<div>
										<h3 className={style.slimTitle}>Namn</h3>
									</div>
									<input
										className={style.inputTextBox}
										value={getCustomerDetailsValue(p_onboardingSession, "contact.name")}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateCustomerDetails(p_onboardingSession, e.target.value, "contact.name"),
											)
										}
									/>
								</div>
								<div className={style.sectionInput}>
									<div>
										<h3 className={style.slimTitle}>Mailadress till kontaktperson</h3>
									</div>
									<input
										className={style.inputTextBox}
										value={getCustomerDetailsValue(p_onboardingSession, "contact.clientEmail")}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateCustomerDetails(
													p_onboardingSession,
													e.target.value,
													"contact.clientEmail",
												),
											)
										}
									/>
								</div>
								<div className={style.sectionInput}>
									<div>
										<h3 className={style.slimTitle}>Mailadress för uppdateringar</h3>
									</div>
									<input
										className={style.inputTextBox}
										value={getCustomerDetailsValue(p_onboardingSession, "contact.emailForUpdates")}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateCustomerDetails(
													p_onboardingSession,
													e.target.value,
													"contact.emailForUpdates",
												),
											)
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={style.section}>
						<div className={style.clientSectionHeader}>
							<div className={style.sectionIcon}>
								<ConsumerContactDetails size={64} iconClassName={style.containerIcon} />
							</div>
							<div className={style.indentSection}>
								<h2 className={style.sectionTitle}>Kontaktuppgifter för era kunder</h2>
								<span className={style.sectionDescriptionText}>
									Uppgifter till er som hänvisas vid eventuella frågor
								</span>
							</div>
						</div>
						<div className={style.sectionInputArea}>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Telefonnummer för kontakt</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(
										p_onboardingSession,
										"clientConsumerContact.phoneNumber",
									)}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(
												p_onboardingSession,
												e.target.value,
												"clientConsumerContact.phoneNumber",
											),
										)
									}
								/>
							</div>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Mailadress för kontakt</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "clientConsumerContact.email")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(
												p_onboardingSession,
												e.target.value,
												"clientConsumerContact.email",
											),
										)
									}
								/>
							</div>
							<div className={style.sectionInput}>
								<div>
									<h3 className={style.slimTitle}>Hemsida</h3>
								</div>
								<input
									className={style.inputTextBox}
									value={getCustomerDetailsValue(p_onboardingSession, "clientConsumerContact.homepage")}
									onChange={(e) =>
										setOnboardingSessionFunction(
											updateCustomerDetails(
												p_onboardingSession,
												e.target.value,
												"clientConsumerContact.homepage",
											),
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	// Products
	function renderProductWizardStep(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<div className={style.tabFrame}>
				<div>
					<h1 className={style.tabTitle}>Välj en resurs från vårt resursbibliotek</h1>
					<span className={style.tabDescriptionText}>Konfigurera resurser så de passar er</span>
				</div>
				{availableProductUI(p_onboardingSession, p_templateData)}

				{selectedProductsUI(p_onboardingSession)}
			</div>
		)
	}

	function availableProductUI(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		const filteredProducts = filterProducts(p_onboardingSession, p_templateData)
		return (
			<dialog id="productTemplate_Modal" className={style.templateModal}>
				<div className={style.clientSectionHeader}>
					<div className={style.sectionIcon}>
						<ProductsLibrary size={64} iconClassName={style.containerIcon} />
					</div>
					<div className={style.sectionDescriptionInHeader}>
						<h2 className={style.sectionTitle}>Resursbibliotek</h2>
						<span className={style.sectionDescriptionText}>Välj resurser från vårt bibliotek</span>
					</div>
					<div className={style.headerActionArea}>
						<button className={style.modalXButton} onClick={() => closeDialogs()}>
							<FontAwesomeIcon icon={faX} />
						</button>
					</div>
				</div>
				<div className={style.sectionDescriptionBelowHeader}>
					<h2 className={style.sectionTitle}>Resursbibliotek</h2>
					<span className={style.sectionDescriptionText}>Välj resurser från vårt bibliotek</span>
				</div>
				{filteredProducts.length > 0 ? (
					<div className={style.displayArea}>
						{filteredProducts.map((templateProduct, index) => (
							<div
								key={index}
								onClick={() =>
									setOnboardingSessionFunction(addProduct(p_onboardingSession, templateProduct))
								}
								tabIndex={index}
								className={style.productDefinitionListItem}>
								<div className={style.listLargeIconSection}>
									<ProductImage
										image={templateProduct.typeImage}
										className={style.productImageSmallInList}
									/>
								</div>
								<div className={style.productImageSmallTitleSection}>
									<div>
										<h4 className={style.productImageSmallTitle}>
											{unitFormatter(templateProduct.name)}
										</h4>
									</div>
									<div className={style.plusButtonDiv}>
										<div className={style.plusButton}>
											<FontAwesomeIcon
												style={{
													marginTop: "0.5rem",
													marginLeft: "0.5rem",
												}}
												icon={faPlus}
											/>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<div className={style.indentSection}>
						<div className={style.sectionDescriptionText}>
							Det finns inga produkter för denna sessionen. Kontakta Skrappy för hjälp
						</div>
					</div>
				)}
				<div className={style.modalFooter}>
					<button className={style.modalCloseButton} onClick={() => closeDialogs()}>
						Klar
					</button>
				</div>
			</dialog>
		)
	}

	function selectedProductsUI(p_onboardingSession: OnboardingSession) {
		return (
			<div className={style.tabSection}>
				<div className={style.stickySection}>
					<div className={style.sectionIcon}>
						<SelectedProducts size={64} iconClassName={style.containerIcon} />
					</div>
					<div className={style.sectionDescriptionInHeader}>
						<h2 className={style.sectionTitle}>Mina resurser</h2>
						<span className={style.sectionDescriptionText}>Redigera resurserna så de passar er verksamhet</span>
					</div>
					<div className={style.headerActionArea}>
						<button
							className={style.headerActionButton}
							onClick={() => handleDialogs("productTemplate_Modal", true)}>
							<span className={style.addTemplateButton}>Lägg till ny resurs</span>
							<FontAwesomeIcon icon={faPlus} />
						</button>
					</div>
				</div>
				<div className={style.sectionDescriptionBelowHeader}>
					<h2 className={style.sectionTitle}>Mina resurser</h2>
					<div className={style.sectionDescriptionText}>Redigera resurserna så de passar er verksamhet</div>
				</div>
				{p_onboardingSession.productDetails.length > 0 ? (
					<div className={style.displayArea}>
						{p_onboardingSession.productDetails.map((productDefinition, index) => (
							<div key={index} className={style.productDefinitionCard}>
								<div>
									<div className={style.productImageBackground}>
										<ProductImage
											image={productDefinition.typeImage}
											className={style.productImageMedium}
										/>
									</div>
									<div>
										<h4 className={style.slimTitle}>Produktnamn</h4>
										<input
											className={style.inputTextBox}
											onChange={(e) =>
												setOnboardingSessionFunction(
													updateProductDefinition(
														p_onboardingSession,
														productDefinition.id,
														e.target.value,
														"name",
													),
												)
											}
											value={getProductDefinitionDetail(productDefinition, "name")}
										/>
									</div>
									<div>
										<h4 className={style.slimTitle}>Produktbeskrivning</h4>
										<textarea
											className={style.inputTextArea}
											onChange={(e) =>
												setOnboardingSessionFunction(
													updateProductDefinition(
														p_onboardingSession,
														productDefinition.id,
														e.target.value,
														"description",
													),
												)
											}
											value={getProductDefinitionDetail(productDefinition, "description")}
										/>
									</div>
									<div className={style.productDimensionArea}>
										{renderDimensionUI(p_onboardingSession, productDefinition, "Volym", "volume")}
										{renderDimensionUI(p_onboardingSession, productDefinition, "Längd", "length")}
										{renderDimensionUI(p_onboardingSession, productDefinition, "Höjd", "height")}
										{renderDimensionUI(p_onboardingSession, productDefinition, "Bredd", "width")}
										{renderDimensionUI(p_onboardingSession, productDefinition, "Max vikt", "maxWeight")}
										{renderDimensionUI(p_onboardingSession, productDefinition, "Vikt", "weigth")}
									</div>

									<div>
										<div className={style.footerContent}>
											<div
												onClick={() =>
													setOnboardingSessionFunction(
														removeProduct(p_onboardingSession, productDefinition.id),
													)
												}>
												<TrashIcon size={32} iconClassName={style.thrashIcon} />
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<span className={style.sectionDescriptionText}>
						Välj en resurs från biblioteket för att börja konfigurera dem för er.
					</span>
				)}
			</div>
		)
	}

	function filterProducts(
		p_onboardingSession: OnboardingSession,
		p_templateData: TemplateModelHolder,
	): ProductDefinitionTemplate[] {
		return filter(p_templateData.productTemplates, (productTemplate) =>
			includes(p_onboardingSession.allowedOnboardingFeatures, productTemplate.productType),
		)
	}

	function renderDimensionUI(
		p_onboardingSession: OnboardingSession,
		p_productDefinition: ProductOnboardingDefinition,
		label: string,
		variableName: string,
	) {
		if (includes(Object.keys(p_productDefinition.dimensions), variableName)) {
			return (
				<div className={style.dimensionInput}>
					<div className={style.fitContent}>
						<h4 className={style.slimTitle}>{label}</h4>
					</div>
					<div>
						<input
							className={style.dimensionInputTextBox}
							type="number"
							onChange={(e) =>
								setOnboardingSessionFunction(
									updateProductDefinition(
										p_onboardingSession,
										p_productDefinition.id,
										e.target.value,
										variableName + ".value",
									),
								)
							}
							value={getProductDefinitionDetail(p_productDefinition, variableName + ".value")}
						/>
						<span className={style.dimensionInputTextBoxUnit}>
							{unitFormatter(
								getProductDefinitionDetail(p_productDefinition, variableName + ".unit") as string,
							)}
						</span>
					</div>
				</div>
			)
		}
		return null
	}

	// Waste type
	function renderWasteTypeWizardStep(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<div className={style.tabFrame}>
				<div>
					<h1 className={style.tabTitle}>Skapa er avfallslista med vårt ikonbibliotek</h1>
					<span className={style.tabDescriptionText}>
						Välj en avfallsikon, redigera namn, skriv in avfallsbeskrivning och kryssa i vilken resurs den går
						att boka på
					</span>
				</div>
				{availableWasteTypeUI(p_onboardingSession, p_templateData)}
				{selectedWasteTypeUI(p_onboardingSession)}
			</div>
		)
	}

	function availableWasteTypeUI(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<dialog id="wasteTypeTemplate_Modal" className={style.templateModal}>
				<div>
					<div className={style.clientSectionHeader}>
						<div className={style.sectionIcon}>
							<WasteTypeLibrary size={64} iconClassName={style.containerIcon} />
						</div>
						<div className={style.sectionDescriptionInHeader}>
							<h2 className={style.sectionTitle}>Avfallsbibliotek</h2>
							<span className={style.sectionDescriptionText}>Välj avfallsikoner från biblioteket</span>
						</div>
						<div className={style.headerActionArea}>
							<button className={style.modalXButton} onClick={() => closeDialogs()}>
								<FontAwesomeIcon icon={faX} />
							</button>
						</div>
					</div>
				</div>
				<div className={style.sectionDescriptionBelowHeader}>
					<h2 className={style.sectionTitle}>Avfallsbibliotek</h2>
					<span className={style.sectionDescriptionText}>Välj avfallsikoner från biblioteket</span>
				</div>
				<div className={style.displayArea}>
					{p_templateData.wasteTypeTemplates.map((wasteType, index) => (
						<div
							key={index}
							tabIndex={index}
							className={style.wasteTypeListItem}
							onClick={() => setOnboardingSessionFunction(addWasteType(p_onboardingSession, wasteType))}>
							<div className={style.listLargeIconSection}>
								<WasteTypeIcon wasteType={wasteType.typeImage} className={style.wasteTypeListLargeIcon} />
							</div>
							<div className={style.productImageSmallTitleSection}>
								<h4 className={style.productImageSmallTitle}>{wasteType.name}</h4>
								<div className={style.plusButtonDiv}>
									<div className={style.plusButton}>
										<FontAwesomeIcon
											style={{
												marginTop: "0.5rem",
												marginLeft: "0.5rem",
											}}
											icon={faPlus}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className={style.modalFooter}>
					<button className={style.modalCloseButton} onClick={() => closeDialogs()}>
						Klar
					</button>
				</div>
			</dialog>
		)
	}

	function selectedWasteTypeUI(p_onboardingSession: OnboardingSession) {
		return (
			<div className={style.tabSection}>
				<div className={style.stickySection}>
					<div className={style.sectionIcon}>
						<SelectedWasteTypes size={64} iconClassName={style.containerIcon} />
					</div>
					<div className={style.sectionDescriptionInHeader}>
						<h2 className={style.sectionTitle}>Mina avfallstyper</h2>
						<span className={style.sectionDescriptionText}>
							Redigera avfallstyperna så de passar er verksamhet
						</span>
					</div>
					<div className={style.headerActionArea}>
						<button
							className={style.headerActionButton}
							onClick={() => handleDialogs("wasteTypeTemplate_Modal", true)}>
							<span className={style.addTemplateButton}>Lägg till ny avfallstyp</span>
							<FontAwesomeIcon icon={faPlus} />
						</button>
					</div>
				</div>
				<div className={style.sectionDescriptionBelowHeader}>
					<h2 className={style.sectionTitle}>Mina avfallstyper</h2>
					<span className={style.sectionDescriptionText}>Redigera avfallstyperna så de passar er verksamhet</span>
				</div>
				{!isUndefined(p_onboardingSession.wasteTypeDetails) && p_onboardingSession.wasteTypeDetails.length > 0 ? (
					<div className={style.displayArea}>
						{p_onboardingSession.wasteTypeDetails.map((wasteType, index) => (
							<div key={index} className={style.productDefinitionCard}>
								<div className={style.productImageBackground}>
									<WasteTypeIcon
										wasteType={wasteType.typeImage}
										className={style.wasteTypeCardLargeIcon}
									/>
								</div>
								<div>
									<h4 className={style.slimTitle}>Avfalls namn</h4>
									<input
										className={style.inputTextBox}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateWasteTypeDefinition(
													p_onboardingSession,
													wasteType.id,
													e.target.value,
													"name",
												),
											)
										}
										value={getWasteTypeDefinitionDetail(wasteType, "name")}
									/>
								</div>
								<div>
									<h4 className={style.slimTitle}>Avfalls beskrivning</h4>
									<textarea
										className={style.inputTextArea}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateWasteTypeDefinition(
													p_onboardingSession,
													wasteType.id,
													e.target.value,
													"info",
												),
											)
										}
										value={getWasteTypeDefinitionDetail(wasteType, "info")}
									/>
								</div>
								<div>
									<div>
										<h4 className={style.slimTitle}>Hanteringsformat</h4>
									</div>
									{includes(
										p_onboardingSession.allowedOnboardingFeatures,
										AllowedOnboardingFeatureEnum.Container,
									) ? (
										<div className={style.radioGroup}>
											<div className={style.radioGroupItem}>
												<span className={style.radioGroupItemLabel}>Container</span>
												<span className={style.radioGroupItemSwitch}>
													<ToggleSwitchSlim
														checked={includes(wasteType.categories, "container")}
														onChange={() =>
															setOnboardingSessionFunction(
																updateWasteTypeDefinition(
																	p_onboardingSession,
																	wasteType.id,
																	"container",
																	"categories",
																),
															)
														}
													/>
												</span>
											</div>
										</div>
									) : null}
									{includes(
										p_onboardingSession.allowedOnboardingFeatures,
										AllowedOnboardingFeatureEnum.Vessel,
									) ? (
										<div className={style.radioGroup}>
											<div className={style.radioGroupItem}>
												<span className={style.radioGroupItemLabel}>Kärl</span>
												<span className={style.radioGroupItemSwitch}>
													<ToggleSwitchSlim
														checked={includes(wasteType.categories, "vessel")}
														onChange={() =>
															setOnboardingSessionFunction(
																updateWasteTypeDefinition(
																	p_onboardingSession,
																	wasteType.id,
																	"vessel",
																	"categories",
																),
															)
														}
													/>
												</span>
											</div>
										</div>
									) : null}
									{includes(
										p_onboardingSession.allowedOnboardingFeatures,
										AllowedOnboardingFeatureEnum.Vessel,
									) ? (
										<div className={style.radioGroup}>
											<div className={style.radioGroupItem}>
												<span className={style.radioGroupItemLabel}>Säck</span>
												<span className={style.radioGroupItemSwitch}>
													<ToggleSwitchSlim
														checked={includes(wasteType.categories, "bag")}
														onChange={() =>
															setOnboardingSessionFunction(
																updateWasteTypeDefinition(
																	p_onboardingSession,
																	wasteType.id,
																	"bag",
																	"categories",
																),
															)
														}
													/>
												</span>
											</div>
										</div>
									) : null}
								</div>
								<div>
									<div className={style.footerContent}>
										<div
											onClick={() =>
												setOnboardingSessionFunction(
													removeWasteType(p_onboardingSession, wasteType.id),
												)
											}>
											<TrashIcon size={32} iconClassName={style.thrashIcon} />
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<span className={style.sectionDescriptionText}>
						Välj en avfallstyp från biblioteket för att börja konfigera dem för er.
					</span>
				)}
			</div>
		)
	}

	// Transport
	function renderTransportationWizardStep(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<div className={style.tabFrame}>
				<div>
					<h1 className={style.tabTitle}>Välj transport typer från vårt transportbibliotek</h1>
					<span className={style.tabDescriptionText}>Namnge transporten och fyll i dess mått</span>
				</div>
				{availableTransportationUI(p_onboardingSession, p_templateData)}
				{selectedTransportationUI(p_onboardingSession)}
			</div>
		)
	}

	function availableTransportationUI(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<dialog id="transportTemplate_Modal" className={style.templateModal}>
				<div>
					<div className={style.clientSectionHeader}>
						<div className={style.sectionIcon}>
							<TransportLibrary size={64} iconClassName={style.containerIcon} />
						</div>
						<div className={style.sectionDescriptionInHeader}>
							<h2 className={style.sectionTitle}>Transportbibliotek</h2>
							<span className={style.sectionDescriptionText}>Välj transporterna som används av er</span>
						</div>
						<div className={style.headerActionArea}>
							<button className={style.modalXButton} onClick={() => closeDialogs()}>
								<FontAwesomeIcon icon={faX} />
							</button>
						</div>
					</div>
					<div className={style.sectionDescriptionBelowHeader}>
						<h2 className={style.sectionTitle}>Transportbibliotek</h2>
						<span className={style.sectionDescriptionText}>Välj transporterna som används av er</span>
					</div>
				</div>
				<div className={style.displayArea}>
					{p_templateData.transportationTemplates.map((transportation, index) => (
						<div
							key={index}
							tabIndex={index}
							className={style.transportationListItem}
							onClick={() =>
								setOnboardingSessionFunction(addTransportationType(p_onboardingSession, transportation))
							}>
							<div className={style.productImageSmallTitleSection}>
								<h4 className={style.productImageSmallTitle}>{transportation.name}</h4>
								<div className={style.transportationListItemImage}>
									<TransportDescription type={transportation.transportationDetails.type} data={{}} />
								</div>
							</div>
							<div className={style.transportationModalFooter}>
								<div className={style.plusButtonDiv}>
									<div className={style.plusButton}>
										<FontAwesomeIcon
											style={{
												marginTop: "0.5rem",
												marginLeft: "0.5rem",
											}}
											icon={faPlus}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className={style.modalFooter}>
					<button className={style.modalCloseButton} onClick={() => closeDialogs()}>
						Klar
					</button>
				</div>
			</dialog>
		)
	}

	function selectedTransportationUI(p_session: OnboardingSession) {
		return (
			<div className={style.tabSection}>
				<div className={style.stickySection}>
					<div className={style.sectionIcon}>
						<TransportationTerms size={64} iconClassName={style.containerIcon} />
					</div>
					<div className={style.sectionDescriptionInHeader}>
						<h2 className={style.sectionTitle}>Mina transporter</h2>
						<span className={style.sectionDescriptionText}>
							Konfigurera måtten så att det stämmer överens med era transporter
						</span>
					</div>
					<div className={style.headerActionArea}>
						<button
							className={style.headerActionButton}
							onClick={() => handleDialogs("transportTemplate_Modal", true)}>
							<span className={style.addTemplateButton}>Lägg till ny transport</span>
							<FontAwesomeIcon icon={faPlus} />
						</button>
					</div>
				</div>
				<div className={style.sectionDescriptionBelowHeader}>
					<h2 className={style.sectionTitle}>Mina transporter</h2>
					<span className={style.sectionDescriptionText}>
						Konfigurera måtten så att det stämmer överens med era transporter
					</span>
				</div>
				{!isUndefined(p_session.transportDetails) && p_session.transportDetails.length > 0 ? (
					<div className={style.displayArea}>
						{p_session.transportDetails.map((transportation, index) => (
							<div key={index} className={style.productDefinitionCard}>
								<div className={style.transportImageBackground}>
									<TransportDescription type={transportation.transportationDetails.type} data={{}} />
								</div>
								<hr />
								<div>
									<h4 className={style.slimTitle}>Transport namn</h4>
									<input
										className={style.inputTextBox}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateTransportationDefinition(
													p_session,
													transportation.id,
													e.target.value,
													"name",
												),
											)
										}
										value={transportation.name}
									/>
								</div>
								<div className={style.productDimensionArea}>
									{renderTransportationDetail(p_session, transportation, "H,H", "height")}
									{renderTransportationDetail(p_session, transportation, "L,L", "length")}
									{renderTransportationDetail(p_session, transportation, "W,W", "width")}
									{renderTransportationDetail(p_session, transportation, "C,C", "containerLength")}
									{renderTransportationDetail(p_session, transportation, "T,T", "tailGateLength")}
									{renderTransportationDetail(p_session, transportation, "M,M", "maxReach")}
								</div>
								<div>
									<div className={style.footerContent}>
										<div
											onClick={() =>
												setOnboardingSessionFunction(
													removeTransportationType(p_session, transportation.id),
												)
											}>
											<TrashIcon size={32} iconClassName={style.thrashIcon} />
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<div className={style.sectionDescriptionText}>
						Inga transporter har blivit valda, välj en transport från biblioteket för att börja konfigurera dem
						för er.
					</div>
				)}
			</div>
		)
	}

	function renderTransportationDetail(
		p_session: OnboardingSession,
		p_transport: TransportationOnboardingDefinition,
		label: string,
		variableName: string,
	) {
		const value = getTransportaionDefinitionDetail(p_transport, variableName)
		if (!isUndefined(value)) {
			return (
				<div className={style.dimensionInput}>
					<h4 className={style.slimTitle}>{label}</h4>
					<div>
						<input
							className={style.dimensionInputTextBox}
							step=".1"
							type="number"
							onChange={(e) =>
								setOnboardingSessionFunction(
									updateTransportationDefinition(p_session, p_transport.id, e.target.value, variableName),
								)
							}
							value={value}
						/>
						<span className={style.dimensionInputTextBoxUnit}>{"m"}</span>
					</div>
				</div>
			)
		}
		return null
	}

	// Timeslot
	function renderTimeSlotWizardStep(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<div className={style.tabFrame}>
				<div>
					<h1 className={style.tabTitle}>Skapa era bokningsbara tidsluckor</h1>
					<span className={style.tabDescriptionText}>
						Välj ett exempel, redigera namn, skriv in en beskrivning och tiden det gäller
					</span>
				</div>
				{availableTimeSlotUI(p_onboardingSession, p_templateData)}
				{selectedTimeSlotsUI(p_onboardingSession)}
			</div>
		)
	}

	function availableTimeSlotUI(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<dialog id="timeSlotTemplate_Modal" className={style.templateModal}>
				<div>
					<div className={style.clientSectionHeader}>
						<div className={style.sectionIcon}>
							<ExampleTimeSlots size={64} iconClassName={style.containerIcon} />
						</div>
						<div className={style.sectionDescriptionInHeader}>
							<h2 className={style.sectionTitle}>Exempeltidsluckor</h2>
							<span className={style.sectionDescriptionText}>Välj ett exempel som önskas</span>
						</div>
						<div className={style.headerActionArea}>
							<button className={style.modalXButton} onClick={() => closeDialogs()}>
								<FontAwesomeIcon icon={faX} />
							</button>
						</div>
					</div>
					<div className={style.sectionDescriptionBelowHeader}>
						<h2 className={style.sectionTitle}>Exempeltidsluckor</h2>
						<span className={style.sectionDescriptionText}>Välj ett exempel som önskas</span>
					</div>
				</div>
				<div className={style.displayArea}>
					{p_templateData.timeSlotTemplates.map((timeSlot, index) => (
						<div
							key={index}
							tabIndex={index}
							className={style.productDefinitionListItem}
							onClick={() => setOnboardingSessionFunction(addTimeSlot(p_onboardingSession, timeSlot))}>
							<div className={style.productImageSmallTitleSection}>
								<div>
									<h4 className={style.productImageSmallTitle}>{timeSlot.name}</h4>
								</div>
								{timeSlot.specificTime ? (
									<div>
										<span className={style.timeSlotItemText}>Begärd tid</span>
									</div>
								) : (
									<div>
										<span className={style.timeSlotItemText}>
											Intervall: {timeSlot.startTime}-{timeSlot.endTime}
										</span>
									</div>
								)}
								<div className={style.plusButtonDiv}>
									<div className={style.plusButton}>
										<FontAwesomeIcon
											style={{
												marginTop: "0.5rem",
												marginLeft: "0.5rem",
											}}
											icon={faPlus}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className={style.modalFooter}>
					<button className={style.modalCloseButton} onClick={() => closeDialogs()}>
						Klar
					</button>
				</div>
			</dialog>
		)
	}

	function selectedTimeSlotsUI(p_onboardingSession: OnboardingSession) {
		return (
			<div className={style.tabSection}>
				<div className={style.stickySection}>
					<div className={style.sectionIcon}>
						<SelectedTimeSlots size={64} iconClassName={style.containerIcon} />
					</div>
					<div className={style.sectionDescriptionInHeader}>
						<h2 className={style.sectionTitle}>Mina tidsluckor</h2>
						<span className={style.sectionDescriptionText}>
							Redigera tidsluckorna så de passar er verksamhet
						</span>
					</div>
					<div className={style.headerActionArea}>
						<button
							className={style.headerActionButton}
							onClick={() => handleDialogs("timeSlotTemplate_Modal", true)}>
							<span className={style.addTemplateButton}>Lägg till ny tidslucka</span>
							<FontAwesomeIcon icon={faPlus} />
						</button>
					</div>
				</div>
				<div className={style.sectionDescriptionBelowHeader}>
					<h2 className={style.sectionTitle}>Mina tidsluckor</h2>
					<span className={style.sectionDescriptionText}>Redigera tidsluckorna så de passar er verksamhet</span>
				</div>
				{p_onboardingSession.timeSlotDetails.length > 0 ? (
					<div className={style.displayArea}>
						{p_onboardingSession.timeSlotDetails.map((timeSlot, index) => (
							<div key={index} className={style.productDefinitionCard}>
								<div>
									<h4 className={style.slimTitle}>Namn</h4>
									<input
										className={style.inputTextBox}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateTimeSlot(p_onboardingSession, timeSlot.id, e.target.value, "name"),
											)
										}
										value={getTimeSlotDetail(timeSlot, "name") as string}
									/>
								</div>
								<div>
									<h4 className={style.slimTitle}>Beskrivning</h4>
									<textarea
										className={style.inputTextAreaSlim}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateTimeSlot(
													p_onboardingSession,
													timeSlot.id,
													e.target.value,
													"description",
												),
											)
										}
										value={getTimeSlotDetail(timeSlot, "description") as string}
									/>
								</div>
								<div className={style.timeSlotItemText}>
									<h4 className={style.slimTitle}>Specifik tid</h4>
									<span>
										<ToggleSwitchSlim
											checked={timeSlot.specificTime}
											onChange={() =>
												setOnboardingSessionFunction(
													updateTimeSlot(
														p_onboardingSession,
														timeSlot.id,
														!timeSlot.specificTime,
														"specificTime",
													),
												)
											}
										/>
									</span>
								</div>
								<div>
									<div className={style.intervallRow}>
										<h4 className={style.slimTitle}>Intervall:</h4>
										<span className={style.intervallOffset}>
											<NativeModuleTimePicker
												selected={true}
												onTimeSelected={(time) =>
													setOnboardingSessionFunction(
														updateTimeSlot(p_onboardingSession, timeSlot.id, time, "startTime"),
													)
												}
												defaultValue={getTimeSlotDetail(timeSlot, "startTime") as string}
												className={
													timeSlot.specificTime ? style.timeOfDayDisabled : style.timeOfDay
												}
												disabled={timeSlot.specificTime}
											/>
										</span>
										<span className={style.intervallOffset}>
											<FontAwesomeIcon
												style={{ color: "white", marginTop: "0.25rem" }}
												icon={faMinus}
											/>
										</span>
										<span className={style.intervallOffset}>
											<NativeModuleTimePicker
												selected={true}
												onTimeSelected={(time) =>
													setOnboardingSessionFunction(
														updateTimeSlot(p_onboardingSession, timeSlot.id, time, "endTime"),
													)
												}
												defaultValue={getTimeSlotDetail(timeSlot, "endTime") as string}
												className={
													timeSlot.specificTime ? style.timeOfDayDisabled : style.timeOfDay
												}
												disabled={timeSlot.specificTime}
											/>
										</span>
									</div>
								</div>
								<div>
									<div className={style.footerContent}>
										<div
											onClick={() =>
												setOnboardingSessionFunction(
													removeTimeSlot(p_onboardingSession, timeSlot.id),
												)
											}>
											<TrashIcon size={32} iconClassName={style.thrashIcon} />
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<span className={style.sectionDescriptionText}>
						Inga tidsluckor har blivit valda, välj en tidslucka från exemplen för att börja konfigurera era
						tidsluckor.
					</span>
				)}
			</div>
		)
	}

	// Services
	function renderServiceWizardStep(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<div className={style.tabFrame}>
				<div>
					<h1 className={style.tabTitle}>Välj en tjänst från våra exempel</h1>
					<span className={style.tabDescriptionText}>
						Namnge tjänsten och välj tider där den är aktuell att genomföras
					</span>
				</div>
				{availableServicesUI(p_onboardingSession, p_templateData)}
				{selectedServciesUI(p_onboardingSession)}
			</div>
		)
	}

	function availableServicesUI(p_onboardingSession: OnboardingSession, p_templateData: TemplateModelHolder) {
		return (
			<div className={style.tabSection}>
				<div>
					<h1 className={style.sectionTitle}>Exempel tjänster</h1>
				</div>
				<div className={style.displayArea}>
					{p_templateData.serviceTemplates.map((service, index) => (
						<div key={index}>
							<div
								className={style.productDefinitionListItem}
								onClick={() => setOnboardingSessionFunction(addService(p_onboardingSession, service))}>
								<div className={style.productImageSmallTitleSection}>
									<div>
										<h4 className={style.productImageSmallTitle}>{service.name}</h4>
									</div>
									<div>
										<span className={style.timeSlotItemText}>Tjänst typ: {service.quantityType}</span>
									</div>

									<div className={style.plusButtonDiv}>
										<div className={style.plusButton}>
											<FontAwesomeIcon
												style={{
													marginTop: "0.5rem",
													marginLeft: "0.5rem",
												}}
												icon={faPlus}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	}

	function selectedServciesUI(p_onboardingSession: OnboardingSession) {
		return (
			<div className={style.tabSection}>
				<h1 className={style.sectionTitle}>Valda tjänster</h1>
				{p_onboardingSession.serviceDetails.length > 0 ? (
					<div className={style.displayArea}>
						{p_onboardingSession.serviceDetails.map((service, index) => (
							<div key={index} className={style.productDefinitionCard}>
								<div>
									<h4 className={style.slimTitle}>Namn</h4>
									<input
										className={style.inputTextBox}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateService(p_onboardingSession, service.id, e.target.value, "name"),
											)
										}
										value={getServiceDetail(service, "name") as string}
									/>
								</div>
								<div>
									<h4 className={style.slimTitle}>Beskrivning</h4>
									<textarea
										className={style.inputTextAreaSlim}
										onChange={(e) =>
											setOnboardingSessionFunction(
												updateService(
													p_onboardingSession,
													service.id,
													e.target.value,
													"description",
												),
											)
										}
										value={getServiceDetail(service, "description") as string}
									/>
								</div>
								{p_onboardingSession.timeSlotDetails.length > 0 ? (
									<div>
										<h4 className={style.slimTitle}>Bokningsbara tidsluckor</h4>
										{p_onboardingSession.timeSlotDetails.map((timeSlot) => (
											<div className={style.serviceTimeSlotGrid}>
												<span className={style.slimTitle}>{timeSlot.name}</span>
												<ToggleSwitchSlim
													checked={includes(service.timeSlotIds, timeSlot.id)}
													onChange={() =>
														setOnboardingSessionFunction(
															updateService(
																p_onboardingSession,
																service.id,
																timeSlot.id,
																"timeSlotIds",
															),
														)
													}
												/>
											</div>
										))}
									</div>
								) : null}
								<div>
									<div className={style.footerContent}>
										<div
											onClick={() =>
												setOnboardingSessionFunction(removeService(p_onboardingSession, service.id))
											}>
											<TrashIcon size={32} iconClassName={style.thrashIcon} />
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<span className={style.sectionDescriptionText}>
						Inga tjänster har blivit valda, välj någon tjänst från exemplen för att börja konfigurera era
						tjänster.
					</span>
				)}
			</div>
		)
	}

	// Comment section
	function renderCommentSection(p_onboardingSession: OnboardingSession) {
		return (
			<div className={style.commentSectionFrame}>
				<div className={style.section}>
					<div className={style.clientSectionHeader}>
						<div className={style.commentSectionIcon}>
							<CommentIcon size={64} iconClassName={style.containerIcon} />
						</div>
						<div className={style.sectionDescriptionInHeader}>
							<h1 className={cls(style.sectionTitle, style.centerTitle)}>Lämna ett meddelande</h1>
						</div>
					</div>
					<div className={style.commentSectionArea}>
						<div className={style.commentSubSection}>
							<h2 className={style.sectionTitle}>Skriv ett meddelande</h2>
							<textarea
								className={style.commentEditor}
								placeholder="Om du har några frågor eller funderingar kan du skriva dem här"
								onChange={(e) => {
									setOnboardingSessionFunction(updateActiveComment(p_onboardingSession, e.target.value))
								}}
								value={p_onboardingSession.activeClientComment}></textarea>
							<button
								onClick={() => setOnboardingSessionFunction(saveComment(p_onboardingSession))}
								className={style.saveCommentButton}>
								<span className={style.saveCommentButtonText}>Skicka </span>
							</button>
						</div>
						{!isUndefined(p_onboardingSession.commentHistory) &&
						p_onboardingSession.commentHistory.length > 0 ? (
							<div className={style.commentSubSection}>
								<h2 className={style.sectionTitle}>Meddelandehistorik</h2>
								<div className={style.commentHistory}>
									<textarea readOnly={true}>
										{formatCommentsIntoString(p_onboardingSession.commentHistory)}
									</textarea>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		)
	}

	// Error tab
	function renderErrorWizardStep() {
		return (
			<div>
				<h1 className={style.tabTitle}>
					Denna sessionen har inte konfigurerats, antingen testa igen om en stund eller kontakta Skrappy!
				</h1>
			</div>
		)
	}

	// Wizard functions
	function renderTabButtonUI(tabNames: string[]) {
		function getTabStruct(tabId: string): TabDetails {
			const foundTabStruct = find(onboardingTabStructures, (struct: TabDetails) => struct.onboardingState === tabId)
			if (!isUndefined(foundTabStruct)) {
				return foundTabStruct
			} else {
				return {
					onboardingState: OnboardingState.None,
					readableName: "Can't be identified",
					description: "THIS SHOULD NOT BE HERE",
				}
			}
		}
		const tabDetails: TabDetails[] = map(tabNames, (tabId) => getTabStruct(tabId))

		return (
			<>
				<div className={style.tabGroups}>
					{tabDetails.map((tabDetail, index) => (
						<div
							key={index}
							className={
								onboardingState === tabDetail.onboardingState ? style.tabButtonSelected : style.tabButton
							}
							onClick={() => setOnboardingStateFunction(tabDetail.onboardingState)}>
							<div className={style.tabName}>{tabDetail.readableName}</div>
							{getTabIconElement(tabDetail.onboardingState, tabDetails.length + 1, style.containerIcon)}
						</div>
					))}
					{screenSize.width < 1024 ? (
						<>
							{showTabDropdown ? (
								<div
									className={style.tabButton}
									onClick={() => {
										closeDialogs()
										setShowTabDropdown(!showTabDropdown)
									}}>
									<TabIconChevronUp
										size={calculateHeaderIconSize(tabDetails.length + 1) * 0.5}
										className={cls(style.containerIcon, style.chevronUp)}
									/>
								</div>
							) : (
								<div
									className={style.tabButton}
									onClick={() => {
										handleDialogs("tab_dialog", false)
										setShowTabDropdown(!showTabDropdown)
									}}>
									<TabIconChevronUp
										size={calculateHeaderIconSize(tabDetails.length + 1) * 0.5}
										className={cls(style.containerIcon, style.chevronDown)}
									/>
								</div>
							)}
						</>
					) : null}
				</div>
				<dialog id="tab_dialog" className={style.tabDropdown}>
					{tabDetails.map((tab, index) => (
						<div
							key={index}
							className={style.tabDropdownButton}
							onClick={() => {
								setShowTabDropdown(!showTabDropdown)
								setOnboardingStateFunction(tab.onboardingState)
							}}>
							<span>
								{getTabIconElement(tab.onboardingState, tabDetails.length + 1, style.tabDropdownButtonIcon)}
							</span>
							<div>
								<h4 className={style.tabDropdownButtonName}>{tab.readableName}</h4>
								<div className={style.tabDropdownButtonDescription}>{tab.description}</div>
							</div>
						</div>
					))}
				</dialog>
			</>
		)
	}

	function getTabIconElement(tabName: OnboardingState, amountOfTabs: number, styles: string) {
		const iconSize = calculateHeaderIconSize(amountOfTabs)
		switch (tabName) {
			case OnboardingState.CustomerDetails:
				return <TabIconCustomerDetails size={iconSize} className={styles} />
			case OnboardingState.Products:
				return <TabIconResources size={iconSize} className={styles} />
			case OnboardingState.WasteType:
				return <TabIconWasteType size={iconSize} className={styles} />
			case OnboardingState.TimeSlot:
				return <TabIconTimeSlot size={iconSize} className={styles} />
			case OnboardingState.Transportation:
				return <TabIconTransportation size={iconSize} className={styles} />
			case OnboardingState.Service:
				return <TabIconTransportation size={iconSize} className={styles} />
			default:
				return <span>MISSING!!!!!</span>
		}
	}

	function calculateHeaderIconSize(amountOfTabs: number) {
		const windowWidth = screenSize.width
		let iconSize = 35
		const limit = (iconSize + 16) * (amountOfTabs + 1)
		if (windowWidth < limit) {
			iconSize = windowWidth / (amountOfTabs + 1) - 16
		}
		return iconSize
	}

	function goToNextTab(p_onboardingSession: OnboardingSession) {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
		const index = p_onboardingSession.availableTabs.findIndex((tabId) => tabId === onboardingState)

		const obj = p_onboardingSession.availableTabs[index + 1]

		if (obj) {
			setOnboardingStateFunction(obj)
		}
	}

	function goToPreviousTab(p_onboardingSession: OnboardingSession) {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
		const index = p_onboardingSession.availableTabs.findIndex((tabId) => tabId === onboardingState)

		const obj = p_onboardingSession.availableTabs[index - 1]

		if (obj) {
			setOnboardingStateFunction(obj)
		}
	}

	function saveComment(p_onboardingSession: OnboardingSession) {
		const onboardingClone = cloneDeep(p_onboardingSession)
		onboardingClone.status = SessionStatus.LastUpdatedByClient
		if (isUndefined(onboardingClone.commentHistory)) {
			onboardingClone.commentHistory = []
		}
		if (!isUndefined(onboardingClone.activeClientComment) && onboardingClone.activeClientComment !== "") {
			let name = "\n\n"
			if (onboardingClone.customerDetails?.contact?.name) {
				name = "**" + onboardingClone.customerDetails?.contact?.name.trim() + "**:\n\n"
			}
			onboardingClone.commentHistory.push({
				text: name + onboardingClone.activeClientComment,
				timeStamp: DateTime.now().toString(),
			})
			onboardingClone.activeClientComment = ""
		}
		return onboardingClone
	}

	function submitForm(p_onboardingSession: OnboardingSession) {
		const onboardingClone = cloneDeep(p_onboardingSession)
		onboardingClone.status = SessionStatus.LastUpdatedByClient
		if (isUndefined(onboardingClone.commentHistory)) {
			onboardingClone.commentHistory = []
		}
		if (!isUndefined(onboardingClone.activeClientComment) && onboardingClone.activeClientComment !== "") {
			let name = "\n\n"
			if (onboardingClone.customerDetails?.contact?.name) {
				name = "**" + onboardingClone.customerDetails?.contact?.name.trim() + "**:\n\n"
			}
			onboardingClone.commentHistory.push({
				text: name + onboardingClone.activeClientComment,
				timeStamp: DateTime.now().toString(),
			})
			onboardingClone.activeClientComment = ""
		}
		const putObject: OnboardingSessionApiObject = {
			session: onboardingClone,
		}
		API.put<OnboardingSessionApiObject>(`/onboarding/session-v1/${p_onboardingSession.identifier}`, putObject)
			.then((apiObject) => {
				setOnboardingSession(apiObject.session)
				navigate("/onboarding/completed")
			})
			.catch((error: ServerError<unknown>) => {
				if (error.status === 404) {
					setLoadingError(OnboardingError.SessionNotFound)
				}
			})
	}
	if (loadingError !== OnboardingError.None || isUndefined(onboardingSession) || isUndefined(templateData)) {
		if (loadingError === OnboardingError.SessionNotFound || loadingError === OnboardingError.IdentifierNotFound) {
			return <ClientNotFound clientName={onboardingId} />
		} else {
			return (
				<div className={style.pageBackground}>
					<div className={style.lottieWrapper}>
						<Lottie
							animationData={fixLottieColor(skrappyLoading)}
							className={style.lottieAnimation}
							autoPlay
							loop
						/>
						<div className={style.pageTitleLogoText}>Laddar...</div>
					</div>
				</div>
			)
		}
	} else if (onboardingSession.status === SessionStatus.Completed) {
		return (
			<div className={style.pageBackground}>
				<OnboardingHeader />
				<div className={style.requestOnboardingBody}>
					<div className={style.lottieWrapperNoMargin}>
						<Lottie animationData={fixLottieColor(thinking)} className={style.lottieAnimation} autoPlay loop />
					</div>
					<h1 className={style.completOnboardingTitle}>Sessionen har blivit stängd</h1>
					<div className={style.completOnboardingSubTitle}>
						Har du fler frågor eller funderingar så kontakta Skrappy
					</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		return (
			<div className={style.pageBackground}>
				<OnboardingHeader />
				<div className={style.pageBody}>
					<div className={style.pageSideFiller} />
					<div className={style.pageBodyContent}>
						<div className={style.pageContentHeader}>
							<h1 className={style.pageTitle}>Fyll i dokumentet</h1>
						</div>
						{renderTabButtonUI(onboardingSession.availableTabs)}
						{onboardingState === OnboardingState.CustomerDetails
							? renderCustomerWizardStep(onboardingSession)
							: null}
						{onboardingState === OnboardingState.Products
							? renderProductWizardStep(onboardingSession, templateData)
							: null}
						{onboardingState === OnboardingState.WasteType
							? renderWasteTypeWizardStep(onboardingSession, templateData)
							: null}
						{onboardingState === OnboardingState.TimeSlot
							? renderTimeSlotWizardStep(onboardingSession, templateData)
							: null}
						{onboardingState === OnboardingState.Service
							? renderServiceWizardStep(onboardingSession, templateData)
							: null}
						{onboardingState === OnboardingState.Transportation
							? renderTransportationWizardStep(onboardingSession, templateData)
							: null}
						{onboardingState === OnboardingState.None ? renderErrorWizardStep() : null}
						{renderCommentSection(onboardingSession)}
						{onboardingSession.availableTabs.length > 0 ? (
							<div>
								<div className={style.pageFooterButtonGroup}>
									{onboardingSession.availableTabs[0] === onboardingState ? null : (
										<button
											className={style.leftPageFooterButton}
											onClick={() => goToPreviousTab(onboardingSession)}>
											<FontAwesomeIcon icon={faArrowLeft} />
										</button>
									)}

									<button
										className={getSendButtonStyle(onboardingSession, onboardingState)}
										onClick={() => {
											submitForm(onboardingSession)
										}}>
										Skicka in
									</button>

									{onboardingSession.availableTabs[onboardingSession.availableTabs.length - 1] ===
									onboardingState ? null : (
										<button
											className={style.rightPageFooterButton}
											onClick={() => goToNextTab(onboardingSession)}>
											<FontAwesomeIcon icon={faArrowRight} />
										</button>
									)}
								</div>
							</div>
						) : (
							<div className={style.pageFooterButtonGroup}>
								<button className={style.saveMiddleButton}>Skicka in</button>
							</div>
						)}
					</div>
					<div className={style.pageSideFiller} />
					{isSavingData === SavingState.PendingSave ? (
						<div className={style.savingIcon}>
							<div className={style.lottieWrapper}>
								<Lottie
									animationData={fixLottieColor(underConstruction)}
									className={style.lottieSmallAnimation}
									autoPlay
									loop
								/>
								<div className={style.pageTitleLogoText}>Skickar...</div>
							</div>
						</div>
					) : (
						<div className={style.savedIcon}>
							<div className={style.lottieWrapper}>
								<Lottie
									animationData={fixLottieColor(comfortable)}
									className={style.lottieSmallAnimation}
									autoPlay
									loop
								/>
								<div className={style.pageTitleLogoText}>Sparat!</div>
							</div>
						</div>
					)}
				</div>
				<Footer />
			</div>
		)
	}
}

function getSendButtonStyle(p_onboardingSession: OnboardingSession, p_currentState: OnboardingState): string {
	if (p_onboardingSession.availableTabs[0] === p_currentState) {
		return style.saveLeftButton
	} else if (p_onboardingSession.availableTabs[p_onboardingSession.availableTabs.length - 1] === p_currentState) {
		return style.saveRightButton
	} else {
		return style.saveMiddleButton
	}
}

// Customer details modifiers
function updateCustomerDetails(
	p_onboardingSession: OnboardingSession,
	newValue: string | number,
	valueName: string,
): OnboardingSession {
	const contactDetails: CustomerDetails = isUndefined(p_onboardingSession.customerDetails)
		? {}
		: cloneDeep(p_onboardingSession.customerDetails)

	switch (valueName) {
		case "organizationName":
			contactDetails.organizationName = newValue as string
			break
		case "organizationNumber":
			contactDetails.organizationNumber = newValue as string
			break
		case "address":
			contactDetails.address = newValue as string
			break
		case "postalNumber":
			contactDetails.postalNumber = newValue as string
			break
		case "city":
			contactDetails.city = newValue as string
			break
		case "orderEmail":
			contactDetails.orderEmail = newValue as string
			break

		case "contact.clientEmail":
			if (isUndefined(contactDetails.contact)) {
				contactDetails.contact = {}
			}
			contactDetails.contact.clientEmail = newValue as string
			break
		case "contact.emailForUpdates":
			if (isUndefined(contactDetails.contact)) {
				contactDetails.contact = {}
			}
			contactDetails.contact.emailForUpdates = newValue as string
			break

		case "contact.name":
			if (isUndefined(contactDetails.contact)) {
				contactDetails.contact = {}
			}
			contactDetails.contact.name = newValue as string
			break

		case "clientConsumerContact.phoneNumber":
			if (isUndefined(contactDetails.clientConsumerContact)) {
				contactDetails.clientConsumerContact = {}
			}
			contactDetails.clientConsumerContact.phoneNumber = newValue as string
			break
		case "clientConsumerContact.email":
			if (isUndefined(contactDetails.clientConsumerContact)) {
				contactDetails.clientConsumerContact = {}
			}
			contactDetails.clientConsumerContact.email = newValue as string
			break
		case "clientConsumerContact.homepage":
			if (isUndefined(contactDetails.clientConsumerContact)) {
				contactDetails.clientConsumerContact = {}
			}
			contactDetails.clientConsumerContact.homepage = newValue as string
			break
	}
	const onboardingClone = cloneDeep(p_onboardingSession)
	onboardingClone.customerDetails = contactDetails
	return onboardingClone
}

function getCustomerDetailsValue(p_onboardingSession: OnboardingSession, valueName: string): string {
	if (!isUndefined(p_onboardingSession.customerDetails)) {
		switch (valueName) {
			case "organizationName":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.organizationName)
				) {
					return p_onboardingSession.customerDetails.organizationName
				}
				break
			case "organizationNumber":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.organizationNumber)
				) {
					return p_onboardingSession.customerDetails.organizationNumber
				}
				break
			case "address":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.address)
				) {
					return p_onboardingSession.customerDetails.address
				}
				break
			case "postalNumber":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.postalNumber)
				) {
					return p_onboardingSession.customerDetails.postalNumber
				}
				break
			case "city":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.city)
				) {
					return p_onboardingSession.customerDetails.city
				}
				break
			case "orderEmail":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.orderEmail)
				) {
					return p_onboardingSession.customerDetails.orderEmail
				}
				break

			case "contact.clientEmail":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.contact) &&
					!isUndefined(p_onboardingSession.customerDetails.contact.clientEmail)
				) {
					return p_onboardingSession.customerDetails.contact.clientEmail
				}
				break
			case "contact.emailForUpdates":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.contact) &&
					!isUndefined(p_onboardingSession.customerDetails.contact.emailForUpdates)
				) {
					return p_onboardingSession.customerDetails.contact.emailForUpdates
				}
				break
			case "contact.name":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.contact) &&
					!isUndefined(p_onboardingSession.customerDetails.contact.name)
				) {
					return p_onboardingSession.customerDetails.contact.name
				}
				break

			case "clientConsumerContact.phoneNumber":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.clientConsumerContact) &&
					!isUndefined(p_onboardingSession.customerDetails.clientConsumerContact.phoneNumber)
				) {
					return p_onboardingSession.customerDetails.clientConsumerContact.phoneNumber
				}
				break
			case "clientConsumerContact.email":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.clientConsumerContact) &&
					!isUndefined(p_onboardingSession.customerDetails.clientConsumerContact.email)
				) {
					return p_onboardingSession.customerDetails.clientConsumerContact.email
				}
				break
			case "clientConsumerContact.homepage":
				if (
					!isUndefined(p_onboardingSession.customerDetails) &&
					!isUndefined(p_onboardingSession.customerDetails.clientConsumerContact) &&
					!isUndefined(p_onboardingSession.customerDetails.clientConsumerContact.homepage)
				) {
					return p_onboardingSession.customerDetails.clientConsumerContact.homepage
				}
				break
		}
	}
	return ""
}

// Product definition modifiers
function addProduct(p_onboardingSession: OnboardingSession, template: ProductDefinitionTemplate): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	const templateClone = parseProductTemplateToOnboardingDefinition(template)
	if (isUndefined(onboardingSessionClone.productDetails)) {
		onboardingSessionClone.productDetails = []
	}
	onboardingSessionClone.productDetails.push(templateClone)
	onboardingSessionClone.productDetails = sortBy(onboardingSessionClone.productDetails, (product) => product.typeImage)
	return onboardingSessionClone
}

function updateProductDefinition(
	p_onboardingSession: OnboardingSession,
	itemId: string,
	newValue: string | number,
	valueName: string,
): OnboardingSession {
	const cloneSession = cloneDeep(p_onboardingSession)
	forEach(cloneSession.productDetails, (product) => {
		if (product.id === itemId) {
			switch (valueName) {
				case "name":
					product.name = newValue as string
					break
				case "description":
					product.description = newValue as string
					break
				case "height.value":
					if (!isUndefined(product.dimensions.height)) {
						product.dimensions.height.value = newValue as number
					}
					break
				case "height.unit":
					if (!isUndefined(product.dimensions.height)) {
						product.dimensions.height.unit = newValue as string
					}
					break
				case "length.value":
					if (!isUndefined(product.dimensions.length)) {
						product.dimensions.length.value = newValue as number
					}
					break
				case "length.unit":
					if (!isUndefined(product.dimensions.length)) {
						product.dimensions.length.unit = newValue as string
					}
					break
				case "maxWeight.value":
					if (!isUndefined(product.dimensions.maxWeight)) {
						product.dimensions.maxWeight.value = newValue as number
					}
					break
				case "maxWeight.unit":
					if (!isUndefined(product.dimensions.maxWeight)) {
						product.dimensions.maxWeight.unit = newValue as string
					}
					break
				case "volume.value":
					if (!isUndefined(product.dimensions.volume)) {
						product.dimensions.volume.value = newValue as number
					}
					break
				case "volume.unit":
					if (!isUndefined(product.dimensions.volume)) {
						product.dimensions.volume.unit = newValue as string
					}
					break
				case "width.value":
					if (!isUndefined(product.dimensions.width)) {
						product.dimensions.width.value = newValue as number
					}
					break
				case "width.unit":
					if (!isUndefined(product.dimensions.width)) {
						product.dimensions.width.unit = newValue as string
					}
					break
			}
		}
	})
	return cloneSession
}

function removeProduct(p_onboardingSession: OnboardingSession, itemId: string): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	remove(onboardingSessionClone.productDetails, (product) => product.id === itemId)
	return onboardingSessionClone
}

function getProductDefinitionDetail(p_productDefinition: ProductOnboardingDefinition, valueName: string) {
	switch (valueName) {
		case "name":
			return p_productDefinition.name
		case "description":
			return p_productDefinition.description
		case "height.value":
			return includes(Object.keys(p_productDefinition.dimensions), "height")
				? p_productDefinition.dimensions.height?.value
				: undefined
		case "height.unit":
			return includes(Object.keys(p_productDefinition.dimensions), "height")
				? p_productDefinition.dimensions.height?.unit
				: undefined
		case "length.value":
			return includes(Object.keys(p_productDefinition.dimensions), "length")
				? p_productDefinition.dimensions.length?.value
				: undefined
		case "length.unit":
			return includes(Object.keys(p_productDefinition.dimensions), "length")
				? p_productDefinition.dimensions.length?.unit
				: undefined
		case "maxWeight.value":
			return includes(Object.keys(p_productDefinition.dimensions), "maxWeight")
				? p_productDefinition.dimensions.maxWeight?.value
				: undefined
		case "maxWeight.unit":
			return includes(Object.keys(p_productDefinition.dimensions), "maxWeight")
				? p_productDefinition.dimensions.maxWeight?.unit
				: undefined
		case "volume.value":
			return includes(Object.keys(p_productDefinition.dimensions), "volume")
				? p_productDefinition.dimensions.volume?.value
				: undefined
		case "volume.unit":
			return includes(Object.keys(p_productDefinition.dimensions), "volume")
				? p_productDefinition.dimensions.volume?.unit
				: undefined
		case "width.value":
			return includes(Object.keys(p_productDefinition.dimensions), "width")
				? p_productDefinition.dimensions.width?.value
				: undefined
		case "width.unit":
			return includes(Object.keys(p_productDefinition.dimensions), "width")
				? p_productDefinition.dimensions.width?.unit
				: undefined
	}

	return undefined //TODO return null instead
}

// Waste type modifiers
function addWasteType(p_onboardingSession: OnboardingSession, template: WasteTypeDefinitionTemplate): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	const templateClone = parseWasteTypeTemplateToOnboardingDefinition(template)
	if (isUndefined(onboardingSessionClone.wasteTypeDetails)) {
		onboardingSessionClone.wasteTypeDetails = []
	}
	onboardingSessionClone.wasteTypeDetails.push(templateClone)
	onboardingSessionClone.wasteTypeDetails = sortBy(
		onboardingSessionClone.wasteTypeDetails,
		(wasteType) => wasteType.typeImage,
	)
	return onboardingSessionClone
}

function updateWasteTypeDefinition(
	p_onboardingSession: OnboardingSession,
	itemId: string,
	newValue: string,
	valueName: string,
): OnboardingSession {
	const cloneSession = cloneDeep(p_onboardingSession)
	forEach(cloneSession.wasteTypeDetails, (wasteType) => {
		if (wasteType.id === itemId) {
			switch (valueName) {
				case "name":
					wasteType.name = newValue as string
					break
				case "info":
					wasteType.info = newValue as string
					break
				case "categories":
					if (isUndefined(wasteType.categories)) {
						wasteType.categories = []
						wasteType.categories.push(newValue)
					} else {
						if (includes(wasteType.categories, newValue)) {
							remove(wasteType.categories, (value) => value === newValue)
						} else {
							wasteType.categories.push(newValue)
						}
					}
					break
			}
		}
	})
	return cloneSession
}

function removeWasteType(p_onboardingSession: OnboardingSession, itemId: string): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	remove(onboardingSessionClone.wasteTypeDetails, (wasteType) => wasteType.id === itemId)
	return onboardingSessionClone
}

function getWasteTypeDefinitionDetail(p_wasteType: WasteTypeDefinitionTemplate, valueName: string) {
	switch (valueName) {
		case "name":
			return p_wasteType.name
		case "info":
			return p_wasteType.info
	}

	return undefined //TODO return null
}

// Transportation definition modifiers
function addTransportationType(
	p_onboardingSession: OnboardingSession,
	template: TransportationDefinitionTemplate,
): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	const templateClone = parseTransportationTemplateToOnboardingDefinition(template)
	onboardingSessionClone.transportDetails.push(templateClone)
	onboardingSessionClone.transportDetails = sortBy(
		onboardingSessionClone.transportDetails,
		(transport) => transport.transportationDetails.type,
	)
	return onboardingSessionClone
}

function updateTransportationDefinition(
	p_onboardingSession: OnboardingSession,
	itemId: string,
	newValue: string | number,
	valueName: string,
): OnboardingSession {
	const cloneSession = cloneDeep(p_onboardingSession)
	if (!isUndefined(cloneSession.transportDetails)) {
		forEach(cloneSession.transportDetails, (transport) => {
			if (transport.id === itemId) {
				switch (valueName) {
					case "name":
						transport.name = newValue as string
						break
					case "height":
						transport.transportationDetails.height = newValue as number
						break
					case "length":
						transport.transportationDetails.length = newValue as number
						break
					case "width":
						transport.transportationDetails.width = newValue as number
						break
					case "containerLength":
						transport.transportationDetails.containerLength = newValue as number
						break
					case "tailGateLength":
						transport.transportationDetails.tailGateLength = newValue as number
						break
					case "maxReach":
						transport.transportationDetails.maxReach = newValue as number
						break
					case "constraints.max":
						transport.constraints.max = newValue as number
						break
				}
			}
		})
	}
	return cloneSession
}

function removeTransportationType(p_onboardingSession: OnboardingSession, itemId: string): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	const transportationObject = onboardingSessionClone.transportDetails
	if (!isUndefined(transportationObject)) {
		remove(transportationObject, (transportation) => transportation.id === itemId)
	}
	return onboardingSessionClone
}

function getTransportaionDefinitionDetail(
	p_transportDefinition: TransportationDefinitionTemplate,
	valueName: string,
): number | undefined {
	switch (valueName) {
		case "height":
			return floatToNumber(p_transportDefinition.transportationDetails.height)
		case "length":
			return floatToNumber(p_transportDefinition.transportationDetails.length)
		case "width":
			return floatToNumber(p_transportDefinition.transportationDetails.width)
		case "containerLength":
			return floatToNumber(p_transportDefinition.transportationDetails.containerLength)
		case "tailGateLength":
			return floatToNumber(p_transportDefinition.transportationDetails.tailGateLength)
		case "maxReach":
			return floatToNumber(p_transportDefinition.transportationDetails.maxReach)
	}
	return undefined //TODO return null
}

// Timeslot
function addTimeSlot(p_onboardingSession: OnboardingSession, template: TimeSlotDefinitionTemplate): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	const templateClone = parseTimeSlotTemplateToOnboardingDefinition(template)
	if (isUndefined(onboardingSessionClone.timeSlotDetails)) {
		onboardingSessionClone.timeSlotDetails = []
	}
	onboardingSessionClone.timeSlotDetails.push(templateClone)
	return onboardingSessionClone
}

function updateTimeSlot(
	p_onboardingSession: OnboardingSession,
	itemId: string,
	newValue: string | boolean,
	valueName: string,
): OnboardingSession {
	const cloneSession = cloneDeep(p_onboardingSession)
	forEach(cloneSession.timeSlotDetails, (timeSlot) => {
		if (timeSlot.id === itemId) {
			switch (valueName) {
				case "name":
					timeSlot.name = newValue as string
					break
				case "specificTime":
					timeSlot.specificTime = newValue as boolean
					break
				case "description":
					timeSlot.description = newValue as string
					break
				case "startTime":
					timeSlot.startTime = newValue as string
					break
				case "endTime":
					timeSlot.endTime = newValue as string
					break
			}
		}
	})
	return cloneSession
}

function removeTimeSlot(p_onboardingSession: OnboardingSession, itemId: string): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	remove(onboardingSessionClone.timeSlotDetails, (timeSlot) => timeSlot.id === itemId)
	forEach(onboardingSessionClone.serviceDetails, (service) => {
		if (includes(service.timeSlotIds, itemId)) {
			remove(service.timeSlotIds, (value) => value === itemId)
		}
	})
	return onboardingSessionClone
}

function getTimeSlotDetail(p_timeSlot: TimeSlotDefinitionTemplate, valueName: string) {
	switch (valueName) {
		case "name":
			return p_timeSlot.name
		case "specificTime":
			return p_timeSlot.specificTime
		case "description":
			return isUndefined(p_timeSlot.description) ? "" : p_timeSlot.description
		case "startTime":
			return isUndefined(p_timeSlot.endTime) ? "06:00" : p_timeSlot.startTime
		case "endTime":
			return isUndefined(p_timeSlot.endTime) ? "17:00" : p_timeSlot.endTime
	}

	return undefined //TODO return null
}

// Services
function addService(p_onboardingSession: OnboardingSession, template: ServiceDefinitionTemplate): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	const templateClone = parseServiceTemplateToOnboardingDefinition(template)
	if (isUndefined(onboardingSessionClone.serviceDetails)) {
		onboardingSessionClone.serviceDetails = []
	}
	onboardingSessionClone.serviceDetails.push(templateClone)
	return onboardingSessionClone
}

function updateService(
	p_onboardingSession: OnboardingSession,
	itemId: string,
	newValue: string,
	valueName: string,
): OnboardingSession {
	const cloneSession = cloneDeep(p_onboardingSession)
	forEach(cloneSession.serviceDetails, (service) => {
		if (service.id === itemId) {
			switch (valueName) {
				case "name":
					service.name = newValue
					break
				case "description":
					service.description = newValue
					break
				case "quantityType":
					service.quantityType = newValue as QuantityTypeTemplateEnum
					break
				case "timeSlotIds":
					if (isUndefined(service.timeSlotIds)) {
						service.timeSlotIds = []
						service.timeSlotIds.push(newValue)
					} else {
						if (includes(service.timeSlotIds, newValue)) {
							remove(service.timeSlotIds, (value) => value === newValue)
						} else {
							service.timeSlotIds.push(newValue)
						}
					}
					break
			}
		}
	})
	return cloneSession
}

function removeService(p_onboardingSession: OnboardingSession, itemId: string): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	remove(onboardingSessionClone.serviceDetails, (service) => service.id === itemId)
	return onboardingSessionClone
}

function getServiceDetail(service: ServiceOnboardingDefinition, valueName: string) {
	switch (valueName) {
		case "name":
			return service.name
		case "description":
			return service.description
		case "quantityType":
			return service.quantityType
		case "timeSlotIds":
			return service.timeSlotIds
	}

	return undefined //TODO return null
}

// MISC
function floatToNumber(value: number | undefined): number | undefined {
	if (!isUndefined(value)) {
		return toNumber(value.toString().replaceAll(",", "."))
	}
	return undefined
}

function updateActiveComment(p_onboardingSession: OnboardingSession, value: string): OnboardingSession {
	const onboardingSessionClone = cloneDeep(p_onboardingSession)
	onboardingSessionClone.activeClientComment = value
	return onboardingSessionClone
}

function parseWasteTypeTemplateToOnboardingDefinition(
	template: WasteTypeDefinitionTemplate,
): WasteTypeOnboardingDefinition {
	return {
		id: "wasteType_" + v4(),
		name: template.name,
		info: template.info,
		typeImage: template.typeImage,
		categories: [],
	}
}

function parseTransportationTemplateToOnboardingDefinition(
	template: TransportationDefinitionTemplate,
): TransportationOnboardingDefinition {
	return {
		id: "transportation_" + v4(),
		name: template.name,
		transportationDetails: template.transportationDetails,
		constraints: template.constraints,
	}
}

function parseTimeSlotTemplateToOnboardingDefinition(template: TimeSlotDefinitionTemplate): TimeSlotOnboardingDefinition {
	return {
		id: "timeSlot_" + v4(),
		name: template.name,
		specificTime: template.specificTime,
		description: template.description,
		startTime: template.startTime,
		endTime: template.endTime,
	}
}

function parseServiceTemplateToOnboardingDefinition(template: ServiceDefinitionTemplate): ServiceOnboardingDefinition {
	return {
		id: "service_" + v4(),
		name: template.name,
		description: "",
		quantityType: template.quantityType,
		timeSlotIds: [],
	}
}

function parseProductTemplateToOnboardingDefinition(template: ProductDefinitionTemplate): ProductOnboardingDefinition {
	return {
		id: "product_" + v4(),
		name: template.name,
		typeImage: template.typeImage,
		dimensions: template.dimensions,
		description: template.description,
	}
}

function handleDialogs(p_dialogId: string, isModal: boolean) {
	const dialogs = document.querySelectorAll("dialog")
	dialogs.forEach((item) => {
		item.close()
	})
	dialogs.forEach((item) => {
		if (item.id === p_dialogId) {
			document.body.classList.add("disableVerticalScroll")
			isModal ? item.showModal() : item.show()
		}
	})
}

function closeDialogs() {
	const dialogs = document.querySelectorAll("dialog")
	dialogs.forEach((item) => {
		item.close()
	})
	document.body.classList.remove("disableVerticalScroll")
}

function formatCommentsIntoString(comments: Comment[]): string {
	let commentsAsString = ""
	const orderedComments = orderBy(comments, (comment) => comment.timeStamp, "desc")
	forEach(orderedComments, (comment: Comment) => {
		const formattedTimeStamp = DateTime.fromISO(comment.timeStamp, {
			zone: "Europe/Stockholm",
		})
			.setLocale("sv-SE")
			.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)

		commentsAsString = commentsAsString + formattedTimeStamp + " " + comment.text + "\n___\n"
	})
	return commentsAsString
}
