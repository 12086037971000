import { Features } from "../Client/FeatureTypes"
import { ClientModes } from "../Client/GetClientResponse"
import { OrderDateTime } from "../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { OrderLicenseAgreements } from "../Orders/api/NewOrderApi"
import {
	ProductDefinition,
	ProductDimensions,
	ProductImageType,
	ServiceType,
	Transportation,
	WasteId,
	WasteTypeImages,
} from "../ProductDefinitions"

export type ClientFromAPI = {
	identifier: string
	branding: {
		colors: {
			mainBackground: string
			header: string
			headerDivider: string
			headerDividerShadow: string
			headerIcon: string
			headerNotification: string
			headerNotificationText: string
			footer: string
			footerLogo: string
			accent: string
			accentInside: string
			sectionBackground: string
			finalizeButton: string
			finalizeButtonText: string
			selectedServiceBackground: string
			productBase: string
			productSecondary: string
			productDetails: string
			text1: string
			text2: string
			text3: string
			text4: string
			icon1: string
			icon2: string
			icon3: string
		}
		logo: string // base64
		logoMime: string
		logoHeight: string
	}
	client: {
		organizationNumber: string
		clientName: string
		homepage: string
		contact: {
			name: string
			phoneNumber: string
			email: string
			orderEmail: string
		}
		licenseAgreement: string
	}
	modes: ClientModes[]
	productDefinitions: ProductDefinition[]
	features?: Features
}

export type OrderCollectionFromAPI = {
	id: string
	consumer: ConsumerFromAPI
	orders: OrderFromAPI[]
	client: ClientFromAPI
	collectionNumber: number
}

export type ConsumerFromAPI = {
	name: string
	company: boolean
	id: string
	email: string
	phone: string
}

export type OrderFromAPI = {
	id: string
	state: OrderState
	notificationStates: Partial<Record<NotificationType, NotificationState>>
	customer: ConsumerFromAPI
	licenseAgreements: OrderLicenseAgreements
	address: Address
	marking: string
	contact: Contact | undefined
	contactPersons: Contact[] | undefined
	datetime: OrderDateTime
	products: ProductFromAPI[]
	comment: string
	clientComment: string
	orderNumber: number
}

export type Contact = {
	name: string
	phone: string
}

export enum NotificationType {
	NewOrderEmailToClient = "NewOrderEmailToClient",
	OrderAcceptanceEmailToConsumer = "OrderAcceptanceEmailToConsumer",
}

export enum NotificationState {
	Sent = "Sent",
	Failed = "Failed",
}

export type ProductFromAPI = {
	name: string
	type: string
	typeImage: ProductImageType
	service?: OrderProductService
	wasteType?: OrderWasteType
	amount: number
	dimensions: ProductDimensions
	transportation: Transportation
}

export type OrderWasteType = {
	id: WasteId
	name: string
	typeImage: WasteTypeImages
}

export type OrderProductService = {
	name: string
	type: ServiceType
}

export type Address = {
	street: string
	zipcode: string
	city: string
	country: string
	coordinates?: google.maps.LatLngLiteral
}

export enum OrderState {
	Created = "Created",
	Accepted = "Accepted",
	Declined = "Declined",
	Done = "Done",
}
