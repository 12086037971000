import { FC, MutableRefObject, PropsWithChildren } from "react"
import { cls } from "../../../Shared/cls"
import style from "./FieldModuleBoxWrapper.module.css"

type Props = PropsWithChildren & {
	className?: string
	reference?: MutableRefObject<HTMLDivElement | null>
}

export const FieldModuleBoxWrapper: FC<Props> = ({ children, className, reference }) => {
	return (
		<div ref={reference} className={cls(style.wrapper, className)}>
			{children}
		</div>
	)
}
