import { ReactComponent as PoweredBySkrappy } from "Orders/Layout/PoweredBySkrappy.svg"
import style from "./Footer.module.css"

export const Footer = () => {
	return (
		<footer className={style.footer}>
			<div className={style.poweredBy}>
				<div className={style.logoWrapper}>
					<PoweredBySkrappy className={style.logo} />
				</div>
			</div>
		</footer>
	)
}
