import { isUndefined } from "lodash"
import { convertTextToInitials } from "Orders/Helpers"
import { FC, useState } from "react"
import { v4 } from "uuid"
import style from "./../HandleUserInvitation.module.css"

type PresentConsumerProps = { name: string; details: (string | undefined)[]; profilePic?: string; noBg: boolean }
export const PresentConsumer: FC<PresentConsumerProps> = ({ name, details, profilePic, noBg }) => {
	const [profilePicture, setProfilePicture] = useState(profilePic)
	return (
		<div className={noBg ? style.presentConsumerNoBg : style.presentConsumer}>
			<div className={style.profilePic}>
				{profilePicture ? (
					<img
						src={profilePicture}
						onError={() => {
							setProfilePicture(undefined)
						}}
						alt="Profilbild"
					/>
				) : (
					<div className={style.noProfilePic}>{convertTextToInitials(name)}</div>
				)}
			</div>
			<div style={{ marginTop: "auto", marginBottom: "auto", minWidth: "100px", marginLeft: "1rem" }}>
				<div style={{ fontSize: "large" }}>{name}</div>
				{details.map((text) => (
					<div key={v4()} style={{ fontSize: "small" }}>
						{text}
					</div>
				))}
			</div>
		</div>
	)
}
export function compileDisplayName(firstName: string | undefined, lastName: string | undefined): string {
	const textPart1 = isUndefined(firstName) ? "" : firstName + " "
	const textPart2 = isUndefined(lastName) ? "" : lastName
	return textPart1 + textPart2
}
