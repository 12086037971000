import { Navigate, NavLinkProps } from "react-router-dom"
import { throwIllegalState } from "../Shared/throwIllegalState"
import { useNavigator } from "./useNavigator"

type ToProps = {
	to: string
}

export const NavigatorNavigate = ({ to, ...props }: ToProps & NavLinkProps) => {
	let navigator = useNavigator()
	let formatHref = navigator.formatHref(to)

	if (formatHref.relativeURL != null) return <Navigate to={formatHref.relativeURL} {...props} />

	throwIllegalState(`Navigation failed for: ${to}`)
}
