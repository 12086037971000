import { isEqual, isNaN, toNumber } from "lodash"
import { v4 } from "uuid"
import { Paging } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import customerPortalOrdersStyle from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders.module.css"
import { ArrowLeftIcon, ArrowRightIcon, CrossIcon, TrashIcon } from "../../Icons/Icon"
import { cls } from "../cls"
import style from "./SkrappyTable.module.css"

export type Props<T> = {
	data: T[]
	columns: TableColumn<T>[]
	tableClass?: string
	tableRowClass?: string
	onRowClick?: (data: T) => void
}

export type TableColumn<T> = {
	headerClass?: string
	headerContent: string | JSX.Element
	headerOnClick?: () => void
	cellContent: TableCell<T>
	condition?: () => boolean
}

export type TableCell<T> = {
	className?: (data: T) => string | undefined
	content: (data: T) => string | JSX.Element
}

export type Filters = { [key: string]: string | string[] | number | boolean | object } & {
	searchText?: string
}

export function selectedFilters(
	filterData: Filters,
	getValue: (key: string, value: string | string[] | number | boolean | object) => string | number,
	onRemove: (key: string) => void,
	onClearAll: () => void,
) {
	if (
		Object.keys(filterData).length === 0 ||
		(Object.keys(filterData).length === 1 &&
			(Object.keys(filterData)[0] === "searchText" || Object.keys(filterData)[0] === "sortBy")) ||
		(Object.keys(filterData).length === 2 && isEqual(Object.keys(filterData), ["searchText", "sortBy"]))
	) {
		return null
	}

	return (
		<div className={customerPortalOrdersStyle.selectedFilterRowWrapper}>
			<div style={{ display: "flex", flexWrap: "wrap" }}>
				<span
					style={{
						display: "flex",
						alignItems: "center",
						width: "100px",
						whiteSpace: "nowrap",
					}}>
					Valda filter:
				</span>{" "}
				<div className={customerPortalOrdersStyle.selectedFilterRow}>
					{Object.entries(filterData).map(([key, value]) => {
						if (key === "searchText" || key === "sortBy") {
							return null
						}
						const theValue = getValue(key, value)
						return (
							<div key={key} className={customerPortalOrdersStyle.selectedFilter} title={theValue.toString()}>
								<span>{theValue}</span>{" "}
								<button
									onClick={() => {
										onRemove(key)
									}}>
									<CrossIcon
										className={customerPortalOrdersStyle.closeXIcon}
										iconClassName={customerPortalOrdersStyle.closeXIconInside}
									/>
								</button>
							</div>
						)
					})}
				</div>
			</div>
			<span
				className={customerPortalOrdersStyle.clearFilters}
				onClick={() => {
					onClearAll()
				}}>
				<TrashIcon
					size={22}
					iconClassName={cls(customerPortalOrdersStyle.iconColorStroke, customerPortalOrdersStyle.iconColorFill)}
				/>{" "}
				<span>Rensa filter</span>
			</span>
		</div>
	)
}

export function rowsPerPageSection(
	filters: Filters,
	currentPageSize: number,
	onSelect: (page: number) => void,
	disabled?: boolean,
) {
	const showMarginTop =
		Object.keys(filters).length === 0 || (Object.keys(filters).length === 1 && Object.keys(filters)[0] === "searchText")
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				marginBottom: "10px",
				marginTop: showMarginTop ? "20px" : undefined,
			}}>
			<span>
				Visa{" "}
				<select
					disabled={disabled}
					className={customerPortalOrdersStyle.brandedSelect}
					defaultValue={currentPageSize}
					onChange={(x) => {
						onSelect(toNumber(x.target.value))
					}}>
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>{" "}
				rader per sida
			</span>
		</div>
	)
}

export type PageInfo = { pageNumber: number; pageSize: number }

export function getTablePageOptions(paging: Paging | null): JSX.Element[] | null {
	if (!paging || paging.totalPages === 0) {
		return null
	}
	const ret = []
	for (let i = 0; i < paging.totalPages; i++) {
		ret.push(
			<option key={v4()} value={i + 1}>
				{i + 1}
			</option>,
		)
	}
	return ret
}

export function tablePagingSection(
	paging: Paging | null,
	pageInfo: PageInfo,
	onPreviousClick: () => void,
	onNextClick: () => void,
	onSpecificPage: (page: number) => void,
	disabled?: boolean,
) {
	if (!paging) {
		return null
	}

	const start = (pageInfo.pageNumber - 1) * pageInfo.pageSize

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				marginTop: "10px",
				flexWrap: "wrap",
			}}>
			<span style={{ color: "var(--main-background-text-color)" }}>
				Visar {start === 0 ? 1 : start + 1} -{" "}
				{pageInfo.pageNumber === paging.totalPages ? paging.totalElements : pageInfo.pageNumber * pageInfo.pageSize}{" "}
				av {paging.totalElements} rader
			</span>
			<span className={style.tablePagination}>
				<span style={{ display: "flex", alignItems: "center" }}>Nuvarande sida</span>{" "}
				<span style={{ display: "flex", gap: "3px" }}>
					<button
						disabled={pageInfo.pageNumber <= 1 || pageInfo.pageNumber > paging.totalPages || disabled}
						onClick={() => onPreviousClick()}>
						<ArrowLeftIcon iconClassName={style.navigationArrow} size={16} />
					</button>{" "}
					<select
						name="pageOptions"
						disabled={paging.totalPages <= 0 || disabled}
						value={pageInfo.pageNumber}
						onChange={(e) => {
							const number = toNumber(e.target.value)
							if (!isNaN(number) && number > 0) {
								onSpecificPage(number)
							}
						}}>
						{getTablePageOptions(paging)}
					</select>
					<button
						disabled={pageInfo.pageNumber >= paging.totalPages || paging.totalPages === 0 || disabled}
						onClick={() => {
							onNextClick()
						}}>
						<ArrowRightIcon iconClassName={style.navigationArrow} size={16} />
					</button>
				</span>
			</span>
		</div>
	)
}

export const SkrappyTable = <T,>(props: Props<T>) => {
	return (
		<table className={cls(style.table, props.tableClass)}>
			<thead>
				<tr>
					{props.columns.map((column) => {
						if (column.condition && !column.condition()) {
							return null
						}
						return (
							<th key={v4()} className={column.headerClass} onClick={column.headerOnClick}>
								{column.headerContent}
							</th>
						)
					})}
				</tr>
			</thead>
			<tbody>
				{props.data.map((dataObj) => {
					return (
						<tr
							key={v4()}
							className={props.tableRowClass}
							onClick={() => {
								if (props.onRowClick) {
									props.onRowClick(dataObj)
								}
							}}>
							{props.columns.map((column) => {
								if (column.condition && !column.condition()) {
									return null
								}

								return (
									<td
										key={v4()}
										className={
											column.cellContent?.className
												? column.cellContent.className(dataObj)
												: undefined
										}>
										{column.cellContent.content(dataObj)}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}
