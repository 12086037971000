import Hotjar from "@hotjar/browser"
import "normalize.css"
import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import "./Branding/clientBranding.css"
import "./Branding/legacyBranding.css"
import "./fonts/hk-grotesk.css"
import "./index.css"
import { remoteLogPusher } from "./Logging/getLogger"
import { setupRootLogger } from "./Logging/loglevelConfig"
import { sendLogEntries } from "./Logging/remoteLogSender"

setupRootLogger()
remoteLogPusher.sendLogEntries = sendLogEntries

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
	<React.StrictMode>
		<App />
		{process.env.NODE_ENV !== "production" || process.env.REACT_APP_SHOW_VERSION === "yes" ? (
			<div
				style={{
					position: "fixed",
					right: "0px",
					bottom: "0px",
					color: "rgba(0, 0, 0, 0.5)",
				}}>
				{process.env.REACT_APP_BUILD_DATE ?? "today"} - {process.env.REACT_APP_DESCRIBE ?? "dev"}
			</div>
		) : null}
	</React.StrictMode>,
)

if (process.env.REACT_APP_HOTJAR_ID != null) {
	const siteId = parseInt(process.env.REACT_APP_HOTJAR_ID, 10)
	const hotjarVersion = 6
	Hotjar.init(siteId, hotjarVersion)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(log.debug)
