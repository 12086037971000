import { LoginResponse, MeResponseAccountType } from "Auth/Auth.types"
import { GetUserInvitation } from "CustomerPortal/UserInvitations/UsersInvitations"
import { isUndefined } from "lodash"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { MbactH1 } from "Orders/Components/Text/MbactH1/MbactH1"
import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { when } from "Shared/when"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { CompleteType, ConnectionCompleted } from "./components/HandleUserInvitationCompleteView"
import { CreateUserForm } from "./components/HandleUserInvitationCreateUserForm"
import { LinkExistingUser } from "./components/HandleUserInvitationLinkAccount"
import { AcceptOptions } from "./components/HandleUserInvitationOptions"
import style from "./HandleUserInvitation.module.css"

export enum HandleInvitationState {
	AcceptInvitationStart,
	LinkExistingUser,
	CreatNewUser,
	CompleteNewUser,
	CompleteConnection,
	LoggedInAsClient,
	AlreadyLinkedToAccount,
}

type Props = {}
export const HandleUserInvitation: FC<Props> = () => {
	const { clientName } = useParams()
	const auth = useAuth()
	const [queryParams] = useSearchParams()

	const [invitation, setInvitation] = useState<GetUserInvitation | undefined>(undefined)
	const [dataLoading, setDataLoading] = useState(true)
	const [state, setState] = useState<HandleInvitationState>(
		auth.Me?.type === MeResponseAccountType.Client
			? HandleInvitationState.LoggedInAsClient
			: HandleInvitationState.AcceptInvitationStart,
	)
	const [loginDetails, setLoginDetails] = useState<LoginResponse | undefined>(undefined)

	const init = useCallback(() => {
		const inviteId = queryParams.get("inviteId")
		setDataLoading(true)
		API.getWithRetries<GetUserInvitation>(`/customer-portal/user-invitation-v1/${inviteId}`, true, {}, 5)
			.then((res) => {
				setInvitation(res)
				setDataLoading(false)
			})
			.catch(() => {
				setInvitation(undefined)
				setDataLoading(false)
			})
	}, [])

	useEffect(() => {
		init()
	}, [init])

	function LogOut() {
		if (!isUndefined(clientName)) {
			auth.logout(clientName)
			setState(HandleInvitationState.AcceptInvitationStart)
		}
	}

	return (
		<>
			{dataLoading ? (
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			) : (
				<div className={style.wrapper}>
					{isUndefined(invitation) ? (
						<>
							<MbactH1>Något gick fel!</MbactH1>
							<div>
								Det verkar som att den sökta inbjudan inte längre är aktiv. Du får begära en ny inbjudan
								från de som skickat den.
							</div>
						</>
					) : (
						<>
							{when(state, {
								[HandleInvitationState.AlreadyLinkedToAccount]: () => (
									<>
										<MbactH1>Användaren är redan kopplad!</MbactH1>
										<div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
											Användaren är redan kopplad till företaget. Logga ut och försök igen.
										</div>
										<FinalizeButton onClick={() => LogOut()}>Logga ut</FinalizeButton>
									</>
								),
								[HandleInvitationState.LoggedInAsClient]: () => (
									<>
										<MbactH1>Inloggad som klient!</MbactH1>
										<div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
											Det verkar som att du försöker öppna en inbjudan som klient. Var god och logga
											ut och försök igen
										</div>
										<FinalizeButton onClick={() => LogOut()}>Logga ut</FinalizeButton>
									</>
								),
								[HandleInvitationState.AcceptInvitationStart]: () => (
									<AcceptOptions invitation={invitation} setState={setState} />
								),
								[HandleInvitationState.CreatNewUser]: () => (
									<CreateUserForm
										invitation={invitation}
										setState={setState}
										setLoginDetails={setLoginDetails}
									/>
								),
								[HandleInvitationState.LinkExistingUser]: () => (
									<LinkExistingUser invitation={invitation} setState={setState} />
								),
								[HandleInvitationState.CompleteConnection]: () => (
									<ConnectionCompleted
										completeType={CompleteType.AddedConnection}
										consumerName={invitation.senderDetails.consumerName}
										loginDetails={loginDetails}
									/>
								),
								[HandleInvitationState.CompleteNewUser]: () => (
									<ConnectionCompleted
										completeType={CompleteType.CreatedNewUser}
										consumerName={invitation.senderDetails.consumerName}
										loginDetails={loginDetails}
									/>
								),
							})}
						</>
					)}
				</div>
			)}
		</>
	)
}
