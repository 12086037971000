import { exhaustive } from "exhaustive"
import React, { ReactNode, useContext } from "react"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { getReloadCount, incrementReloadCountAndReloadPage, reloadCountLimitReached } from "../ErrorBoundary/ErrorBoundary"
import { Loader } from "../Loader/Loader"
import { getLogger } from "../Logging/getLogger"
import { throwIllegalState } from "../Shared/throwIllegalState"
import { when } from "../Shared/when"
import { ArticleTree, ArticleTreeType } from "./articleTrees/ArticleTreeDataModel"
import { useClient } from "./ClientAndUserProvider"
import {
	ConsumerCatalogConfiguration,
	MissingPricePolicy,
	PreferredVATRenderPolicy,
	PrepayConfiguration,
} from "./consumerCatalogConfigurationTypes"
import { useConsumer } from "./ConsumerContext"
import { ConsumerCatalogContextType, useConsumerConfiguration } from "./useConsumerConfiguration"

const logger = getLogger("ConsumerCatalogContext")

export type GetConsumerCatalogConfigurationResponse = {
	configuration: ConsumerCatalogConfiguration
	articleTrees: ArticleTree[]
	features: ConsumerFeatures
}

export type ConsumerFeatures = {
	projectMode: ConsumerProjectMode
	fullProjectMarkingMode?: FullProjectMarkingMode
}

export enum FullProjectMarkingMode {
	Optional = "Optional",
	Required = "Required",
}

export enum ConsumerProjectMode {
	SimpleAddress = "SimpleAddress",
	FullProject = "FullProject",
}
export class ConsumerCatalogInstance {
	readonly configuration: ConsumerCatalogConfiguration
	readonly articleTrees: ArticleTree[]
	readonly features: ConsumerFeatures

	readonly staticProductPricing: ArticleTree | undefined
	readonly transportPricings: ArticleTree[]
	readonly datePricing: ArticleTree | undefined
	readonly preferredVATRenderPolicy: PreferredVATRenderPolicy | undefined

	constructor(configuration: ConsumerCatalogConfiguration, articleTrees: ArticleTree[], features: ConsumerFeatures) {
		this.configuration = configuration
		this.articleTrees = articleTrees
		this.features = features

		this.staticProductPricing = articleTrees.find((x) => x.treeType === ArticleTreeType.StaticProductPricing)
		this.transportPricings = articleTrees.filter((x) => x.treeType === ArticleTreeType.StaticTransportationPricing)
		this.datePricing = articleTrees.find((x) => x.treeType === ArticleTreeType.StaticDateSlotPricing)

		this.preferredVATRenderPolicy = exhaustive(this.configuration, "type", {
			Contract: () => {
				return undefined
			},
			Prepay: (it) => {
				return it.preferredVATRenderPolicy
			},
			Postpay: (it) => {
				return it.preferredVATRenderPolicy
			},
		})
	}

	get configurationType(): ConsumerCatalogConfiguration["type"] {
		return this.configuration.type
	}

	get missingPricePolicy(): PrepayConfiguration["missingPricePolicy"] {
		return exhaustive(this.configuration, "type", {
			Contract: () => {
				return undefined
			},
			Prepay: (it) => {
				return it.missingPricePolicy ?? MissingPricePolicy.Hide //Forward-compatible
			},
			Postpay: (it) => {
				return it.missingPricePolicy ?? MissingPricePolicy.Hide
			},
			_: () => {
				logger.error("MissingPricePolicy looked up for CCC that doesn't support it!", this.configuration)
				return MissingPricePolicy.Hide
			},
		})
	}

	get discountTrees(): ArticleTree[] {
		return this.articleTrees.filter((x) => x.treeType === ArticleTreeType.Discounts)
	}

	get markingRequired(): boolean {
		if (this.features.projectMode === ConsumerProjectMode.FullProject && this.features.fullProjectMarkingMode) {
			return when(this.features.fullProjectMarkingMode, {
				[FullProjectMarkingMode.Optional]: () => {
					return false
				},
				[FullProjectMarkingMode.Required]: () => {
					return true
				},
			})
		}

		return false
	}

	get pricesEnabled(): boolean {
		return exhaustive(this.configurationType, {
			Contract: () => {
				return false
			},
			Prepay: () => {
				return this.articleTrees.length > 0
			},
			Postpay: () => {
				return this.articleTrees.length > 0
			},
		})
	}

	get renderVAT(): boolean {
		return (
			this.preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVAT ||
			this.preferredVATRenderPolicy === PreferredVATRenderPolicy.IncludeVATExplicit
		)
	}
}

export function preferredVATRenderPolicyAsHumanStringShortForm(value: PreferredVATRenderPolicy) {
	return exhaustive(value, {
		[PreferredVATRenderPolicy.IncludeVAT]: () => "inkl. moms",
		[PreferredVATRenderPolicy.IncludeVATExplicit]: () => "inkl. moms",
		[PreferredVATRenderPolicy.ExcludeVAT]: () => "exkl. moms",
		_: () => "<okänd typ>",
	})
}

const ConsumerCatalogContext = React.createContext<ConsumerCatalogContextType>("loading")

type Props = {
	children: ReactNode
}

export function ConsumerCatalogContextProvider({ children }: Props) {
	const client = useClient()
	const consumer = useConsumer()

	const value = useConsumerConfiguration(client.identifier, consumer?.consumerId)

	if (value === "loading") {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	if (value === null) {
		if (reloadCountLimitReached()) {
			throwIllegalState(
				`Unable to resolve Consumer Catalog Configuration for, client: ${client.identifier}, consumer: ${
					consumer?.consumerId || "undefined"
				}, reload count reached.`,
			)
		}

		logger.error(
			`Unable to resolve Consumer Catalog Configuration for, client: ${client.identifier}, consumer: ${
				consumer?.consumerId || "undefined"
			}, the page will be reloaded. Reload count: ${getReloadCount()}`,
		)
		setTimeout(() => {
			incrementReloadCountAndReloadPage()
		}, 500)
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	logger.debug("ConsumerCatalogContext: ", value)
	return <ConsumerCatalogContext.Provider value={value}>{children}</ConsumerCatalogContext.Provider>
}

export function useConsumerCatalog(): ConsumerCatalogInstance {
	let context = useContext(ConsumerCatalogContext)
	if (context instanceof ConsumerCatalogInstance) {
		return context
	}

	throwIllegalState(`ConsumerCatalog Context not setup, was: ${context}`)
}
