import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { z } from "zod"
import { API } from "../network/API"
import { SbtRHFError } from "../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { DemoProps } from "./demoState"
import { ReactComponent as CompanySvg } from "./Images/company.svg"
import { ReactComponent as DescribeYourSelfSvg } from "./Images/describe-your-self.svg"
import { ReactComponent as MailSvg } from "./Images/mail.svg"
import { ReactComponent as SurveySvg } from "./Images/survey.svg"
import { ReactComponent as YourNameSvg } from "./Images/your-name.svg"

import { ReactComponent as YouBuilderSvg } from "./Images/youBuilder.svg"
import { ReactComponent as YouCuriousSvg } from "./Images/youCurious.svg"
import { ReactComponent as YouDiySvg } from "./Images/youDiy.svg"
import { ReactComponent as YouMaterialSvg } from "./Images/youMaterial.svg"
import { ReactComponent as YouWasteManagmentSvg } from "./Images/youWasteManagment.svg"

import React from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form/dist/types"
import { getLogger } from "../Logging/getLogger"
import { cls } from "../Shared/cls"
import style from "./DemoStyle.module.css"

const logger = getLogger("Demo2Start")

function requiredCompanyName(roll: string) {
	return ["Avfallshantering", "GrusOchJordLeveranser", "Byggarbetare"].indexOf(roll) >= 0
}

const DemoStartSchema = z
	.object({
		firstName: z.string().min(1),
		lastName: z.string().min(1),
		email: z.string().email(),
		roll: z.string({ required_error: "Du måste göra ett val" }).min(1),
		companyName: z.string().optional(),
	})
	.superRefine((data, ctx) => {
		if (requiredCompanyName(data.roll)) {
			if ((data.companyName?.trim().length ?? 0) < 1) {
				ctx.addIssue({
					path: ["companyName"],
					code: "custom",
					message: "Företagsnamn måste fyllas i",
				})
			}
		}
	})

type DemoStartData = z.input<typeof DemoStartSchema>

export function Demo2Start({ setDemoState }: DemoProps) {
	const { clientName } = useParams()

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors, isSubmitting },
	} = useForm<DemoStartData>({
		resolver: async (data, context, options) => {
			return zodResolver(DemoStartSchema)(data, context, options)
		},
	})

	const onSubmit: SubmitHandler<DemoStartData> = async (data) => {
		const request = {
			clientIdentifier: clientName,
			...data,
		}

		try {
			await API.post("/survey/generic-v1/demo-start", request)
		} catch (e) {
			logger.log("Error when submitting survey, nothing to crash about", e)
		}
		setDemoState("demoing")
	}

	const roll = watch("roll")
	const showCompanyField = requiredCompanyName(roll)

	return (
		<>
			<div className={style.topImage}>
				<SurveySvg />
			</div>
			<h3>MARKNADSUNDERSÖKNING</h3>
			<h1>Kan du vara vänlig att lämna lite info?</h1>
			<p>Vi använder detta som en marknadsundersökning och blir väldigt tacksamma för all feedback vi kan få.</p>
			<p>Först skulle vi vilja veta lite mer om dig innan du kan testa.</p>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div className={style.icon}>
							<YourNameSvg />
						</div>
						<div>
							<h1>Vad är ditt namn?</h1>
							<p>Så att vi kan samla in feedbacken rätt</p>
						</div>
					</div>
					<div className={style.sectionInputs}>
						<label>
							Förnamn*
							<input {...register("firstName")} />
							<SbtRHFError error={errors.firstName} />
						</label>
						<label>
							Efternamn*
							<input {...register("lastName")} />
							<SbtRHFError error={errors.lastName} />
						</label>
					</div>
				</div>
				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div className={style.icon}>
							<MailSvg />
						</div>
						<div>
							<h1>Vad har du för e-post?</h1>
							<p>(Vi kommer inte att skicka ut nyhetsbrev)</p>
						</div>
					</div>
					<div className={style.sectionInputs}>
						<label>
							E-post*
							<input {...register("email")} />
							<SbtRHFError error={errors.email} />
						</label>
					</div>
				</div>

				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div className={style.icon}>
							<DescribeYourSelfSvg />
						</div>
						<div>
							<h1>Vad beskriver dig bäst?</h1>
							<p>Vi vill veta detta så att vi kan kategorisera er feedback rätt</p>
						</div>
					</div>
					<label>
						<div className={style.sectionSelectCards}>
							<SelectableCard name={"roll"} onSelectValue={"Avfallshantering"} control={control}>
								Arbetar inom avfallshantering
								<YouWasteManagmentSvg />
							</SelectableCard>
							<SelectableCard name={"roll"} onSelectValue={"GrusOchJordLeveranser"} control={control}>
								Arbetar med grus och jord-leveranser
								<YouMaterialSvg />
							</SelectableCard>
							<SelectableCard name={"roll"} onSelectValue={"Byggarbetare"} control={control}>
								Arbetar inom bygg och beställer avfall eller material-tjänster i arbetet
								<YouBuilderSvg />
							</SelectableCard>
							<SelectableCard name={"roll"} onSelectValue={"PrivatPerson"} control={control}>
								Beställer avfalls eller material-tjänster som privatperson
								<YouDiySvg />
							</SelectableCard>
							<SelectableCard name={"roll"} onSelectValue={"Nyfiken"} control={control}>
								Jag är bara nyfiken på att titta på demot
								<YouCuriousSvg />
							</SelectableCard>
						</div>
						<SbtRHFError error={errors.roll} />
					</label>
				</div>

				<div
					className={cls(style.formSection, {
						[style.hide]: !showCompanyField,
					})}>
					<div className={style.sectionHeader}>
						<div className={style.icon}>
							<CompanySvg />
						</div>
						<div>
							<h1>Vad heter företaget?</h1>
							<p>Så att vi kan samla in feedbacken rätt</p>
						</div>
					</div>
					<div className={style.sectionInputs}>
						<label>
							Företagsnamn*
							<input {...register("companyName")} />
							<SbtRHFError error={errors.companyName} />
						</label>
					</div>
				</div>

				<div className={style.buttonWrapper}>
					<button type={"submit"} disabled={isSubmitting}>
						Kör igång demot
					</button>
				</div>

				<a
					href={"https://skrappy.se/privacy-notice/"}
					rel="noreferrer"
					target="_blank"
					className={style.privacyNotice}>
					* läs mer om hur vi hanterar personuppgifter
				</a>
			</form>
		</>
	)
}

type CardProps<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
	name: TName
	onSelectValue: string
	control: Control<any>
	children: React.ReactNode
}

function SelectableCard({ name, onSelectValue, control, children }: CardProps) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange } }) => {
				return (
					<div
						className={cls(style.selectableCard, {
							[style.selected]: value === onSelectValue,
						})}
						onClick={() => {
							onChange(onSelectValue)
						}}>
						{children}
					</div>
				)
			}}
		/>
	)
}
