import { includes, isNaN, join, split, toNumber } from "lodash"
import { getLogger } from "../Logging/getLogger"

export function cleanMimeString(text: string): string {
	let updatedText = text
	if (includes(updatedText, ";")) {
		const resArray = split(updatedText, ";", 1)
		updatedText = resArray[0] || ""
	}
	if (includes(updatedText, ":")) {
		const resArray = split(updatedText, ":", 2)
		updatedText = resArray[1] || ""
	}
	return updatedText
}

const loggerImgUrl = getLogger("ImageURLFromBase64")
export const getImageURLFromBase64 = async (base64Image: string, mimeType: string) => {
	try {
		const imageBlob = await fetch(`data:${mimeType};base64,${base64Image}`).then((res) => res.blob())
		return URL.createObjectURL(imageBlob)
	} catch (e: any) {
		loggerImgUrl.error(`Unable to create imageBlob from string with length: ${base64Image}`, { cause: e })
		return ""
	}
}

export function convertTextToInitials(text: string): string {
	if (text.length < 4) {
		return text
	}
	const textCleaned = text.replace("  ", " ")
	const textSplit = textCleaned.split(" ")
	const textSegmentsConsolidated = join(
		textSplit.map((section) => section[0]),
		"",
	)
	const textSegmentsConsolidatedSliced = textSegmentsConsolidated.slice(0, 3)
	return textSegmentsConsolidatedSliced.toLocaleUpperCase()
}

/**
 * Parses a number safely from string
 * If number is null, NaN, or less than minValue (if specified), it returns defaultValue
 * @param value
 * @param defaultValue
 * @param minValue
 */
export function parseNumberSafe(
	value: string | number | null | undefined,
	defaultValue: number,
	minValue?: number,
): number {
	if (value == null) {
		return defaultValue
	}

	const number = toNumber(value)

	if (isNaN(number)) {
		return defaultValue
	}

	if (minValue !== undefined && number < minValue) {
		return defaultValue
	}

	return number
}

export type GetBy<T> = { index: number; value: T }

/**
 * Gets any arbitrary object and index from any array by the given key and value combo
 *
 * returns null if not found
 */
export function getBy<T>(key: keyof T, value: string, items: T[]): GetBy<T> | null {
	const index = items.findIndex((x) => x[key] === value)

	const item = items[index]
	if (index > -1 && item) {
		return { index, value: item }
	}

	return null
}
