import { FC } from "react"
import style from "./ClientNotFound.module.css"

type Props = {
	clientName: string | undefined
}

export const ClientNotFound: FC<Props> = ({ clientName }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.header}>
				{clientName ? <>Tyvärr finns inte '{clientName}' hos Skrappy.</> : <>Sökväg saknas</>}
			</div>
			<p>Sidan existerar inte. Vänligen kontakta din leverantör.</p>
		</div>
	)
}
