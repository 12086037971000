import { FC, PropsWithChildren } from "react"
import { cls } from "Shared/cls"
import style from "./Mbact.module.css"

type Props = PropsWithChildren

/* MBA 14pt regular */
export const Mbact: FC<Props> = ({ children }) => {
	return <p className={cls(style.mbact)}>{children}</p>
}
