import { DateTime } from "luxon"

/**
 * Returns a string in yyyy-MM-dd format corresponding to the next work day
 */
export function nextWorkday(): string {
	const currentWeekDay: number = DateTime.now().setZone("Europe/Stockholm").weekday
	let nextWorkDay: string
	switch (currentWeekDay) {
		case 5:
		case 6:
		case 7:
			nextWorkDay = DateTime.now()
				.setZone("Europe/Stockholm")
				.plus({ days: 8 - currentWeekDay })
				.toFormat("yyyy-MM-dd")
			break
		default:
			nextWorkDay = DateTime.now().setZone("Europe/Stockholm").plus({ days: 1 }).toFormat("yyyy-MM-dd")
	}
	return nextWorkDay
}

/**
 * Returns a string in yyyy-MM-dd format corresponding to three workdays from now
 */
export function thirdNextWorkday(): string {
	const currentWeekDay: number = DateTime.now().setZone("Europe/Stockholm").weekday
	let nextWorkDay: string
	switch (currentWeekDay) {
		case 3:
		case 4:
		case 5:
		case 6:
		case 7:
			nextWorkDay = DateTime.now()
				.setZone("Europe/Stockholm")
				.plus({ days: 10 - currentWeekDay })
				.toFormat("yyyy-MM-dd")
			break
		default:
			nextWorkDay = DateTime.now().setZone("Europe/Stockholm").plus({ days: 3 }).toFormat("yyyy-MM-dd")
	}
	return nextWorkDay
}
