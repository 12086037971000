import { faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isString, sortBy } from "lodash"
import { DateTime } from "luxon"
import { FieldModuleBox } from "Orders/Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "Orders/Components/FieldModuleBox/FieldModuleBoxWrapper"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { NativeModuleDatePicker } from "Orders/Components/Form/NativeModuleDatePicker/NativeModuleDatePicker"
import { ModulChooserButton } from "Orders/Components/ModulChooserButton/ModulChooserButton"
import { ModulePopup, ModulePopupProps } from "Orders/Components/ModulePopup/ModulePopup"
import { Mbt } from "Orders/Components/Text/Mbt/Mbt"
import { MbtH5 } from "Orders/Components/Text/MbtH5/MbtH5"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { FC, useRef, useState } from "react"
import { nextWorkday } from "Shared/tomorrow"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ProductDateSlot } from "../../Client/ProductDefinitionsByCategories"
import { getLogger } from "../../Logging/getLogger"
import { notNull } from "../../Shared/notNull"
import { SbtH6 } from "../Components/Text/SbtH6/SbtH6"
import { OrderItemDateType } from "../order-data-model"
import style from "./DateSelectModule.module.css"

const logger = getLogger("DateSelectModule")

type Props = ModulePopupProps & {
	onDateSelected: (date: OrderItemDateType) => void
	defaultValue?: OrderItemDateType
	allowedValues: string[]
}

/**
 * Takes a date slot and calculates the from- and to-dates for it.
 *
 * If saturday and sunday is skipped, it automatically calculates the dates offset to compensate
 */
export function getDatesFromDateSlot(dateSlot: ProductDateSlot): { from: DateTime; to: DateTime } {
	if (dateSlot.settings.skipSaturdayAndSunday) {
		let nextWorkDay = getNextWorkDaySkipWeekends(DateTime.now().setZone("Europe/Stockholm").plus({ day: 1 }))

		// First get tomorrow's date, shifted to monday if needed
		let from = getNextWorkDaySkipWeekends(DateTime.now().setZone("Europe/Stockholm").plus({ day: 1 }))
		// Add "from days" - 1 since the first day has already been stepped to in the above row
		from = from.plus({ day: dateSlot.fromDays - 1 })

		// If the from-date date crosses into a new week, compared to the next working day, shift the date 2 days per week's difference
		if (from.weekNumber > nextWorkDay.weekNumber) {
			from = getNextWorkDaySkipWeekends(from.plus({ day: (from.weekNumber - nextWorkDay.weekNumber) * 2 }))
		} else if (from.weekday > 5) {
			// If the week doesn't diff, but the from-date is on a saturday or sunday, add two days to skip it
			from = from.plus({ day: 2 })
		}

		// Get to-date by adding days difference between to and from
		let to = from.plus({ day: dateSlot.toDays - dateSlot.fromDays })

		// If the to-date date crosses into a new week, compared to the next working day, shift the date 2 days per week's difference
		if (to.weekNumber > from.weekNumber) {
			// Add 2 days for every weeks difference
			// if from is week 2 and to is week 4, we've skipped two weekends and as such should shift 4 days
			to = getNextWorkDaySkipWeekends(to.plus({ day: (to.weekNumber - from.weekNumber) * 2 }))
		} else if (to.weekday > 5) {
			// If the week doesn't diff, but we end on a weekend, shift the date to monday after said weekend
			to = getNextWorkDaySkipWeekends(to)
		}

		return {
			from: from,
			to: to,
		}
	} else {
		return {
			from: DateTime.now().setZone("Europe/Stockholm").plus({ day: dateSlot.fromDays }),
			to: DateTime.now().setZone("Europe/Stockholm").plus({ day: dateSlot.toDays }),
		}
	}
}

/**
 * Adds 1 or 2 days to transform a date which is a saturday or sunday to a monday
 * For example: If the day is saturday (6), add 8-6 = 2 days, making it monday
 */
export function getNextWorkDaySkipWeekends(date: DateTime): DateTime {
	if (date.weekday > 5) {
		return date.plus({ day: 8 - date.weekday })
	}
	return date
}

export function getDatesFromDateSlotAsString(dateSlot: ProductDateSlot): { from: string; to: string } {
	let dates = getDatesFromDateSlot(dateSlot)

	return {
		from: dates.from.toFormat("yyyy-MM-dd"),
		to: dates.to.toFormat("yyyy-MM-dd"),
	}
}

export const DateSelectModule: FC<Props> = ({ onDateSelected, defaultValue, allowedValues, onClose, ...props }) => {
	const client = useClient()
	const dateSlots = useRef(
		sortBy(
			(allowedValues ?? [])
				.map((x) => {
					const possibleDateSlot = client.possibleDateSlots[x]
					if (possibleDateSlot == null) {
						logger.error(`DateSlot missing! id: ${x}`)
						return null
					}
					return possibleDateSlot
				})
				.filter(notNull),
			"order",
		),
	)

	const [selected, setSelected] = useState<OrderItemDateType>(() => {
		if (typeof defaultValue === "string") {
			if (defaultValue === nextWorkday()) {
				return "Tomorrow"
			} else if (new Date(defaultValue) > new Date()) {
				return "SpecificDate"
			}
		} else if (defaultValue?.dateSlotId) {
			return {
				dateSlotId: defaultValue.dateSlotId,
			}
		}

		return undefined
	})
	const [specificDate, setSpecificDate] = useState<string | null>(() => {
		if (defaultValue) {
			if (isString(defaultValue) && new Date(defaultValue) >= new Date(nextWorkday())) {
				return defaultValue
			} else if (!isString(defaultValue)) {
				if (defaultValue.specificDate) {
					return defaultValue.specificDate
				}
			}
		}

		return null
	})

	const [dateSlotExactDateError, setDateSlotExactDateError] = useState<string | null>(null)

	const nextWorkDay = nextWorkday()

	const validate = () => {
		if (selected === "Tomorrow") return true

		if (selected === "SpecificDate") {
			if (specificDate === null || specificDate.length <= 0) return false

			const date = new Date(specificDate)
			const _nextDay = new Date(nextWorkDay)
			date.setHours(0, 0, 0, 0)
			_nextDay.setHours(0, 0, 0, 0)

			return date >= _nextDay
		}

		return !!selected && !dateSlotExactDateError
	}

	const isValid = validate()

	const onDone = () => {
		if (!isValid) return
		if (selected === "Tomorrow") {
			onDateSelected(nextWorkDay)
		} else if (selected === "SpecificDate" && specificDate !== null) {
			onDateSelected(specificDate)
		} else {
			onDateSelected(selected)
		}

		onClose()
	}

	function getDateIntervalFromDateSlot(dateSlotId: string): JSX.Element {
		const dateSlot = client.possibleDateSlots[dateSlotId]
		const dates = getDatesFromDateSlotAsString(dateSlot)

		return (
			<span>
				{dates.from}{" "}
				{dates.to > dates.from ? (
					<>
						<FontAwesomeIcon icon={faArrowsLeftRight} /> {dates.to}
					</>
				) : null}
			</span>
		)
	}

	return (
		<ModulePopup onClose={onClose} {...props}>
			<div>
				<SbtH2>Datum</SbtH2>
				<SbtH6>
					<span style={{ fontWeight: "normal" }}>Välj den dag du önskar att din beställning utförs</span>
				</SbtH6>
				<FieldModuleBoxWrapper className={style.dateSlotsWrapper}>
					{allowedValues.length > 0 ? (
						dateSlots.current.map((dateSlot) => {
							const isSelected = selected
								? dateSlot.id === (typeof selected !== "string" ? selected.dateSlotId : selected)
								: false
							return (
								<FieldModuleBox
									key={dateSlot.id + "-dateslot"}
									className={style.normalDate}
									selected={isSelected}>
									<div>
										<MbtH5>
											{dateSlot.name}
											{!dateSlot.settings.exactDate ? (
												<>
													<br />
													<span style={{ fontSize: "14px" }}>
														{getDateIntervalFromDateSlot(dateSlot.id)}
													</span>
												</>
											) : null}
										</MbtH5>
										<Mbt>{dateSlot.description}</Mbt>
									</div>
									{dateSlot.settings.exactDate ? (
										<NativeModuleDatePicker
											selected={selected === "SpecificDate"}
											onDateSelected={(date) => {
												if (
													DateTime.fromFormat(date, "yyyy-MM-dd").weekday > 5 &&
													dateSlot.settings.skipSaturdayAndSunday
												) {
													setDateSlotExactDateError(dateSlot.id)
												} else {
													setDateSlotExactDateError(null)
												}
												setSpecificDate(date)
												setSelected({ dateSlotId: dateSlot.id, specificDate: date })
											}}
											value={specificDate}
											className={style.dateSelectInput}
										/>
									) : (
										<ModulChooserButton
											selected={isSelected}
											onClick={() => {
												setDateSlotExactDateError(null)
												setSelected({ dateSlotId: dateSlot.id })
											}}>
											{isSelected ? "Vald" : "Välj"}
										</ModulChooserButton>
									)}
									{dateSlotExactDateError === dateSlot.id ? (
										<div className={style.dateSlotError}>Lördagar och söndagar är inte valbara</div>
									) : null}
								</FieldModuleBox>
							)
						})
					) : (
						<>
							<FieldModuleBox className={style.normalDate} selected={selected === "Tomorrow"}>
								<div>
									<MbtH5>Imorgon</MbtH5>
									<Mbt>Nästa arbetsdag - {nextWorkDay}</Mbt>
								</div>
								<ModulChooserButton
									selected={selected === "Tomorrow"}
									onClick={() => {
										setSelected("Tomorrow")
									}}>
									{selected === "Tomorrow" ? "Vald" : "Välj"}
								</ModulChooserButton>
							</FieldModuleBox>
							<FieldModuleBox
								className={style.specificDate}
								selected={selected === "SpecificDate"}
								onClick={() => {
									setSelected("SpecificDate")
								}}>
								<div>
									<MbtH5>Välj datum</MbtH5>
									<Mbt>Utförs på önskat datum</Mbt>
								</div>
								<NativeModuleDatePicker
									selected={selected === "SpecificDate"}
									onDateSelected={(date) => {
										setSpecificDate(date)
										setSelected("SpecificDate")
									}}
									value={specificDate}
									className={style.dateSelectInput}
								/>
							</FieldModuleBox>
						</>
					)}
				</FieldModuleBoxWrapper>
				<FinalizeButton className={style.finalize} disabled={!isValid} onClick={onDone}>
					Klar
				</FinalizeButton>
			</div>
		</ModulePopup>
	)
}
