import { faCircle as faCirlceReg } from "@fortawesome/free-regular-svg-icons"
import { faChevronLeft, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useClient } from "Client/ClientAndUserProvider"
import { ImageRenderMode, ImageType, ProductImage } from "Client/ProductDefinitionsByCategories"
import { exhaustive } from "exhaustive"
import { ProductImage as ProductImageRenderer } from "Orders/Components/ProductImage/ProductImage"
import { FC, useState } from "react"
import style from "./ProductImageSlideshow.module.css"

type ProductImageSlideshowProps = {
	images: ProductImage[]
	categoryName: string
}

export const ProductImageSlideShow: FC<ProductImageSlideshowProps> = ({ images, categoryName }) => {
	const [slideIndex, setSlideIndex] = useState(1)
	const client = useClient()

	const nextSlide = () => {
		if (slideIndex >= images.length - 1) {
			setSlideIndex(0)
		} else {
			setSlideIndex(slideIndex + 1)
		}
	}
	const prevSlide = () => {
		if (slideIndex <= 0) {
			setSlideIndex(images.length - 1)
		} else {
			setSlideIndex(slideIndex - 1)
		}
	}

	const image = images[slideIndex]

	if (!image) {
		return null
	}

	return (
		<div style={{ backgroundColor: "var(--product-image-background-color)" }}>
			<div className={style.slideshowContainer}>
				{exhaustive(image, "type", {
					[ImageType.Typed]: (it) => (
						<ProductImageRenderer
							client={client}
							categoryName={categoryName}
							image={it.typeImage}
							wrapperClassName={style.productImageWrapper}
						/>
					),
					[ImageType.Url]: (it) => (
						<div
							className={
								it.renderMode === ImageRenderMode.Fill ? style.imageWrapperFill : style.imageWrapperFit
							}>
							<img
								className={it.renderMode === ImageRenderMode.Fill ? style.imageFill : style.imageFit}
								src={it.url}
								alt="Bild"
							/>
						</div>
					),
				})}
				<span className={style.prev} onClick={prevSlide}>
					<FontAwesomeIcon icon={faChevronLeft} className={style.slideshowArrow} />
				</span>

				<span className={style.next} onClick={nextSlide}>
					<FontAwesomeIcon icon={faChevronRight} className={style.slideshowArrow} />
				</span>
			</div>
			<div style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem", gap: "0.5rem" }}>
				{images.map((image, index) => (
					<span onClick={(e) => setSlideIndex(index)} key={`slider_footer_${index}`}>
						<FontAwesomeIcon
							icon={index === slideIndex ? faCircle : faCirlceReg}
							className={index === slideIndex ? style.selectedDot : style.regularDot}
						/>
					</span>
				))}
			</div>
		</div>
	)
}
