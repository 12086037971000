import { exhaustive } from "exhaustive"
import { isEmpty } from "lodash"
import { FC } from "react"
import { v4 } from "uuid"
import { BranchTargetKeyEnum } from "../../../Client/articleTrees/ArticleTreeDataModel"
import { PreferredVATRenderPolicy } from "../../../Client/consumerCatalogConfigurationTypes"
import orderConfirmPriceSummaryStyle from "../../../Orders/Components/OrderConfirmPriceSummarySection/OrderConfirmPriceSummarySection.module.css"
import { AnonymousOrder } from "../../../Orders/OrderCompleted/AnonymousOrderResponse"
import { productServiceUnitToHumanText } from "../../../Orders/OrderContainer/Logic"
import { Annotation } from "../../../Shared/Annotation/Annotation"
import { getKey } from "../../../Shared/getKey"
import { lets } from "../../../Shared/lets"
import { currencyFormatter } from "../../../Shared/numberFormatter"
import { DiscountEntry } from "../../../Shared/PriceDisplay/DiscountEntry"
import { when } from "../../../Shared/when"
import { ArticleType, GetOrder } from "../../CustomerPortalOrders/CustomerPortalOrders"
import { DiscountRefExplanationTooltip } from "../ExplainDiscount/ExplainDiscount"
import style from "./OrderPriceDetails.module.css"

type Props = {
	order: GetOrder | AnonymousOrder
	preferredVATRenderPolicy?: PreferredVATRenderPolicy
}

export const OrderPriceDetails: FC<Props> = ({ order, preferredVATRenderPolicy: extPreferredVATRenderPolicy }) => {
	let preferredVATRenderPolicy: PreferredVATRenderPolicy = getVatPolicy(extPreferredVATRenderPolicy)

	function getVatPolicy(policy?: PreferredVATRenderPolicy): PreferredVATRenderPolicy {
		if (policy) {
			return policy
		}

		if (order.consumerGroupSettings) {
			return exhaustive(order.consumerGroupSettings, "type", {
				Contract: () => {
					return PreferredVATRenderPolicy.IncludeVAT
				},
				Prepay: (it) => {
					return it.preferredVATRenderPolicy
				},
				Postpay: (it) => {
					return it.preferredVATRenderPolicy
				},
			})
		}

		return PreferredVATRenderPolicy.IncludeVAT
	}

	let totalPriceWithoutTax: number = 0
	let totalTaxAmount: number = 0
	function getOrderPriceSummaryTable(): JSX.Element | null {
		const productPriceElements: (JSX.Element | null)[] = order.products.map((product) => {
			const article = product.productArticles?.find((x) => x.type === ArticleType.Product)

			if (!article) {
				return null
			}

			totalPriceWithoutTax += article.price * product.amount
			totalTaxAmount += article.price * article.taxPercentage * product.amount

			let taxPercentage: number | null = null
			let theNumber: number = when(preferredVATRenderPolicy, {
				[PreferredVATRenderPolicy.ExcludeVAT]: () => {
					return article.price * product.amount
				},
				[PreferredVATRenderPolicy.IncludeVAT]: () => {
					taxPercentage = article.taxPercentage
					return article.price * (1 + article.taxPercentage) * product.amount
				},
				[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
					taxPercentage = article.taxPercentage
					return article.price * (1 + article.taxPercentage) * product.amount
				},
			})

			const price = currencyFormatter(theNumber)

			const key = getKey(product)
			const priceId = "productPrice-" + key

			return (
				<div key={key} className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryRow}>
					<span>
						{product.amount} {productServiceUnitToHumanText(product.unit)}{" "}
						{"productName" in product ? product.productName : product.name}
						{"productName" in product
							? product?.wasteName
								? " - " + product?.wasteName
								: null
							: product?.wasteType?.name
							? " - " + product?.wasteType?.name
							: null}
					</span>
					<span id={priceId}>
						{price}
						{lets(product.discountDescriptionRef, (id) => (
							<>
								<DiscountRefExplanationTooltip
									discountDescriptionId={id}
									taxPercentage={taxPercentage}
									anchorSelect={`#${priceId}`}
									order={order}
								/>
								<Annotation annotationClassName={style.annotation}>R</Annotation>
							</>
						))}
					</span>
				</div>
			)
		})

		const discounts = discountElements()

		return (
			<div key={v4()} className={style.orderItemPriceSummaryWrapper}>
				{productPriceElements}
				{transportPriceElements()}
				{dateSlotPriceElement()}
				{discounts != null ? (
					<>
						<hr />
						{discounts}
					</>
				) : null}
				<hr />
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryTotal}>
					<span>Totalt</span>
					<span>
						{when(preferredVATRenderPolicy, {
							[PreferredVATRenderPolicy.ExcludeVAT]: () => {
								return currencyFormatter(totalPriceWithoutTax)
							},
							[PreferredVATRenderPolicy.IncludeVAT]: () => {
								return currencyFormatter(totalPriceWithoutTax + totalTaxAmount)
							},
							[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
								return currencyFormatter(totalPriceWithoutTax + totalTaxAmount)
							},
						})}
					</span>
				</div>
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryTotalSubRow}>
					{when(preferredVATRenderPolicy, {
						[PreferredVATRenderPolicy.ExcludeVAT]: () => {
							return (
								<>
									<span>Priser är exklusive moms</span>
									<span></span>
								</>
							)
						},
						[PreferredVATRenderPolicy.IncludeVAT]: () => {
							return (
								<>
									<span>Varav moms</span>
									<span>{currencyFormatter(totalTaxAmount)}</span>
								</>
							)
						},
						[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
							return (
								<>
									<span>Varav moms</span>
									<span>{currencyFormatter(totalTaxAmount)}</span>
								</>
							)
						},
					})}
				</div>
			</div>
		)
	}

	function transportPriceElements(): JSX.Element | null {
		const transportationArticles = order.orderArticles?.filter((x) => x.type === ArticleType.Transport)

		if (!transportationArticles || transportationArticles.length === 0) {
			return (
				<div key={v4()} className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryRow}>
					<div style={{ wordBreak: "break-word", hyphens: "auto" }}>
						Transportavgift -{" "}
						<strong>
							Inga priser kunde hittas för transportmetod eller zon, extra avgifter kan tillkomma i efterhand
						</strong>
					</div>
				</div>
			)
		}

		let ret: JSX.Element[] = []
		if (!order.transportZoneName) {
			ret.push(
				<div key={v4()} className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryRow}>
					<div style={{ wordBreak: "break-word", hyphens: "auto" }}>
						Transportzonavgift - <strong>Ej identifierbar zon, extra pris kan tillkomma</strong>
					</div>
					<div></div>
				</div>,
			)
		}

		transportationArticles.forEach((article) => {
			let theNumber: number = when(preferredVATRenderPolicy, {
				[PreferredVATRenderPolicy.ExcludeVAT]: () => {
					return article.price
				},
				[PreferredVATRenderPolicy.IncludeVAT]: () => {
					return article.price * (1 + article.taxPercentage)
				},
				[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
					return article.price * (1 + article.taxPercentage)
				},
			})

			totalPriceWithoutTax += article.price
			totalTaxAmount += article.price * article.taxPercentage

			const articlePath = article.articlePath
			const treeName = articlePath.find((x) => x.targetKey === "Tree")?.name
			// Give default value in case we can't resolve actual type
			let info = <>{"Transportavgift"}</>

			let zoneIdIndex = articlePath.findIndex((x) => x.targetKey === BranchTargetKeyEnum.Zone)
			let transportationIdIndex = articlePath.findIndex((x) => x.targetKey === BranchTargetKeyEnum.Transportation)

			// try to show the name of the transport method or transport zone, depending on what type we find last
			let zoneName, transportationName
			if (zoneIdIndex > -1 && transportationIdIndex === -1) {
				// only zone found
				zoneName = articlePath[zoneIdIndex]?.name
			} else if (zoneIdIndex === -1 && transportationIdIndex > -1) {
				// only transportation found
				transportationName = articlePath[transportationIdIndex]?.name
			} else if (zoneIdIndex > -1 && transportationIdIndex > -1) {
				// both found
				if (zoneIdIndex > transportationIdIndex) {
					// zone is later than transport, use it
					zoneName = articlePath[zoneIdIndex]?.name
				} else if (transportationIdIndex > zoneIdIndex) {
					// transportation is later than zone, use it
					transportationName = articlePath[transportationIdIndex]?.name
				}
			} else {
				// none found
			}

			if (zoneName) {
				info = (
					<>
						{treeName} - <strong>{zoneName}</strong>
					</>
				)
			} else if (transportationName) {
				info = (
					<>
						{treeName} - <strong>{transportationName}</strong>
					</>
				)
			}

			ret.push(
				<div key={v4()} className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryRow}>
					<div style={{ wordBreak: "break-word", hyphens: "auto" }}>{info}</div>
					<div>{currencyFormatter(theNumber)}</div>
				</div>,
			)
		})

		return <>{ret}</>
	}

	function dateSlotPriceElement(): JSX.Element | null {
		if (!order.datetime.dateSlot) {
			return null
		}

		let dateSlotPriceElement: JSX.Element

		const dateSlotPriceArticle = order.orderArticles?.find((x) => x.type === ArticleType.DateSlot)

		if (dateSlotPriceArticle) {
			let theNumber: number = when(preferredVATRenderPolicy, {
				[PreferredVATRenderPolicy.ExcludeVAT]: () => {
					return dateSlotPriceArticle.price
				},
				[PreferredVATRenderPolicy.IncludeVAT]: () => {
					return dateSlotPriceArticle.price * (1 + dateSlotPriceArticle.taxPercentage)
				},
				[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
					return dateSlotPriceArticle.price * (1 + dateSlotPriceArticle.taxPercentage)
				},
			})

			totalPriceWithoutTax += dateSlotPriceArticle.price
			totalTaxAmount += dateSlotPriceArticle.price * dateSlotPriceArticle.taxPercentage
			dateSlotPriceElement = (
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}>
					<div>
						Datumavgift - <strong>{order.datetime.dateSlot.name}</strong>
					</div>
					<div>{currencyFormatter(theNumber)}</div>
				</div>
			)
		} else {
			dateSlotPriceElement = (
				<div className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryRow}>
					<div>
						Datumavgift - <strong>{order.datetime.dateSlot.name} - Pris ej identifierat</strong>
					</div>
					<div></div>
				</div>
			)
		}

		return dateSlotPriceElement
	}

	function discountElements() {
		const discountArticles = order.orderArticles?.filter((x) => x.type === ArticleType.Discount)

		if (isEmpty(discountArticles)) {
			return null
		}

		return discountArticles?.map((discountArticle) => {
			let renderVat = when(preferredVATRenderPolicy, {
				[PreferredVATRenderPolicy.ExcludeVAT]: () => {
					return false
				},
				[PreferredVATRenderPolicy.IncludeVAT]: () => {
					return true
				},
				[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
					return true
				},
			})

			totalPriceWithoutTax += discountArticle.price
			totalTaxAmount += discountArticle.price * discountArticle.taxPercentage
			return (
				<DiscountEntry
					renderVAT={renderVat}
					discountArticle={discountArticle}
					className={orderConfirmPriceSummaryStyle.orderItemPriceSummaryZoneRow}
					key={getKey(discountArticle)}
				/>
			)
		})
	}

	return (
		<>
			{lets(order.discountCode, (it) => {
				return (
					<div key={v4()} className={style.orderItemPriceSummaryWrapper}>
						<strong>Använd rabattkod: {it.identifier}</strong>
						<div>{it.label}</div>
					</div>
				)
			})}

			<div>{getOrderPriceSummaryTable()}</div>
		</>
	)
}
