import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import log, { Logger, LogLevelDesc } from "loglevel"
import { useEffect, useState } from "react"
import { remoteLogPusher } from "./getLogger"

function getAllLoggers() {
	return [log.default, ...Object.entries(log.getLoggers()).map(([, logger]) => logger)]
}

export function LoglevelSettings() {
	const [show, setShow] = useState(false)
	const [logPushQueue, setLogPushQueue] = useState(0)
	const [logCount, setLogCount] = useState(0)

	useEffect(() => {
		let interval = setInterval(() => {
			setLogPushQueue(remoteLogPusher.queueLength)
			setLogCount(remoteLogPusher.count)
		}, 500)

		return () => {
			clearInterval(interval)
		}
	}, [])

	function getLevelName(level: number) {
		const found = Object.entries(log.levels).find(([, levelValue]) => {
			return levelValue === level
		})
		if (found != null) {
			const [levelName] = found
			return levelName
		}
		return null
	}

	function renderLogger(title: string, logger: Logger) {
		return (
			<>
				<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", margin: "6px" }} key={title}>
					{title}
					<select
						defaultValue={getLevelName(logger.getLevel()) ?? undefined}
						onChange={(event) => {
							logger.setLevel(event.target.value as LogLevelDesc, false)
						}}>
						{Object.entries(logger.levels).map(([level, value]) => (
							<option key={level} value={level}>
								{level}
							</option>
						))}
					</select>
				</div>
			</>
		)
	}

	function persistAllLogLevels() {
		getAllLoggers().forEach((logger) => logger.setLevel(logger.getLevel(), true))
		setShow(false)
	}

	function resetAllLogLevels() {
		getAllLoggers().forEach((logger) => logger.resetLevel())
		setShow(false)
	}

	return (
		<div
			style={{
				position: "fixed",
				top: "0",
				left: "50%",
				transform: "translate(-50%, 0)",
				zIndex: 9999,
				backgroundColor: "#eeeeee",
				border: "1px solid #ddd",
				borderRadius: "0 0 5px 5px",
				padding: "0",
				margin: "0",
				maxHeight: show ? "90vh" : "30px",
				maxWidth: show ? "600px" : "30px",
				overflow: show ? "auto" : "hidden",
				transition: "all 0.2s",
			}}>
			<div
				onClick={() => setShow(!show)}
				style={{
					width: "100%",
					minWidth: "20px",
					margin: "0 auto",
				}}>
				<FontAwesomeIcon
					icon={faChevronRight}
					style={{
						display: "block",
						height: "16px",
						padding: "0",
						transform: show ? "rotate(-90deg)" : "rotate(90deg)",
						margin: "0 auto",
						transition: "all 0.5s",
					}}
				/>
			</div>
			{show ? (
				<>
					<div>
						<h1 style={{ fontSize: "18px" }}>
							Loggers and levels
							<span style={{ fontSize: "14px", float: "inline-end" }}>
								(Queue: {logPushQueue}, Count: {logCount})
							</span>
						</h1>
						{renderLogger("ROOT", log)}
						{Object.entries(log.getLoggers()).map(([name, logger]) => {
							return renderLogger(name, logger)
						})}
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<button type={"button"} onClick={() => persistAllLogLevels()}>
							Persist
						</button>
						<button type={"button"} onClick={() => resetAllLogLevels()}>
							Reset
						</button>
						<button type={"button"} onClick={() => setShow(false)}>
							Stäng
						</button>
					</div>
				</>
			) : (
				<></>
			)}
		</div>
	)
}
