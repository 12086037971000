import { DynamicAgreement, DynamicAgreementTypes } from "Client/GetClientResponse"
import { exhaustive } from "exhaustive"
import { ToggleSwitch } from "../Form/ToggleSwitch/ToggleSwitch"
import style from "./OrderConfirmCheckout.module.css"

type LicenseToggleProps = {
	title: string
	url: string
	onCheck: (check: boolean) => void
}

export function LicenseToggle({ title, url, onCheck }: LicenseToggleProps) {
	return (
		<div className={style.licenseToggleWrapper}>
			<ToggleSwitch
				onChange={(x) => {
					onCheck(x.target.checked)
				}}
			/>
			<span className={style.licenseToggleUrl}>
				<a className={style.termsLink} href={url} target="_blank" rel="noopener noreferrer">
					{title}
				</a>
			</span>
		</div>
	)
}

type AgreementLicenseToggleProps = {
	agreement: DynamicAgreement
	onCheck: (key: string, check: boolean) => void
}
export function AgreementLicenseToggle({ agreement, onCheck }: AgreementLicenseToggleProps) {
	function check(check: boolean) {
		const checkKey = exhaustive(agreement, "type", {
			[DynamicAgreementTypes.URL]: (it) => it.title,
			[DynamicAgreementTypes.Text]: (it) => it.text,
		})
		onCheck(checkKey, check)
	}
	return (
		<div className={style.licenseToggleWrapper}>
			<ToggleSwitch
				onChange={(x) => {
					check(x.target.checked)
				}}
			/>
			{exhaustive(agreement, "type", {
				[DynamicAgreementTypes.URL]: (it) => (
					<span className={style.licenseToggleUrl}>
						<a className={style.termsLink} href={it.url} target="_blank" rel="noopener noreferrer">
							{it.title}
						</a>
					</span>
				),
				[DynamicAgreementTypes.Text]: (it) => <span className={style.licenseToggleText}>{it.text}</span>,
			})}
		</div>
	)
}
