import { ButtonHTMLAttributes, FC, PropsWithChildren, useRef } from "react"
import { cls } from "Shared/cls"
import { v4 } from "uuid"
import { StdTooltip } from "../../../../Shared/StdTooltip/StdTooltip"
import style from "./Buttons.module.css"

export type BtnStyles = "Finalize" | "Accent" | "Deny" | "Accept" | "Done"

export const styleMap: Record<BtnStyles, string> = {
	Finalize: style.finalizeButton,
	Accent: style.accentButton,
	Deny: style.denyButton,
	Accept: style.acceptButton,
	Done: style.doneButton,
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
	PropsWithChildren & {
		className?: string
		btnStyle?: BtnStyles | undefined
		inProgress?: boolean
		disabledTooltip?: string
	}

export const Button: FC<ButtonProps> = ({
	onClick,
	type = "button",
	className,
	disabled = false,
	inProgress = false,
	btnStyle,
	disabledTooltip,
	children,
	...props
}) => {
	const newClassName = cls(
		className,
		btnStyle ? styleMap[btnStyle] : undefined,
		style.baseButton,
		{ [style.disabled]: disabled ?? false },
		{ [style.inProgress]: inProgress ?? false },
	)
	const tooltipId = useRef(v4())
	return (
		<>
			{disabledTooltip && disabled ? <StdTooltip id={tooltipId.current} openOnClick={true} /> : null}
			<button
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						;(event.target as HTMLButtonElement).click()
					}
				}}
				onClick={(e) => {
					if (onClick) {
						onClick(e)
					}
				}}
				className={newClassName}
				disabled={disabled || inProgress}
				type={type}
				{...props}>
				{disabledTooltip && disabled ? (
					<span data-tooltip-id={tooltipId.current} data-tooltip-content={disabledTooltip}>
						{children}
					</span>
				) : (
					children
				)}
			</button>
		</>
	)
}

export const AccentButton: FC<ButtonProps> = ({ className, ...props }) => {
	return <Button className={className} btnStyle="Accent" {...props} />
}

export const AcceptButton: FC<ButtonProps> = ({ className, ...props }) => {
	return <Button className={className} btnStyle="Accept" {...props} />
}

export const DenyButton: FC<ButtonProps> = ({ className, ...props }) => {
	return <Button className={className} btnStyle="Deny" {...props} />
}

export const DoneButton: FC<ButtonProps> = ({ className, ...props }) => {
	return <Button className={className} btnStyle="Done" {...props} />
}

export const FinalizeButton: FC<ButtonProps> = ({ className, ...props }) => {
	return <Button className={className} btnStyle="Finalize" {...props} />
}
