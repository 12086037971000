import { PilNerIcon as DownArrow, PilUppIcon as UpArrow } from "../../../Icons/Icon"
import { cls } from "../../../Shared/cls"
import { NotificationState, OrderState } from "../../OrderCollection"
import style from "./Header.module.css"
import { orderStateTranslation } from "./Helpers"

type IconColorChooserProps = {
	sent: boolean
	shown: boolean
}
const IconWithColor = ({ sent, shown }: IconColorChooserProps) => {
	const styling = sent ? `${style.icon} ${style.iconWhite}` : `${style.icon} ${style.iconNormal}`
	return shown ? <UpArrow iconClassName={styling} /> : <DownArrow iconClassName={styling} />
}

type Props = {
	orderState: OrderState
	emailState?: NotificationState
	orderNumber: number
	shown: boolean
	showSwapper: () => void
}
export const Header = ({ orderState, emailState, orderNumber, shown, showSwapper }: Props) => {
	const styling = shown ? style.orderHeader : `${style.orderHeader} ${style.compressedOrderHeader}`
	const isSent = emailState === NotificationState.Sent

	return (
		<div
			className={cls(
				// style.orderHeader,
				styling,
				{ [style.accepted]: orderState === OrderState.Accepted },
				{ [style.declined]: orderState === OrderState.Declined },
				{ [style.sent]: isSent },
			)}
			onClick={() => {
				if (orderState !== OrderState.Created) {
					showSwapper()
				}
			}}>
			<div className={style.orderHeaderLeft}>
				<span>Ordernummer</span>
				<span className={style.orderNumber}># {orderNumber}</span>
			</div>
			<div className={style.orderHeaderRight}>
				{/* {cachedOrder.state !== OrderState.Created ? ( */}
				{isSent ? (
					<div
						className={cls(
							style.orderHeaderRightInfo,
							{ [style.accepted]: orderState === OrderState.Accepted },
							{ [style.declined]: orderState === OrderState.Declined },
						)}>
						<span>{orderStateTranslation(orderState)}</span>
					</div>
				) : null}

				{orderState !== OrderState.Created ? <IconWithColor shown={shown} sent={isSent} /> : null}
			</div>
		</div>
	)
}
