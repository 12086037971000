let curId = 1
const ids = new WeakMap<any, string>()

/**
 * Global helper that will generate a unique (React) key string for an object.
 */
export function getKey(object: any) {
	let id = ids.get(object)
	if (null == id) {
		id = String(curId++)
		ids.set(object, id)
	}

	return id
}
