import { Features } from "Client/FeatureTypes"
import { Widget } from "Shared/Widget/Widgets"
import { ApiArticleTree } from "./articleTrees/ArticleTreeDataModel"
import { ProductDefinitionsByCategories } from "./ProductDefinitionsByCategories"

export type GetClientResponse = {
	client: Client
	links: Links | undefined
	dynamicAgreements: DynamicClientAgreements | undefined
	productDefinitions: ProductDefinitionsByCategories
	features: Features
	articleTrees: ApiArticleTree[]
	welcomeWidget?: Widget
}

export type Client = {
	identifier: string
	branding: ClientBranding
	clientInfo: ClientInfo
	modes: ClientModes[]
}

export type ClientInfo = {
	organizationNumber: string
	clientName: string
	homepage: string
	contact: ContactInfo
	licenseAgreement: string
	address?: ClientAddress
}

export type ClientAddress = {
	street?: string
	city?: string
	zipCode?: string
}

export type ContactInfo = {
	name: string
	phoneNumber?: string
	email: string
	orderEmail: string
}

export type ClientBranding = {
	colors: Colors
	images: Images
	logoHeight: string
}

export type Colors = {
	header?: string
	headerDivider?: string
	headerShadow?: string
	headerIcon?: string
	headerChosenColor?: string
	headerTextColor?: string
	headerHoverColor?: string
	headerNotification?: string
	headerNotificationInside?: string
	headerButtonColor?: string
	headerButtonInsideColor?: string
	headerButtonInsideHoverColor?: string
	headerDropdownMain?: string
	headerDropdownMainText?: string
	headerDropdownHover?: string
	headerDropdownChosen?: string
	headerDropdownChosenInside?: string
	headerDropdownSecondText?: string
	headerDropdownAccent?: string
	headerDropdownAccentInside?: string
	headerDropdownAccentHover?: string
	headerDropdownAccentHoverInside?: string
	headerDropdownSelectedColor?: string
	headerDropdownSelectedColorInside?: string
	headerDropdownSelectedHoverColor?: string
	headerDropdownSelectedHoverColorInside?: string
	chatNotificationColor?: string
	chatNotificationInsideColor?: string
	chatNotificationHoverColor?: string
	footer?: string
	footerLogo?: string
	accent?: string
	accentHover?: string
	accentHoverInside?: string
	accentInside?: string
	finalize?: string
	finalizeHover?: string
	finalizeHoverInside?: string
	finalizeInside?: string
	disable?: string
	disableInside?: string
	sectionValueBox?: string
	sectionValueBoxHover?: string
	sectionValueBoxInside?: string
	sectionValueBoxHoverInside?: string
	selectedSectionValueBox?: string
	selectedSectionValueBoxHover?: string
	selectedSectionValueBoxInside?: string
	confirmedAmount?: string
	confirmedAmountInside?: string
	sectionBackground?: string
	selectedSectionBackground?: string
	sectionIcon?: string
	sectionBackgroundText?: string
	moduleBox?: string
	moduleBoxIcon?: string
	moduleBoxText?: string
	moduleBoxDiscountColor?: string
	inputField?: string
	inputFieldInside?: string
	mainBackground?: string
	mainBackgroundText?: string
	hoverBorder?: string
	chosenBorder?: string
	invalid?: string
	valid?: string
	productBase?: string
	productSecondary?: string
	productDetails?: string
	clientNotificationColor?: string
	clientNotificationInsideColor?: string
	filterButtonColor?: string
	filterButtonHoverColor?: string
	filterButtonInsideColor?: string
	filterButtonHoverInsideColor?: string
	sectionHeaderColor?: string
	sectionHeaderInsideColor?: string
	sectionBorderColor?: string
	sectionDiscountColor?: string
	accentSecondaryColor?: string
	accentSecondaryHoverColor?: string
	accentSecondaryInsideColor?: string
	statusNotManagedColor?: string
	statusNotManagedInsideColor?: string
	statusAcceptedColor?: string
	statusAcceptedInsideColor?: string
	statusDeniedColor?: string
	statusDeniedInsideColor?: string
	statusDoneColor?: string
	statusDoneInsideColor?: string
	sidebarColor?: string
	sidebarChosenColor?: string
	sidebarIconColor?: string
	sidebarTextColor?: string
	sidebarLogoColor?: string
	sidebarHoverColor?: string
	inputFieldColorMback?: string
	deliveryColor?: string
	deliveryMeasurementColor?: string
	deliveryMeasurementInsideColor?: string
	underHeaderColor?: string
	underHeaderInsideColor?: string
	underHeaderChosenColor?: string
	underHeaderChosenInsideColor?: string
	underHeaderHoverColor?: string
	underHeaderHoverInsideColor?: string
	sectionHoverColor?: string
	basketMainColor?: string
	basketMainTextColor?: string
	basketMainIconColor?: string
	basketBackgroundColor?: string
	basketBackgroundTextColor?: string
	basketBackgroundIconColor?: string
	basketModuleColor?: string
	basketModuleTextColor?: string
	basketModuleIconColor?: string
	basketSectionColor?: string
	basketSectionTextColor?: string
	basketSectionIconColor?: string
	basketFinalizeColor?: string
	basketFinalizeInsideColor?: string
	basketFinalizeIconColor?: string
	basketFinalizeAccentColor?: string
	basketFinalizeAccentInsideColor?: string
	moduleBubbleConsumerColor?: string
	moduleBubbleConsumerTextColor?: string
	moduleBubbleClientColor?: string
	moduleBubbleClientTextColor?: string
	imageScrollAccentInsideColor?: string
	imageScrollAccentHoverColor?: string
	imageScrollAccentHoverInsideColor?: string
	collapsibleTitleBackgroundColor?: string
	collapsibleTitleColor?: string
	collapsibleContentBackgroundColor?: string
	collapsibleContentColor?: string
	toggleBackgroundColor?: string
	toggleBackgroundInsideColor?: string
	toggleSelectedColor?: string
	toggleSelectedInsideColor?: string
	productImageBackgroundColor?: string
	productSectionColor?: string
	productSectionTextColor?: string
	productBorderColor?: string
}

export type Images = {
	logos: { [key: number]: string }
	icons: { [key: number]: string }
}

export enum ClientModes {
	Live = "Live",
	Demo = "Demo",
	Dead = "Dead",
	Removed = "Removed",
	Test = "Test",
}

export type Links = {
	footerLinks: UrlLink[]
}

export type UrlLink = {
	name: string
	url: string
}

export type DynamicClientAgreements = {
	agreements?: DynamicAgreement[]
}

export type DynamicAgreement = DynamicUrlAgreement | TextAgreement
export enum DynamicAgreementTypes {
	URL = "URL",
	Text = "Text",
}
export type DynamicUrlAgreement = {
	type: DynamicAgreementTypes.URL
	title: string
	url: string
}

export type TextAgreement = {
	type: DynamicAgreementTypes.Text
	text: string
}
