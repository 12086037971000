import style from "./DemoStyle.module.css"

import _ from "lodash"
import { cls } from "../Shared/cls"
import { ReactComponent as StarFilledSvg } from "./Images/star-filled.svg"

type Props = {
	starCount: number
	value: number
	onChange: (value: number) => void
}

export function RatingStars({ starCount, value, onChange }: Props) {
	return (
		<div className={style.ratingStars}>
			{_.range(starCount).map((i) => {
				return (
					<StarFilledSvg
						className={cls(style.star, { [style.starFilled]: i < value })}
						onClick={() => onChange(i + 1)}
					/>
				)
			})}
		</div>
	)
}
