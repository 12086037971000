import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { isString, sum } from "lodash"
import { FC, useState } from "react"
import { v4 } from "uuid"
import { BranchTargetKeyEnum } from "../../../Client/articleTrees/ArticleTreeDataModel"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { useConsumerCatalog } from "../../../Client/ConsumerCatalogContext"
import { findDiscountDescription } from "../../../CustomerPortal/OrderDetails/ExplainDiscount/ExplainDiscount"
import { DatumIcon, ListaOrdersIcon } from "../../../Icons/Icon"
import { getKey } from "../../../Shared/getKey"
import { lets } from "../../../Shared/lets"
import { currencyFormatter } from "../../../Shared/numberFormatter"
import { DiscountEntry } from "../../../Shared/PriceDisplay/DiscountEntry"
import { Price } from "../../../Shared/PriceDisplay/Price"
import { OrderItem, OrderItemProduct } from "../../order-data-model"
import { getAmountOfArticlesInOrderItemProduct, productServiceUnitToHumanText } from "../../OrderContainer/Logic"
import { OrderItemTotalPrices } from "../../OrderContainer/OrderContainer"
import { contactInformationOneLineText } from "../../OrderContainer/SubComponents/BasketSection"
import { orderItemDateElementContent } from "../OrderConfirmCheckout/OrderConfirmCheckout"
import orderConfirmCheckoutStyle from "../OrderConfirmCheckout/OrderConfirmCheckout.module.css"
import { Mbt } from "../Text/Mbt/Mbt"
import { MbtH5 } from "../Text/MbtH5/MbtH5"
import style from "./OrderConfirmPriceSummarySection.module.css"

type Props = {
	orderItems: OrderItem[]
	totalPrices: OrderItemTotalPrices
}
export const OrderConfirmPriceSummarySection: FC<Props> = ({ orderItems, totalPrices }) => {
	const client = useClient()
	const consumerCatalog = useConsumerCatalog()

	const [showPriceSummarySection, setShowPriceSummarySection] = useState<boolean>(() => {
		return orderItems.length === 1
	})

	function getOrderItemPriceSummaryTable(orderItem: OrderItem): JSX.Element | null {
		if (!consumerCatalog.pricesEnabled) {
			return null
		}

		const productPriceElements: (JSX.Element | null)[] = (orderItem.products as OrderItemProduct[]).map((product) => {
			const article = product?.articles?.getProductArticle()

			if (!article) {
				return null
			}

			let discountDescription = lets(product.discountDescriptionRef, (it) => {
				return findDiscountDescription(it, orderItem.articles?.allArticles || [])
			})

			let amount = getAmountOfArticlesInOrderItemProduct(product, client)

			let category = client.categories[product.category]

			let unit =
				category &&
				exhaustive(category, "type", {
					WasteCategory: (category) => {
						if (product.serviceId) {
							return category?.services?.[product.serviceId]?.unit
						}
						return null
					},
					GoodsCategory: () => {
						if (product.packagingMethod) {
							return client.possiblePackagingMethods?.[product.packagingMethod.id]?.productUnit
						}
						return null
					},
				})

			return (
				<div key={v4()} className={style.orderItemPriceSummaryRow}>
					<span>
						{amount} {productServiceUnitToHumanText(unit)} {product.name}
						{product?.wasteType?.wasteTypeId
							? " - " + (client.possibleWasteTypes[product.wasteType.wasteTypeId]?.name || "")
							: null}
						{product.packagingMethod
							? " - " + (client.possiblePackagingMethods[product.packagingMethod.id]?.name || "")
							: null}
					</span>
					<Price priced={article} discountDescription={discountDescription} />
				</div>
			)
		})

		let totalPrice = totalPrices[orderItem.id]
		const totalDiscount =
			(consumerCatalog.renderVAT
				? lets(totalPrice, (it) => it.discount + it.taxDiscount)
				: lets(totalPrice, (it) => it.discount)) ?? 0
		return (
			<div key={v4()} className={style.orderItemPriceSummaryWrapper}>
				<div className={orderConfirmCheckoutStyle.projectInfoBox} style={{ padding: 0, marginBottom: 0 }}>
					<MbtH5>{orderItem.project.marking || orderItem.project.street}</MbtH5>
					<Mbt>
						{orderItem.project.street}, {orderItem.project.zipcode ? orderItem.project.zipcode + " " : ""}
						{orderItem.project.city}
					</Mbt>
					<Mbt>{contactInformationOneLineText(orderItem.project, true)}</Mbt>
					<br />
					<div className={orderConfirmCheckoutStyle.dateAndTimeInfoWrapper}>
						<div className={orderConfirmCheckoutStyle.dateAndTimeInfoIcon}>
							<span className={orderConfirmCheckoutStyle.dateAndTimeInfoIcon}>
								<DatumIcon size={18} />
							</span>
							<div className={orderConfirmCheckoutStyle.dateAndTimeInfoText}>
								{orderItemDateElementContent(client, orderItem.date)}
							</div>
						</div>
						<div>
							<span className={orderConfirmCheckoutStyle.dateAndTimeInfoIcon}>
								<FontAwesomeIcon icon={faClock} />
							</span>
							<p className={orderConfirmCheckoutStyle.dateAndTimeInfoText}>
								{orderItem.time
									? orderItem.time.specificTime
										? orderItem.time.timeValue
										: orderItem.time.timeName
									: null}
							</p>
						</div>
					</div>
				</div>
				<hr />
				<div className={style.orderItemPriceSummaryHeader}>Varor</div>
				{productPriceElements}
				{orderItemTransportationPriceElements(orderItem)}
				{orderItemDateSlotPriceElement(orderItem)}
				{totalDiscount < 0 ? (
					<>
						<hr />
						<div className={style.orderItemPriceSummaryHeader}>Rabattsats</div>
						{orderItemDiscountElements(orderItem)}
					</>
				) : null}
				<hr />
				{totalPrice == null ? (
					<div className={style.orderItemPriceSummaryTotal}>
						Inget totalpris, då inte alla artiklar är prissatta!
					</div>
				) : (
					<>
						<div className={style.orderItemPriceSummaryTotal}>
							<span style={{ flexBasis: "70px" }}>Totalt</span>
							<span>
								{consumerCatalog.renderVAT
									? currencyFormatter(totalPrice.price + totalPrice.tax)
									: currencyFormatter(totalPrice.price)}
							</span>
						</div>
						<div className={style.orderItemPriceSummaryTotalSubRow}>
							{consumerCatalog.renderVAT ? (
								<>
									<span style={{ flexBasis: "110px" }}>Varav moms</span>
									<span>{currencyFormatter(totalPrice.tax)}</span>
								</>
							) : (
								<>
									<span>Priser är exklusive moms</span>
									<span></span>
								</>
							)}
						</div>
					</>
				)}
			</div>
		)
	}

	function orderItemTransportationPriceElements(orderItem: OrderItem): JSX.Element | null {
		const transportationArticlesAndTrees = orderItem?.articles?.getTransportationArticles()

		if (!transportationArticlesAndTrees || transportationArticlesAndTrees.length === 0) {
			return (
				<div className={style.orderItemPriceSummaryZoneRow} style={{ marginTop: "5px" }}>
					<div style={{ wordBreak: "break-word", hyphens: "auto" }}>
						Transportavgift -{" "}
						<strong>
							Inga priser kunde hittas för transportmetod eller zon, extra avgifter kan tillkomma i efterhand
						</strong>
					</div>
				</div>
			)
		}

		let ret: JSX.Element[] = []
		if (!orderItem.zoneId || !client.transportZones[orderItem.zoneId]) {
			ret.push(
				<div key={v4()} className={style.orderItemPriceSummaryZoneRow} style={{ marginTop: "5px" }}>
					<div style={{ wordBreak: "break-word", hyphens: "auto" }}>
						Transportzonavgift - <strong>Ej identifierbar zon, extra pris kan tillkomma</strong>
					</div>
					<div></div>
				</div>,
			)
		}

		transportationArticlesAndTrees.forEach((articleAndTree) => {
			let price

			if (consumerCatalog.renderVAT) {
				price = articleAndTree.article.price * (1 + articleAndTree.article.taxPercentage)
			} else {
				price = articleAndTree.article.price
			}

			// Give default value in case we can't resolve actual type
			let info = <>{articleAndTree.tree.name}</>
			const articlePath = articleAndTree.article.articlePath

			let zoneIdIndex = articlePath.findIndex((x) => x.targetKey === BranchTargetKeyEnum.Zone)
			let transportationIdIndex = articlePath.findIndex((x) => x.targetKey === BranchTargetKeyEnum.Transportation)

			// try to show the name of the transport method or transport zone, depending on what type we find last
			let zoneId, transportationId
			if (zoneIdIndex > -1 && transportationIdIndex === -1) {
				// only zone found
				zoneId = articlePath[zoneIdIndex]?.targetValue
			} else if (zoneIdIndex === -1 && transportationIdIndex > -1) {
				// only transportation found
				transportationId = articlePath[transportationIdIndex]?.targetValue
			} else if (zoneIdIndex > -1 && transportationIdIndex > -1) {
				// both found
				if (zoneIdIndex > transportationIdIndex) {
					// zone is later than transport, use it
					zoneId = articlePath[zoneIdIndex]?.targetValue
				} else if (transportationIdIndex > zoneIdIndex) {
					// transportation is later than zone, use it
					transportationId = articlePath[transportationIdIndex]?.targetValue
				}
			} else {
				// none found
			}

			if (zoneId) {
				const zone = client.transportZones[zoneId]
				if (zone) {
					info = (
						<>
							{articleAndTree.tree.name} - <strong>{zone.name}</strong>
						</>
					)
				}
			} else if (transportationId) {
				const transport = client.possibleTransportations[transportationId]

				if (transport) {
					info = (
						<>
							{articleAndTree.tree.name} - <strong>{transport.name}</strong>
						</>
					)
				}
			}
			ret.push(
				<div key={v4()} className={style.orderItemPriceSummaryZoneRow} style={{ marginTop: "5px" }}>
					<div style={{ wordBreak: "break-word", hyphens: "auto" }}>{info}</div>
					<div>{currencyFormatter(price)}</div>
				</div>,
			)
		})

		return <>{ret}</>
	}

	function orderItemDateSlotPriceElement(orderItem: OrderItem): JSX.Element | null {
		if (!orderItem.date || isString(orderItem.date)) {
			// If the date is a string, it's not a dateSlot, and as such this order item cannot have a date price, so we skip it
			return null
		}

		const article = orderItem?.articles?.getDateSlotArticle()

		if (!article) {
			return null
		}

		return (
			<div className={style.orderItemPriceSummaryZoneRow} style={{ marginTop: "5px" }}>
				<div style={{ wordBreak: "break-word" }}>
					Datumavgift - <strong>{client.possibleDateSlots[orderItem.date.dateSlotId]?.name || ""}</strong>
				</div>
				<div>
					{consumerCatalog.renderVAT
						? currencyFormatter(article.price * (1 + article.taxPercentage))
						: currencyFormatter(article.price)}
				</div>
			</div>
		)
	}

	function orderItemDiscountElements(orderItem: OrderItem) {
		let discountArticles = orderItem.articles?.getDiscountArticles() || []
		return discountArticles.map((discountArticleAndTree) => (
			<DiscountEntry
				discountArticle={discountArticleAndTree.article}
				className={style.orderItemPriceSummaryZoneRow}
				key={getKey(discountArticleAndTree.article)}
			/>
		))
	}

	function renderOrderPriceSummary() {
		if (orderItems.length <= 1) {
			return null
		}

		const everyOrderItemHasAPrice = orderItems.every((orderItem) => totalPrices[orderItem.id] != null)
		if (!everyOrderItemHasAPrice) {
			return (
				<div className={style.allOrdersPriceSummaryWrapper}>
					<div className={style.grandTotal}>
						<div>Inget totalpris, då inte alla artiklar är prissatta!</div>
					</div>
				</div>
			)
		}

		const sumDiscount = consumerCatalog.renderVAT
			? sum(Object.values(totalPrices).map((x) => x.discount + x.taxDiscount))
			: sum(Object.values(totalPrices).map((x) => x.discount))
		return (
			<div className={style.allOrdersPriceSummaryWrapper}>
				<div className={style.grandTotal}>
					<div>Total summa av alla ordrar</div>
					<div>
						{consumerCatalog.renderVAT
							? currencyFormatter(sum(Object.values(totalPrices).map((x) => x.price + x.tax)))
							: currencyFormatter(sum(Object.values(totalPrices).map((x) => x.price)))}
					</div>
				</div>
				{sumDiscount < 0 ? (
					<div className={style.grandTotalTaxes}>
						<div>Rabatt</div>
						<div>{currencyFormatter(sumDiscount)}</div>
					</div>
				) : null}
				<div className={style.grandTotalTaxes}>
					{consumerCatalog.renderVAT ? (
						<>
							<div>Varav moms</div>
							<div>{currencyFormatter(sum(Object.values(totalPrices).map((x) => x.tax)))}</div>
						</>
					) : (
						<>
							<div>Priser är exklusive moms</div>
							<div></div>
						</>
					)}
				</div>
			</div>
		)
	}

	return (
		<>
			<div className={style.orderItemProductToggleSection}>
				<div>
					<div style={{ position: "relative", display: "flex" }}>
						<ListaOrdersIcon className={style.listOrdersIcon} size={30} />
					</div>
					<div style={{ color: "var(--module-box-text-color)" }}>
						<div style={{ fontWeight: 600 }}>Ordrar</div>
						<div style={{ fontSize: "14px" }}>{orderItems.length} st</div>
					</div>
				</div>
				<button
					type="button"
					className={style.basketCollapseButton}
					onClick={() => setShowPriceSummarySection(!showPriceSummarySection)}>
					{showPriceSummarySection ? (
						<FontAwesomeIcon
							className={style.basketCollapseButtonIcon}
							style={{ paddingTop: "2px" }}
							icon={faAngleDown}
						/>
					) : (
						<FontAwesomeIcon
							className={style.basketCollapseButtonIcon}
							style={{ paddingTop: "3px" }}
							icon={faAngleRight}
						/>
					)}
				</button>
			</div>
			<div style={{ display: showPriceSummarySection ? "block" : "none" }}>
				{orderItems.map((orderItem) => {
					return getOrderItemPriceSummaryTable(orderItem)
				})}
			</div>
			{renderOrderPriceSummary()}
		</>
	)
}
