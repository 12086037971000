import React, { FC, ReactNode, useEffect, useState } from "react"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { Loader } from "../Loader/Loader"
import { useNavigator } from "../Navigator/useNavigator"
import { useAuth } from "./AuthContext"

type Props = {
	component: ReactNode
}

export const AuthGuard: FC<Props> = ({ component }) => {
	const auth = useAuth()
	const navigator = useNavigator()

	const [status, setStatus] = useState(false)

	useEffect(() => {
		checkToken()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [component])

	const checkToken = () => {
		if (!auth.IsLoggedIn) {
			navigator.open("order")
		}
		setStatus(true)
	}

	return status ? (
		<React.Fragment>{component}</React.Fragment>
	) : (
		<React.Fragment>
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		</React.Fragment>
	)
}
