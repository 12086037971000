import log, { Logger } from "loglevel"
import { LoggerPrefix } from "./LoggerPrefix"
import { loglevelConfig } from "./loglevelConfig"
import { RemoteLogPusher } from "./RemoteLogPusher"

const loggersAlreadySetup = new WeakSet<Logger>()

export const remoteLogPusher = new RemoteLogPusher("WARN")
const loggerPrefix = new LoggerPrefix()

/**
 * Use this getLogger instead of log.getLogger to make sure plugins and settings are loaded correctly.
 */
export function getLogger(name: string): Logger {
	const logger = log.getLogger(name)

	try {
		if (loggersAlreadySetup.has(logger)) {
			return logger
		}

		const defaultLevel = loglevelConfig[name]
		if (defaultLevel != null) {
			logger.setDefaultLevel(defaultLevel)
		}

		loggerPrefix.apply(logger)
		remoteLogPusher.apply(logger)
	} catch (error) {
		console.error(`Unable to apply settings and plugins to Logger: ${name}`, error)
	}

	return logger
}
