import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { renderToStaticMarkup } from "react-dom/server"

/**
 * Given an html element, looks inside of it for all occurances of collapsibles
 * and adds relevant classes and sets click handlers
 * @param element
 */
export function createCollapsiblesFromHtml(element: HTMLElement) {
	const collapsibleWrappers = element.querySelectorAll("[data-name='custom-collapsible-wrapper']")

	for (const collapsibleWrapper of collapsibleWrappers) {
		if (!collapsibleWrapper.classList.contains("collapsible-wrapper")) {
			collapsibleWrapper.classList.add("collapsible-wrapper")
		}
		if (!collapsibleWrapper.classList.contains("collapsible-wrapper-closed")) {
			collapsibleWrapper.classList.add("collapsible-wrapper-closed")
		}
		const title = collapsibleWrapper.querySelector("[data-name='custom-collapsible-title']")
		const content = collapsibleWrapper.querySelector("[data-name='custom-collapsible-content']")

		if (title && content) {
			title.addEventListener("click", (e) => {
				if (collapsibleWrapper.classList.contains("collapsible-wrapper-closed")) {
					collapsibleWrapper.classList.remove("collapsible-wrapper-closed")
					collapsibleWrapper.classList.add("collapsible-wrapper-open")
				} else {
					collapsibleWrapper.classList.add("collapsible-wrapper-closed")
					collapsibleWrapper.classList.remove("collapsible-wrapper-open")
				}
			})

			if (!title.classList.contains("collapsible-title")) {
				title.classList.add("collapsible-title")
			}
			if (!content.classList.contains("collapsible-content")) {
				content.classList.add("collapsible-content")
			}

			if (title.getElementsByClassName("collapsible-toggle-icon").length === 0) {
				title.insertAdjacentHTML(
					"beforeend",
					renderToStaticMarkup(
						<span className="collapsible-toggle-icon">
							<FontAwesomeIcon icon={faAngleDown} className="collapsible-toggle-icon-closed" />
							<FontAwesomeIcon icon={faAngleUp} className="collapsible-toggle-icon-open" />
						</span>,
					),
				)
			}
		}
	}
}
