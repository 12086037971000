import { AuthInstance, useAuth } from "Auth/AuthContext"
import { exhaustive } from "exhaustive"
import { DiscountIcon, TrashIcon } from "Icons/Icon"
import { API, ServerError } from "network/API"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { cls } from "Shared/cls"
import { useConsumerCatalog } from "../../../Client/ConsumerCatalogContext"
import { SbtH4 } from "../Text/SbtH4/SbtH4"
import { SbtRHFError } from "../Text/SbtInvalid/SbtRHFError"
import style from "./DiscountCodeVerifier.module.css"

/* Possible error codes
	"discount_code_not_found"
	"discount_code_has_expired"
	"discount_code_not_active_yet"
	"discount_code_depleted"
*/
type VerifierState =
	| { state: "Empty" }
	| { state: "Loading" }
	| { state: "Error"; error: string }
	| { state: "Success"; data: GetVerifiedDiscountCode }

type Props = {
	currentDiscountCode: GetVerifiedDiscountCode | null
	addDiscountCode: (discountCodeIdentifier: GetVerifiedDiscountCode | null) => void
}

export const DiscountCodeVerifier: FC<Props> = ({ currentDiscountCode, addDiscountCode }) => {
	const auth = useAuth()
	const consumerCatalog = useConsumerCatalog()
	const { clientName } = useParams()

	const [enteredDiscountCode, setEnteredDiscountCode] = useState<string>(
		currentDiscountCode != null ? currentDiscountCode.identifier : "",
	)
	const [state, setState] = useState<VerifierState>(
		currentDiscountCode != null ? { data: currentDiscountCode, state: "Success" } : { state: "Empty" },
	)

	useEffect(() => {
		if (currentDiscountCode != null) {
			verifyDiscountCode()
		}
	}, [])

	function verifyDiscountCode() {
		updateState({ state: "Loading" })
		return API.get<GetVerifiedDiscountCode>(
			`/order-ui/discount-codes-v1/${clientName}/${enteredDiscountCode}`,
			true,
		).then(
			async (data) => {
				updateState({ state: "Success", data: data })
			},
			(err: ServerError<any>) => {
				updateState({ state: "Error", error: err.data.message })
			},
		)
	}

	function updateState(newState: VerifierState) {
		exhaustive(newState, "state", {
			Empty: () => {
				setEnteredDiscountCode("")
				addDiscountCode(null)
			},
			Loading: () => addDiscountCode(null),
			Error: () => addDiscountCode(null),
			Success: (it) => addDiscountCode(it.data),
		})
		setState(newState)
	}

	function renderError(errorMessage: string, auth: AuthInstance) {
		if (auth.IsLoggedInClient) {
			switch (errorMessage) {
				case "discount_code_not_found": {
					return <SbtRHFError error={{ message: "Koden finns inte" }} />
				}
				case "discount_code_has_expired": {
					return <SbtRHFError error={{ message: "Kodens giltighetstid har gått ut" }} />
				}
				case "discount_code_not_active_yet": {
					return <SbtRHFError error={{ message: "Koden är inte aktiv än" }} />
				}
				case "discount_code_depleted": {
					return <SbtRHFError error={{ message: "Koden är förbrukad" }} />
				}
			}
		}
		return <SbtRHFError error={{ message: "Koden är ogiltig" }} />
	}

	if (!consumerCatalog.pricesEnabled) {
		return null
	}

	return (
		<div className={cls(style.discountCodeContainer)}>
			<SbtH4>Kampanjer</SbtH4>
			<hr />
			<div className={style.addDiscountCodeWrapper}>
				<input
					placeholder="Rabattkod"
					className={style.discountCodeInput}
					value={enteredDiscountCode}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							verifyDiscountCode()
						}
					}}
					disabled={state.state === "Loading"}
					onChange={(e) => setEnteredDiscountCode(e.target.value)}
				/>
				<button onClick={verifyDiscountCode} disabled={state.state === "Loading"}>
					Lägg till
				</button>
			</div>
			{state.state === "Success" ? (
				<div className={style.validDiscountCodeWrapper}>
					<div className={style.discountIcon}>
						<DiscountIcon size={22} iconClassName={style.discountIcon} />
					</div>
					<div className={style.validDiscountCodeTextWrapper}>
						<strong className={style.validDiscountCodeText}>{state.data.identifier}</strong>
						<span className={style.validDiscountCodeText}>{state.data.label}</span>
					</div>
					<div className={style.deleteDiscountCode}>
						<TrashIcon
							size={22}
							iconClassName={style.trashIcon}
							onClick={(e) => {
								e?.stopPropagation()
								updateState({ state: "Empty" })
							}}
						/>
					</div>
				</div>
			) : null}
			{state.state === "Error" ? <div>{renderError(state.error, auth)}</div> : null}
		</div>
	)
}

export type GetVerifiedDiscountCode = {
	identifier: string
	label: string
	templateId: string
}
