import { useClient } from "Client/ClientAndUserProvider"
import { exhaustive } from "exhaustive"
import { FC, ForwardedRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { NavigateOptions, URLSearchParamsInit, useSearchParams } from "react-router-dom"
import { cls } from "Shared/cls"
import { WidgetRendererV1 } from "Shared/Widget/Widgets"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { ProductSelectionMode } from "../../Client/FeatureTypes"
import { ProductDefinition } from "../../Client/ProductDefinitionsByCategories"
import { getLogger } from "../../Logging/getLogger"
import { CalculationProduct } from "../../QuantityCalculator/QuantityCalculator"
import { EventQueue } from "../../Shared/eventQueue"
import { indexWithinBounds } from "../../Shared/indexWithinBounds"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { when } from "../../Shared/when"
import { removeModalOpenClass } from "../Components/ModulePopup/ModulePopup"
import { DateSelectModule } from "../DateSelectModule/DateSelectModule"
import {
	ProductInformationAndSelectionModule,
	ProductSelectionReturnValue,
} from "../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { TimeSelectModule } from "../TimeSelectModule/TimeSelectModule"
import { useBasket } from "./BasketProvider"
import style from "./OrderContainer.module.css"
import { modalOpenOrderItemIndexKey, modalOpenQueryKey } from "./OrderContainerWrapper"
import {
	allowedDateOrTimeSelectValues,
	handleQueryParamsOrderItemEditDate,
	handleQueryParamsOrderItemEditTime,
} from "./ProductSelectionLogic"
import {
	ProductDefinitionWithPrice,
	resolveProductDefinitionsWithPriceData,
} from "./resolveProductDefinitionsWithPriceData"
import { CategorySelection } from "./SubComponents/CategorySelection"
import { ClientConsumerSelection } from "./SubComponents/ClientConsumerSelection"
import { ProductSelection } from "./SubComponents/ProductSelection"
import { ProjectSelection, ProjectSelectionRefProps } from "./SubComponents/ProjectSelection"
import { ServiceSelection } from "./SubComponents/ServiceSelection"

const logger = getLogger("OrderContainer")

type Props = {}

export enum OpenModalTypes {
	NewProject = "new_proj",
	OldProject = "old_proj",
	OrderItemEditProject = "oi_e_p",
	OrderItemEditDate = "oi_e_d",
	OrderItemEditTime = "oi_e_t",
	OrderItemEditExactDeliveryLocation = "oi_e_edl",
}
export type OrderItemTotalPrices = Record<
	string,
	{ price: number; tax: number; amountOfArticles: number; discount: number; taxDiscount: number }
>

type SetURLSearchParams = (
	nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
	navigateOpts?: NavigateOptions,
) => void
export function removeModalOpen(queryParams: URLSearchParams, setQueryParams: SetURLSearchParams): void {
	let deleted: boolean = false
	if (queryParams.has(modalOpenQueryKey)) {
		queryParams.delete(modalOpenQueryKey)
		deleted = true
	}

	if (queryParams.has(modalOpenOrderItemIndexKey)) {
		queryParams.delete(modalOpenOrderItemIndexKey)
		deleted = true
	}

	if (deleted) {
		setQueryParams(queryParams)
	}

	removeModalOpenClass()
}

type ProductInfoAndSelection = {
	productId: string
	descriptionOpen: boolean
}

export const OrderContainer: FC<Props> = () => {
	const auth = useAuth()
	const client = useClient()
	const consumerCatalog = useConsumerCatalog()
	const basket = useBasket()
	const isMobileSize = useMediaQuery({ query: `(max-width: 1100px)` })

	const [showDateSelectModule, setShowDateSelectModule] = useState(false)
	const [showTimeSelectModule, setShowTimeSelectModule] = useState(false)

	const [queryParams, setQueryParams] = useSearchParams({
		category: (() => {
			const category = client.firstCategory()

			if (!category) {
				return ""
			}

			return category.name || ""
		})(),
		service: (() => {
			const category = client.firstCategory()

			if (!category || category.type === "GoodsCategory") {
				return ""
			}

			return category.serviceIds[0] || ""
		})(),
	})
	const previousQueryParams = useRef<URLSearchParams>(queryParams)
	const queriedCategory = queryParams.get("category")

	const selectedCategoryNameToUse = (() => {
		if (queriedCategory) {
			return queriedCategory
		}

		const categoryKey = client.categoryKeys[0]

		if (!categoryKey) {
			return null
		}

		return client.categories[categoryKey]?.name || null
	})()
	const selectedCategory = client.findCategoryByName(selectedCategoryNameToUse)
	const selectedServiceId = queryParams.get("service") || null

	const [productInfoAndSelection, setProductInfoAndSelection] = useState<ProductInfoAndSelection | null>(null)

	const availableProducts: ProductDefinitionWithPrice[] = useMemo(() => {
		if (selectedCategoryNameToUse && selectedCategory) {
			const categoryProducts: ProductDefinition[] = Object.values(selectedCategory.products)
			categoryProducts.sort((a, b) => a.order - b.order)
			return (
				resolveProductDefinitionsWithPriceData(
					categoryProducts,
					selectedServiceId,
					consumerCatalog,
					client,
					auth,
				) ?? []
			)
		} else {
			return []
		}
	}, [selectedCategoryNameToUse, selectedCategory, selectedServiceId, consumerCatalog, client, auth])

	const projectSelectionComponentRef: ForwardedRef<ProjectSelectionRefProps> = useRef(null)

	const [showRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const openProduct =
		productInfoAndSelection && selectedCategory ? selectedCategory.products[productInfoAndSelection.productId] : null

	const closeAllModals = useCallback((closeMobileBasket: boolean = true) => {
		projectSelectionComponentRef.current?.setShowProjectSelectModule(false, () => {})

		projectSelectionComponentRef.current?.setEditProject(null)

		setProductInfoAndSelection(null)

		setShowDateSelectModule(false)

		setShowTimeSelectModule(false)

		if (closeMobileBasket) {
			basket.functions.setMobileBasketShown(false)
		}
	}, [])

	const setQueryParamsUrl = useCallback(
		(
			category: string,
			service?: string | null,
			product?: string | null,
			openModal?: OpenModalTypes,
			removeModal?: boolean,
			orderItemIndex?: number,
		) => {
			if (queryParams.get("category") !== category) {
				queryParams.set("category", category)
			}

			if (!service) {
				queryParams.delete("service")
			} else if (queryParams.get("service") !== service) {
				queryParams.set("service", service)
			}

			if (!product) {
				queryParams.delete("product")
			} else {
				queryParams.set("product", product)
			}

			if (openModal) {
				queryParams.set(modalOpenQueryKey, openModal)
			}

			if (removeModal && queryParams.has(modalOpenQueryKey)) {
				queryParams.delete(modalOpenQueryKey)
			}

			if (orderItemIndex !== undefined) {
				queryParams.set(modalOpenOrderItemIndexKey, orderItemIndex.toString())
			}
			setQueryParams(queryParams)
		},
		[queryParams, setQueryParams],
	)

	useEffect(() => {
		const element = document.getElementById("mainLayoutPageContent")
		if (element) {
			element.scrollTop = 0
		}
	}, [])

	useEffect(() => {
		const eventListenerIds: string[] = []
		eventListenerIds.push(
			EventQueue.addEventListener("quantityCalculatorBasket", (calcProducts: CalculationProduct[]) => {
				const vals: ProductSelectionReturnValue[] = []
				calcProducts.forEach((calcProduct) => {
					if (calcProduct.type === "Goods" && calcProduct.selectedPackagingMethod) {
						vals.push({
							productId: calcProduct.productId,
							name: calcProduct.productName,
							category: calcProduct.productCategoryName,
							packagingMethods: {
								[calcProduct.selectedPackagingMethod.packagingMethodId]: calcProduct.selectedAmount,
							},
						})
					}
				})

				if (!basket.values.selectedProject) {
					projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, (project) => {
						basket.functions.addProduct(vals, 1, project)
					})
				} else {
					basket.functions.addProduct(vals)
				}
			}),
		)

		return () => {
			eventListenerIds.forEach((id) => EventQueue.removeEventListener(id))
		}
	}, [basket.functions, basket.values.selectedProject])

	useEffect(() => {
		if (selectedCategory && selectedServiceId) {
			exhaustive(selectedCategory, "type", {
				WasteCategory: (it) => {
					if (!it.serviceIds.includes(selectedServiceId)) {
						setQueryParamsUrl(selectedCategory.name, null)
					}
				},
				GoodsCategory: () => {
					setQueryParamsUrl(selectedCategory.name, null)
				},
			})
		}
	}, [selectedCategory, selectedServiceId, setQueryParamsUrl])

	useEffect(() => {
		const eventListenerIds: string[] = []
		eventListenerIds.push(
			EventQueue.addEventListener("closeAllModals", () => {
				closeAllModals()
			}),
		)

		return () => {
			eventListenerIds.forEach((id) => EventQueue.removeEventListener(id))
		}
	}, [closeAllModals])

	useEffect(() => {
		let productQueryId = queryParams.get("product")
		if (!productQueryId || (selectedCategory && !selectedCategory.products[productQueryId])) {
			setProductInfoAndSelection(null)
		} else if (selectedCategory && productQueryId && !queryParams.has(modalOpenQueryKey) && !productInfoAndSelection) {
			const product = availableProducts.find((it) => it.id === productQueryId)
			if (product != null) {
				setProductInfoAndSelection({
					productId: product.id,
					descriptionOpen: false,
				})
			}
		}
	}, [availableProducts, productInfoAndSelection, queryParams, selectedCategory])

	useEffect(() => {
		let productQueryId = queryParams.get("product")
		if (productQueryId && projectSelectionComponentRef?.current?.isOpen) {
			projectSelectionComponentRef?.current.close()
		}
	}, [productInfoAndSelection, queryParams])

	useEffect(() => {
		const indexParam = queryParams.get(modalOpenOrderItemIndexKey)
		const orderItemIndexRaw = indexParam ? parseInt(indexParam, 10) : undefined
		const orderItemIndex = indexWithinBounds(orderItemIndexRaw, basket.orderItems)

		const modalOpenParam = queryParams.get(modalOpenQueryKey) as OpenModalTypes | null
		if (modalOpenParam) {
			when(modalOpenParam, {
				[OpenModalTypes.NewProject]: () => {
					projectSelectionComponentRef.current?.setEditProject({ isNew: true, project: {} })
				},
				[OpenModalTypes.OldProject]: () => {
					projectSelectionComponentRef.current?.setShowProjectSelectModule(true, () => {})
				},
				[OpenModalTypes.OrderItemEditDate]: () => {
					handleQueryParamsOrderItemEditDate(orderItemIndex, basket, isMobileSize, () => {
						setShowDateSelectModule(true)
					})
				},
				[OpenModalTypes.OrderItemEditTime]: () => {
					handleQueryParamsOrderItemEditTime(orderItemIndex, basket, client, isMobileSize, () => {
						setShowTimeSelectModule(true)
					})
				},
				[OpenModalTypes.OrderItemEditProject]: () => {
					if (orderItemIndex == null) {
						return
					}

					if (isMobileSize) {
						basket.functions.setMobileBasketShown(true)
					}
					basket.functions.setSelectedOrderItem(orderItemIndex)
					setTimeout(() => {
						projectSelectionComponentRef.current?.setShowProjectSelectModule(true, (project) => {
							basket.functions.onProjectSelected(project, false, null, orderItemIndex)
						})
					}, 50)
				},
				[OpenModalTypes.OrderItemEditExactDeliveryLocation]: () => {},
			})
		}
	}, [basket, client, isMobileSize, queryParams])

	useEffect(() => {
		const typeOfClosedModal = (previousQueryParams.current?.get(modalOpenQueryKey) || null) as OpenModalTypes | null
		if (typeOfClosedModal && !queryParams.has(modalOpenQueryKey)) {
			// Don't close mobile basket if the modal being closed is a modal that was opened in context of said basket
			closeAllModals(
				!(
					typeOfClosedModal === OpenModalTypes.OrderItemEditDate ||
					typeOfClosedModal === OpenModalTypes.OrderItemEditTime ||
					typeOfClosedModal === OpenModalTypes.OrderItemEditProject
				),
			)
			removeModalOpen(queryParams, setQueryParams)
		}

		previousQueryParams.current = new URLSearchParams(queryParams)
	}, [closeAllModals, queryParams, setQueryParams])

	if (client.categoryKeys.length <= 0) {
		return (
			<div className={style.noProductsWrapper}>
				<div className={style.noProductsMessage}>
					{client.clientInfo.clientName} har tyvärr inga produkter upplagda än, men det kommer snart!
				</div>
			</div>
		)
	}

	const onProductSelected = (data: ProductSelectionReturnValue) => {
		const res = basket.functions.addProduct([data])

		if (res === "no-project") {
			projectSelectionComponentRef.current?.setShowProjectSelectModule(true, (project) => {
				basket.functions.addProduct([data], 1, project)
			})
		}
	}

	function modalElements(): JSX.Element | null {
		const orderItem =
			basket.values.selectedOrderItemIndex !== null ? basket.orderItems[basket.values.selectedOrderItemIndex] : null
		const categoryOfSelectedOrderItem = orderItem ? client.categories[orderItem.category] : null

		const openProduct = availableProducts.find((it) => it.id === productInfoAndSelection?.productId)
		const descriptionOpen = productInfoAndSelection?.descriptionOpen ?? false

		return (
			<>
				{openProduct != null && selectedCategory ? (
					<ProductInformationAndSelectionModule
						product={openProduct}
						descriptionOpen={descriptionOpen}
						category={selectedCategory}
						service={
							selectedServiceId !== null && selectedCategory?.type === "WasteCategory"
								? selectedCategory?.services[selectedServiceId]
								: undefined
						}
						possibleServices={
							selectedCategory?.type === "WasteCategory" ? selectedCategory?.services || {} : {}
						}
						onClose={() => {
							setProductInfoAndSelection(null)
							setQueryParamsUrl(selectedCategory!.name, selectedServiceId || "", "", undefined, true)
							removeModalOpen(queryParams, setQueryParams)
						}}
						onProductSelected={(data) => {
							onProductSelected(data)
						}}
						onSetSelectedServiceId={onSetSelectedServiceId}
					/>
				) : null}
				{showDateSelectModule && orderItem ? (
					<DateSelectModule
						onDateSelected={(date) =>
							basket.functions.setDateOnOrderItem(basket.values.selectedOrderItemIndex, date)
						}
						allowedValues={allowedDateOrTimeSelectValues(
							basket,
							client,
							categoryOfSelectedOrderItem || null,
							"date",
						)}
						defaultValue={orderItem.date}
						onClose={() => {
							setShowDateSelectModule(false)
							removeModalOpen(queryParams, setQueryParams)
						}}
					/>
				) : null}
				{showTimeSelectModule && orderItem ? (
					<TimeSelectModule
						onTimeSelected={(timeslotId, timeName, specificTime) =>
							basket.functions.setTimeOnOrderItem(
								basket.values.selectedOrderItemIndex,
								timeslotId,
								timeName,
								specificTime,
							)
						}
						allTimeSlots={client.possibleTimeSlots}
						allowedValues={allowedDateOrTimeSelectValues(
							basket,
							client,
							categoryOfSelectedOrderItem || null,
							"time",
						)}
						defaultValue={orderItem.time}
						onClose={() => {
							setShowTimeSelectModule(false)
							removeModalOpen(queryParams, setQueryParams)
						}}
					/>
				) : null}
			</>
		)
	}

	function onSetShowProjectSelectModule() {
		setQueryParamsUrl(selectedCategoryNameToUse || "", selectedServiceId || "", "", OpenModalTypes.OldProject)
	}

	function onSetShowProjectInputModule() {
		setQueryParamsUrl(selectedCategoryNameToUse || "", selectedServiceId || "", "", OpenModalTypes.NewProject)
	}

	function onSetSelectedCategory(categoryName: string) {
		let category = client.findCategoryByName(categoryName)
		if (category == null) {
			logger.error("Selected a non-existing category:", categoryName)
			return
		}
		exhaustive(category, "type", {
			WasteCategory: (it) => {
				const fullPage =
					client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage &&
					!!it.productSelectionConfig &&
					it.productSelectionConfig.steps.length > 0

				let service = null
				if (!fullPage && it.serviceIds.length === 1) {
					service = it.serviceIds[0]
				}

				if (queryParams.get("category") !== categoryName || queryParams.get("service") !== service) {
					setQueryParamsUrl(categoryName, service)
				}
			},
			GoodsCategory: () => {
				setQueryParamsUrl(categoryName, null)
			},
			_: () => {
				logger.error("Undefined category selected, trying to continue: ", category)
			},
		})

		if (
			basket.values.selectedOrderItemIndex !== null &&
			basket.orderItems[basket.values.selectedOrderItemIndex]?.category !== ""
		) {
			basket.functions.setSelectedOrderItem(null)
		}
	}

	function onSetSelectedServiceId(selectedCategory: string, serviceId: string | null) {
		setQueryParamsUrl(selectedCategory, serviceId || undefined, openProduct?.id)
	}

	function onProductClick(selectedCategory: string, product: ProductDefinitionWithPrice) {
		setProductInfoAndSelection({ productId: product.id, descriptionOpen: false })
		setQueryParamsUrl(selectedCategory, selectedServiceId || "", product.id, undefined, true)
	}

	function onProductCardClick(selectedCategory: string, product: ProductDefinitionWithPrice) {
		setProductInfoAndSelection({ productId: product.id, descriptionOpen: true })
		setQueryParamsUrl(selectedCategory, selectedServiceId || "", product.id, undefined, true)
	}

	const onProductIncrementorChange = (
		buttonClicked: "addClick" | "removeClick" | "text",
		product: ProductDefinition,
		value: number,
		currentValue: number,
		category: string,
		service: string,
	) => {
		const res = basket.functions.onProductIncrementorChange(
			buttonClicked,
			product,
			value,
			currentValue,
			category,
			service,
		)

		if (res === "no-project") {
			projectSelectionComponentRef.current?.setShowProjectSelectModule(true, (project) => {
				if (project) {
					basket.functions.onProductIncrementorChange(
						buttonClicked,
						product,
						value,
						currentValue,
						category,
						service,
						project,
					)
				}
			})
		}
	}

	return (
		<>
			{modalElements()}
			<div
				className={cls(style.wrapper, {
					[style.regularBasketHidden]: !showRegularBasket,
				})}>
				<div className={style.content}>
					{client.welcomeWidget != null ? <WidgetRendererV1 widgets={[client.welcomeWidget]} /> : null}
					<ul
						className={cls(style.timeline, style.mobileOnly)}
						style={{ marginBlockStart: 0, marginBlockEnd: 0, marginBottom: "20px" }}>
						<li className={style.current}>
							<div />
						</li>
						<div className={style.timelineLine} />
						<li>
							<div />
						</li>
						<div className={style.timelineLine} />
						<li>
							<div />
						</li>
					</ul>
					<ClientConsumerSelection />
					<ProjectSelection
						project={basket.values.selectedProject}
						onSetShowProjectSelectModule={onSetShowProjectSelectModule}
						onSetShowProjectInputModule={onSetShowProjectInputModule}
						ref={projectSelectionComponentRef}
						currentOrderItemProject={
							basket.values.selectedOrderItemIndex != null &&
							basket.orderItems[basket.values.selectedOrderItemIndex]
								? basket.orderItems[basket.values.selectedOrderItemIndex]?.project || null
								: basket.values.selectedProject || null
						}
						onProjectSelected={(project, isNew, productSelectionReturnValues) => {
							basket.functions.onProjectSelected(project, isNew, productSelectionReturnValues, null)
						}}
						onProjectInputClose={() => {}}
						onProjectSelectClose={(reason) => {
							if (basket.values.mobileBasketShown) {
								removeModalOpen(queryParams, setQueryParams)
							}
						}}
					/>
					<CategorySelection
						selectedCategory={selectedCategoryNameToUse}
						onSetSelectedCategory={onSetSelectedCategory}
					/>
					<ServiceSelection
						selectedCategory={selectedCategoryNameToUse}
						selectedServiceId={selectedServiceId}
						onSetSelectedServiceId={onSetSelectedServiceId}
					/>
					{selectedCategoryNameToUse ? (
						<ProductSelection
							selectedCategoryName={selectedCategoryNameToUse}
							selectedServiceId={selectedServiceId}
							availableProducts={availableProducts}
							onProductClick={onProductClick}
							onProductCardClick={onProductCardClick}
							onProductIncrementorChange={onProductIncrementorChange}
						/>
					) : null}
				</div>
			</div>
		</>
	)
}
