export type LoginRequest = {
	clientIdentifier: string
	username?: string
	email?: string
	phoneNumber?: string
	password: string
}

export type LoginResponse = {
	token: string
	expirationDate: string
}

export type RefreshResponse = {
	token: string
	expirationDate: string
}

export type MeResponse = {
	userId: string
	username: string
	type: MeResponseAccountType
	email?: string
	phoneNumber?: string
	clientIdentifier: string
	consumers: MeResponseConsumerData[]
	firstName?: string
	lastName?: string
	profilePicture?: string
}

export enum MeResponseAccountType {
	Client = "Client",
	Consumer = "Consumer",
}

export enum MeResponseConsumerType {
	Company = "Company",
	Person = "Person",
}

export type MeResponseConsumerData = {
	type: MeResponseConsumerType
	id: string
	name: string
	identifier: string
	profilePicture?: string
	priceType?: ConsumerPriceType
}

export enum ConsumerPriceType {
	Contract = "Contract",
	Prepay = "Prepay",
}
