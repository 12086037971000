/**
 * Helper function to convert any value to a string that can be used for logging.
 * The prefered method of serilization is JSON.stringify, but that doesn't work for circular objects.
 * @param value
 */
export function anyToLoggableString(value: any): string {
	try {
		return JSON.stringify(value)
	} catch (e) {
		try {
			return Object.entries(value)
				.map(([key, value]) => key + ":" + String(value))
				.join(", ")
		} catch (e2) {
			return `-Object with Circular Data, e:${e}, e2:${e2}-`
		}
	}
}
