import { FC } from "react"
import { FieldError } from "react-hook-form"
import { SbtInvalid } from "./SbtInvalid"

type Props = {
	error: Partial<FieldError> | undefined
}

export const SbtRHFError: FC<Props> = ({ error }) => {
	return <SbtInvalid>{error ? error?.message : null}</SbtInvalid>
}
