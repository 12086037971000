import { FC, useState } from "react"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { EventQueue } from "../../Shared/eventQueue"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { CustomerPortalConsumerDetails } from "../CustomerPortalConsumerDetails/CustomerPortalConsumerDetails"
import consumerInfoStyle from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import myAccountStyle from "../CustomerPortalMyAccount/CustomerPortalMyAccount.module.css"
import { EditClientContactDetails } from "../EditClientContactDetails/EditClientContactDetails"
import { KlarnaSettings } from "../KlarnaSettings/KlarnaSettings"
import { Users } from "../Users/Users"
import style from "./CustomerPortalCustomerDetails.module.css"

function onWheel(event: globalThis.WheelEvent) {
	const target = event.target as HTMLElement
	if (target && target.clientWidth < target.scrollWidth) {
		event.preventDefault()
	}
	;(event.target as HTMLElement).scrollLeft += event.deltaY
}

export function onWheelRef(node: HTMLElement | null) {
	if (node) {
		node.addEventListener("wheel", (event) => onWheel(event), { passive: false })
	}
}

const ClientDetails: FC = () => {
	const client = useClient()
	return (
		<div className={consumerInfoStyle.wrapper}>
			<div className={consumerInfoStyle.headerText} style={{ marginBottom: "35px" }}>
				Mitt företag
			</div>

			<div className={consumerInfoStyle.textInputWithLabel}>
				<label>Företagsnamn</label>
				<span ref={onWheelRef} title={client.clientInfo.clientName} className={consumerInfoStyle.textAsInput}>
					{client.clientInfo.clientName}
				</span>
			</div>
			<div className={consumerInfoStyle.textInputWithLabel}>
				<label>Organisationsnummer</label>
				<span
					ref={onWheelRef}
					title={client.clientInfo.organizationNumber}
					className={consumerInfoStyle.textAsInput}>
					{client.clientInfo.organizationNumber}
				</span>
			</div>
		</div>
	)
}

const ContactDetails: FC = () => {
	const client = useClient()
	const permissions = usePermissions()

	const [edit, setEdit] = useState(false)

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Information_View)) {
		return null
	}

	return (
		<>
			{edit && permissions.isAllowed(PermissionAreaLocation.Client_Information_Update) ? (
				<EditClientContactDetails
					onClose={() => {
						setEdit(false)
					}}
					onDone={() => {
						setEdit(false)
						EventQueue.dispatchEvent("reload-client-data", {})
					}}
				/>
			) : null}
			<div className={consumerInfoStyle.wrapper}>
				<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
					<div className={consumerInfoStyle.headerText}>Kontaktdetaljer</div>
					{permissions.isAllowed(PermissionAreaLocation.Client_Information_Update) ? (
						<div>
							<PenIcon
								size={22}
								iconClassName={myAccountStyle.editIcon}
								onClick={() => {
									setEdit(true)
								}}
							/>
						</div>
					) : null}
				</div>
				<div className={consumerInfoStyle.textInputWithLabel} style={{ marginTop: "35px" }}>
					<label>Visningsnamn</label>
					<span ref={onWheelRef} className={consumerInfoStyle.textAsInput} title={client.clientInfo.contact.name}>
						{client.clientInfo.contact.name}
					</span>
				</div>
				<div className={consumerInfoStyle.textInputWithLabel}>
					<label>Hemsida</label>
					<span ref={onWheelRef} className={consumerInfoStyle.textAsInput} title={client.clientInfo.homepage}>
						{client.clientInfo.homepage}
					</span>
				</div>
				<div className={consumerInfoStyle.textInputWithLabel}>
					<label>E-post för ordermottagning</label>
					<span
						ref={onWheelRef}
						className={consumerInfoStyle.textAsInput}
						title={client.clientInfo.contact.orderEmail}>
						{client.clientInfo.contact.orderEmail}
					</span>
				</div>
				<div className={consumerInfoStyle.twoInputsSameRow}>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Telefonnummer</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={client.clientInfo.contact.phoneNumber}>
							{client.clientInfo.contact.phoneNumber}
						</span>
					</div>
					<div></div>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>E-post</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={client.clientInfo.contact.email}>
							{client.clientInfo.contact.email}
						</span>
					</div>
				</div>
				<div className={consumerInfoStyle.textInputWithLabel}>
					<label>Besöksadress</label>
					<span
						ref={onWheelRef}
						className={consumerInfoStyle.textAsInput}
						title={client.clientInfo?.address?.street}>
						{client.clientInfo?.address?.street}
					</span>
				</div>
				<div className={consumerInfoStyle.twoInputsSameRow}>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Postnummer</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={client.clientInfo?.address?.zipCode}>
							{client.clientInfo?.address?.zipCode}
						</span>
					</div>
					<div></div>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Postort</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={client.clientInfo?.address?.city}>
							{client.clientInfo?.address?.city}
						</span>
					</div>
				</div>
			</div>
		</>
	)
}

const CustomerPortalClientDetails: FC = () => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	if (!auth.Me || auth.Me.type !== "Client") {
		return null
	}

	return (
		<div className={style.clientDetailsWrapper}>
			<div className={style.box}>
				<ClientDetails />
				<ContactDetails />
				{client.features.allowKlarnaIntegration ? <KlarnaSettings /> : null}
			</div>
			{permissions.isAllowed(PermissionAreaLocation.Client_Users_Read) ? (
				<div className={style.box}>
					<Users type={"Client"} clientIdentifier={client.identifier} />
				</div>
			) : null}
		</div>
	)
}

export const CustomerPortalCustomerDetails: FC = () => {
	const auth = useAuth()

	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	if (!auth.Me) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	return (
		<>
			{auth.Me.type === "Consumer" ? (
				<CustomerPortalConsumerDetails consumerId={selectedConsumerId} />
			) : (
				<CustomerPortalClientDetails />
			)}
		</>
	)
}
