/**
 * @format
 */

import { OrderState } from "../../OrderCollection"

export const orderStateTranslation = (state: OrderState): string => {
	switch (state) {
		case OrderState.Accepted:
			return "Godkänd"
		case OrderState.Declined:
			return "Nekad"
		case OrderState.Done:
			return "Klar"
		default:
			return "" // TODO: Should throw error right?
	}
}
