import { Logger, LoggingMethod } from "loglevel"

export class LoggerPrefix {
	public apply(logger: Logger) {
		try {
			const originalFactory = logger.methodFactory

			logger.methodFactory = (logLevelName, logLevel, loggerName): LoggingMethod => {
				const originalMethod = originalFactory(logLevelName, logLevel, loggerName)

				return (...messages: any[]) => {
					originalMethod(`[${logLevelName}: ${loggerName.toString()}] `, ...messages)
				}
			}
			logger.rebuild() // Be sure to call the rebuild method in order to apply plugin.
		} catch (error) {
			console.error("Failed to apply 'loglevel' plugin for Logger Prefix. ", error)
		}
	}
}
