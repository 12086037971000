import { faEye, faEyeSlash, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { isEmpty } from "lodash"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import consumerInfoStyle from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { onWheelRef } from "../CustomerPortalCustomerDetails/CustomerPortalCustomerDetails"
import {
	GetKlarnaSettings,
	GetKlarnaSettingsResponse,
	KlarnaEnabled,
	SetKlarnaSettingsRequest,
	SetKlarnaSettingsResponse,
} from "../CustomerPortalCustomerDetails/KlarnaSettingsApi"
import myAccountStyle from "../CustomerPortalMyAccount/CustomerPortalMyAccount.module.css"
import style from "./KlarnaSettings.module.css"

type EditKlarnaSettingsProps = {
	settings: GetKlarnaSettings | null
	onClose: () => void
	onDone: (settings: GetKlarnaSettings) => void
}

const EditKlarnaSettings: FC<EditKlarnaSettingsProps> = ({ settings, onClose, onDone }) => {
	const client = useClient()
	const permissions = usePermissions()
	const FormSchema = z.object({
		shopIdentifier: z.string().min(1, "Måste ange Klarna Butiks Id"),
		username: z.string().min(1, "Måste ange användarnamn"),
		newPassword: z.string().min(1, "Måste ange lösenord").nullable(),
		editPassword: z.string().nullable(),
		enable: z.nativeEnum(KlarnaEnabled),
	})
	type FormSchemaType = z.input<typeof FormSchema>
	const isNew = settings === null

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm<FormSchemaType>({
		resolver: async (data, context, options) => {
			return zodResolver(FormSchema)(data, context, options)
		},
		defaultValues: buildDefaultValues(settings, isNew),
	})

	const [showPassword, setShowPassword] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	function buildDefaultValues(settings: GetKlarnaSettings | null, isNew: boolean) {
		return {
			username: settings?.username,
			shopIdentifier: settings?.shopIdentifier,
			newPassword: isNew ? "" : null,
			editPassword: isNew ? null : "",
			enable: settings?.enable,
		}
	}

	async function onSubmit(data: FormSchemaType) {
		if (!permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Update)) {
			return
		}

		setSubmitting(true)
		let password = data.newPassword
		if (password === null) {
			if (isEmpty(data.editPassword)) {
				password = null
			} else {
				password = data.editPassword
			}
		}
		const reqObj: SetKlarnaSettingsRequest = {
			klarnaSettings: {
				shopIdentifier: data.shopIdentifier,
				username: data.username,
				password: password,
				enabled: data.enable,
			},
		}

		API.postWithRetries<SetKlarnaSettingsResponse>(`/customer-portal/klarna-settings-v1/${client.identifier}`, reqObj)
			.then((res) => {
				onDone(res.klarnaSettings)
			})
			.catch(() => {})
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Update)) {
		return null
	}

	return (
		<ModulePopup className={style.modal} onClose={onClose} disableCloseWithEsc={submitting}>
			<div className={style.wrapper}>
				<div
					className={consumerInfoStyle.headerText}
					style={{ marginTop: "-15px", marginBottom: "35px", marginRight: "30px" }}>
					Redigera klarnainställningar
				</div>
				<form className={style.form} onSubmit={handleSubmit((data) => onSubmit(data))}>
					<div className={orderConfirmStyle.fields}>
						<label>
							<SbtH4>Tillåt Klarna för</SbtH4>
							<select className={orderConfirmStyle.input} disabled={submitting} {...register("enable")}>
								<option value={KlarnaEnabled.None}>Inga kunder</option>
								<option value={KlarnaEnabled.AllPrepaid}>Alla förskottskunder</option>
							</select>
							<SbtRHFError error={errors.enable} />
						</label>
						<label>
							<SbtH4>Handlar-id*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								disabled={submitting}
								{...register("shopIdentifier")}
								placeholder="PK1234567"
							/>
							<SbtRHFError error={errors.shopIdentifier} />
						</label>
						<label>
							<SbtH4>Användarnamn (UID)*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								disabled={submitting}
								{...register("username")}
								autoComplete="off"
								placeholder="PK1234567_abcdef12345678"
							/>
							<SbtRHFError error={errors.username} />
						</label>
						<label>
							<SbtH4>Lösenord*</SbtH4>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={orderConfirmStyle.input}
									disabled={submitting}
									type={showPassword ? "text" : "password"}
									{...register(isNew ? "newPassword" : "editPassword")}
									autoComplete="off"
									placeholder="Lösenord"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showPassword ? faEyeSlash : faEye}
									onClick={() => {
										setShowPassword(!showPassword)
									}}
								/>
							</div>
							<SbtRHFError error={isNew ? errors.newPassword : errors.editPassword} />
						</label>
					</div>
					<FinalizeButton className={style.finalize} disabled={submitting || !isDirty || !isValid} type="submit">
						Spara Klarnainställningar{" "}
						{submitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				</form>
			</div>
		</ModulePopup>
	)
}

export const KlarnaSettings: FC = () => {
	const client = useClient()
	const permissions = usePermissions()
	const [klarnaSettings, setKlarnaSettings] = useState<GetKlarnaSettings | "None" | null | "Loading">("Loading")
	const [edit, setEdit] = useState<boolean>(false)

	function getSettings(identifier: string) {
		if (!permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Read)) {
			return
		}

		API.getWithRetries<GetKlarnaSettingsResponse>(`/customer-portal/klarna-settings-v1/${identifier}`, true)
			.then((klarnaSettingsResponse) => {
				setKlarnaSettings(klarnaSettingsResponse?.klarnaSettings ?? "None")
			})
			.catch(() => {
				setKlarnaSettings(null)
			})
	}

	useEffect(() => {
		if (
			!client.features.allowKlarnaIntegration ||
			!permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Read)
		) {
			return
		}
		getSettings(client.identifier)
	}, [client.features.allowKlarnaIntegration, client.identifier, permissions])

	if (
		!client.features.allowKlarnaIntegration ||
		!permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Read)
	) {
		return null
	}

	if (klarnaSettings === null) {
		return null
	}

	if (klarnaSettings === "Loading") {
		return (
			<div className={consumerInfoStyle.wrapper} style={{ minHeight: "250px" }}>
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			</div>
		)
	}

	return (
		<>
			{edit && permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Update) ? (
				<EditKlarnaSettings
					settings={klarnaSettings !== "None" ? klarnaSettings : null}
					onDone={() => {
						getSettings(client.identifier)
						setEdit(false)
					}}
					onClose={() => {
						setEdit(false)
					}}
				/>
			) : null}
			<div className={consumerInfoStyle.wrapper}>
				<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
					<div className={consumerInfoStyle.headerText}>Klarnainställningar</div>
					{permissions.isAllowed(PermissionAreaLocation.Client_Payment_Details_Update) ? (
						<div>
							<PenIcon
								size={22}
								iconClassName={myAccountStyle.editIcon}
								onClick={() => {
									setEdit(true)
								}}
							/>
						</div>
					) : null}
				</div>
				<div style={{ marginTop: "35px" }}>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>Tillåt klarna för</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={
								klarnaSettings !== "None"
									? klarnaSettings.enable === KlarnaEnabled.None
										? "Inga kunder"
										: "Alla förskottskunder"
									: undefined
							}>
							{klarnaSettings !== "None"
								? klarnaSettings.enable === KlarnaEnabled.None
									? "Inga kunder"
									: "Alla förskottskunder"
								: null}
						</span>
					</div>
					<div className={consumerInfoStyle.twoInputsSameRow}>
						<div className={consumerInfoStyle.textInputWithLabel}>
							<label>Handlar-id</label>
							<span
								ref={onWheelRef}
								className={consumerInfoStyle.textAsInput}
								title={klarnaSettings !== "None" ? klarnaSettings.shopIdentifier : undefined}>
								{klarnaSettings !== "None" ? klarnaSettings.shopIdentifier : null}
							</span>
						</div>
						<div></div>
						<div className={consumerInfoStyle.textInputWithLabel}>
							<label>Användarnamn (UID)</label>
							<span
								ref={onWheelRef}
								className={consumerInfoStyle.textAsInput}
								title={klarnaSettings !== "None" ? klarnaSettings.username : undefined}>
								{klarnaSettings !== "None" ? klarnaSettings.username : null}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
