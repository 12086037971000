import { CSSProperties, FC, PropsWithChildren } from "react"
import { cls } from "Shared/cls"
import style from "./MbactH4.module.css"

type Props = {
	styles?: CSSProperties
}

// 20px semibold
export const MbactH4: FC<PropsWithChildren<Props>> = ({ styles, children }) => {
	return (
		<h4 className={cls(style.mbactH4)} style={styles}>
			{children}
		</h4>
	)
}
