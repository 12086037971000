import Lottie from "lottie-react"
import { Footer } from "OrderAcceptance/Footer/Footer"
import { fixLottieColor } from "../Lottie/Helpers"
import Sent from "../Lottie/Sent.json"
import style from "../Onboarding/Onboarding.module.css"
import { OnboardingHeader } from "./OnboardingHeader/OnboardingHeader"

export const OnboardingCompleted = () => {
	return (
		<div className={style.pageBackground}>
			<OnboardingHeader />
			<div className={style.requestOnboardingBody}>
				<div className={style.lottieWrapperNoMargin}>
					<Lottie animationData={fixLottieColor(Sent)} className={style.lottieAnimation} autoPlay loop />
				</div>
				<h1 className={style.completOnboardingTitle}>Formuläret har skickats in</h1>
				<div className={style.completOnboardingSubTitle}>
					All information har skickats till Skrappy och vi återkommer så fort den har blivit granskad.
				</div>
			</div>
			<Footer />
		</div>
	)
}
