import { CSSProperties, FC, PropsWithChildren } from "react"
import { cls } from "Shared/cls"
import style from "./MbactH1.module.css"

type Props = {
	styles?: CSSProperties
	className?: string
}

export const MbactH1: FC<PropsWithChildren<Props>> = ({ styles, className, children }) => {
	return (
		<h1 className={cls(style.mbactH1, className)} style={styles}>
			{children}
		</h1>
	)
}
