import { ReactNode } from "react"
import { Helmet } from "react-helmet-async"
import { Outlet } from "react-router-dom"
import { useClient } from "../Client/ClientAndUserProvider"
import { API } from "../network/API"
import { swedishGenitiv } from "../Shared/swedishGenitiv"
import { MainLayout } from "./MainLayout"
import style from "./MainLayout.module.css"

export function ClientIndexLayout() {
	return (
		<ClientIndex
			element={
				<MainLayout style={style.pageContent}>
					<div className={style.mobileOnly} style={{ height: "32px", minHeight: "32px" }}></div>
					<div className={style.desktopOnly} style={{ height: "48px", minHeight: "48px" }}></div>

					<Outlet />
				</MainLayout>
			}
		/>
	)
}

export function ClientPageLayout() {
	return (
		<ClientIndex
			element={
				<MainLayout style={style.pagesPageContent}>
					<Outlet />
				</MainLayout>
			}
		/>
	)
}

export function ClientIndex(props: { element: ReactNode }) {
	const client = useClient()
	let iconUrl = API.getUrl(`/clients/${client.identifier}/resources/icon/32`)
	let appleIconUrl = API.getUrl(`/clients/${client.identifier}/resources/icon/180`)
	return (
		<>
			<Helmet>
				<meta name="theme-color" content={client.branding.colors.header ?? "#381533"} />
				<meta name="description" content={`${swedishGenitiv(client.clientInfo.clientName)} bokningsapp`} />
				<link rel="icon" href={iconUrl} type="image/png" />
				<link rel="apple-touch-icon" href={appleIconUrl} type="image/png" />
				<title>{`${swedishGenitiv(client.clientInfo.clientName)} bokningsapp`}</title>
			</Helmet>
			{props.element}
		</>
	)
}
