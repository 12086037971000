/**
 * A typescript 'variant' of Kotlin's ?.let function.
 */
export function lets<T, R>(value: T | undefined | null, fn: (value: T) => R, elseFn?: () => R) {
	if (value == null) {
		if (elseFn == null) {
			return null
		} else {
			return elseFn()
		}
	}

	return fn(value)
}
