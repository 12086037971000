import { HappyCustomer } from "../Lottie/AnimationComponents"
import { DemoProps } from "./demoState"
import { ReactComponent as PilSvg } from "./Images/pil.svg"
import { ReactComponent as SpadeSvg } from "./Images/spade.svg"

import style from "./DemoStyle.module.css"

export function Demo1Intro({ setDemoState }: DemoProps) {
	function onNext() {
		setDemoState("start-survey")
	}

	return (
		<>
			<HappyCustomer className={style.topImage} />
			<h3>HEJ OCH VÄLKOMMEN!</h3>
			<h1>Kul att du vill testa vår demo.</h1>

			<p>
				Denna demo är till för att testa, som en kund, att boka avfallstjänster eller grus & jord via en
				entreprenör.
			</p>
			<p>Du kommer att kunna testa att boka t.ex: en container eller leverans av jord.</p>

			<h2>Vi hade varit tacksamma om du:</h2>
			<div>
				<div className={style.bullet}>
					<SpadeSvg />
					<p>Testar att klicka runt i mjukvaran</p>
				</div>
				<div className={style.bullet}>
					<SpadeSvg />
					<p>Fullföljer en bokning</p>
				</div>
				<div className={style.bullet}>
					<SpadeSvg />
					<p>Svarar på frågorna som dyker upp efter att bokningen är gjord</p>
				</div>
			</div>

			<div className={style.arrowBox}>
				<PilSvg />
				<div className={style.psst}>
					<div>Pssst!</div> Svarar du på frågorna skickar vi hem en present till dig
				</div>
			</div>
			<div className={style.buttonWrapper}>
				<button type={"button"} onClick={onNext}>
					Gå vidare
				</button>
			</div>
			<a href={"https://skrappy.se/privacy-notice/"} rel="noreferrer" target="_blank" className={style.privacyNotice}>
				* läs mer om hur vi hanterar personuppgifter
			</a>
		</>
	)
}
