import { exhaustive } from "exhaustive"
import { AuthInstance } from "../../Auth/AuthContext"
import { BranchTargetKeyEnum, TreeSearchObject } from "../../Client/articleTrees/ArticleTreeDataModel"
import {
	ArticleResolverService,
	LeafOrigin,
	ResolvedArticle,
	ResolvedLeafWithTree,
} from "../../Client/articleTrees/ArticleTreeResolver"
import { ClientInstance } from "../../Client/ClientInstance"
import { ConsumerCatalogInstance } from "../../Client/ConsumerCatalogContext"
import {
	GoodsProductDefinition,
	PackagingMethod,
	ProductDefinition,
	ProductPackagingWithMethod,
	ProductService,
	WasteProductDefinition,
} from "../../Client/ProductDefinitionsByCategories"
import { getLogger } from "../../Logging/getLogger"
import { WasteType } from "../../ProductDefinitions"
import { notNull } from "../../Shared/notNull"
import { throwIllegalState } from "../../Shared/throwIllegalState"

const logger = getLogger("resolveProductDefinitionsWithPriceData")

export type ProductDefinitionWithPrice = WasteProductDefinitionWithPrice | GoodsProductDefinitionWithPrice

export type WasteProductDefinitionWithPrice =
	| AmountWasteProductDefinitionWithPrice
	| WasteWasteProductDefinitionWithPrice
	| NoneWasteProductDefinitionWithPrice

export type NoneWasteProductDefinitionWithPrice = WasteProductDefinition & {
	quantityType: "None"
	service: null
	priceData: ProductPriceData
}

export type AmountWasteProductDefinitionWithPrice = WasteProductDefinition & {
	quantityType: "Amount"
	service: ProductService
	priceData: ProductPriceData
}

export type WasteWasteProductDefinitionWithPrice = WasteProductDefinition & {
	quantityType: "Waste"
	service: ProductService
	priceData: ProductPriceData
	pricedWaste: WastePriceData[]
}

export type GoodsProductDefinitionWithPrice = GoodsProductDefinition & {
	priceData: ProductPriceData
	packagings: ProductPackagingWithMethodWithPriceData[]
}

export type WastePriceData = {
	wasteType: WasteType
	priceData: ProductPriceData
}

export type ProductPackagingWithMethodWithPriceData = ProductPackagingWithMethod & {
	priceData: ProductPriceData
	packagingMethod: PackagingMethod
}

export type ProductPriceData =
	| {
			/**
			 * Don't show the thing nor the price.
			 */
			type: "Hide"
			article: null
	  }
	| {
			/**
			 * The thing is enabled and can be selected but the price is not shown.
			 */
			type: "EnabledWithoutPrice"
			article: null
	  }
	| {
			/**
			 * The thing can be selected, but a notice that the price is missing is shown.
			 */
			type: "EnabledButMissingPrice"
			article: null
	  }
	| {
			/**
			 * Show the thing, but it cannot be selected and a call us notice.
			 */
			type: "DisabledWithCallUs"
			article: null
	  }
	| {
			/**
			 * The price is defined by an article.
			 */
			type: "Article"
			article: ResolvedLeafWithTree<ResolvedArticle>
	  }

/**
 * This will convert an array of {@Link ProductDefinition}s to an array of {@Link ProductDefinitionWithPrice}s.
 *
 * The PriceData contains not only price/article but also a type indicating how the production should be visualized
 * (i.e. shown with its price, or hidden in some way).
 *
 * NOTE: We will probably end up moving this logic even further up the component hierarchy.
 */
export function resolveProductDefinitionsWithPriceData(
	productDefs: ProductDefinition[] | null,
	selectedServiceId: string | null,
	consumerCatalog: ConsumerCatalogInstance,
	client: ClientInstance,
	authInstance: AuthInstance,
): ProductDefinitionWithPrice[] | null {
	if (productDefs == null) {
		return null
	}

	return productDefs
		.map((someProductDef) => {
			return exhaustive.tag(someProductDef, "type", {
				WasteProductDefinition: (productDef): WasteProductDefinitionWithPrice | null => {
					if (selectedServiceId == null)
						return {
							...productDef,
							quantityType: "None", //The typing is a bit weird in this case...
							service: null,
							priceData: { type: "EnabledWithoutPrice", article: null },
						}

					let service = productDef.category.services[selectedServiceId]
					if (service == null) {
						return null
					}

					return exhaustive(service, "quantityType", {
						Amount: (): AmountWasteProductDefinitionWithPrice => {
							let priceData = productPriceData(
								consumerCatalog,
								{
									categoryId: productDef.category.id,
									productId: productDef.id,
									serviceId: service.id,
								},
								authInstance,
							)

							return {
								...productDef,
								quantityType: "Amount",
								service: service,
								priceData: priceData,
							}
						},
						Waste: (): WasteWasteProductDefinitionWithPrice => {
							const pricedWaste: WastePriceData[] = productDef.allowedWaste.map((wasteId) => {
								const wasteType = client.possibleWasteTypes[wasteId]
								if (wasteType == null) throwIllegalState("WasteType missing! id: " + wasteId)

								let priceData = productPriceData(
									consumerCatalog,
									{
										categoryId: productDef.category.id,
										productId: productDef.id,
										serviceId: service.id,
										wasteTypeId: wasteId,
									},
									authInstance,
								)

								return {
									wasteType: wasteType,
									priceData,
								}
							})

							const allWasteTypesAreHidden = pricedWaste.every((value) => {
								return value.priceData.type === "Hide"
							})

							return {
								...productDef,
								quantityType: "Waste",
								service: service,
								priceData: { type: allWasteTypesAreHidden ? "Hide" : "EnabledWithoutPrice", article: null },
								pricedWaste,
							}
						},
						_: () => throwIllegalState("Unknown service quantityType! " + service.quantityType),
					})
				},
				GoodsProductDefinition: (productDef): GoodsProductDefinitionWithPrice => {
					const packagings: ProductPackagingWithMethodWithPriceData[] = productDef.packagings.map((packaging) => {
						const packagingMethod = client.possiblePackagingMethods[packaging.packagingMethodId]
						if (packagingMethod == null) {
							throwIllegalState("Missing Packaging Method! " + packaging.packagingMethodId)
						}

						let priceData = productPriceData(
							consumerCatalog,
							{
								categoryId: productDef.category.id,
								productId: productDef.id,
								packagingMethodId: packaging.packagingMethodId,
							},
							authInstance,
						)

						return {
							...packaging,
							packagingMethod,
							priceData,
						}
					})

					let allPackagingsAreHidden = packagings.every((value) => value.priceData.type === "Hide")

					return {
						...productDef,
						priceData: { type: allPackagingsAreHidden ? "Hide" : "EnabledWithoutPrice", article: null },
						packagings,
					}
				},
				_: () => {
					logger.error("Undefined product type in use! " + someProductDef.type)
					return null
				},
			})
		})
		.filter(notNull)
}

export type SearchParameters = {
	categoryId: string
	productId: string
	serviceId?: string
	wasteTypeId?: string
	packagingMethodId?: string
}

export function productPriceData(
	consumerCatalog: ConsumerCatalogInstance,
	searchParameters: SearchParameters,
	authInstance: AuthInstance,
): ProductPriceData {
	const staticProductPricing = consumerCatalog.staticProductPricing
	if (staticProductPricing == null) return { type: "EnabledWithoutPrice", article: null }

	const { categoryId, productId, serviceId, wasteTypeId, packagingMethodId } = searchParameters

	let searchObject: TreeSearchObject = new Map([
		[BranchTargetKeyEnum.Category, categoryId],
		[BranchTargetKeyEnum.ProductDefinition, productId],
	])

	if (serviceId) {
		searchObject.set(BranchTargetKeyEnum.Service, serviceId)
	}

	if (wasteTypeId) {
		searchObject.set(BranchTargetKeyEnum.WasteType, wasteTypeId)
	}

	if (packagingMethodId) {
		searchObject.set(BranchTargetKeyEnum.PackagingMethod, packagingMethodId)
	}

	const article = ArticleResolverService.resolveArticleFromTree(searchObject, staticProductPricing)

	if (article != null && article instanceof ResolvedArticle) {
		return {
			type: "Article",
			article: new ResolvedLeafWithTree<ResolvedArticle>(article, staticProductPricing, LeafOrigin.Regular),
		}
	}

	if (authInstance?.IsLoggedInClient) {
		return { type: "EnabledButMissingPrice", article: null }
	}

	let priceData: ProductPriceData = exhaustive(consumerCatalog.missingPricePolicy, {
		Hide: (): ProductPriceData => ({ type: "Hide", article: null }),
		ShowCallUs: (): ProductPriceData => ({ type: "DisabledWithCallUs", article: null }),
		_: () => throwIllegalState("Unknown MissingPricePolicy used! " + consumerCatalog.missingPricePolicy),
	})

	return priceData
}

export enum ShowSelector {
	Show = "Show",
	Hide = "Hide",
	ShowCallUs = "ShowCallUs",
}

/**
 * Helper to decide if an item's selector should be shown, i.e. incrementor for waste types and such.
 * @param priceData
 */
export function showItemSelector(priceData: ProductPriceData): ShowSelector {
	return exhaustive.tag(priceData, "type", {
		Hide: (): ShowSelector => ShowSelector.Hide,
		EnabledWithoutPrice: (): ShowSelector => ShowSelector.Show,
		EnabledButMissingPrice: (): ShowSelector => ShowSelector.Show,
		DisabledWithCallUs: (): ShowSelector => ShowSelector.ShowCallUs,
		Article: (): ShowSelector => ShowSelector.Show,
	})
}
