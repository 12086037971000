import useLocalStorageState, { LocalStorageState } from "use-local-storage-state"
import { ZodType } from "zod"
import { useClientContext } from "../Client/ClientAndUserProvider"
import { getLogger } from "../Logging/getLogger"

const logger = getLogger("useBrandedLocalStorage")

export type Options<T> = {
	defaultValue: T
}

export const useBrandedLocalStorage = <T>(key: string, schema: ZodType<T>, options: Options<T>): LocalStorageState<T> => {
	const brandRoot = useClientContext()?.identifier

	if (!brandRoot) {
		throw new Error("Unable to resolve client identifier")
	}

	const [data, setData, dataOptions] = useLocalStorageState(`${brandRoot}.${key}`, options)

	if (schema) {
		const parsed = schema.safeParse(data)
		if (parsed.success) {
			return [parsed.data, setData, dataOptions]
		} else {
			logger.warn(`Data in localStorage '${key}' did not match schema, replacing with default values.`)
			dataOptions.removeItem()

			const idiotCheck = schema.safeParse(options.defaultValue)
			if (!idiotCheck.success) {
				throw Error(`The supplied default value does not validate, this is a bug. key: ${key}`)
			}
			return [idiotCheck.data, setData, dataOptions]
		}
	}

	return [data, setData, dataOptions]
}
