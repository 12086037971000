import { faEye, faEyeSlash, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { exhaustive } from "exhaustive"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { OrderCompleted, SkrappyLoading, Thinking } from "../../Lottie/AnimationComponents"
import { useNavigator } from "../../Navigator/useNavigator"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup, removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import style from "./PasswordResetAccept.module.css"

export function PasswordResetAccept() {
	const navigator = useNavigator()
	let [searchParams] = useSearchParams()
	let resetKey = searchParams.get("resetKey")

	if (resetKey == null) {
		return (
			<div>
				<h1>Nu har du hamnat lite fel tyvärr.</h1>
			</div>
		)
	}

	return (
		<PasswordResetAcceptModal
			onCancel={() => navigator.open("")}
			onDone={() => navigator.open("")}
			resetKey={resetKey}
		/>
	)
}

type ModalProps = {
	resetKey: string
	onCancel: () => void
	onDone: () => void
}

const FormSchema = z
	.object({
		newPassword: z.string().min(8, "Minst 8 tecken krävs"),
		newPasswordAgain: z.string().min(8, "Minst 8 tecken krävs"),
	})
	.superRefine((val, ctx) => {
		if (val.newPassword && val.newPasswordAgain && val.newPassword !== val.newPasswordAgain) {
			ctx.addIssue({
				path: ["newPasswordAgain"],
				code: z.ZodIssueCode.custom,
				message: "Ditt nya lösenord måste matcha i båda rutorna",
			})
		}
	})

type FormSchemaType = z.input<typeof FormSchema>

const PasswordResetAcceptModal: FC<ModalProps> = ({ onCancel, onDone, resetKey }) => {
	const auth = useAuth()
	const client = useClient()

	const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
	const [showNewPasswordAgain, setShowNewPasswordAgain] = useState<boolean>(false)

	const [checkResetKey, setCheckResetKey] = useState<"loading" | "ok" | "failed" | "completed">("loading")

	useEffect(() => {
		try {
			API.getWithRetries(`/auth/reset-password-v1/${resetKey}`)
				.then(() => setCheckResetKey("ok"))
				.catch(() => setCheckResetKey("failed"))
		} catch (e) {
			setCheckResetKey("failed")
		}
	}, [resetKey])

	let isLoggedIn = auth.IsLoggedIn

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors, isSubmitting },
		reset,
	} = useForm<FormSchemaType>({
		resolver: async (data, context, options) => {
			return zodResolver(FormSchema)(data, context, options)
		},
	})

	const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
		auth.resetPasswordAndLogin(client.identifier, resetKey, data.newPassword).then(
			() => {
				reset()
				setCheckResetKey("completed")
			},
			() => {
				setError("newPasswordAgain", {
					message:
						"Gick inte att återställa, kontrollera så du fyllt i rätt. Annars får du begära en ny återställning.",
				})
			},
		)
	}

	function onClose() {
		if (!window.confirm("Säker att du vill avbryta lösenordsåterställningen?")) return

		removeModalOpenClass()
		onCancel()
	}

	function done() {
		removeModalOpenClass()
		onDone()
	}

	function renderError() {
		return (
			<>
				<Thinking color={client.branding.colors.accent} />
				<p className={style.infoText}>Återställningslänken är ogiltig eller förbrukad.</p>
				<p className={style.infoText}>
					Om du redan har återställt ditt lösenord och vill återställa igen behöver du begära en ny återställning.
				</p>
			</>
		)
	}

	function renderCompleted() {
		return (
			<>
				<OrderCompleted color={client.branding.colors.accent} />
				<p className={style.infoText}>Återställningen lyckades</p>
				<FinalizeButton type="button" onClick={() => done()}>
					Ok, stäng dialogen
				</FinalizeButton>
			</>
		)
	}

	function renderLoading() {
		return <SkrappyLoading color={client.branding.colors.accent} />
	}

	function renderAcceptForm() {
		return (
			<>
				<p>För att logga in behöver du välja ett nytt lösenord.</p>
				{isLoggedIn ? (
					<p className={style.warning}>Du är redan inloggad, säker att du vill byta ditt lösenord?</p>
				) : null}
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={style.fields}>
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={style.input}
									disabled={isSubmitting}
									type={showNewPassword ? "text" : "password"}
									{...register("newPassword")}
									placeholder="Nytt lösenord"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showNewPassword ? faEyeSlash : faEye}
									onClick={() => {
										setShowNewPassword(!showNewPassword)
									}}
								/>
							</div>

							<SbtRHFError error={errors.newPassword} />
						</label>
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={style.input}
									disabled={isSubmitting}
									type={showNewPasswordAgain ? "text" : "password"}
									{...register("newPasswordAgain")}
									placeholder="Upprepa nytt lösenord"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showNewPasswordAgain ? faEyeSlash : faEye}
									onClick={() => {
										setShowNewPasswordAgain(!showNewPasswordAgain)
									}}
								/>
							</div>

							<SbtRHFError error={errors.newPasswordAgain} />
						</label>
					</div>
					<FinalizeButton disabled={isSubmitting} type="submit">
						Byt lösenord{" "}
						{isSubmitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				</form>
			</>
		)
	}

	return (
		<ModulePopup onClose={onClose} disableClose={isSubmitting} className={style.modal}>
			<div className={style.wrapper}>
				<div className={style.mainPageTitle}>Återställ ditt lösenord</div>
				{exhaustive(checkResetKey, {
					loading: () => renderLoading(),
					ok: () => renderAcceptForm(),
					failed: () => renderError(),
					completed: () => renderCompleted(),
				})}
			</div>
		</ModulePopup>
	)
}
