import { faAngleRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import sv from "date-fns/locale/sv"
import { filter, isArray, uniqBy } from "lodash"
import { DateTime } from "luxon"
import React, { FC, useEffect, useState } from "react"
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { v4 } from "uuid"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ArrowLeftIcon, ArrowRightIcon, CrossIcon } from "../../Icons/Icon"
import { OrderState } from "../../OrderAcceptance/OrderCollection"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { WasteTypeIcon } from "../../Orders/Components/WasteTypeIcon/WasteTypeIcon"
import { WasteTypeImages } from "../../ProductDefinitions"
import { nextWorkday, thirdNextWorkday } from "../../Shared/tomorrow"
import { GetOrder, getStatusElement } from "../CustomerPortalOrders/CustomerPortalOrders"
import style from "./OrderTableFilterSidebar.module.css"
registerLocale("sv", sv)
setDefaultLocale("sv")

type Props = {
	onClose: () => void
	onDone: (filterData: OrderTableFilterData) => void
	orders: GetOrder[]
	filterData?: OrderTableFilterData
}

export type OrderTableFilterOptionType =
	| "contactName"
	| "marking"
	| "orderer"
	| "customer"
	| "street"
	| "city"
	| "date"
	| "time"
	| "state"
	| "wasteType"
	| "service"
	| "category"
	| "customerType"
	| "loggedInUser"
	| "hasPaymentReference"

type OrderTableFilterOption = {
	name: string
	type: OrderTableFilterOptionType
}

export const orderTableFilterOptions: OrderTableFilterOption[] = [
	{
		name: "Kund",
		type: "customer",
	},
	{
		name: "Kundtyp",
		type: "customerType",
	},
	{
		name: "Märkning",
		type: "marking",
	},
	{
		name: "Kontaktperson",
		type: "contactName",
	},
	{
		name: "Beställare",
		type: "orderer",
	},
	{
		name: "Orderläggare",
		type: "loggedInUser",
	},
	{
		name: "Adress",
		type: "street",
	},
	{
		name: "Ort",
		type: "city",
	},
	{
		name: "Datum",
		type: "date",
	},
	{
		name: "Tid",
		type: "time",
	},
	{
		name: "Status",
		type: "state",
	},
	{
		name: "Avfallstyp",
		type: "wasteType",
	},
	{
		name: "Tjänst",
		type: "service",
	},
	{
		name: "Kategori",
		type: "category",
	},
]

type SelectionItem = {
	$id: string
	key: string
	value: string
	typeImage?: WasteTypeImages
}

export enum OrderTableFilterDataCustomerType {
	Individual = "Individual",
	Organization = "Organization",
}

export const OrderTableFilterDataSchema = z.object({
	contactName: z.string().array().optional(),
	marking: z.string().array().optional(),
	orderer: z.string().array().optional(),
	customer: z.string().array().optional(),
	street: z.string().array().optional(),
	city: z.string().array().optional(),
	dateFrom: z.string().optional(),
	dateTo: z.string().optional(),
	time: z.string().array().optional(),
	state: z.string().array().optional(),
	wasteType: z.string().array().optional(),
	service: z.string().array().optional(),
	category: z.string().array().optional(),
	searchText: z.string().optional(),
	customerType: z.nativeEnum(OrderTableFilterDataCustomerType).optional(),
	loggedInUser: z.string().array().optional(),
	dateSlotName: z.string().array().optional(),
	collectionNumber: z.number().optional(),
	hasPaymentReference: z.boolean().optional(),
})

export type OrderTableFilterDataSchemaType = z.input<typeof OrderTableFilterDataSchema>

export type OrderTableFilterData = { [key: string]: string | string[] | number | boolean } & {
	contactName?: string[]
	marking?: string[]
	orderer?: string[]
	customer?: string[]
	street?: string[]
	city?: string[]
	dateFrom?: string
	dateTo?: string
	time?: string[]
	state?: string[]
	wasteType?: string[]
	service?: string[]
	category?: string[]
	searchText?: string
	customerType?: OrderTableFilterDataCustomerType
	loggedInUser?: string[]
	dateSlotName?: string[]
	zipCode?: string[]
	collectionNumber?: number
	hasPaymentReference?: boolean
}
export const OrderTableFilterSidebar: FC<Props> = ({ onClose, orders, filterData, onDone }) => {
	const client = useClient()
	const [openedCategory, setOpenedCategory] = useState<OrderTableFilterOptionType | null>(null)

	const [tick, setTick] = useState<number>(0)

	const [selectedFilterData, setSelectedFilterData] = useState<OrderTableFilterData>(filterData || {})
	const [contactOptions, setContactOptions] = useState<SelectionItem[]>([])
	const [loggedInUserOptions, setLoggedInUserOptions] = useState<SelectionItem[]>([])
	const [markingOptions, setMarkingOptions] = useState<SelectionItem[]>([])
	const [streetOptions, setStreetOptions] = useState<SelectionItem[]>([])
	const [cityOptions, setCityOptions] = useState<SelectionItem[]>([])
	const [ordererOptions, setOrdererOptions] = useState<SelectionItem[]>([])
	const [customerOptions, setCustomerOptions] = useState<SelectionItem[]>([])
	const stateOptions: SelectionItem[] = [
		{
			$id: v4(),
			key: OrderState.Created,
			value: "Ej hanterad",
		},
		{
			$id: v4(),
			key: OrderState.Accepted,
			value: "Accepterad",
		},
		{
			$id: v4(),
			key: OrderState.Declined,
			value: "Nekad",
		},
		{
			$id: v4(),
			key: OrderState.Done,
			value: "Avslutad",
		},
	]
	const [timeOptions, setTimeOptions] = useState<SelectionItem[]>([])
	const [wasteTypeOptions, setWasteTypeOptions] = useState<SelectionItem[]>([])
	const [serviceOptions, setServiceOptions] = useState<SelectionItem[]>([])
	const [categoryOptions, setCategoryOptions] = useState<SelectionItem[]>([])
	const [textSearchValue, setTextSearchValue] = useState<string>("")
	const [selectedDateOption, setSelectedDateOption] = useState<
		"today" | "someDays" | "tomorrow" | "thisWeek" | "dateSlotName" | null
	>(null)
	const customerTypeOptions: SelectionItem[] = [
		{
			$id: v4(),
			key: OrderTableFilterDataCustomerType.Organization,
			value: "Företag",
		},
		{
			$id: v4(),
			key: OrderTableFilterDataCustomerType.Individual,
			value: "Privatpersoner",
		},
	]

	const paymentReferenceOptions: SelectionItem[] = [
		{
			$id: v4(),
			key: "true",
			value: "Ja",
		},
		{
			$id: v4(),
			key: "false",
			value: "Nej",
		},
	]

	useEffect(() => {
		if (client.features.allowKlarnaIntegration) {
			if (!orderTableFilterOptions.find((x) => x.type === "hasPaymentReference")) {
				orderTableFilterOptions.push({
					name: "Betalad med klarna?",
					type: "hasPaymentReference",
				})
			}
		}
		orders.forEach((order) => {
			if (order.contact) {
				contactOptions.push({ key: order.contact.name, value: order.contact.name, $id: v4() })
			} else if (order.contactPersons) {
				order.contactPersons.forEach((x) => {
					contactOptions.push({ key: x.name, value: x.name, $id: v4() })
				})
			}

			if (
				order.loggedInUser &&
				`${order.loggedInUser.firstName || ""} ${order.loggedInUser.lastName || ""}` !==
					order.customer.ordererName &&
				order.loggedInUser.email !== order.customer.email
			) {
				loggedInUserOptions.push({
					key: order.loggedInUser.id,
					value: `${order.loggedInUser.firstName || ""} ${order.loggedInUser.lastName || ""}`,
					$id: v4(),
				})
			}

			markingOptions.push({ key: order.marking, value: order.marking, $id: v4() })
			streetOptions.push({ key: order.address.street, value: order.address.street, $id: v4() })
			cityOptions.push({ key: order.address.city, value: order.address.city, $id: v4() })
			customerOptions.push({ key: order.customer.name, value: order.customer.name, $id: v4() })
			if (order.customer.ordererName) {
				ordererOptions.push({ key: order.customer.ordererName, value: order.customer.ordererName, $id: v4() })
			}
			timeOptions.push({ key: order.datetime.timeDescription, value: order.datetime.timeDescription, $id: v4() })
			order.products.forEach((product) => {
				if (product.wasteType) {
					wasteTypeOptions.push({
						key: product.wasteType.name,
						value: product.wasteType.name,
						$id: v4(),
						typeImage: product.wasteType.typeImage,
					})
				}
				if (product.service) {
					serviceOptions.push({ key: product.service.name, value: product.service.name, $id: v4() })
				}
				if (product.category) {
					categoryOptions.push({ key: product.category.name, value: product.category.name, $id: v4() })
				}
			})
		})
		setContactOptions(sortStringOptionsAscending(uniqBy(contactOptions, (opt) => opt.key.toLocaleLowerCase())))
		setMarkingOptions(
			sortStringOptionsAscending(
				uniqBy(
					markingOptions.filter((x) => x.value),
					(opt) => opt.key.toLocaleLowerCase(),
				),
			),
		)
		setStreetOptions(uniqBy(streetOptions, (opt) => opt.key.toLocaleLowerCase()))
		setCityOptions(sortStringOptionsAscending(uniqBy(cityOptions, (opt) => opt.key.toLocaleLowerCase())))
		setOrdererOptions(sortStringOptionsAscending(uniqBy(ordererOptions, (opt) => opt.key.toLocaleLowerCase())))
		setCustomerOptions(sortStringOptionsAscending(uniqBy(customerOptions, (opt) => opt.key.toLocaleLowerCase())))
		setTimeOptions(sortStringOptionsAscending(uniqBy(timeOptions, (opt) => opt.key.toLocaleLowerCase())))
		setWasteTypeOptions(sortStringOptionsAscending(uniqBy(wasteTypeOptions, (opt) => opt.key.toLocaleLowerCase())))
		setServiceOptions(sortStringOptionsAscending(uniqBy(serviceOptions, (opt) => opt.key.toLocaleLowerCase())))
		setCategoryOptions(sortStringOptionsAscending(uniqBy(categoryOptions, (opt) => opt.key.toLocaleLowerCase())))
		setLoggedInUserOptions(uniqBy(loggedInUserOptions, (opt) => opt.key.toLocaleLowerCase()))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function sortStringOptionsAscending(options: SelectionItem[]): SelectionItem[] {
		return options.sort((a, b) => {
			if (a.key.toLocaleLowerCase() > b.key.toLocaleLowerCase()) {
				return 1
			}
			if (b.key.toLocaleLowerCase() > a.key.toLocaleLowerCase()) {
				return -1
			}
			return 0
		})
	}

	function getData(type: OrderTableFilterOptionType): SelectionItem[] {
		let ret: SelectionItem[]
		switch (type) {
			case "customer":
				ret = customerOptions
				break
			case "street":
				ret = streetOptions
				break
			case "city":
				ret = cityOptions
				break
			case "contactName":
				ret = contactOptions
				break
			case "marking":
				ret = markingOptions
				break
			case "orderer":
				ret = ordererOptions
				break
			case "service":
				ret = serviceOptions
				break
			case "state":
				ret = stateOptions
				break
			case "time":
				ret = timeOptions
				break
			case "wasteType":
				ret = wasteTypeOptions
				break
			case "category":
				ret = categoryOptions
				break
			case "customerType":
				ret = customerTypeOptions
				break
			case "loggedInUser":
				ret = loggedInUserOptions
				break
			case "hasPaymentReference":
				ret = paymentReferenceOptions
				break
			default:
				ret = []
				break
		}

		if (textSearchValue) {
			ret = ret.filter((x) => x.value.toLocaleLowerCase().indexOf(textSearchValue.toLocaleLowerCase()) > -1)
		}

		return ret
	}

	function setSelectedValue(value: string, type: keyof OrderTableFilterData) {
		if (type === "dateFrom" || type === "dateTo" || type === "searchText" || type === "customerType") {
			selectedFilterData[type as string] = value
		} else if (type === "hasPaymentReference") {
			selectedFilterData[type as string] = value === "true"
		} else {
			if (isArray(selectedFilterData[type])) {
				const arr = selectedFilterData[type] as string[]
				if (arr.includes(value)) {
					arr.splice(arr.indexOf(value), 1)
					if (arr.length === 0) {
						delete selectedFilterData[type]
					}
				} else {
					;(selectedFilterData[type] as string[]).push(value)
				}
			} else {
				selectedFilterData[type] = [value]
			}
		}
		setSelectedFilterData(selectedFilterData)
		setTick(tick + 1)
	}

	function getFormattedDateString(date: Date) {
		return `${date.getFullYear()}-${
			date.getMonth() < 8 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1
		}-${date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate()}`
	}

	function categoriesItems(): JSX.Element | null {
		if (openedCategory) {
			return null
		}

		return (
			<div style={{ position: "relative", height: "calc(100% - 60px)" }}>
				<div className={style.clearAllFiltersButtonWrapper}>
					<button
						className={style.clearAllFiltersButton}
						disabled={Object.keys(selectedFilterData).filter((x) => x).length === 0}
						onClick={() => {
							setSelectedFilterData({})
							setTick(tick + 1)
						}}>
						Rensa alla filter
					</button>
				</div>
				<span style={{ height: "calc(100% - 65px)", display: "block", overflowY: "auto" }}>
					{orderTableFilterOptions.map((x) => {
						return (
							<React.Fragment key={v4()}>
								<div
									key={v4()}
									className={style.optionsHeader}
									onClick={() => {
										setOpenedCategory(x.type)
									}}>
									<FontAwesomeIcon icon={faAngleRight} style={{ width: "15px" }} /> {x.name}{" "}
									{selectedFilterData[x.type] !== undefined ||
									(x.type === "date" &&
										(selectedFilterData["dateFrom"] ||
											selectedFilterData["dateTo"] ||
											selectedFilterData["dateSlotName"])) ? (
										<button
											className={style.unselectOptionButton}
											onClick={(event) => {
												event.preventDefault()
												event.stopPropagation()
												if (x.type === "date") {
													delete selectedFilterData["dateFrom"]
													delete selectedFilterData["dateTo"]
												} else {
													delete selectedFilterData[x.type]
												}
												setSelectedFilterData(selectedFilterData)
												setTick(tick + 1)
											}}>
											Ta bort
										</button>
									) : null}
								</div>
							</React.Fragment>
						)
					})}
				</span>

				<FinalizeButton
					onClick={() => {
						setOpenedCategory(null)
						onClose()
						onDone(selectedFilterData)
					}}
					className={style.finalizeButton}>
					Klar
				</FinalizeButton>
			</div>
		)
	}

	function openedCategoryItems(): JSX.Element | null {
		if (!openedCategory) {
			return null
		}

		let disableFinalizeButtons
		if (openedCategory === "date") {
			if (selectedDateOption === "dateSlotName") {
				disableFinalizeButtons = false
			} else {
				disableFinalizeButtons = !selectedFilterData["dateTo"] && !selectedFilterData["dateFrom"]
			}
		} else {
			disableFinalizeButtons = selectedFilterData[openedCategory] === undefined
		}

		return (
			<div style={{ position: "relative", height: "calc(100% - 60px)" }}>
				<div className={style.headerText} style={{ marginBottom: "20px", marginLeft: 0 }}>
					{orderTableFilterOptions.find((x) => x.type === openedCategory)?.name}
				</div>
				{openedCategory !== "date" && openedCategory !== "state" ? (
					<span className={style.searchInputWrapper}>
						<span>
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</span>
						<input
							type="search"
							placeholder={"Sök"}
							value={textSearchValue}
							onChange={(e) => {
								setTextSearchValue(e.target.value)
							}}
						/>
					</span>
				) : null}
				<div className={style.selectionItemsWrapper}>
					{openedCategory === "date"
						? openedDateCategory()
						: getData(openedCategory).map((dataItem) => {
								let checked

								const data = selectedFilterData[openedCategory]
								if (isArray(data)) {
									checked = data.includes(dataItem.key)
								} else {
									if (openedCategory === "hasPaymentReference") {
										const textToBool = dataItem.key === "true"
										checked = data === textToBool
									} else {
										checked = data === dataItem.key
									}
								}
								return (
									<div
										key={dataItem.$id + "_inputWrapper"}
										style={{ display: "flex", alignItems: "center", gap: "5px" }}>
										<input
											id={dataItem.$id + "_input"}
											type={openedCategory === "customerType" ? "radio" : "checkbox"}
											name={openedCategory + "_name"}
											value={dataItem.key}
											defaultChecked={checked}
											onChange={(event) => {
												setSelectedValue(event.target.value, openedCategory)
											}}
											className={style.input}
										/>{" "}
										<label
											style={{ width: "240px", display: "flex", alignItems: "center" }}
											htmlFor={dataItem.$id + "_input"}>
											{openedCategory === "wasteType" && dataItem.typeImage ? (
												<WasteTypeIcon className={style.wasteIcon} wasteType={dataItem.typeImage} />
											) : null}
											{openedCategory === "state"
												? getStatusElement(dataItem.key as OrderState)
												: (dataItem.value[0]?.toLocaleUpperCase() || "") +
												  dataItem.value.substring(1)}
										</label>
									</div>
								)
						  })}
				</div>
				<FinalizeButton
					onClick={() => {
						if (openedCategory === "date") {
							setSelectedDateOption(null)
							delete selectedFilterData["dateFrom"]
							delete selectedFilterData["dateTo"]
							delete selectedFilterData["dateSlotName"]
						} else {
							delete selectedFilterData[openedCategory]
						}
						setSelectedFilterData(selectedFilterData)
						setTick(tick + 1)
					}}
					disabled={disableFinalizeButtons}
					className={style.finalizeButtonClear}>
					Rensa
				</FinalizeButton>
				<FinalizeButton
					onClick={() => {
						setOpenedCategory(null)
					}}
					disabled={disableFinalizeButtons}
					className={style.finalizeButton}>
					Välj
				</FinalizeButton>
			</div>
		)
	}

	function openedDateCategory(): JSX.Element | null {
		if (openedCategory !== "date") {
			return null
		}

		const dateSlotOrders = uniqBy(
			filter(orders, (y) => !!y.datetime?.dateSlot?.name),
			(x) => x.datetime.dateSlot?.name,
		).filter((x) => x)

		return (
			<>
				{dateSlotOrders.length > 0 ? (
					<>
						<div style={{ color: "var(--sidebar-text-color)", fontSize: "18px", fontWeight: 600 }}>
							Datumalternativ
						</div>
						{dateSlotOrders.map((x) => {
							const dateSlot = x.datetime?.dateSlot
							if (!dateSlot) {
								return null
							}

							return (
								<div key={v4()}>
									<input
										id={dateSlot.name + "_dateSlotName"}
										type="radio"
										name={openedCategory + "_name"}
										value={dateSlot.name}
										defaultChecked={
											selectedDateOption === "dateSlotName" &&
											selectedFilterData["dateSlotName"]?.includes(dateSlot.name)
										}
										onChange={() => {
											delete selectedFilterData["dateFrom"]
											delete selectedFilterData["dateTo"]
											setSelectedValue(dateSlot.name, "dateSlotName")
											setSelectedDateOption("dateSlotName")
											setTick((tick) => tick + 1)
										}}
										className={style.input}
									/>{" "}
									<label htmlFor={dateSlot.name + "_dateSlotName"}>{dateSlot.name}</label>
								</div>
							)
						})}
						<hr style={{ border: "none", borderTop: "1px solid var(--sidebar-text-color)" }} />
					</>
				) : null}
				<div
					style={{
						color: "var(--sidebar-text-color)",
						marginTop: dateSlotOrders.length > 0 ? "20px" : 0,
						fontSize: "18px",
						fontWeight: 600,
					}}>
					Datumintervall
				</div>
				<div key={v4()}>
					<input
						id={"today_input"}
						type="radio"
						name={openedCategory + "_name"}
						value={"today"}
						defaultChecked={selectedDateOption === "today"}
						onChange={() => {
							const today = DateTime.now().setZone("Europe/Stockholm").toFormat("yyyy-MM-dd")
							setSelectedValue(today, "dateFrom")
							setSelectedValue(today, "dateTo")
							setTick(tick + 1)
							setSelectedDateOption("today")
						}}
						className={style.input}
					/>{" "}
					<label htmlFor={"today_input"}>Idag</label>
				</div>
				<div key={v4()}>
					<input
						id={"tomorrow_input"}
						type="radio"
						name={openedCategory + "_name"}
						value={"tomorrow"}
						defaultChecked={selectedDateOption === "tomorrow"}
						onChange={() => {
							setSelectedValue(nextWorkday(), "dateFrom")
							setSelectedValue(nextWorkday(), "dateTo")
							setTick(tick + 1)
							setSelectedDateOption("tomorrow")
						}}
						className={style.input}
					/>{" "}
					<label htmlFor={"tomorrow_input"}>
						Imorgon <small>Nästa arbetsdag</small>
					</label>
				</div>
				<div key={v4()}>
					<input
						id={"someDays_input"}
						type="radio"
						name={openedCategory + "_name"}
						value={"someDays"}
						defaultChecked={selectedDateOption === "someDays"}
						onChange={() => {
							setSelectedValue(nextWorkday(), "dateFrom")
							setSelectedValue(thirdNextWorkday(), "dateTo")
							setTick(tick + 1)
							setSelectedDateOption("someDays")
						}}
						className={style.input}
					/>{" "}
					<label htmlFor={"someDays_input"}>1-3 arbetsdagar</label>
				</div>
				<div key={v4()}>
					<input
						id={"thisWeekDate_input"}
						type="radio"
						name={openedCategory + "_name"}
						value={"thisWeek"}
						defaultChecked={selectedDateOption === "thisWeek"}
						onChange={() => {
							setSelectedValue(
								DateTime.now().setZone("Europe/Stockholm").startOf("week").toFormat("yyyy-MM-dd"),
								"dateFrom",
							)
							setSelectedValue(
								DateTime.now().setZone("Europe/Stockholm").endOf("week").toFormat("yyyy-MM-dd"),
								"dateTo",
							)
							setTick(tick + 1)
							setSelectedDateOption("thisWeek")
						}}
						className={style.input}
					/>{" "}
					<label htmlFor={"thisWeekDate_input"}>Den här veckan</label>
				</div>
				<div className={style.datePickersWrapper}>
					<ReactDatePicker
						selected={selectedFilterData["dateFrom"] ? new Date(selectedFilterData["dateFrom"]) : undefined}
						onChange={(date: Date | null) => {
							setSelectedDateOption(null)
							if (date) {
								setSelectedValue(getFormattedDateString(date), "dateFrom")
								if (selectedFilterData["dateTo"] && date > new Date(selectedFilterData["dateTo"])) {
									setSelectedValue(getFormattedDateString(date), "dateTo")
								}
							} else {
								delete selectedFilterData["dateFrom"]
								setSelectedFilterData(selectedFilterData)
							}
							setTick(tick + 1)
						}}
						isClearable={true}
						dateFormat={"yyyy-MM-dd"}
						className={style.dateSelectInput}
						placeholderText={"Från"}
					/>
					<ArrowRightIcon iconClassName={style.navigationArrow} size={16} />
					<ReactDatePicker
						selected={selectedFilterData["dateTo"] ? new Date(selectedFilterData["dateTo"]) : undefined}
						onChange={(date: Date | null) => {
							setSelectedDateOption(null)
							if (date) {
								setSelectedValue(getFormattedDateString(date), "dateTo")
								if (selectedFilterData["dateFrom"] && date < new Date(selectedFilterData["dateFrom"])) {
									setSelectedValue(getFormattedDateString(date), "dateFrom")
								}
							} else {
								delete selectedFilterData["dateTo"]
								setSelectedFilterData(selectedFilterData)
							}
							setTick(tick + 1)
						}}
						isClearable={true}
						dateFormat={"yyyy-MM-dd"}
						className={style.dateSelectInput}
						placeholderText={"Till"}
					/>
				</div>
			</>
		)
	}

	return (
		<>
			<div className={style.backdrop} onClick={() => onClose()}></div>
			<div className={style.sidebar}>
				<div className={style.closeButtonWrapper}>
					{openedCategory ? (
						<span
							style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
							onClick={() => {
								setTextSearchValue("")
								setOpenedCategory(null)
							}}>
							<ArrowLeftIcon iconClassName={style.backArrow} size={28} />
						</span>
					) : (
						<>
							<div className={style.headerText}>Filtrera</div>
							<span
								className={style.closeX}
								onClick={() => {
									onClose()
								}}>
								<CrossIcon size={22} className={style.closeXIcon} iconClassName={style.closeXIconInside} />
							</span>
						</>
					)}
				</div>
				{!openedCategory ? categoriesItems() : openedCategoryItems()}
			</div>
		</>
	)
}
