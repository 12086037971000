import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isUndefined } from "lodash"
import { FC, useState } from "react"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { when } from "Shared/when"
import style from "./ButtonWithConfirmation.module.css"

type ButtonWithConfirmationProp = { onClick: () => Promise<void>; buttonText?: string; icon: ReactElement }

/**
 * Button that shows a spinner while the api call is being performed and a check mark when the call is completed.
 * @param onClick the call that will be executed when the button is clicked
 * @param buttonText optional buttontext that will be rendered to the right of the icon
 * @param icon a ReactElement that is rendered in the button when it is possible to execute the action, this will be replaced by a spinner when the action is being performed and a check mark when the action is completed.
 */

export const ButtonWithConfirmation: FC<ButtonWithConfirmationProp> = ({ onClick, buttonText, icon }) => {
	enum ButtonStateEnum {
		Start,
		Loading,
		Complete,
	}
	const [buttonState, setButtonState] = useState(ButtonStateEnum.Start)

	function handleClick() {
		setButtonState(ButtonStateEnum.Loading)
		onClick().finally(() => {
			setButtonState(ButtonStateEnum.Complete)
			window.setTimeout(() => setButtonState(ButtonStateEnum.Start), 1000)
		})
	}

	return (
		<div className={style.confirmButtonWrapper}>
			{when(buttonState, {
				[ButtonStateEnum.Start]: () => (
					<div onClick={handleClick} className={style.confirmButtonContent}>
						<div className={style.confirmButtonContentItem}>{icon}</div>
						{isUndefined(buttonText) ? null : <span className={style.confirmButtonText}>{buttonText}</span>}
					</div>
				),
				[ButtonStateEnum.Loading]: () => (
					<div className={style.confirmButtonContent}>
						<div className={style.confirmButtonContentItem}>
							<FontAwesomeIcon icon={faSpinner} size={"lg"} fixedWidth spin />
						</div>
						{isUndefined(buttonText) ? null : <span className={style.confirmButtonText}>{buttonText}</span>}
					</div>
				),
				[ButtonStateEnum.Complete]: () => (
					<div className={style.confirmButtonContent}>
						<div className={style.confirmButtonContentItem}>
							<FontAwesomeIcon icon={faCheck} size={"lg"} fixedWidth />
						</div>
						{isUndefined(buttonText) ? null : <span className={style.confirmButtonText}>{buttonText}</span>}
					</div>
				),
			})}
		</div>
	)
}
