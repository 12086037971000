import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { z } from "zod"
import { getLogger } from "../Logging/getLogger"
import { API } from "../network/API"
import { SbtRHFError } from "../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { DemoProps } from "./demoState"
import style from "./DemoStyle.module.css"
import { ReactComponent as AddressSvg } from "./Images/address.svg"
import { ReactComponent as GiftSvg } from "./Images/gift.svg"

const logger = getLogger("Demo4OptionalAddress")

const DemoOptionalAddressSchema = z.object({
	street: z.string().min(1),
	postalCode: z.string().min(1),
	city: z.string().min(1),
})
type DemoOptionalAddressSchemaType = z.input<typeof DemoOptionalAddressSchema>

export function Demo4OptionalAddress({ setDemoState }: DemoProps) {
	const { clientName } = useParams()

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<DemoOptionalAddressSchemaType>({
		resolver: async (data, context, options) => {
			return zodResolver(DemoOptionalAddressSchema)(data, context, options)
		},
	})

	const onSubmit: SubmitHandler<DemoOptionalAddressSchemaType> = async (data) => {
		const request = {
			clientIdentifier: clientName,
			...data,
		}

		try {
			await API.post("/survey/generic-v1/demo-optional-address", request)
		} catch (e) {
			logger.log("Error when submitting survey, nothing to crash about", e)
		}
		setDemoState("thank-you")
	}

	const onNoThanks = () => {
		setDemoState("thank-you")
	}

	return (
		<>
			<div className={style.topImage}>
				<GiftSvg />
			</div>
			<h3>PRESENT</h3>
			<h1>Nu vill vi ge något tillbaka till dig!</h1>
			<p>Detta är ovärderligt för oss och vi vill ge något tillbaka till dig, som ett bevis på vår tacksamhet.</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={style.formSection}>
					<div className={style.sectionHeader}>
						<div className={style.icon}>
							<AddressSvg />
						</div>
						<div>
							<h1>Skriv in din adress</h1>
							<p>Så postar vi hem en väldoftande present till dig!</p>
						</div>
					</div>
					<div className={style.sectionInputs}>
						<label>
							Gatuadress*
							<input {...register("street")} />
							<SbtRHFError error={errors.street} />
						</label>
					</div>
					<div className={style.sectionInputs}>
						<label>
							Postnummer*
							<input {...register("postalCode")} />
							<SbtRHFError error={errors.postalCode} />
						</label>
						<label>
							Ort*
							<input {...register("city")} />
							<SbtRHFError error={errors.city} />
						</label>
					</div>
				</div>

				<a
					href={"https://skrappy.se/privacy-notice/"}
					rel="noreferrer"
					target="_blank"
					className={style.privacyNotice}>
					* läs mer om hur vi hanterar personuppgifter
				</a>

				<button type={"submit"} disabled={isSubmitting}>
					Ja tack, skicka en present.
				</button>
				<button type={"button"} className={style.secondary} onClick={onNoThanks}>
					Nej tack.
				</button>
			</form>
		</>
	)
}
