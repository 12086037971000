import { FC, useState } from "react"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { FieldModuleBox } from "../../Orders/Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "../../Orders/Components/FieldModuleBox/FieldModuleBoxWrapper"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulChooserButton } from "../../Orders/Components/ModulChooserButton/ModulChooserButton"
import { ModulePopup, removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { MbtH5 } from "../../Orders/Components/Text/MbtH5/MbtH5"
import { convertTextToInitials } from "../../Orders/Helpers"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import style from "./SelectActiveConsumer.module.css"

type SelectActiveConsumerProps = {
	onClose: () => void
}
export const SelectActiveConsumer: FC<SelectActiveConsumerProps> = ({ onClose: extOnClose }) => {
	const auth = useAuth()

	const [selectedConsumerId, setSelectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})
	const [selectedItem, setSelectedItem] = useState<string>(selectedConsumerId)
	const [, setTick] = useState(0)

	if (!auth.Me || !auth.Me.consumers) {
		return null
	}

	function onDone() {
		setSelectedConsumerId(selectedItem)
		onClose()
	}

	function onClose() {
		removeModalOpenClass()
		extOnClose()
	}

	return (
		<ModulePopup className={style.modal} onClose={onClose}>
			<div className={style.wrapper}>
				<div className={style.header}>Välj konto</div>
				<FieldModuleBoxWrapper className={style.fields}>
					{auth.Me.consumers.map((consumerData) => {
						return (
							<FieldModuleBox
								className={style.item}
								key={consumerData.id}
								selected={selectedItem === consumerData.id}>
								<div className={style.itemContent}>
									<span className={style.profilePicture}>
										{consumerData.profilePicture ? (
											<img
												src={consumerData.profilePicture}
												onError={() => {
													consumerData.profilePicture = undefined
													setTick((tick) => tick + 1)
												}}
												alt=""
											/>
										) : (
											<AbsolutCentered>
												<span style={{ color: "white" }}>
													{convertTextToInitials(consumerData.name)}
												</span>
											</AbsolutCentered>
										)}
									</span>
									<div style={{ wordBreak: "break-all" }}>
										<MbtH5>{consumerData.name}</MbtH5>
										<MbtH5 styles={{ fontWeight: 300, fontSize: "16px" }}>
											{consumerData.identifier}
										</MbtH5>
									</div>
								</div>
								<ModulChooserButton
									selected={selectedItem === consumerData.id}
									onClick={() => {
										setSelectedItem(consumerData.id)
									}}>
									{!!(selectedConsumerId && selectedConsumerId === consumerData.id) ? "Vald" : "Välj"}
								</ModulChooserButton>
							</FieldModuleBox>
						)
					})}
				</FieldModuleBoxWrapper>
				<FinalizeButton className={style.finalize} onClick={() => onDone()}>
					Klar
				</FinalizeButton>
			</div>
		</ModulePopup>
	)
}
