import { useEffect, useState } from "react"
import { Fraktion32Icon } from "../../Icons/Icon"
import style from "./CountDownIndicator.module.css"

type Props = {
	count: number
	done: () => void
}

export function CountDownIndicator(props: Props) {
	const [count, setCount] = useState(props.count)

	useEffect(() => {
		let timeout = window.setTimeout(() => {
			if (count <= 0) {
				props.done()
			} else {
				setCount((prev) => {
					return prev - 1
				})
			}
		}, 1000)

		return () => {
			window.clearInterval(timeout)
		}
	}, [count, props])

	return (
		<div className={style.feedbackIndicator}>
			<div>
				<div>Feedbackformulär</div>
				<div>Startar om:</div>
			</div>
			<div className={style.iconBox}>
				<Fraktion32Icon size={null} className={style.icon} />
				<div className={style.counter}>{count}</div>
			</div>
		</div>
	)
}
