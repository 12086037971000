import { createRef, useEffect } from "react"
import { ProductTransportation } from "../../../Client/ProductDefinitionsByCategories"
import { TransportationType } from "../../../Client/TransportationDetails"
import { getLogger } from "../../../Logging/getLogger"
import { SvgComponent } from "../../../Shared/svgComponent"
import { ReactComponent as CraneTruckSvg } from "./images/CraneTruck.svg"
import { ReactComponent as CraneTruckSmallSvg } from "./images/CraneTruckSmall.svg"
import { ReactComponent as DeliveryTruckSvg } from "./images/DeliveryTruck.svg"
import { ReactComponent as RollOfDumpsterTruckSvg } from "./images/RollOfDumpsterTruck.svg"
import { ReactComponent as RollOfDumpsterTruckWithTrailer1Svg } from "./images/RollOfDumpsterTruckWithTrailer1.svg"
import { ReactComponent as RollOfDumpsterTruckWithTrailer2Svg } from "./images/RollOfDumpsterTruckWithTrailer2.svg"
import { ReactComponent as SkipLoaderTruckSvg } from "./images/SkipLoaderTruck.svg"
import { ReactComponent as SkipLoaderTruckWithTrailer1Svg } from "./images/SkipLoaderTruckWithTrailer1.svg"
import { ReactComponent as SkipLoaderTruckWithTrailer2Svg } from "./images/SkipLoaderTruckWithTrailer2.svg"
import { ReactComponent as TailGateLiftTruckSvg } from "./images/TailGateLiftTruck.svg"
import { ReactComponent as TipperTruckSvg } from "./images/TipperTruck.svg"
import { ReactComponent as TipperTruckWithTrailer1Svg } from "./images/TipperTruckWithTrailer1.svg"
import { ReactComponent as TipperTruckWithTrailer2Svg } from "./images/TipperTruckWithTrailer2.svg"
import style from "./TransportDescription.module.css"

const logger = getLogger("TransportDescription")

const transportTypeToSvg: Record<TransportationType, SvgComponent> = {
	CraneTruck: CraneTruckSvg,
	CraneTruckSmall: CraneTruckSmallSvg,
	DeliveryTruck: DeliveryTruckSvg,
	RollOfDumpsterTruck: RollOfDumpsterTruckSvg,
	RollOfDumpsterTruckWithTrailer1: RollOfDumpsterTruckWithTrailer1Svg,
	RollOfDumpsterTruckWithTrailer2: RollOfDumpsterTruckWithTrailer2Svg,
	SkipLoaderTruck: SkipLoaderTruckSvg,
	SkipLoaderTruckWithTrailer1: SkipLoaderTruckWithTrailer1Svg,
	SkipLoaderTruckWithTrailer2: SkipLoaderTruckWithTrailer2Svg,
	TailGateLiftTruck: TailGateLiftTruckSvg,
	TipperTruck: TipperTruckSvg,
	TipperTruckWithTrailer1: TipperTruckWithTrailer1Svg,
	TipperTruckWithTrailer2: TipperTruckWithTrailer2Svg,
}

type Props = {
	type: TransportationType
	data: Record<string, string>
	id?: string
}

export const getDataForTransportSvg = (transport: ProductTransportation): Record<string, string> => {
	const data: Record<string, string> = {}
	if (transport.transportationDetails.height !== "-") {
		data["H,H"] = transport.transportationDetails.height
	}

	if (transport.transportationDetails.width !== "-") {
		data["W,W"] = transport.transportationDetails.width
	}

	if ("maxReach" in transport.transportationDetails && transport.transportationDetails.maxReach !== "-") {
		data["M,M"] = transport.transportationDetails.maxReach
	}

	if (
		"length" in transport.transportationDetails &&
		transport.transportationDetails.length &&
		transport.transportationDetails.length !== "-"
	) {
		data["L,L"] = transport.transportationDetails.length
	}

	if ("containerLength" in transport.transportationDetails && transport.transportationDetails.containerLength !== "-") {
		data["C,C"] = transport.transportationDetails.containerLength
	}

	if ("tailGateLength" in transport.transportationDetails && transport.transportationDetails.tailGateLength !== "-") {
		data["T,T"] = transport.transportationDetails.tailGateLength
	}

	if (
		"baseVehicleLength" in transport.transportationDetails &&
		transport.transportationDetails.baseVehicleLength !== "-"
	) {
		data["B,B"] = transport.transportationDetails.baseVehicleLength
	}

	if ("trailerLength" in transport.transportationDetails && transport.transportationDetails.trailerLength !== "-") {
		data["S,S"] = transport.transportationDetails.trailerLength
	}

	return data
}

/**
 * Sets height, width, length etc. on a transport definition SVG
 *
 * @param svgElement
 * @param data
 */
export function setTransportSvgDetails(svgElement: SVGSVGElement, data: Record<string, string>) {
	const allKeys = Object.getOwnPropertyNames(data)
	const tags = svgElement.getElementsByTagName("tspan") as HTMLCollectionOf<SVGTSpanElement>

	const unmatchedTags = [...tags].filter((tag) => {
		let notFound = true
		for (const key of allKeys) {
			const value = data[key]
			if (value === undefined) {
				logger.error(`Undefined value for key: ${key}`)
				continue
			}

			if (tag.textContent === undefined) {
				logger.debug("textContent not defined on tag: " + JSON.stringify(tag))
				notFound = notFound && true
				break
			}

			if (tag.textContent?.includes(key)) {
				const oldText = tag.textContent ?? ""
				const newText = oldText.replace(key, value)
				tag.textContent = newText

				logger.debug(`Found '${key}', replaced: '${oldText}', with: '${newText}'`)
				notFound = notFound && false
				break
			}

			notFound = notFound && true
		}
		return notFound
	})

	if (unmatchedTags.length > 0) {
		logger.debug(`unMatchedTags: ${unmatchedTags.map((tag) => tag.textContent)}`)
	}
}

export const TransportDescription = (props: Props) => {
	const ref = createRef<SVGSVGElement>()

	useEffect(() => {
		if (ref.current === null) {
			logger.error("Ref not set yet")
			return
		}

		setTransportSvgDetails(ref.current, props.data)
	}, [props.data, ref])

	const Svg = transportTypeToSvg[props.type]

	return <>{Svg ? <Svg id={props.id} width={undefined} height={undefined} ref={ref} className={style.svg} /> : null}</>
}
