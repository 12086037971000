import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEventHandler, FC } from "react"
import { cls } from "../../../../Shared/cls"
import style from "./ToggleSwitchSlim.module.css"

export type Props = {
	onChange: ChangeEventHandler<HTMLInputElement> | undefined
	checked?: boolean
}
export const ToggleSwitchSlim: FC<Props> = ({ onChange, checked }) => {
	return (
		<label className={style.switch}>
			<input type={"checkbox"} checked={checked} onChange={onChange} />
			<span className={cls(style.slider, style.round)}>
				<FontAwesomeIcon className={style.iconColor} icon={faCheck} />
			</span>
		</label>
	)
}
