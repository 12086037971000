import log, { LogLevelNames } from "loglevel"

/**
 * Set log level on Root logger.
 */
export function setupRootLogger() {
	log.setDefaultLevel((process.env.REACT_APP_LOGLEVEL as LogLevelNames) ?? "error")
}

export const loglevelConfig: { [loggerName: string]: LogLevelNames | undefined } = {}

/**
 * Development specific log settings.
 */
if (process.env.NODE_ENV === "development") {
	loglevelConfig["ArticleTreeResolver"] = "warn"
	loglevelConfig["SetBrandingCssVariables"] = "warn"
}
