import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { isNull, split } from "lodash"
import { ChangeEvent, FC, useState } from "react"
import { useForm } from "react-hook-form"
import { v4 } from "uuid"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { TrashIcon } from "../../Icons/Icon"
import { getLogger } from "../../Logging/getLogger"
import { API, ServerError } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import { SelectedFile } from "../../Orders/Components/OrderConfirmCheckout/OrderConfirmCheckout"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { cleanMimeString, getImageURLFromBase64 } from "../../Orders/Helpers"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "../../Orders/order-data-model"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import { getFile as getFileExt } from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import {
	default as consumerInformationStyle,
	default as consumerInfoStyle,
} from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { GetUser } from "../Users/Users"
import style from "./EditUser.module.css"

const logger = getLogger("EditUser")

export const UserFormSchema = z.object({
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	email: z.string().email("Felaktigt format på e-post").min(1),
	phoneNumber: z
		.string()
		.optional()
		.transform((x) => (!x ? null : x))
		.refine(
			(data) => {
				if (data === null) {
					return true
				}
				return validatePhoneNumber(data)
			},
			{ message: invalidPhoneNumberMessage },
		),
})

type UserForm = z.input<typeof UserFormSchema>

type Props = {
	type: "Client" | "Consumer"
	consumerId?: string
	user: GetUser
	disableEditing: boolean
	onClose: () => void
	onDone: () => void
	onProfilePicChange: (userId: string, url: string) => void
}
export const EditUser: FC<Props> = ({
	type,
	consumerId,
	user,
	onClose: extOnClose,
	onDone: extOnDone,
	onProfilePicChange,
	disableEditing,
}) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	const [selectedImage, setSelectedImage] = useState<SelectedFile | null>(
		user?.profilePictureUrl
			? {
					id: v4(),
					imageName: user.profilePictureUrl,
					originalName: "",
					url: user.profilePictureUrl,
					file: undefined,
					uploading: false,
					uploaded: true,
					uploadFailed: false,
					tooBig: false,
					type: "Image",
			  }
			: null,
	)
	const [showPfpTooBig, setShowPfpTooBig] = useState<boolean>(false)
	const [disableClose, setDisableClose] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [submitFailed, setSubmitFailed] = useState<null | "phone" | "email" | "generic">(null)

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<UserForm>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(UserFormSchema)(data, context, options)
		},
		defaultValues: user
			? {
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phoneNumber: user.phoneNumber,
			  }
			: undefined,
	})

	function setFile(file: File & { tooBig?: boolean }) {
		const reader = new FileReader()
		reader.readAsDataURL(file as Blob)
		reader.onload = async () => {
			const res = reader.result
			if (!isNull(res) && (res as string)) {
				const resSplit = split(res as string, ",", 10)
				const res0 = resSplit[0]
				const res1 = resSplit[1]
				if (res0 && res1) {
					const cleanMime = cleanMimeString(res0)
					getImageURLFromBase64(res1, cleanMime).then((res) => {
						const newImage: SelectedFile = {
							id: v4(),
							imageName: file.name,
							originalName: file.name,
							url: res,
							file: file,
							uploading: !!user,
							uploaded: false,
							uploadFailed: false,
							tooBig: false,
							type: "Image",
						}
						setSelectedImage(newImage)
						if (user) {
							uploadProfilePictureForExistingUser(user.id, newImage)
						}
					})
				}
			}
		}
		reader.onerror = (error) => {
			logger.error("File reader onError: ", error)
		}
	}

	function addProfilePictureForConsumerUser(e: ChangeEvent<HTMLInputElement>, accept?: string[]) {
		const file: (File & { tooBig?: boolean }) | null = getFileExt(e, accept)

		if (!file) {
			return
		}
		setShowPfpTooBig(file.tooBig === true)
		if (!file.tooBig) {
			setFile(file)
		}
	}

	function onClose() {
		extOnClose()
	}

	function onDone() {
		if (auth.Me && user && user.id === auth.Me.userId) {
			auth.refreshMeData()
		}
		onClose()
		extOnDone()
	}

	function handleApiRequestRejection(res?: ServerError<any>) {
		if (res?.data?.message) {
			if (res.data.message === "email_in_use") {
				setSubmitFailed("email")
			} else if (res.data.message === "phone_in_use") {
				setSubmitFailed("phone")
			} else {
				setSubmitFailed("generic")
			}
		} else {
			setSubmitFailed("generic")
		}
		setSubmitting(false)
	}

	function patchUser(reqObj: object) {
		if (!user) {
			return
		}
		API.patchWithRetries<void>(`/customer-portal/users-v1/myself/edit`, reqObj, undefined, 30)
			.then(() => {
				onDone()
			})
			.catch(handleApiRequestRejection)
	}

	function createConsumerUser(newForm: FormData) {
		if (!consumerId) {
			return
		}

		API.postWithRetries<GetUser>(
			`/customer-portal/consumer-users-v1/${client.identifier}/${consumerId}/create`,
			newForm,
			undefined,
			30,
			"Raw",
		)
			.then(() => {
				onDone()
			})
			.catch(handleApiRequestRejection)
	}

	function createClientUser(newForm: FormData) {
		API.postWithRetries<GetUser>(`/customer-portal/client-users-v1/${client.identifier}`, newForm, undefined, 30, "Raw")
			.then(() => {
				onDone()
			})
			.catch(handleApiRequestRejection)
	}

	function onSubmit(data: UserForm) {
		setSubmitting(true)
		setSubmitFailed(null)

		if (user) {
			const reqObj: object = {
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
			}
			if (data.phoneNumber) {
				;(reqObj as any).phoneNumber = data.phoneNumber
			}
			patchUser(reqObj)
		} else {
			const newForm = new FormData()
			newForm.append("firstName", data.firstName)
			newForm.append("lastName", data.lastName)
			newForm.append("email", data.email)
			if (data.phoneNumber) {
				newForm.append("phoneNumber", data.phoneNumber)
			}
			if (selectedImage?.file) {
				newForm.append("profilePicture", selectedImage.file)
			}
			if (type === "Client") {
				createClientUser(newForm)
			} else {
				createConsumerUser(newForm)
			}
		}
	}

	function uploadProfilePictureForExistingUser(userId: string, newImage: SelectedFile) {
		if (!newImage.file) {
			return
		}
		setSubmitting(true)
		const newForm = new FormData()
		newForm.append("file", newImage.file)
		API.postWithRetries<GetUser>(`/customer-portal/users-v1/myself/profile-picture`, newForm, undefined, 30, "Raw")
			.then(() => {
				newImage.uploading = false
				newImage.uploaded = true
				setSelectedImage(newImage)
				onProfilePicChange(userId, newImage.url)
				setSubmitting(false)
				setDisableClose(false)
				if (auth.Me && user && user.id === auth.Me.userId) {
					auth.refreshMeData()
				}
			})
			.catch(() => {
				newImage.uploadFailed = true
				newImage.uploading = false
				setSelectedImage(newImage)
				setSubmitting(false)
				setDisableClose(false)
			})
	}

	function profilePicRow() {
		const id = v4()
		return (
			<div className={consumerInformationStyle.profilePicRow}>
				<div style={{ display: "flex", flexDirection: "column", maxWidth: "270px" }}>
					<div className={consumerInformationStyle.profilePic}>
						{selectedImage?.uploading ? (
							<div className={style.pfpUploading}>
								<FontAwesomeIcon spin={true} icon={faSpinner} />
							</div>
						) : null}
						{selectedImage?.url ? (
							<img
								src={selectedImage.url}
								onClick={() => {
									const url = selectedImage?.url
									if (url && !selectedImage?.uploading) {
										window.open(url, "_blank")
									}
								}}
								alt="Profilbild"
							/>
						) : (
							<div className={style.noProfilePic}>Ej vald</div>
						)}
					</div>
					{selectedImage?.uploadFailed ? (
						<div style={{ color: "var(--invalid-color)" }}>Uppladdning misslyckades, vänligen försök igen</div>
					) : null}
				</div>
				<span style={{ display: "flex", flexDirection: "column" }}>
					{!disableEditing ? (
						<>
							<label
								id={id + "_label"}
								htmlFor={id}
								className={consumerInformationStyle.uploadProfilePictureButton}>
								<span className={consumerInformationStyle.text}>
									{user ? "Ladda upp profilbild" : "Välj profilbild"}
								</span>
								<span className={consumerInformationStyle.icon}>+</span>
							</label>
							<input
								id={id}
								style={{ display: "none" }}
								type="file"
								accept=".png,.jpeg,.jpg"
								multiple={true}
								onClick={(e) => {
									const target: HTMLInputElement = e.target as HTMLInputElement
									if (target) {
										target.addEventListener(
											"cancel",
											() => {
												setTimeout(() => {
													setDisableClose(false)
												}, 100)
											},
											{ once: true },
										)
									}
									setDisableClose(true)
								}}
								onChange={(e) => {
									addProfilePictureForConsumerUser(e, ["image/png", "image/jpg", "image/jpeg"])
								}}
							/>
							{showPfpTooBig ? (
								<div style={{ color: "var(--invalid-color)", fontWeight: 500 }}>
									För stor bild, max 10MB
								</div>
							) : null}
						</>
					) : null}
				</span>
			</div>
		)
	}

	function deleteConsumerUser(userId: string) {
		if (!consumerId) {
			return
		}
		API.deleteWithRetries<void>(
			`/customer-portal/consumer-users-v1/${client.identifier}/${consumerId}/delete/${userId}`,
		)
			.then(() => {
				onDone()
			})
			.catch(() => {})
	}

	function deleteClientUser(userId: string) {
		API.deleteWithRetries<void>(`/customer-portal/client-users-v1/${client.identifier}/delete/${userId}`)
			.then(() => {
				onDone()
			})
			.catch(() => {
				setDisableClose(false)
			})
	}

	function deleteCurrentUser(userId: string) {
		if (!canRemoveUser()) {
			return
		}

		setDisableClose(true)

		setTimeout(() => {
			if (window.confirm("Är du säker att du vill ta bort användaren?")) {
				if (type === "Client") {
					deleteClientUser(userId)
				} else {
					deleteConsumerUser(userId)
				}
			} else {
				setTimeout(() => {
					setDisableClose(false)
				}, 200)
			}
		}, 10)
	}

	function canRemoveUser() {
		let permissionAllowed = false

		if (type === "Consumer") {
			permissionAllowed = permissions.isAllowed(PermissionAreaLocation.Consumer_Users_Delete)
		} else if (type === "Client") {
			permissionAllowed = permissions.isAllowed(PermissionAreaLocation.Client_Users_Delete)
		}

		return permissionAllowed && auth.Me && user && user.id !== auth.Me.userId
	}

	return (
		<ModulePopup className={style.modal} onClose={onClose} disableClose={disableClose || submitting}>
			<div className={style.wrapper}>
				<div className={style.header}>{disableEditing ? "Visar användare" : "Redigera användare"}</div>
				{profilePicRow()}
				{disableEditing ? (
					<>
						<div style={{ marginTop: "20px" }} className={consumerInfoStyle.textInputWithLabel}>
							<label>Förnamn</label>
							<span className={consumerInfoStyle.textAsInput} title={user?.firstName}>
								{user?.firstName}
							</span>
						</div>
						<div style={{ marginTop: "20px" }} className={consumerInfoStyle.textInputWithLabel}>
							<label>Efternamn</label>
							<span className={consumerInfoStyle.textAsInput} title={user?.lastName}>
								{user?.lastName}
							</span>
						</div>
						<div style={{ marginTop: "20px" }} className={consumerInfoStyle.textInputWithLabel}>
							<label>E-post</label>
							<span className={consumerInfoStyle.textAsInput} title={user?.email}>
								{user?.email}
							</span>
						</div>
						<div style={{ marginTop: "20px" }} className={consumerInfoStyle.textInputWithLabel}>
							<label>Telefonnummer</label>
							<span className={consumerInfoStyle.textAsInput} title={user?.phoneNumber}>
								{user?.phoneNumber}
							</span>
						</div>
					</>
				) : (
					<form style={{ marginTop: "20px" }} onSubmit={handleSubmit(onSubmit)} id="addEditForm">
						<div className={orderConfirmStyle.fields}>
							<label>
								<SbtH4>Förnamn*</SbtH4>
								<input
									disabled={disableEditing}
									className={orderConfirmStyle.input}
									{...register("firstName")}
									placeholder="Anders"
								/>
								<SbtRHFError error={errors.firstName} />
							</label>
							<label>
								<SbtH4>Efternamn*</SbtH4>
								<input
									disabled={disableEditing}
									className={orderConfirmStyle.input}
									{...register("lastName")}
									placeholder="Andersson"
								/>
								<SbtRHFError error={errors.lastName} />
							</label>
							<label>
								<SbtH4>E-post*</SbtH4>
								<input
									disabled={disableEditing}
									className={orderConfirmStyle.input}
									{...register("email")}
									placeholder="exempel@exempel.se"
								/>
								<SbtRHFError error={errors.email} />
							</label>
							<label>
								<SbtH4>Telefonnummer</SbtH4>
								<input
									disabled={disableEditing}
									className={orderConfirmStyle.input}
									{...register("phoneNumber")}
									placeholder="070 XXX XX XX"
								/>
								<SbtRHFError error={errors.phoneNumber} />
							</label>
						</div>
					</form>
				)}
				{disableEditing ? (
					<p className={orderConfirmStyle.mandatoryFieldInformation} style={{ marginTop: "10px" }}>
						Endast användaren kan redigera sina detaljer
					</p>
				) : (
					<p className={orderConfirmStyle.mandatoryFieldInformation}>*Obligatoriskt fält</p>
				)}
				{canRemoveUser() ? (
					<div
						className={style.deleteUser}
						onClick={() => {
							deleteCurrentUser(user.id)
						}}>
						<TrashIcon
							size={22}
							iconClassName={cls(style.iconColorStroke, style.iconColorFill, {
								[style.disabled]: disableClose,
							})}
						/>
						<span style={{ lineHeight: "16px" }} className={cls({ [style.disabled]: disableClose })}>
							Ta bort användare
						</span>
					</div>
				) : null}
				{submitFailed ? (
					<div style={{ color: "var(--invalid-color)", marginBottom: "10px" }}>
						{submitFailed === "phone"
							? "Det valda telefonnummret är redan kopplat till ett annat konto, vänligen försök igen"
							: submitFailed === "email"
							? "Den valda e-posten är redan kopplad till ett annat konto, vänligen försök igen"
							: "Något gick fel, vänligen försök igen"}
					</div>
				) : null}
				{!disableEditing ? (
					<FinalizeButton
						className={style.finalize}
						onClick={() => {
							;(document.getElementById("addEditForm") as HTMLFormElement).requestSubmit()
						}}
						disabled={
							!isValid || showPfpTooBig || submitting || (type === "Consumer" && !user ? !consumerId : false)
						}
						type="submit">
						Spara
					</FinalizeButton>
				) : null}
			</div>
		</ModulePopup>
	)
}
