import { FC, PropsWithChildren } from "react"
import { cls } from "../../../../Shared/cls"
import style from "./SbtH6.module.css"

type Props = PropsWithChildren & {
	className?: string
}

/* SBT - H6 14pt semibold */
export const SbtH6: FC<Props> = ({ className, children }) => {
	return <h6 className={cls(style.sbtH6, className)}>{children}</h6>
}
