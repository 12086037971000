import { faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEmpty } from "lodash"
import { ReactComponent as PoweredBySkrappy } from "Orders/Layout/PoweredBySkrappy.svg"
import { FC, useEffect, useRef, useState } from "react"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { cls } from "../../../Shared/cls"
import { useHorizontalOverflowDetector } from "../../../Shared/useHorizontalOverflowDetector"
import style from "./Footer.module.css"

type Props = {
	className?: string
}
export const Footer: FC<Props> = ({ className }) => {
	const client = useClient()

	let [showLinksOverlay, setShowLinksOverlay] = useState(false)

	const overflowRef = useRef<HTMLDivElement | null>(null)
	const overflow = useHorizontalOverflowDetector(overflowRef)

	useEffect(() => {
		/**
		 * Hide the overlay, if it was open when the browser was resized
		 */
		if (!overflow && showLinksOverlay) {
			setShowLinksOverlay(false)
		}
	}, [overflow, showLinksOverlay])

	let footerLinks = client.links?.footerLinks ?? []
	let hasFooterLinks = !isEmpty(footerLinks)
	return (
		<footer className={cls(style.footerRow, className)}>
			<div className={style.footerInnerWrapper}>
				<div className={style.poweredBy}>
					<PoweredBySkrappy className={style.logo} />
				</div>
				{hasFooterLinks ? (
					<>
						<div className={style.links} ref={overflowRef}>
							{footerLinks?.map((link, index) => {
								return (
									<a
										title={link.url}
										href={link.url}
										rel="noreferrer"
										target="_blank"
										className={style.footerLink}
										key={index}>
										{link.name}
									</a>
								)
							})}
						</div>
						{overflow ? (
							<div className={style.overflowedWrapper}>
								<span>...</span>
								<FontAwesomeIcon
									className={cls(style.overflowedFolder, { [style.overlayOpen]: showLinksOverlay })}
									icon={faAngleUp}
									onClick={() => setShowLinksOverlay(!showLinksOverlay)}
								/>
								{showLinksOverlay ? (
									<div className={style.overflowLinksOverlay}>
										{footerLinks?.map((link, index) => {
											return (
												<a
													title={link.url}
													href={link.url}
													rel="noreferrer"
													target="_blank"
													className={style.footerLink}
													key={index}>
													{link.name}
												</a>
											)
										})}
									</div>
								) : null}
							</div>
						) : null}
					</>
				) : null}
			</div>
		</footer>
	)
}
