import { exhaustive } from "exhaustive"
import { every, some } from "lodash"
import React, { FC } from "react"
import { Route, Routes, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { SelectedConsumerUserSchema } from "../../Client/ConsumerContext"
import { ProductSelectionMode } from "../../Client/FeatureTypes"
import { NavigatorNavigate } from "../../Navigator/NavigatorNavigate"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { OrderConfirmCheckout } from "../Components/OrderConfirmCheckout/OrderConfirmCheckout"
import { OrderItemProduct } from "../order-data-model"
import { OrderCompleted } from "../OrderCompleted/OrderCompleted"
import { useBasket } from "./BasketProvider"
import { FullPageProductSelection } from "./FullPageProductSelection"
import { OpenModalTypes, OrderContainer } from "./OrderContainer"
import { BasketSection } from "./SubComponents/BasketSection"

type Props = {}

export const modalOpenQueryKey = "m_op"
export const modalOpenOrderItemIndexKey = "oi_idx"

export const OrderContainerWrapper: FC<Props> = () => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()
	const consumerCatalog = useConsumerCatalog()
	const basket = useBasket()

	const [queryParams, setQueryParams] = useSearchParams()
	const queriedProductId = queryParams.get("product")

	const selectedCategory = client.findCategoryByName(queryParams.get("category"))

	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})
	const [selectedUserLocalstorage] = useBrandedLocalStorage(
		"client-selected-consumer-user",
		z.string().or(SelectedConsumerUserSchema),
		{
			defaultValue: "",
		},
	)

	const openProduct = queriedProductId && selectedCategory ? selectedCategory.products[queriedProductId] : null
	let showFullPageProductConfig = false

	if (client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage && selectedCategory) {
		exhaustive(selectedCategory, "type", {
			GoodsCategory: () => {
				showFullPageProductConfig = true
			},
			WasteCategory: (it) => {
				showFullPageProductConfig = !!it.productSelectionConfig && it.productSelectionConfig.steps.length > 0
			},
		})
	}

	function canRenderOrderCheckout() {
		const somePricesAreCalculated = consumerCatalog.pricesEnabled
			? Object.keys(basket.values.orderItemTotalPrices).length > 0
			: true
		const clientUserOrSomePricesAreCalculated = auth.IsLoggedInClient || somePricesAreCalculated
		const submitEnabled = !submitDisabled()
		const allowedToOrder = auth.IsLoggedIn ? true : client.features.orderUiAllowAnonymousOrders
		const allowedPermission = permissions.isAllowed(PermissionAreaLocation.Order_Process_Start)

		return (
			basket.orderItems && clientUserOrSomePricesAreCalculated && submitEnabled && allowedToOrder && allowedPermission
		)
	}

	function submitDisabled() {
		let hasNoOrderItems = basket.orderItems.length < 1
		let someThingSomeThingConsumerSelected =
			auth.IsLoggedIn && clientSelectedConsumerId ? !selectedUserLocalstorage : false
		let everyOrderHasProductsAndDateAndTime = every(
			basket.orderItems,
			(item) => item.products.length > 0 && item.date && item.time,
		)
		let anOrderIsMissingProductOrDateOrTime = !everyOrderHasProductsAndDateAndTime
		let anyOrderItemHasProductWithMissingArticles = some(basket.orderItems, (orderItem) =>
			some(orderItem.products, (product: OrderItemProduct) => {
				if (product.articles) {
					return product.articles.isEmpty()
				}
				return true
			}),
		)
		let userIsClient = auth.IsLoggedInClient

		let requiredPricesAreMissing =
			!userIsClient && consumerCatalog.pricesEnabled && anyOrderItemHasProductWithMissingArticles

		return (
			someThingSomeThingConsumerSelected ||
			hasNoOrderItems ||
			anOrderIsMissingProductOrDateOrTime ||
			requiredPricesAreMissing
		)
	}

	function selectOrderItem(index: number) {
		if (!(openProduct != null && selectedCategory && showFullPageProductConfig)) {
			const item = basket.orderItems[index]

			setQueryParams((oldParams) => {
				if (!item) {
					return oldParams
				}
				const newParams = new URLSearchParams(oldParams)

				if (item && oldParams.get("category") !== item.category) {
					newParams.set("category", item.category)
				}

				if (item.serviceId && oldParams.get("service") !== item.serviceId) {
					newParams.set("service", item.serviceId)
				}

				return newParams
			})
		}

		basket.functions.setSelectedOrderItem(index)
	}

	return (
		<Routes key={"orderContainer_route1"}>
			<Route
				key={"orderContainer_route2"}
				path=""
				element={
					<React.Fragment key={"orderContainer_route3"}>
						{openProduct != null && selectedCategory && showFullPageProductConfig ? (
							<FullPageProductSelection
								product={openProduct}
								category={selectedCategory}
								onCancel={() => {
									queryParams.delete("product")
									setQueryParams(queryParams)
								}}
							/>
						) : (
							<OrderContainer />
						)}

						<BasketSection
							hideShowBasketButton={openProduct != null && !!selectedCategory && showFullPageProductConfig}
							submitDisabled={submitDisabled()}
							onOrderItemSelected={(val) => selectOrderItem(val)}
							onOrderItemEditProject={(index) => {
								queryParams.set(modalOpenQueryKey, OpenModalTypes.OrderItemEditProject)
								queryParams.set(modalOpenOrderItemIndexKey, index.toString())
								setQueryParams(queryParams)
							}}
							onRemoveOrderItem={(index) => {
								basket.functions.removeOrderItem(index)
							}}
							onOrderItemEditDate={(index) => {
								queryParams.set(modalOpenQueryKey, OpenModalTypes.OrderItemEditDate)
								queryParams.set(modalOpenOrderItemIndexKey, index.toString())
								setQueryParams(queryParams)
							}}
							onOrderItemEditTime={(index) => {
								queryParams.set(modalOpenQueryKey, OpenModalTypes.OrderItemEditTime)
								queryParams.set(modalOpenOrderItemIndexKey, index.toString())
								setQueryParams(queryParams)
							}}
							onRemoveProductFromOrderItem={basket.functions.removeProductFromOrderItem}
							onOrderItemProductIncrementorChange={basket.functions.onOrderItemProductIncrementorChange}
						/>
					</React.Fragment>
				}
			/>
			{canRenderOrderCheckout() ? (
				<Route key={"orderContainer_route4"} path="checkout/*" element={<OrderConfirmCheckout />} />
			) : (
				<Route key={"orderContainer_route5"} path="checkout/*" element={<NavigatorNavigate to={"order"} />} />
			)}
			<Route key={"orderContainer_route6"} path="completed/:collectionNumber" element={<OrderCompleted />} />
		</Routes>
	)
}
