import _ from "lodash"
import { Article, ArticleType } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { ExpectedNewOrderArticle } from "../../Orders/api/NewOrderApi"
import { ArticleTree } from "./ArticleTreeDataModel"
import { toShortArticlePath } from "./ArticleTreeResolver"

export type ArticleAndTree = {
	article: Article
	tree: ArticleTree
}

/**
 * A helper class for keeping a list of Articles and Trees.
 */
export class Articles {
	static empty(): Articles {
		return new Articles([])
	}

	constructor(private articlesAndTrees: ArticleAndTree[]) {}

	get listAll() {
		return this.articlesAndTrees
	}

	get allArticles() {
		return this.articlesAndTrees.map((it) => it.article)
	}

	isEmpty() {
		return _.isEmpty(this.articlesAndTrees)
	}

	getProductArticle() {
		return this.getResolvedArticleForTreeType(ArticleType.Product)
	}

	getTransportationArticles() {
		return this.getResolvedArticlesForTreeType(ArticleType.Transport)
	}

	getDateSlotArticle() {
		return this.getResolvedArticleForTreeType(ArticleType.DateSlot)
	}

	getDiscountArticles() {
		return this.getAllResolvedArticleForTreeType(ArticleType.Discount)
	}

	private getAllResolvedArticleForTreeType(type: ArticleType) {
		return this.articlesAndTrees.filter((x) => x.article.type === type)
	}

	private getResolvedArticleForTreeType(type: ArticleType) {
		let leafAndTree = this.articlesAndTrees.find((x) => x.article.type === type)

		if (leafAndTree == null) return null

		return leafAndTree.article
	}

	private getResolvedArticlesForTreeType(type: ArticleType) {
		return this.articlesAndTrees.filter((x) => x.article.type === type)
	}

	public toExpectedArticles(): ExpectedNewOrderArticle[] {
		return this.articlesAndTrees.map((value) => {
			const article = value.article
			return {
				articlePath: toShortArticlePath(article.articlePath),
				price: article.price.toFixed(2),
				taxPercent: article.taxPercentage.toFixed(4),
			}
		})
	}
}
