import { ProductFromAPI } from "../../OrderCollection"
import style from "./ProductListing.module.css"

type Props = {
	products: ProductFromAPI[]
}

export const ProductListing = ({ products }: Props) => {
	return (
		<table className={style.productTable}>
			<thead>
				<tr>
					<th>Produkt</th>
					<th>Tjänst</th>
					<th>Avfallstyp</th>
					<th>Antal</th>
				</tr>
			</thead>
			<tbody>
				{products.map((product, index) => (
					<tr className={style.listRow} key={index}>
						<td>{product.name}</td>
						<td>{product.service?.name ?? "Leverans"}</td>
						<td>{product.wasteType?.name ?? "-"}</td>
						<td id="amount">{product.amount} st</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
