import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { FC, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { invalidPhoneNumberMessage, validatePhoneNumber } from "../../Orders/order-data-model"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import editUserStyle from "../EditUser/EditUser.module.css"
import { GetUser } from "../Users/Users"
import style from "./EditClientContactDetails.module.css"

export const ClientContactDetailsFormSchema = z.object({
	contactInfoName: z.string().optional(),
	website: z.string().optional(),
	orderEmail: z.string().email("Felaktigt format på e-post").optional(),
	phoneNumber: z
		.string()
		.optional()
		.transform((x) => (!x ? null : x))
		.refine(
			(data) => {
				if (data === null) {
					return true
				}
				return validatePhoneNumber(data)
			},
			{ message: invalidPhoneNumberMessage },
		),
	email: z.string().email("Felaktigt format på e-post").optional(),
	street: z.string().optional(),
	zipCode: z.string().optional(),
	city: z.string().optional(),
})

type ClientContactDetailsForm = z.input<typeof ClientContactDetailsFormSchema>

type EditClientContactDetailsProps = {
	onClose: () => void
	onDone: () => void
}

type EditClientInfo = {
	phoneNumber?: string | null
	email?: string
	website?: string
	street?: string
	zipCode?: string
	city?: string
	contactInfoName?: string
	orderEmail?: string
}
export const EditClientContactDetails: FC<EditClientContactDetailsProps> = ({ onClose, onDone }) => {
	const client = useClient()
	const permissions = usePermissions()
	const [submitting, setSubmitting] = useState(false)
	const [submitFailed, setSubmitFailed] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<ClientContactDetailsForm>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(ClientContactDetailsFormSchema)(data, context, options)
		},
		defaultValues: {
			contactInfoName: client.clientInfo.contact.name,
			website: client.clientInfo.homepage,
			orderEmail: client.clientInfo.contact.orderEmail,
			phoneNumber: client.clientInfo.contact.phoneNumber,
			email: client.clientInfo.contact.email,
			street: client.clientInfo?.address?.street,
			zipCode: client.clientInfo?.address?.zipCode,
			city: client.clientInfo?.address?.city,
		},
	})

	function onSubmit(data: ClientContactDetailsForm) {
		if (!permissions.isAllowed(PermissionAreaLocation.Client_Information_Update)) {
			return
		}

		setSubmitting(true)
		setSubmitFailed(false)
		const reqObj: EditClientInfo = {
			email: data.email,
			website: data.website,
			street: data.street,
			zipCode: data.zipCode,
			city: data.city,
			contactInfoName: data.contactInfoName,
			orderEmail: data.orderEmail,
		}

		if (data.phoneNumber && data.phoneNumber !== client.clientInfo.contact.phoneNumber) {
			reqObj.phoneNumber = data.phoneNumber
		} else if (!data.phoneNumber) {
			reqObj.phoneNumber = null
		}

		API.patchWithRetries<GetUser>(`/customer-portal/clients-v1/${client.identifier}/edit`, reqObj)
			.then(() => {
				onDone()
			})
			.catch(() => {
				setSubmitFailed(true)
				setSubmitting(false)
			})
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Information_Update)) {
		return null
	}

	return (
		<ModulePopup className={style.modal} onClose={onClose} disableCloseWithEsc={submitting}>
			<div className={editUserStyle.wrapper}>
				<div className={editUserStyle.header}>Redigera kontaktdetaljer</div>
				<form
					className={style.form}
					style={{ marginTop: "20px" }}
					onSubmit={handleSubmit(onSubmit)}
					id="addEditContactDetailsForm">
					<div className={orderConfirmStyle.fields}>
						<label>
							<SbtH4>Visningsnamn</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("contactInfoName")}
								placeholder="Exempel AB"
							/>
							<SbtRHFError error={errors.contactInfoName} />
						</label>
						<label>
							<SbtH4>Hemsida</SbtH4>
							<input className={orderConfirmStyle.input} {...register("website")} placeholder="exempel.se" />
							<SbtRHFError error={errors.website} />
						</label>
						<label>
							<SbtH4>E-post för ordermottagning</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("orderEmail")}
								placeholder="exempel@exempel.se"
							/>
							<SbtRHFError error={errors.orderEmail} />
						</label>
						<label>
							<SbtH4>Telefonnummer</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("phoneNumber")}
								placeholder="070 XXX XX XX"
							/>
							<SbtRHFError error={errors.phoneNumber} />
						</label>
						<label>
							<SbtH4>E-post</SbtH4>
							<input
								className={orderConfirmStyle.input}
								{...register("email")}
								placeholder="exempel@exempel.se"
							/>
							<SbtRHFError error={errors.email} />
						</label>

						<label>
							<SbtH4>Besöksadress</SbtH4>
							<input className={orderConfirmStyle.input} {...register("street")} placeholder="Gatan 2" />
							<SbtRHFError error={errors.street} />
						</label>
						<label>
							<SbtH4>Postnummer</SbtH4>
							<input className={orderConfirmStyle.input} {...register("zipCode")} placeholder="123 45" />
							<SbtRHFError error={errors.zipCode} />
						</label>
						<label>
							<SbtH4>Postort</SbtH4>
							<input className={orderConfirmStyle.input} {...register("city")} placeholder="Stockholm" />
							<SbtRHFError error={errors.city} />
						</label>
					</div>
					{submitFailed ? (
						<div style={{ color: "var(--invalid-color)", marginBottom: "10px" }}>
							Något gick fel, vänligen försök igen
						</div>
					) : null}
					<FinalizeButton
						onClick={() => {
							;(document.getElementById("addEditContactDetailsForm") as HTMLFormElement).requestSubmit()
						}}
						disabled={!isValid || submitting}
						type="button">
						Spara
						{submitting ? (
							<>
								{"    "}
								<FontAwesomeIcon spin={true} icon={faSpinner} />
							</>
						) : null}
					</FinalizeButton>
				</form>
			</div>
		</ModulePopup>
	)
}
