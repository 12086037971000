import { ReactNode } from "react"
import { ModulChooserButton } from "../Orders/Components/ModulChooserButton/ModulChooserButton"
import style from "./SidebarBottomAction.module.css"

type Props = {
	title: string
	description?: string
	narrowTitle: string
	buttonText: string
	buttonIcon: ReactNode
	buttonAction: () => void
}

export function SidebarBottomAction({ title, description, narrowTitle, buttonIcon, buttonText, buttonAction }: Props) {
	return (
		<div>
			<div className={style.infoText}>
				<div className={style.title}>{title}</div>
				{description ? <div className={style.description}>{description}</div> : null}
			</div>
			<div className={style.narrowTitle}>{narrowTitle}</div>
			<ModulChooserButton className={style.downloadButton} selected={false} onClick={buttonAction}>
				<span className={style.downloadText}>{buttonText}</span>
				<span className={style.downloadIcon}>{buttonIcon}</span>
			</ModulChooserButton>
		</div>
	)
}

type WrapperProps = {
	children: ReactNode
}

export function SidebarBottomActionWrapper({ children }: WrapperProps) {
	return <div className={style.wrapper}>{children}</div>
}
