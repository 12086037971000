/**
 * @format
 */

import { OrderDateTime } from "../../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { Address } from "../../OrderCollection"

export const dayOfWeek = (date: string): string => {
	switch (new Date(date).getDay()) {
		case 0:
			return "Söndag"
		case 1:
			return "Måndag"
		case 2:
			return "Tisdag"
		case 3:
			return "Onsdag"
		case 4:
			return "Torsdag"
		case 5:
			return "Fredag"
		case 6:
			return "Lördag"
		default:
			return ""
	}
}

export const parseAddress = (address: Address): Array<string> => {
	return [`${address.street}`, `${address.city}`]
}

export const getDeliveryTime = (datetime: OrderDateTime): string => {
	if (!datetime.timeExact) {
		return datetime.timeDescription.toLowerCase()
	}

	// Below is really not pretty, but as it's delivered as a string, including seconds,
	// and as there is no elegant way to parse DateTime in Javascript, keep it as this for now.
	return `kl. ${datetime.timeExact.substring(0, 5)}`
}
