import { useEffect, useState } from "react"

type CountdownProps = {
	count: number
	done: () => void
}

export function Countdown(props: CountdownProps) {
	const [count, setCount] = useState(props.count)

	useEffect(() => {
		let timeout = window.setTimeout(() => {
			if (count <= 0) {
				props.done()
			} else {
				setCount((prev) => {
					return prev - 1
				})
			}
		}, 1000)

		return () => {
			window.clearInterval(timeout)
		}
	}, [count, props])

	return <span>{count}</span>
}
