export type UniqueArticleTreeId = string

export type ApiArticleTree = {
	id: UniqueArticleTreeId
	version: number
} & ArticleTree

export type ArticleTree = {
	name: string
	pathKey: string
	treeType: ArticleTreeType
	branches: Branch[]
	appendix?: Appendix
}

export enum ArticleTreeType {
	StaticProductPricing = "StaticProductPricing",
	StaticTransportationPricing = "StaticTransportationPricing",
	StaticDateSlotPricing = "StaticDateSlotPricing",
	Discounts = "Discounts",
}

export type Branch = {
	name: string
	pathKey: string
	condition: BranchCondition
	leaf?: Leaf
	typedLeaf?: TypedLeaf
	branches: Branch[] | null
}

export type BranchCondition = Equals

export type Equals = {
	type: "Equals"
	key: BranchTargetKeyEnum
	value: string
}

export type Leaf = {
	price: number // price for each unit of measure
	taxPercentage: number
	externalArticles?: ExternalArticle[]
}

export type TypedLeaf = DiscountRef

export type DiscountRef = {
	type: "DiscountRef"
	ref: DiscountDefinitionId
}

type ExternalArticle = FortnoxArticle

export type FortnoxArticle = {
	type: "FortnoxArticle"
	articleId: string
}

//enum class UnitOfMeasureEnum {
//	Unit, //Price is per unit, or per piece
//	PerKg,
//	PerTon,
//}

export enum BranchTargetKeyEnum {
	Tree = "Tree",
	Category = "Category",
	Service = "Service",
	Timeslot = "Timeslot",
	Transportation = "Transportation",
	ProductDefinition = "ProductDefinition",
	WasteType = "WasteType",
	Zone = "Zone",
	PackagingMethod = "PackagingMethod",
	DateSlot = "DateSlot",
	DiscountTemplate = "DiscountTemplate",
}

export type TreeSearchObject = Map<BranchTargetKeyEnum, string | null | undefined>

export type Appendix = {
	discounts?: DiscountDefinition[]
}

export type DiscountDefinitionId = string & { isDiscountDefinitionId: true }

export type DiscountDefinition = ThresholdQuantityDiscounts | SingleValueDiscount

export enum DiscountTarget {
	Order = "Order",
	Product = "Product",
}
/**
 * A type of discount based on the summarized quantity of all products that match the search criteria.
 * Ex.
 * 2 Bags of Gravel, 2 Bags of Dirt would result a quantity of 4, and thus the thresholds matching would be used.
 */
export type ThresholdQuantityDiscounts = {
	type: "ThresholdQuantityDiscounts"
	id: DiscountDefinitionId
	name: string
	target: DiscountTarget
	pathKey: string
	thresholds: Threshold[]
}

export type Threshold = {
	start: number
	stop?: number
	discount: DiscountRate
}

export type SingleValueDiscount = {
	type: "SingleValueDiscount"
	id: string
	name: string
	target: DiscountTarget
	pathKey: string
	discount: DiscountRate
	description: string
}

export type DiscountRate = DiscountRateOnEach | DiscountRateOnTotal

export type DiscountRateOnEach = (PercentageDiscount | PerItemAmountDiscount) & {
	subType: "OnEach"
}
export type DiscountRateOnTotal = FixedAmountDiscount & {
	subType: "OnTotal"
}

export type PercentageDiscount = {
	type: "Percentage"
	percent: number
}

export type PerItemAmountDiscount = {
	type: "PerItemAmount"
	price: number
}

export type FixedAmountDiscount = {
	type: "FixedAmount"
	price: number
}

/**
 * The sub classing in BE for DiscountRateOnEach, DiscountRateOnTotal cannot be expressed in the same way with
 * types and get it working during runtime (I think at least), so this mapping table is another way to describe it.
 */
export const DiscountRateSubTypeMapping: Record<DiscountRate["type"], DiscountRate["subType"]> = {
	Percentage: "OnEach",
	PerItemAmount: "OnEach",
	FixedAmount: "OnTotal",
}
