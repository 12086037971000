import { ReactElement } from "react"
import style from "./InfoBlobWithIcon.module.css"

type Props = {
	icon: ReactElement
	firstLine: string
	secondLine?: string
}

export const InfoBlobWithIcon = ({ icon, firstLine, secondLine }: Props) => {
	return (
		<div className={style.wrapper}>
			<div className={style.icon}>{icon}</div>
			<div className={style.info}>
				<div>{firstLine}</div>
				{secondLine ? <div>{secondLine}</div> : null}
			</div>
		</div>
	)
}
