import { FC, PropsWithChildren } from "react"
import style from "./SbtInvalid.module.css"

/*SBT - 14pt invalid semibold */
export const SbtInvalid: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className={style.wrapper}>
			<span className={style.message}>{children}</span>
		</div>
	)
}
