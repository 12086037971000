import { exhaustive } from "exhaustive"
import { DiscountRate } from "../../../Client/articleTrees/ArticleTreeDataModel"
import { getLogger } from "../../../Logging/getLogger"
import { AnonymousOrder } from "../../../Orders/OrderCompleted/AnonymousOrderResponse"
import { lets } from "../../../Shared/lets"
import { StdTooltip } from "../../../Shared/StdTooltip/StdTooltip"
import {
	Article,
	DiscountDescription,
	DiscountDescriptionId,
	DiscountOrigin,
	GetOrder,
} from "../../CustomerPortalOrders/CustomerPortalOrders"

const logger = getLogger("ExplainDiscount")

type DiscountRefExplanationTooltipProps = {
	discountDescriptionId: DiscountDescriptionId
	order: GetOrder | AnonymousOrder
	taxPercentage: number | null
	anchorSelect?: string
}

export function DiscountRefExplanationTooltip({
	discountDescriptionId,
	order,
	taxPercentage,
	anchorSelect,
}: DiscountRefExplanationTooltipProps) {
	let discountDescription = findDiscountDescription(discountDescriptionId, order.orderArticles)

	if (discountDescription == null) {
		logger.error(`Discount Description ${discountDescriptionId} is missing`)
		return null
	}

	return (
		<DiscountExplanationTooltip
			discountDescription={discountDescription}
			anchorSelect={anchorSelect}
			taxPercentage={taxPercentage}
		/>
	)
}

type DiscountExplanationTooltipProps = {
	discountDescription: DiscountDescription | undefined | null
	anchorSelect?: string
	taxPercentage: number | null
}

export function DiscountExplanationTooltip({
	discountDescription,
	anchorSelect,
	taxPercentage,
}: DiscountExplanationTooltipProps) {
	if (discountDescription == null) return null

	const desc = explainedDiscountDescriptionDetails(discountDescription, taxPercentage)
	return (
		<StdTooltip anchorSelect={anchorSelect} clickable place={"top-start"}>
			<h1>{discountDescription.name}</h1>
			{desc}
		</StdTooltip>
	)
}

/**
 * Used to guesstimate if the start and stop interval can be considered as "one discrete step".
 */
const APPROXIMATELY_ONE_FOR_DISCRETE_UNITES = 1.0001

function explainedDiscountDescriptionDetails(discountDescription: DiscountDescription, taxPercentage: number | null) {
	return exhaustive(discountDescription, "type", {
		ThresholdQuantityDiscount: (discountDescription) => {
			//FIXME, explanation here is only valid for discrete integers, add a unit here
			let intervalExplained = lets(
				discountDescription?.stop,
				(stop) => {
					let intervalGap = stop - discountDescription.start
					if (intervalGap < APPROXIMATELY_ONE_FOR_DISCRETE_UNITES) {
						return `för ${discountDescription.start} st`
					}
					return `från och med ${discountDescription.start} st till och med ${stop - 1} st`
				},
				() => {
					return `för ${discountDescription.start} st och flera`
				},
			)

			const discountRateExplained = explainDiscountRate(discountDescription.discountRate, 2, taxPercentage)

			return (
				<>
					<p>
						Rabatten ger {discountRateExplained}, gäller {intervalExplained}.
					</p>
					{discountDescription.discountOrigin === DiscountOrigin.Code ? (
						<p>Rabatten kommer från en rabattkod.</p>
					) : null}
				</>
			)
		},
		SingleValueDiscount: (discountDescription) => {
			const discountRateExplained = explainDiscountRate(discountDescription.discountRate, 2, taxPercentage)
			return (
				<>
					<p>Rabatten ger {discountRateExplained}.</p>
					{discountDescription.discountOrigin === DiscountOrigin.Code ? (
						<p>Rabatten kommer från en rabattkod.</p>
					) : null}
				</>
			)
		},
	})
}

export function findDiscountDescription(discountDescriptionRef: DiscountDescriptionId, orderArticles: Article[]) {
	return orderArticles.find((it) => it.discountDescription?.id === discountDescriptionRef)?.discountDescription
}

export function discountOneLineSummaryFromRef(
	discountDescriptionRef: DiscountDescriptionId,
	orderArticles: Article[],
	taxPercentage: number | null,
	percentDigits: number,
) {
	const discountDescription = findDiscountDescription(discountDescriptionRef, orderArticles)
	return discountOneLineSummary(discountDescription, taxPercentage, percentDigits)
}

export function discountOneLineSummary(
	discountDescription: DiscountDescription | undefined | null,
	taxPercentage: number | null,
	percentDigits: number,
) {
	if (discountDescription == null) return null
	return exhaustive(discountDescription, "type", {
		ThresholdQuantityDiscount: (discountDescription) => {
			let intervalGap = (discountDescription?.stop ?? Number.MAX_SAFE_INTEGER) - discountDescription.start

			let intervalExplained =
				intervalGap >= APPROXIMATELY_ONE_FOR_DISCRETE_UNITES
					? () => {
							let stopStr = lets(discountDescription.stop, (it) => `-${it - 1}`) ?? "+"
							return `${discountDescription.start}${stopStr} st`
					  }
					: () => `${discountDescription.start} st`

			let discountRate = explainDiscountRate(discountDescription.discountRate, percentDigits, taxPercentage)
			return `${discountDescription.name} (${intervalExplained()}, ${discountRate})`
		},
		SingleValueDiscount: (discountDescription) => {
			let discountRate = explainDiscountRate(discountDescription.discountRate, percentDigits, taxPercentage)
			return `${discountDescription.name} ${discountRate}`
		},
	})
}

export const explainDiscountRate = (discountRate: DiscountRate, percentDigits: number, taxPercentage: number | null) =>
	exhaustive(discountRate, "type", {
		Percentage: (discountRate) => `${(discountRate.percent * 100).toFixed(percentDigits)} %`,
		FixedAmount: (discountRate) => {
			if (taxPercentage == null || taxPercentage === 0) {
				return `${discountRate.price} kr exkl. moms`
			}
			return `${discountRate.price * (1 + taxPercentage)} kr inkl. moms`
		},
		PerItemAmount: (discountRate) => {
			if (taxPercentage == null || taxPercentage === 0) {
				return `${discountRate.price} kr/st exkl. moms`
			}
			return `${discountRate.price * (1 + taxPercentage)} kr/st inkl. moms`
		},
	})
