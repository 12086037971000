import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api"
import React, { FC, PropsWithChildren } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { GetOrder } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { GetProject } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { objectOfError } from "../../ErrorBoundary/objectOfError"
import { getLogger } from "../../Logging/getLogger"
import { addModalOpenClass, removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { Project } from "../../Orders/order-data-model"
import { AnonymousOrder } from "../../Orders/OrderCompleted/AnonymousOrderResponse"
import { markerDefaultOptions } from "../../Orders/ProjectInputModule/ProjectInputModule"
import style from "./GoogleMapComponent.module.css"

const loggerExtFunctions = getLogger("GoogleMapComponent")

declare global {
	interface Document {
		mozCancelFullScreen?: () => Promise<void>
		msExitFullscreen?: () => Promise<void>
		webkitExitFullscreen?: () => Promise<void>
		mozFullScreenElement?: Element
		msFullscreenElement?: Element
		webkitFullscreenElement?: Element
		onwebkitfullscreenchange?: any
		onmsfullscreenchange?: any
		onmozfullscreenchange?: any
	}

	interface HTMLElement {
		msRequestFullScreen?: () => Promise<void>
		mozRequestFullScreen?: () => Promise<void>
		webkitRequestFullScreen?: () => Promise<void>
	}
}

export function addAddressAndExactDeliveryMarkersToMap(
	map: google.maps.Map,
	data: AnonymousOrder | GetOrder | Project | GetProject,
): google.maps.marker.AdvancedMarkerElement[] {
	const ret: google.maps.marker.AdvancedMarkerElement[] = []

	let coordinates
	if ("address" in data) {
		// AnonymousOrder | GetOrder | GetProject
		coordinates = data.address.coordinates
	} else {
		// Project (localstorage)
		coordinates = data.coordinates
	}

	let deliveryCoordinates: google.maps.LatLngLiteral | null = null

	if ("execution" in data) {
		// AnonymousOrder | GetOrder
		deliveryCoordinates = data.execution?.deliveryCoordinates || null
	} else if ("deliveryCoordinates" in data) {
		// Project (localstorage)
		deliveryCoordinates = data.deliveryCoordinates || null
	} // else, nothing, GetProject (from backend) has no delivery coordinates

	if (coordinates) {
		try {
			const marker = new google.maps.marker.AdvancedMarkerElement(markerDefaultOptions("Vald adress"))
			marker.map = map
			marker.position = coordinates
			ret.push(marker)
		} catch (e) {
			loggerExtFunctions.error("Failed to set address marker 1 on map", e)
		}
	}

	if (deliveryCoordinates) {
		try {
			const marker2 = new google.maps.marker.AdvancedMarkerElement(markerDefaultOptions("Önskad leveransplats", true))
			marker2.map = map
			marker2.position = deliveryCoordinates
			ret.push(marker2)
		} catch (e) {
			loggerExtFunctions.error("Failed to set address marker 2 on map", e)
		}
	}
	return ret
}

export function maybeSetMapBoundsForExactDelivery(
	map: google.maps.Map,
	data: AnonymousOrder | GetOrder | Project,
	padding?: google.maps.Padding,
) {
	if (!map) {
		return
	}

	const coordinates = "address" in data ? data.address.coordinates : data.coordinates
	const deliveryCoordinates = "address" in data ? data?.execution?.deliveryCoordinates : data.deliveryCoordinates

	if (!deliveryCoordinates) {
		return
	}

	const bounds = new google.maps.LatLngBounds()

	if (coordinates) {
		bounds.extend(coordinates)
	}

	if (deliveryCoordinates) {
		bounds.extend(deliveryCoordinates)
	}

	map.fitBounds(bounds, padding || { top: 100, left: 60, right: 60, bottom: 30 })
}

// Custom fullscreen control copied and modified from https://developers.google.com/maps/documentation/javascript/examples/control-replacement
// Since mobile devices don't always support regular fullscreen mode, we have to have our own version as fallback
// Otherwise, google just removes the button, which is kinda annoying
export function initGoogleMapsCustomFullscreenControl(map: google.maps.Map) {
	const mapDiv = map.getDiv()
	if (!mapDiv) {
		return
	}
	const elementToSendFullscreen = map.getDiv().firstChild as HTMLElement
	if (!elementToSendFullscreen) {
		return
	}
	const fullscreenControl = document.createElement("button")
	fullscreenControl.innerHTML = renderToStaticMarkup(
		<>
			<FontAwesomeIcon className="expandIcon" color="black" size={"2x"} icon={faExpand} />{" "}
			<FontAwesomeIcon className="compressIcon" color="black" size={"2x"} icon={faCompress} />
		</>,
	)
	fullscreenControl.classList.add("google-maps-custom-fullscreen-button")

	map.controls[google.maps.ControlPosition.RIGHT_TOP]?.push(fullscreenControl)

	fullscreenControl.onclick = function () {
		if (document.fullscreenEnabled) {
			if (isFullscreen(elementToSendFullscreen)) {
				exitFullscreen()
				map.setOptions({ gestureHandling: "cooperative" })
			} else {
				requestFullscreen(elementToSendFullscreen)
				map.setOptions({ gestureHandling: "greedy" })
			}
		} else {
			if (!elementToSendFullscreen.parentElement) {
				return
			}

			const customHiddenElements = document.getElementsByClassName(
				"google-maps-custom-hidden-fullscreen",
			) as HTMLCollectionOf<HTMLElement>
			if (fullscreenControl.classList.contains("is-fullscreen")) {
				map.setOptions({ gestureHandling: "cooperative" })
				fullscreenControl.classList.remove("is-fullscreen")
				elementToSendFullscreen.parentElement.classList.remove("google-map-container-custom-fullscreen")
				removeModalOpenClass()
				for (let customHiddenElement of customHiddenElements) {
					customHiddenElement.style.display = "unset"
				}
			} else {
				map.setOptions({ gestureHandling: "greedy" })
				fullscreenControl.classList.add("is-fullscreen")
				elementToSendFullscreen.parentElement.classList.add("google-map-container-custom-fullscreen")
				addModalOpenClass()
				for (let customHiddenElement of customHiddenElements) {
					customHiddenElement.style.display = "none"
				}
			}
		}
	}

	document.onwebkitfullscreenchange =
		document.onmsfullscreenchange =
		document.onmozfullscreenchange =
		document.onfullscreenchange =
			function () {
				if (isFullscreen(elementToSendFullscreen)) {
					fullscreenControl.classList.add("is-fullscreen")
				} else {
					fullscreenControl.classList.remove("is-fullscreen")
					map.setOptions({ gestureHandling: "cooperative" })
				}
			}
}

function isFullscreen(element: HTMLElement) {
	return (
		(document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement) == element
	)
}

function requestFullscreen(element: HTMLElement) {
	if (element.requestFullscreen) {
		element.requestFullscreen()
	} else if (element.webkitRequestFullScreen) {
		element.webkitRequestFullScreen()
	} else if (element.mozRequestFullScreen) {
		element.mozRequestFullScreen()
	} else if (element.msRequestFullScreen) {
		element.msRequestFullScreen()
	}
}

function exitFullscreen() {
	if (document.exitFullscreen) {
		document.exitFullscreen()
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen()
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen()
	} else if (document.msExitFullscreen) {
		document.msExitFullscreen()
	}
}

type GoogleMapErrorBoundaryProps = PropsWithChildren
type GoogleMapErrorBoundaryState = {
	hasError: boolean
	reloadAttempt: number
}

const logger = getLogger("GoogleMapErrorBoundary")

export class GoogleMapErrorBoundary extends React.Component<GoogleMapErrorBoundaryProps, GoogleMapErrorBoundaryState> {
	constructor(props: any) {
		super(props)
		this.state = { hasError: false, reloadAttempt: 0 }
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		logger.error(`Google maps component threw error`, objectOfError(error), errorInfo)
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className={style.errorWrapper}>
					<div className={style.errorHeader}>
						Hoppsan!
						<br />
						Något gick fel när kartan skulle laddas in.
					</div>
					<p>
						Vi håller på att åtgärda problemet.
						<br />
						Testa att{" "}
						<button
							type="button"
							disabled={this.state.reloadAttempt >= 5}
							onClick={() => {
								this.setState({
									...this.state,
									hasError: false,
									reloadAttempt: this.state.reloadAttempt + 1,
								})
							}}
							style={{ cursor: "pointer" }}>
							ladda om kartan
						</button>{" "}
						eller kom tillbaka senare. Om felet kvarstår, kontakta din leverantör för mer hjälp.
					</p>
				</div>
			)
		}

		return this.props.children
	}
}

type GoogleMapComponentProps = GoogleMapProps

export const GoogleMapComponent: FC<GoogleMapComponentProps> = (props: GoogleMapComponentProps) => {
	return (
		<GoogleMapErrorBoundary>
			<GoogleMap {...props}></GoogleMap>
		</GoogleMapErrorBoundary>
	)
}
