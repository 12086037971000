import { useClient } from "../../Client/ClientAndUserProvider"
import { Sent } from "../../Lottie/AnimationComponents"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { SbtH1 } from "../../Orders/Components/Text/SbtH1/SbtH1"
import style from "./Login.module.css"

type Props = {
	onClose: (data?: string) => void
}

export function PasswordResetSent({ onClose }: Props) {
	const client = useClient()
	return (
		<div className={style.wrapper}>
			<SbtH1 className={style.title}>Skickat</SbtH1>
			<Sent color={client.branding.colors.accent} />
			<p className={style.infoText}>Ett e-postmeddelande med återställningsinstruktioner är på väg.</p>
			<div className={style.bottomSection}>
				<FinalizeButton type="button" onClick={() => onClose("password-reset")}>
					Ok, stäng dialogen
				</FinalizeButton>
			</div>
		</div>
	)
}
