import { SvgComponent, SvgProps } from "SkrappyShared/types/SvgComponent"
import { ReactComponent as MaterialBagCategory } from "./images/Ikon_Material_Säck.svg"
import { ReactComponent as MaterialCategory } from "./images/Ikon_Material_TippTruck.svg"
import { ReactComponent as NoImage } from "./images/NoImage.svg"
import { ReactComponent as BagCategory } from "./images/Thin_Skrappy_Bag.svg"
import { ReactComponent as ContainerCategory } from "./images/Thin_Skrappy_Container.svg"
import { ReactComponent as VesselCategory } from "./images/Thin_Skrappy_Vessel.svg"

export type TypedCategoryImages = "Container" | "Bag" | "Vessel" | "Material" | "MaterialBag"

const imgMapping: Record<TypedCategoryImages, SvgComponent> = {
	Container: ContainerCategory,
	Bag: BagCategory,
	Vessel: VesselCategory,
	Material: MaterialCategory,
	MaterialBag: MaterialBagCategory,
}

type Props = {
	category: TypedCategoryImages | null
} & SvgProps

export function TypedCategoryImage({ category, ...props }: Props) {
	const Component = (category == null ? null : imgMapping[category]) ?? NoImage

	return <Component {...props} />
}

export function getAllCategoryImages() {
	return Object.keys(imgMapping) as TypedCategoryImages[] //Safe cast since the record is private to this component
}
