import { CSSProperties, FC, PropsWithChildren } from "react"
import style from "./RelativeCentered.module.css"

type Props = {
	styles?: CSSProperties
} & PropsWithChildren
export const RelativeCentered: FC<Props> = ({ styles, children }) => {
	return (
		<div className={style.relativeWrapper} style={styles}>
			<div className={style.absoluteWrapper}>{children}</div>
		</div>
	)
}
