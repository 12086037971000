import { z } from "zod"
import { useZodLocalStorage } from "../Shared/useClientLocalStorage"

export const DemoStateSchema = z
	.enum([
		"intro", //Show Intro Form
		"start-survey", //Show DemoStart Form
		"demoing", //Show booking App
		"end-survey", //Show DemoEnd Form
		"optional-address", //Show Optional Address Form
		"thank-you", //Show thank you
		"done", //Done, don't show the demo forms again
	])
	.nullable()
export type DemoState = z.input<typeof DemoStateSchema>

export function useDemoState(clientName: string) {
	return useZodLocalStorage<DemoState>(`${clientName}.demo-start`, null, DemoStateSchema)
}

export type DemoProps = {
	setDemoState: (value: DemoState) => void
}
