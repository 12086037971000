/**
 * @format
 */

import { NotificationState, NotificationType, OrderFromAPI, OrderState } from "./OrderCollection"

export const validKey = (key: string): boolean => {
	if (!key) {
		return false
	}
	const valid: RegExp = /^[0-9a-f]{24}$/ // BSON ObjectId is 24 hexadecimal characters
	return valid.test(key)
}

export const sortOrders = (orders: OrderFromAPI[]): OrderFromAPI[] => {
	const sent = orders.filter(
		(order) => order.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer] === NotificationState.Sent,
	)
	const decided = orders.filter(
		(order) =>
			!order.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer] &&
			(order.state === OrderState.Accepted || order.state === OrderState.Declined),
	)
	const undecided = orders.filter((order) => order.state === OrderState.Created)

	return [...sent, ...decided, ...undecided]
}

export const allSent = (orders: OrderFromAPI[]): boolean => {
	const sent = orders.filter(
		(order) => order.notificationStates[NotificationType.OrderAcceptanceEmailToConsumer] === NotificationState.Sent,
	).length
	return orders.length - sent === 0
}
